.asset-info-container {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  a:active {
      color: #0ec22b !important;
  }
  a:visited {
      color: #0ec22b !important;
  }
  a:link {
      color: #0ec22b !important;
  }
  .higher-info {
      font-size: 13px;
      line-height: 19px;
  }
  .divider-img {
      display: block;
      margin-top: 25px;
      margin-bottom: 10px;
  }
  .asset-title {
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
        span{
            color: #0ec22b;
        }
  }
  @media (max-width: 1025px) {
      width: 35%;
      .divider-img {
          display: none;
      }
  }
  @media (max-width: 1025px) {
      display: none;
  }

  @media (max-width: 600px) {
      margin-right: 0px;
      width: 100%;
      display: flex;
  }
  .green-text p {
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
  }
  .buyButton {
      height: 60px;
      font-family: "Montserrat", sans-serif;
      margin-top: 34px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      background: #0ec22b;
      &.funded{
        background-color: gray !important;
        cursor: default; 
      }
  }
  .buyButton:hover {
      background: #0ec22b;
      box-shadow: 0px 4px 4px rgba(14, 194, 43, 0.24);
      &.funded{
        background: unset;
        box-shadow: none;   
      }
  }
  .buyButton:active {
      background: #089d20;
      box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.08);
  }
  .field-value {
      font-weight: bold;
      margin-top: 25px !important;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
  }
  .field-name {
      font-size: 13px;
      line-height: 19px;
      color: #929292;
  }
  .green-text {
      margin-top: 15px;
      margin-bottom: 5px;
  }

  .line-devider {
      width: 100%;
  }
  .geo {
      display: flex;
      margin-top: 10px;
      margin-bottom: 16px;
      flex-direction: row;
      p {
          margin: 0;
      }
  }
  .geo img {
      margin-right: 5px;
  }
  .green-label {
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
  }
  .green-label p,
  a {
      text-decoration: none;
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 13px;
      color: #0ec22b;
  }
  .green-label a:active {
      color: #0ec22b !important;
  }
  .green-label a:visited {
      color: #0ec22b !important;
  }
  .green-label a:link {
      color: #0ec22b !important;
  }
  .green-label.green-label svg {
      margin-right: 10px;
  }
  .optionalField {
      width: 50%;
      display: inline-block;
  }
  .optionalField2 {
      width: 50%;

      display: inline-block;
      text-align: right;
      @media (max-width: 420px) {
          text-align: initial;
      }
  }
  .inner-optional {
      display: flex;
      flex-direction: column;
      p {
          margin: 0;
      }
  }
}