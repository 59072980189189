.info-containers {
    display: flex;
    flex-direction: column-reverse;
}

.asset-info-container-ipad {
    display: none;

    @media (max-width: 1025px) {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 600px) {
        display: none;
    }

    .ipad-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .higher-info-ipad {
        width: 40%;
    }

    .main-ipad-info {
        width: 40%;
        margin-right: 40px;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .GREEN-text p {
        font-weight: bold;
        font-size: 13px;
        line-height: 19px;
    }

    .buyButton {
        height: 60px;
        font-family: "Montserrat", sans-serif;
        margin-top: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        background: #0ec22b;
        width: 30%;
        margin: 20px auto;
    }

    .buyButton:hover {
        background: #0ec22b;
        box-shadow: 0px 4px 4px rgba(14, 194, 43, 0.24);
    }

    .buyButton:active {
        background: #089d20;
        box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.08);
    }

    .field-value {
        font-weight: bold;
        margin-top: 25px !important;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
    }

    .field-name {
        font-size: 13px;
        line-height: 19px;
        color: #929292;
    }

    .GREEN-text {
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .line-devider {
        background: #e5e5e5;
        width: 0.5%;
        margin: 20px 0;
    }

    .geo {
        display: flex;
        margin-top: 10px;
        margin-bottom: 16px;
        flex-direction: row;
    }

    .geo img {
        margin-right: 5px;
    }

    .green-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
    }

    .green-label p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .green-label svg {
        margin-right: 10px;
    }

    .optionalField {
        width: 50%;
        display: inline-block;
    }

    .optionalField2 {
        width: 50%;

        display: inline-block;
        text-align: right;

        @media (max-width: 420px) {
            text-align: initial;
        }
    }

    .inner-optional {
        display: flex;
        flex-direction: column;

        p {
            margin: 0;
        }
    }
}