.wrapper {
    background: #fbf6ff;
    padding: 95px 20px 185px 20px;

    @media(max-width: 1050px) {
        padding-top: 118px;
    }

    .content {
        margin: 0 auto;
        max-width: 1400px;
        width: 100%;
        padding: 52px 260px 43px;

        @media (max-width: 1400px) {
            padding: 52px 30px 43px;
        }

        .img {
            display: flex;
            margin: 58px auto 4px;
            max-width: 100%;
        }

        p {
            font-family: Montserrat;
            font-weight: normal;
            font-size: 20px;
            line-height: 35px;
            color: #000000;
            margin-bottom: 35px;

            &>a {
                color: #8a00f6;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        ul {
            margin-left: 19px;
            margin-top: 0px;
            margin-bottom: 35px;
        }

        li {
            font-family: Montserrat;
            font-weight: normal;
            font-size: 20px;
            line-height: 35px;
        }

        .header {
            font-family: Montserrat;
            font-weight: bold;
            font-size: 30px;
            text-align: center;
            color: #8a00f6;
            margin-bottom: 19px;
        }

        .join {
            display: block;
            width: max-content;
            text-decoration: none;
            margin: 0 auto 47px;
            cursor: pointer;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            text-align: center;
            padding: 11px;
            color: white;
            background: #8a00f6;
            transition: all .3s linear;

            &:hover {
                background: #6d06bd;
            }

            @media (max-width: 495px) {
                width: 100%;
            }
        }
    }
}