.bid-area {
    width: 100%;
    height: 600px;
    background-color: #580BE4;
    display: flex;
    justify-content: center;
    align-items: center;
    .bidButton {
        height: 60px;
        font-family: Poppins, sans-serif;
        margin-top: 34px;
        width: 230px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: black !important;
        background-color: white !important;
    }
    .bidButton:hover {
        background: #0ec22b;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    }
    .bidButton:active {
        background: #089d20;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    }
    .inner-bid {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        @media(max-width:800px){
            width:80%
        }
        h1 {
            font-size: 32px;
            line-height: 40px;
            @media (max-width: 600px) {
                font-size: 20px;
                line-height: 40px;
            }
        }
        @media (max-width: 600px) {
            width: 100%;
        }

        .bid-item-container {
            width: 100%;
            justify-content: space-around;
            display: flex;
            flex-direction: row;
            @media (max-width: 600px) {
                flex-direction: column;
            }
            .bid-item {
                text-align: center;
                display: flex;
                flex-direction: column;
                h2 {
                    margin-bottom: 0px;
                    @media (max-width: 600px) {
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
                p {
                    margin-top: 5px;
                    @media (max-width: 600px) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}
