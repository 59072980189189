footer {
    background: #1f1f1f;
    color: #fff;
    padding: 30px 0;
    margin-top: auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // height: 260px;

    .w-container {
        display: flex;
        justify-content: space-between;
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        

        &:before,
        &:after {
            display: none;
        }
        @media (max-width: 1400px) {
            padding: 0 20px;
        }
    }

    @media (max-width: 1160px) {
        .w-container {
            flex-flow: row wrap;
        }
        .footer-company-desktop{
            margin-left: 10px;
        }
    }

    .logo {
        width: 200px;
        margin-top: 8px;
    }

    .footer-company-desktop {
        font-family: "Poppins", sans-serif;
        // padding-left: 12px;

        .logo {
            box-sizing: border-box;
            max-width: 115px;
            padding-left: 10px;
        }

        @media (max-width: 758px) {
            display: none;
        }
    }

    .footer-company-mobile {
        font-family: "Poppins", sans-serif;
        padding-left: 12px;
        text-align: center;

        @media (min-width: 758px) {
            display: none;
        }
    }

    .company {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;
        color: #ffffff;
        opacity: 0.7;
        padding: 24px 3px;
    }

    .footer-menu-container {
        display: flex;
        padding-right: 75px;

        .footer-menu-item {
            display: flex;
            flex-direction: column;
            margin: 0px 45px;
            @media (max-width:1221px) {
                margin: 0px 18px;
            }
        }

        .social-links {
            display: flex;

            .social-link {
                margin-right: 16px;
            }
        }

        @media (max-width: 991px) {
            div {
                margin: 0 10px;
            }
        }

        @media (max-width: 758px) {
            div {
                margin: 0;
                margin-bottom: 40px;
            }
        }

        h6 {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            margin: 08px 0 18px 0;
        }

        a {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            text-decoration-line: none;
            color: #ffffff;
            //opacity: 0.7;
            margin-bottom: 2px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .disclaimer {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #ffffff;
        opacity: 0.7;
        text-align: justify;
        display: block;
        margin: 0 auto;
        margin-top: 3%;
        max-width: 1400px;
        width: 100%;

        a {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            color: #ffffff;
            opacity: 0.7;
            // @media (min-width: 758px) {
            //     display: none;
            // }
        }

        @media (max-width: 758px) {
            display: block;
            font-size: 12px;
            line-height: 18px;
        }
    }

    @media (max-width: 991px) {
        .footer-menu-container {
            padding-right: 0;
        }
    }

    @media (max-width: 758px) {
        .footer-menu-container {
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
        }

        .footer-menu-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .social-links {
                display: none;
            }

            .social-icons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 250px;
                max-width: 100%;
            }
        }

        .footer-menu-item:not(:last-child) {
            width: 100%;
        }

        .footer-menu-item:last-child {
            width: 100%;
        }
    }
}

@media (max-width: 991px) {
    footer {
        height: auto;
        padding: 40px 0;
    }
}

.social-link-icon {
    display: none;
}

@media (max-width: 758px) {
    .social-link {
        display: block;
    }

    .social-link-icon {
        display: inline-block;
        margin: 0 2px;

        img {
            width: 30px;
            height: 30px;
        }
    }
}

.social-icons {
    display: none;
}
