.wrapper{
    padding: 95px 20px 185px 20px;
    background: #FBF7FF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width: 1050px){
        padding-top: 118px;
    }
    .content_field{
        width: 990px;
        max-width: 100%;
        .path{
            margin-left: 3px;
            font-family: Poppins;
            font-size: 13px;
            .link{
                text-decoration: none;
                color: #000000;
            }
            .current{
                font-weight: 700;
            }
        }
        .title{
            margin-top: 23px;
            margin-bottom: 12px;
            font-weight: bold;
            font-size: 24px;
            color: #8A00F7;
        }
        .plans{
            background: #FFFFFF;
            border: 1px solid #EAD0FE;
            header{
                text-align: center;
                padding: 0 20px;
                font-size: 18px;
                line-height: 35px;
                margin-top: 28px;
            }
            main{
                display: flex;
                margin: 28px 65px;
                min-height: unset;
                @media(max-width: 1050px){
                    flex-direction: column;
                    margin: 28px 24px;
                }
                .plan{
                    position: relative;
                    width: 100%;
                    background: #F9F9F9;
                    transition: .3s;
                    box-sizing: border-box;
                    cursor: pointer;
                    @media(max-width: 1050px){
                        padding: 20px;
                        margin-top: 20px;
                        display: grid;
                        grid-template-areas: 
                         'hed prj .'
                         'img fet fet'
                         'prc fet fet'
                         'fee fet fet'
                        ;
                    }
                    @media(max-width: 768px){
                        display: flex;
                        flex-direction: column;
                    }
                    &:hover{
                        transform: scale(1.03);
                    }
                    &.selected{
                        background: #FFFFFF;
                        border: 1px solid #0EC22B;
                        transform: scale(1.03);
                        z-index: 1;
                        &::after{
                            content: url('../../../Assets/images/apply-tokenization/plan_selected.svg');
                            position: absolute;
                            top:20px;
                            right: 15px;
                        }
                    }
                    header{
                        grid-area: hed;
                        font-weight: 700;
                        font-size: 20px;
                        padding: 0;
                    }
                    .image{
                        grid-area: img;
                        height: 160px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media(max-width: 1050px){
                            height: unset;
                            &>img{
                                height: 84px;
                            }
                        }
                    }
                    .price{
                        grid-area: prc;
                        margin-top: 9px;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 30px;
                        text-align: center;
                        color: #8A00F7;
                    }
                    .fees{
                        grid-area: fee;
                        margin-top: -2px;
                        font-weight: 700;
                        text-align: center;
                        font-size: 14px;
                        height: 21px;
                    }
                    .projects{
                        grid-area: prj;
                        font-weight: 700;
                        font-size: 16px;
                        margin-top: 21px;
                        text-align: center;
                        @media(max-width: 1050px){
                            text-align: start;
                        }
                        @media(max-width: 768px){
                            text-align: center;
                        }
                    }
                    .features{
                        margin-top: 12px;
                        grid-area: fet;
                        @media(max-width: 1050px){
                            display: flex;
                            justify-content: space-between;
                        }
                        @media(max-width: 768px){
                            flex-direction: column;
                            align-items: center;
                        }
                        ul{
                            list-style-image: url('../../../Assets/images/apply-tokenization/feature-bullet.svg');
                            padding-left: 43px;
                            padding-right: 20px;
                            margin: 0;
                            @media(max-width: 1050px){
                                padding-left: 20px;
                                padding-right: 0;
                                &:first-child{
                                    padding-right: 10px;
                                    padding-left: 25px;
                                }
                            }
                            @media(max-width: 768px){
                                width: 270px;
                                max-width: 100%;
                                padding: 0 ;
                                padding-left: 20px;
                                &:first-child{
                                    padding: 0 ;
                                    padding-left: 20px;
                                }
                            }
                            li{
                                padding-left: 6px;
                                font-size: 13px;
                                min-height: 33px;
                            }
                        }
                    }
                }
            }
            .create_own{
                font-size: 18px;
                text-align: center;
                padding: 0 20px;
                margin-top: 34px;
                margin-bottom: 39px;
                a{
                    text-decoration: none;
                    color: #8A00F7;
                    font-weight: 700;    
                }
            }            
        }
        .button_container{
            display: flex;
            justify-content: flex-end;
            margin-top: 38px;
            .button{
                width: 300px;
                padding: 10px 34px;
                background: #8a00f7;
                border-radius: 0;
                text-transform: none;
                font-weight: bold;
                font-size: 18px;
                color: white;
                transition: all .3s linear;
                margin-left: auto;
                cursor: pointer;
                @media(max-width: 768px){
                   width: 100%;
                }
                &:hover{
                    background: #6b0ab6;
                }
                &:disabled{
                    background: #6b0ab6;
                }
            }
        }
    }
}