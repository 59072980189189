.summary-container {
    grid-area: col3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;

    .summary-map-wrapper {
        width: 55%;
        display: flex;
        flex-direction: column;

        @media (max-width: 600px) {
            width: 100%;
        }

        .summary {
            height: 50%;
            p{
                margin: 15px 0;
                font-size: 14px;
                line-height: 18px;
            }
        }

        .map-area {
            height: 50%;

            @media(max-width:600px) {
                display: none;
            }
        }
    }

    @media(max-width:600px) {
        flex-direction: column;
    }

    p {
        font-size: 13px;
    }

    .summary {
        width: 100%;

        @media(max-width:600px) {
            width: 100%;
        }
    }

    .highlights {
        width: 42%;

        @media(max-width:600px) {
            width: 100%;
        }

        .map-area {
            display: none;

            @media(max-width:600px) {
                display: block;
            }
        }

        .inner-container {
            width: 90%;

            @media(max-width:600px) {
                width: 100%;
            }

            h2 {
                @media(max-width:600px) {
                    padding-left: 0px
                }

                padding-left:20px
            }
        }

        .highlight-item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: 15px 0;
            
            img {
                padding-top: 5px;
                margin-right: 10px;
            }

            p {
                margin-top: 0;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}