.confirmed {
  margin: 0 auto;
  max-width: 530px;
  padding-top: 70px;
  padding-bottom: 209px;
  @media (max-width: 1200px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }
  .links{
    display: flex;
    justify-content: center;
  }
  .linksRight{
    display: flex;
    justify-content: flex-end;
  }
  &__field {
    margin-top: 76px;
    margin-bottom: 19px;
    padding: 40px 65px;
    border: 1px solid #ead0fe;
    background: #fbf7ff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      margin-bottom: 20px;
      span{
        color: #8a00f7;
      }
    }

    &__loading {
      color: #8a00f7;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      margin-right: 15px;
      img{
        margin-left: 5px;
      }
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
  .googleAuthField{
    border: 1px solid #ead0fe;
    background: #fbf7ff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 750px) {
      flex-direction: column;
    }
    .left{
      margin-left: 78px;
      margin-top: 63px;
      @media (max-width: 750px) {
        margin: 63px 56px 0px;
      }
      @media (max-width: 400px) {
        margin: 35px 15px 0px;
      }
      .headline {
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
        color: #8A00F7;
        @media (max-width: 750px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
      .text {
        font-weight: normal;
        font-size: 24px;
        line-height: 170%;
        color: #000000;
        @media (max-width: 750px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    .right{
      max-width: 335px;
      margin: 63px 56px;
      @media (max-width: 750px) {
        max-width: 100%;
        margin-top: 20px;
      }
      @media (max-width: 400px) {
        margin: 15px;
        margin-bottom: 35px;
      }
      .formBlock{
        margin-top: 14px;
        margin-bottom: 14px;
        width: 100%;
      }
      label.Mui-focused{
        color: #8a00f7;
      }
      .MuiFormControl-root{
        margin-top: 14px;
        margin-bottom: 14px;
      }
      .MuiOutlinedInput-root{
        border-radius: 0px;
        font-family: "Montserrat", sans-serif;
        &.Mui-focused fieldset{
          border-color: #8a00f7
        }
        .MuiOutlinedInput-input {
          padding-top: 15px;
          padding-bottom: 15px;
          background: white;
        }
      }
    }
  }
}
.googleConfirmed{
  max-width: 1193px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 750px) {
    padding-top: 25px;
    padding-bottom: 40px;
  }
}
