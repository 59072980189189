
.drop-menu{
    margin: 29px 0px 0px 104px;
    .MuiList-padding{
        padding: 0;
    }
    .MuiMenu-paper{
        border: none;
        border-radius: 0px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    }
}
.drop-tokenization-menu{
    margin: 29px 0px 0px 80px;
    .MuiList-padding{
        padding: 0;
    }
    .MuiMenu-paper{
        border: none;
        border-radius: 0px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    }
}
