.investor-perks {
  background-color: #fff;
  border: 1px solid #99d2a2;

  margin-bottom: 30px;
  position: relative;
  margin-top: 80px;

  .top {
    padding: 74px 32px 34px;
  }
  .icon {
    position: absolute;
    left: 50%;
    top: -62px;
    transform: translateX(-50%);
  }

  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #0ec22b;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .descr {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .field-item {
    display: flex;
    flex-direction: column;
    padding: 22px 32px;

    &:nth-child(even) {
      background-color: #fff;
    }
    &:nth-child(odd) {
      background-color: rgba($color: #e5e5e5, $alpha: 0.33);
    }

    &.last {
      background-color: #fff;
      border-top: 1px solid #99d2a2;
    }

    &:last-of-type {
      border-bottom: none;
      font-size: 14px;
      line-height: 160%;
    }

    .invest {
      font-size: 18px;
      color: #0ec22b;
      line-height: 44px;
      font-weight: 700;
    }

  }
}
