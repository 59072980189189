img{
    max-width: 100%;
}
.main{
    .mainImg{
        display: flex;
        margin: 58px auto 4px;
        max-width: 100%;
    }
    .firstTokBlock{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 85px auto 24px;
        background: #FBF6FF;
        border-bottom: 1px solid #EAD0FE;
        color: black;
        .tokRight{
            display: flex;
            flex-direction: column;
            padding: 76px 0;
            a{
                font-family: "Montserrat", sans-serif;
            }
            span{
                font-family: "Montserrat", sans-serif;
            }
            .tokSubTitle{
                text-align: left;
                padding: 0;
                margin: 0;
            }
            .tokDetails{
                font-weight: 500;
                font-family: "Montserrat", sans-serif;
                // font-size: 22px;
                font-size: 18px;
                line-height: 158.4%;
                color: #000000;
                margin: 32px 0;
                @media (max-width:500px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        @media (max-width:800px){
            flex-direction: column;
            .tokRight{
                align-items: center;
                text-align: center;
                padding: 20px;
            }
            .tokStartBtn{
                margin-bottom: 32px;
            }
        }
    }
    .tokWrapper{
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        background-color: #ffffff;
        .text-article{
            p{
                font-family: "Montserrat", sans-serif;
                // font-size: 22px;
                font-size: 18px;
                line-height: 158.4%;
                color: #000000;
                @media (max-width:500px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            a{
                font-family: "Montserrat", sans-serif;
            }
            span{
                font-family: "Montserrat", sans-serif;
            }
            .info-box__text{
                font-family: "Montserrat", sans-serif;
            }
        }
        @media (max-width: 910px){
            padding: 0 27px;
        }
    }
    .tokSubTitle{
        color: #8A00F7;
        margin: 0;
        padding: 26px 0px 17px;
        font-weight: bold;
        // font-size: 36px;
        // line-height: 45px;
        font-size: 32px;
        line-height: 43px;
        text-transform: uppercase;
        text-align: center;
        font-family: "Montserrat", sans-serif;
    }
    .buttons{
        display: flex;
        margin-right: 5px;
        .tokOutlinedBtn{
            box-sizing: border-box;
            font-family: "Montserrat", sans-serif;
            border: 1px solid #8A00F7;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 13px 37px;
            background: transparent;
            color: #8A00F7;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            transition: all .3s linear;
            img{
                margin-left: 8px;
            }
            &:hover{
                background: #ddc6ee6e;
                cursor: pointer;
            }
            margin-right: 36px;
        }
    }
    @media (max-width:500px) {
        .buttons{
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 0;
            .tokOutlinedBtn{
                margin-bottom: 15px;
                margin-right: 0;
            }
        }
    }
    .tokStartBtn{
        font-family: "Montserrat", sans-serif;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px 20px;
        background: #8A00F7;
        color: white;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        transition: all .3s linear;
        img{
            margin-left: 8px;
        }
        &:hover{
            background: #6e00c2;
            cursor: pointer;
        }
    }
    .tokWrapper{
        .tokSubTitle{
            color:#0EC22B;
            margin: 0;
            padding: 22px 0px 17px;
            font-weight: bold;
            // font-size: 36px;
            // line-height: 45px;
            font-size: 32px;
            line-height: 43px;
            text-transform: uppercase;
            text-align: center;
            @media (max-width:500px) {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    .purpleWrapper{
        background: #FBF6FF;
        border-bottom: 1px solid #EAD0FE;
        border-top: 1px solid #EAD0FE;
        margin-bottom: 60px;
        margin-top: 58px;
        &:last-child{
            margin-bottom: 190px;
            .tokStartBtn{
                margin: 59px auto;
                color: #ffffff;
            }
            .mainImg{
                margin-bottom: 41px;
            }
        }
        .mainImg{
            margin-top: 16px;
            margin-bottom: 27px;
        }
        .text-article{
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            a{
                color: #000000;
                font-weight: 700;
            }
            p{
                font-family: "Montserrat", sans-serif;
                // font-size: 22px;
                font-size: 18px;
                line-height: 158.4%;
                color: #000000;
                @media (max-width:500px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .tokCenterText{
                font-weight: 700;
                text-align: center;
            }
            .tokStartBtn{
                margin: 36px auto 50px;
                color: #ffffff;
            }
            .tokSubTitle{
                padding-top: 0;
            }
        }
        @media (max-width: 910px){
            padding: 0 27px;
        }
    }
    .green{
        background: #FAFFFB;
        border-top: 1px solid #0EC22B;
        border-bottom: 1px solid #0EC22B;
        padding-top: 44px;
        padding-bottom: 35px;
        .tokSubTitle{
            color:#0EC22B;
            margin: 0;
            padding: 22px 0px 17px;
            font-weight: bold;
            // font-size: 36px;
            // line-height: 45px;
            font-size: 32px;
            line-height: 43px;
            text-transform: uppercase;
            text-align: center;
            @media (max-width:500px) {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    .infoBox{
        padding: 38px;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
        margin-top: 35px;
        margin-bottom: 35px;
    }
}
