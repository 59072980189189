.landing-advantages {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .title {
        font-size: 32px;
        line-height: 48px;
        max-width: 950px;
        text-align: center;
        color: #333;

        @media(max-width: 500px){
            font-size: 24px;

        }
    }

    .advantages-item {
        max-width: 1000px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 70px 0;


        .item-image {
            max-width: 390px;
            img {
                width: 100%;
            }

            @media(max-width: 920px) {
                text-align: center;
                margin-bottom: 15px;
            }
        }

        &:nth-of-type(2n) {
            .item-content {
                order: 1;
            }

            .item-image {
                order: 2;
            }
        }

        @media(max-width: 920px) {
            flex-direction: column;
            align-items: center;
            margin: 50px 0;
            text-align: center;

            &:nth-of-type(2n) {
                .item-content {
                    order: 2;
                }

                .item-image {
                    order: 1;
                }
            }
        }

        .item-content {
            max-width: 370px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .item-title {
                font-size: 24px;
                font-weight: 700;
                color: #333;
            }

            .item-descr {
                display: inline-block;
                font-size: 16px;
                line-height: 32px;
                margin: 20px 0 60px;
                color: #5b5b5b;

                @media(max-width: 920px) {
                    margin-bottom: 20px;
                }
            }

            .item-link {
                text-decoration: none;
                position: relative;

                &:hover {
                    text-decoration: underline;
                }

                &:after {
                    // content: '';
                    // position: absolute;
                    // //background-color: #580BE4;
                    // opacity: 0.2;
                    // width: 54px;
                    // height: 7px;
                    // bottom: -15px;
                    // left: 0;

                    @media(max-width: 920px) {
                        left: 40%;
                    }
                }
            }

        }
    }
}