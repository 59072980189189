.kyc-check-page {
    display: flex;
    margin-left: 420px;
    padding-bottom: 30px;
    min-height: 100vh;

    @media(max-width: 1100px) {
        margin-left: 340px;
    }

    @media(max-width: 900px) {
        margin-left: 255px;
    }

    @media(max-width: 768px) {
        margin-left: 0;
    }

    .kyc-check {
        padding: 125px 0 0 120px;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        min-height: 100vh;

        @media(max-width: 900px) {
            padding: 110px 0 0 76px;
        }

        @media(max-width: 768px) {
            padding: 25px 20px 20px 20px;
            align-items: center;
        }
    }

    .kyc-check-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 560px;

        @media(max-width: 768px) {
            width: 100%;
        }

        .form-title {
            font-size: 28px;
            margin-bottom: 4px;
            margin-top: 0;
        }

        .subtitle {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 25px;
            color: #8F948F;

            @media(max-width: 768px) {
                margin-bottom: 0;
            }
        }

        .questions-list {
            padding: 0;
        }

        .questions-item {
            display: flex;
            flex-direction: column;

            .title {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 18px;
                margin-top: 30px;
            }

            .answer {
                span {
                    font-size: 13px;
                    font-size: inherit;
                }
            }

            .option {
                font-size: 13px;
                margin-left: 8px;
            }
        }
    }

    .accreditation-next-button {
        max-width: 116px;
        width: 100%;
        color: #fff;
        margin-top: 24px;
        text-transform: none;
        font-family: Poppins !important;
        font-weight: 600 !important;

        @media(max-width: 768px) {
            max-width: initial;
        }
    }

    .input-error {
        font-size: 12px;
        color: #ea2d2d;
    }

    .get-help-container {
        position: static !important;
        margin-top: auto;
        text-align: left;
        padding-top: 20px;

        @media(max-width: 768px) {
            text-align: center;
        }
    }
}