.item-container {
    width: 100%;
    padding: 13px 24px;
    box-shadow: 0px 4px 20px #ece8f1;
    margin-top: 16px;

    .item-content {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .asset-fields {
            width: 17%;
            display: flex;
            font-size: 16px;
            font-weight: 500;
            justify-content: flex-end;

            @media (max-width: 768px) {
                width: 100%;
                justify-content: space-between;
            }

            .mobile-title {
                display: none;
                font-size: 16px;
                font-weight: 400;
                color: #929292;

                @media (max-width: 768px) {
                    display: inline-block;
                }
            }

            .MuiButton-text {
                text-transform: none;
                color: white;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }

        .logo-container {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            font-weight: 500;
            width: 32%;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;

            @media (max-width: 768px) {
                width: 100%;
            }

            img {
                margin-right: 10px;
            }
        }
    }
}
