.empty-invest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 33px;
  border: 2px solid rgba($color: #c2bfcd, $alpha: 0.3);


  .title {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(73, 73, 73, 1);
  }

  .subtitle {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    a{
      color: rgba(246, 74, 0, 1);
      font-weight: 700;
    }
  }
}