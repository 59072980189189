.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #363343;
    padding: 20px 0;
    font-size: 13px;
    color: #fff;

    span {
        text-align: center;
    }

    .banner-icon {
        display: none;
    }

    @media(max-width: 600px) {
        flex-direction: column;

        .banner-icon {
            display: inline-block;
        }

        span {
            margin: 15px 10px;
        }
    }

    .link {
        color: #fff;
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 4px;
        padding: 4px 15px;
        margin-left: 20px;
        text-decoration: none;

        @media(max-width: 600px) {
            margin-left: 0;
        }
    }
}


.marketplace-page {
    padding-top: 84px;
    width: 100%;

    @media(max-width: 768px) {
        // padding-top: 0;
    }

    .marketplace-content {
        max-width: 1144px;
        width: 100%;
        margin: 0 auto;
    }

}