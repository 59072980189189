.add-margin {
    position: relative !important;
}
.landing-nav-mobile {
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
    padding: 20px 30px;
    z-index: 100;

    @media (max-width: 768px) {
        // width: calc(100% - 60px);
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: 375px) {
        padding: 10px 20px;
    }
    .landing-nav-logo {
        max-width: 120px;
        &:hover {
            cursor: pointer;
        }
    }
 
}
.login-button span {
    background: inherit;
    color: #fff;
    font-family: inherit;
    text-transform: none;
}

.login-button span:hover {
    background: transparent;
}
