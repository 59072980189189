.new-landing-nav {
    position: fixed;
    width: 100vw;
    background-color: #fff;
    z-index: 101;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 12px rgba(00, 00, 00, 0.12);

    .mobile-menu {
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      outline: 0;
      z-index: 1200;
      position: fixed;
      overflow-y: auto;
      flex-direction: column;
      background: linear-gradient(to bottom, #bfee92 0%, #ffffff 70%);
      padding: 40px 20px 50px;
      transition: 0.3s all ease-in-out;

      &.hidden {
        left: -150%;
        transition: 0.3s all ease-in-out;
      }

      .dropdown-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        &.active {
          height: min-content;
          transition: all 0.3s ease-in-out;
        }

        .sub-link {
          font-size: 15px;
          margin-bottom: 20px;
          font-weight: 400;
          font-family: "Montserrat", sans-serif;
          color: #000;
          text-decoration: none;
        }
      }

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20%;

        .logo {
          display: flex;
          padding-left: 0;
        }

        .logo-cube-mobile {
          width: 48px;
          margin-right: 17px;
        }

        .logo-main-mobile {
          width: 180px;
        }
      }

      .nav-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nav-link {
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 24px;
          color: #000;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;

        .login {
          width: 100%;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 24px;
          color: #000;
          text-decoration: none;
          text-align: center;
        }

        .sign-up {
          width: 100%;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          color: #fff;
          text-decoration: none;
          text-align: center;
          font-weight: 500;
          background-color: #8A00F6;
          padding: 9px 24px;
        }
      }
    }
  }

  .new-landing-nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 20px;
    max-width: 1255px;
    min-height: 84px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1255px) {
      padding: 25px 20px 20px;
    }

    .logo {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 110px;
      transition: all 0.4s ease-in-out;

      .logo-cube {
        position: absolute;
        width: 86px;
        transform: translateY(15px);
        left: 0;
        transition: all 0.4s ease-in-out;
      }
    }

    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .navSubmenus{
        display: flex;
        align-items: center;
      }
      .menu-item {
        margin-left: 35px;
        font-size: 15px;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        transition: all 0.2s linear;

        .drop-controller {
          display: inline-flex;
          align-items: center;

          img {
            margin-left: 5px;
          }
        }
      }

      .dropdown {
        position: relative;
      }

      .signup {
        font-size: 14px;
        color: #fff;
        padding: 10px 24px;
      }
    }

    .dropDownMenu{
      ul {
          list-style: none;
          margin: 0;
          padding: 0;
      }
      .topmenu>li {
          position: relative;
      }
      .topmenu>li>span {
          text-decoration: none;
          font-size: 15px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          color: rgb(0, 0, 0);
          position: relative;
          &::after{
              content: '';
              position: absolute;
              width: calc(100% + 15px);
              height: 210%;
              left: -5px;
              top: 0;
          }
      }
      .submenu li:hover {
          background-color: #BFEE924D;
      }
      .submenu {
          background: white;
          position: absolute;
          left: -13px;
          top: calc(100% + 26px);
          z-index: 5;
          width: max-content;
          opacity: 0;
          transform: scaleY(0);
          transform-origin: 0 0;
          transition: opacity 281ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 187ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transform-origin: 91px 0px;
          display: flex;
          flex-direction: column;
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      }
  
      .submenu a {
          float: left;
          display: block;
          color: rgb(0, 0, 0);
          text-align: left;
          padding: 12px 16px;
          text-decoration: none;
          font-size: 15px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.00938em;
          text-decoration: none;
      }
      .disabled{
          float: left;
          display: block;
          color: rgb(0, 0, 0);
          text-align: left;
          padding: 12px 16px;
          text-decoration: none;
          font-size: 15px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.00938em;
          text-decoration: none;
          cursor: default;
      }
  
      .topmenu>li:hover .submenu {
          opacity: 1;
          transform: scaleY(1);
      }
    }

    .burger-nav {
      display: none;
      cursor: pointer;
    }

    .logo-main{
      max-width: 255px;
    }

    @media (max-width: 1060px) {
      .logo {
        padding-left: 60px;

        .logo-cube {
          bottom: initial;
          width: 44px;
          margin-right: 17px;
          transform: translateY(0);
        }
      }
      .logo-main {
        max-width: 180px;
      }
      .nav {
        display: none;
      }
      .burger-nav {
        display: block;
      }
    }

    @media (max-width: 1010px) {
      .logo-main {
        max-width: 180px;
      }
    }
  }

  .new-landing-nav.scrolled {
    box-shadow: 0 2px 12px rgba(00, 00, 00, 0.12);
    transition: all 0.2s ease-in-out;

    .logo {
      padding-left: 60px;
      transition: all 0.4s ease-in-out;

      .logo-cube {
        bottom: initial;
        width: 44px;
        margin-right: 17px;
        transform: translateY(0);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  .get-started-link {
    padding: 9px 13px;
    border-radius: 0px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    text-transform: none;
    text-decoration: none;
    background-color: #8A00F6;
    transition: background-color .3s linear;

    @media (max-width: 600px) {
        margin-bottom: 10px;
    }
    &:hover{
        background-color: #6e00c2;
    }
}