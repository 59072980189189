.asset-checkout-wrapper {
  overflow: hidden;
  height: 100%;
  padding: 10px 30px;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
}

.asset-checkout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  animation: SlideIn 0.5s ease-in-out;

  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 10px;
  }

  .slider {
    padding-right: 20px;
    @media (max-width: 768px) {
      padding: 0 35px 0 15px;
    }
  }

  .tokens {
    display: flex;
    align-items: center;
    color: #0ec22b;
    font-size: 30px;
    margin-bottom: 16px;
    @media (max-width: 1250px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      flex-direction: row;
      align-items: center;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .amount {
      width: 70%;
      color: #0ec22b;
      padding: 8px 10px;
      border: 1px solid #d2d9dd;
      font-weight: 700;
      margin-right: 12px;
      display: inline-block;
      font-size: 30px;
      outline: none;
      max-width: 220px;
      @media (max-width: 950px) {
        width: 100%;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
      }
      &:focus {
        border-color: #0ec22b;
      }
      &.red,
      &:focus.red {
        border-color: #f36969;
      }
    }
  }

  .remain {
    text-align: right;
    margin-top: 14px;
    font-size: 16px;
    color: #6d7584;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  .bottom {
    text-align: right;
    padding-bottom: 76px;
    padding-top: 45px;
  }

  .total {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;

    .amount {
      color: #0ec22b;
      margin-left: 9px;
    }
  }

  .checkout {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .back {
      font-size: 18px;
      margin-right: 45px;
      text-decoration: underline;
      cursor: pointer;
      @media (max-width: 1100px) {
        margin-right: 10px;
      }
      @media (max-width: 800px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        margin-right: 45px;
      }
    }

    .begin {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f64a00;
      color: #fff;
      transition: all 0.2s ease-out;
      padding: 12px 35px;
      font-weight: 700;
      cursor: pointer;
      @media(max-width: 940px){
        padding: 12px 5px;
      }
      @media(max-width: 768px){
        padding: 12px 35px;
      }

      @media (min-width: 768px) and (max-width: 940px) {
        padding: 12px 5px;
      }

      img {
        margin-right: 8px;
      }

      &:hover {
        background-color: #d63300;
        transition: all 0.2s ease-out;
      }
    }
  }
}

@keyframes SlideIn {
  from {
    right: -500px;
  }

  30% {
    right: -500px;
  }

  to {
    right: 0;
  }
}

.minimum-tooltip {
  color: #f36969;
  font-size: 12px;
}
