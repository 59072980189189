.drawer-button {
    &.MuiButtonBase-root {
        width: 140px;
        height: 39px;
        background: #0ec22b;
        box-shadow: 0px 6px 25px rgba(64, 166, 41, 0.25);
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        border-radius: 0;
        text-transform: none;
        margin: 0 auto;
    }
}
