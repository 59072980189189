.wrapper{
    padding: 95px 20px 185px 20px;
    background: #FBF7FF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width: 1050px){
        padding-top: 118px;
    }
    .content_field{
        width: 990px;
        max-width: 100%;
        .path{
            margin-left: 3px;
            font-family: Poppins;
            font-size: 13px;
            .link{
                text-decoration: none;
                color: #000000;
            }
            .current{
                font-weight: 700;
            }
        }
        .notification{
            margin-top: 18px;
            background: #ffffff;
            border: 1px solid #EAD0FE;
            padding: 24px 34px 26px 34px;
            header{
                font-size: 24px;
                font-weight: 700;
                text-align: center;
            }
            main{
                margin-top: 26px;
                font-size: 18px;
                text-align: center;
                min-height: unset;
            }
        }
        .title{
            margin-top: 30px;
            margin-bottom: 12px;
            font-weight: bold;
            font-size: 24px;
            color: #8A00F7;
            letter-spacing: .4px;
            span{
                font-weight: normal;
            }
        }
        &>main{
            box-sizing: border-box;
            border: 1px solid #EAD0FE;
            min-height: unset;
            background-color: #ffffff;
            &.second{
                margin-top: 43px;
            }
            .following_header{
                padding: 0 20px;
                background:#8A00F6;
                color: white;
                min-height: 66px;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 24px;
            }
            header{
                padding:36px 68px 0px 85px;
                display: flex;
                justify-content: space-between;
                font-weight: 700;
                font-size: 14px;
                &.second{
                    padding-top: 8px;
                }
            }
        }
        .button_container{
            display: flex;
            justify-content: flex-end;
            margin-top: 38px;
            @media(max-width: 768px){
                flex-direction: column;
             }
            .button{
                width: 300px;
                padding: 10px 24px;
                background: #8a00f7;
                border-radius: 0;
                text-transform: none;
                font-weight: bold;
                font-size: 18px;
                color: white;
                transition: all .3s linear;

                margin-left: auto;
                &.second{
                    margin-left: 30px;
                    @media(max-width: 768px){
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
                &.disabled{
                    opacity: 0.2;
                }
                &.transparent{
                    background-color: transparent;
                    box-shadow: none;
                    color: #8a00f7;
                    border: 1px solid #8A00F7;;
                    &:hover{
                        color: white;
                    }
                }
                @media(max-width: 768px){
                   width: 100%;
                }
                &:hover{
                    background: #6b0ab6;
                }
            }
        }
    }
}