.live-chat {
  .images {
    margin-bottom: 25px;
    img {
      margin-right: 15px;
    }
  }
  .chat {
    cursor: pointer;
    width: 100%;
    background-color: #fcfcfc;
    border: 1px solid #b9c0c4;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    img {
      margin-right: 14px;
    }
  }
}
