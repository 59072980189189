.redfrog-content {
  .title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .subtitle {
    font-size: 18px;
    font-weight: 700;
    color: #0ec22b;
    line-height: 40px;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .text {
    font-size: 18px;
    line-height: 40px;
    color: #6d7584;
    span.smaller{
      font-size: 15px;
    }
    span.green{
      color: #0ec22b;
      font-weight: 700;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  .text-title {
    font-weight: 700;
    color: #6d7584;
  }

  .image1 {
    margin: 25px 0 19px;
    width: 100%;
    img {
      width: 100%;
    }
  }

  .swiper-image{
    margin: 25px 0 19px;
    width: 100%;
    height: 430px;
    .swiper-button-prev,
    .swiper-button-next {
      color: lightgray;
    }
    .swiper-slide {
      height: 430px;
      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
      }
    }
    @media (max-width: 768px) {
      height: 300px;
      .swiper-slide {
        height: 300px;
      }
    }
    @media (max-width: 480px) {
      height: 200px;
      .swiper-slide {
        height: 200px;
      }
    }
    img {
      width: 100%;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    margin: 20px 0;
  }
  .greenList{
    color:#0ec22b;
    .liTitle{
      color: #0ec22b;
      font-weight: 700;
    }
    .liText{
      color: #6d7584;
    }
  }
  .reviews-slider {
    margin: 15px 0;
    position: relative;

    .testimonials-forward {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      z-index: 1000;

      @media (max-width: 768px) {
        top: initial;
        left: initial;
        right: 37%;
        bottom: -70px;
      }
    }

    .testimonials-back {
      position: absolute;
      left: 10px;
      top: 50%;
      z-index: 1000;
      transform: translateY(-50%);

      @media (max-width: 768px) {
        top: initial;
        left: 37%;
        right: initial;
        bottom: -70px;
      }
    }

    .reviews-swiper {
      width: 100%;
      max-width: 100%;
      background-color: #fafffb;
      border: 1px solid #99d2a2;

      @media (max-width: 768px) {
        margin-bottom: 80px;
      }
    }

    .slide-inner {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      padding: 70px 0 34px;

      @media (max-width: 768px) {
        padding: 30px 10px;
        width: 100%;
      }

      .review {
        font-size: 28px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 12px;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 27px;
        }
      }

      .name {
        font-size: 18px;
        font-weight: 700;
        color: #0ec22b;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }

  .two-compontent {
    background-color: #fafffb;
    border: 1px solid #99d2a2;
    padding: 20px 45px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0 40px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .component {
      width: 45%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .component-divider {
      width: 1px;
      min-height: 100%;
      background-color: #99d2a2;

      @media (max-width: 768px) {
        width: 50%;
        height: 1px;
        min-height: initial;
        margin: 20px auto;
      }
    }

    .component-title {
      font-weight: 700;
      color: #0ec22b;
      line-height: 200%;
      margin-bottom: 4px;
    }

    .component-text {
      line-height: 200%;
    }
  }

  .solidblock-logo {
    display: block;
    max-width: 170px;
    margin: 15px 0 10px;
  }

  .oceans-logo {
    display: block;
    width: 450px;
    max-width: 100%;
    margin: 15px 0 10px;
  }

  .how-works {
    background-color: #fafffb;
    border: 1px solid #99d2a2;
    padding: 20px 45px;
    margin: 20px 0 37px;

    .works-title {
      font-size: 32px;
      font-weight: 700;
      color: #0ec22b;
      margin-bottom: 14px;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    .works-subtitle {
      font-size: 22px;
      max-width: 80%;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .top {
      position: relative;
      cursor: pointer;
    }

    .arrow {
      position: absolute;
      top: 40%;
      right: 0;
      transform-origin: center;
      transition: all 0.2s ease-in;
      transform: rotate(360deg);

      &.active {
        transform: rotate(540deg);
        transition: all 0.2s ease-in;
      }
    }

    .dropdown {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease-in-out;

      &.shown {
        max-height: 5000px;
        transition: all 0.5s ease-out;
      }
    }

    .works-item {
      display: flex;
      padding: 35px 0;
      border-bottom: 1px solid #99d2a2;
      align-items: center;

      &:last-of-type {
        border-bottom: none;
      }

      img {
        max-width: 110px;
        margin-right: 45px;

        @media (max-width: 768px) {
          max-width: 40px;
          margin-right: 25px;
        }
        @media (max-width: 360px) {
          display: none;
        }
      }

      .item-title {
        font-size: 28px;
        font-weight: 700;
        color: #0ec22b;
        margin-bottom: 15px;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      .item-descr {
        font-size: 22px;
        line-height: 130%;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}
