.get-help-container {
    position: absolute;
    bottom: 48px;
    margin-bottom: 20px;
    @media (max-height: 900px) {
        position: relative;
        margin-top: 10px;
        bottom: 0;
    }
    @media (max-width: 950px) {
        width: 335px;
        position: static;
        margin-top: 12px;
        text-align: center;
    }

    @media (max-width: 800px) {
        margin-top: 100px;
    }

    @media (max-width: 375px) {
        margin-top: 28px;
        width: unset;
    }

    .get-help {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #8f948f;

        .get-help-green {
            margin-left: 7px;
            font-weight: 600;
            color: #0ec22b;
        }
    }
}
