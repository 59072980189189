.account-nav-mobile {
    width: 100%;
    position: fixed;
    box-shadow: 0 2px 12px #00000012;
    z-index: 10;
    display: none;
    padding: 10px 20px;
    background-color: #ffffff;

    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
    }

    .account-nav-logo {
        max-width: 120px;
        &:hover {
            cursor: pointer;
        }
    }

    .user-photo-container{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .menu-container {
        display: flex;
        align-items: center;
        .user-photo {
            margin-right: 32px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
