.redfrog-presentation {
  background-color: #fafffb;
  border: 1px solid #99d2a2;
  padding: 29px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 26px 0;
  .ppt {
    width: 62px;
    margin-bottom: 14px;
  }
  .downloaded{
    margin-top: 18px;
    font-size: 16px;
  }
  .inner {
    display: flex;
    flex-direction: column;
    max-width: 335px;
    text-align: center;
  }
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 4px;
    color: #0ec22b;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
  .subtitle {
    font-size: 18px;
    color: #0ec22b;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .email {
    width: 100%;
    border-radius: 0;
    padding: 15px 18px 15px 10px;
    height: 54px;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #dbe0ea;
    outline: none;
    font-size: 18px;
    color: #6f6f6f;
    margin-bottom: 14px;
    text-align: center;
  }
  .enter {
    background-color: #f64a00;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding: 14px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-out;
    @media (max-width: 1000px) {
      padding: 8px 20px;
    }
    &:hover {
      background-color: #d63300;
      transition: all 0.2s ease-out;
    }
  }
}

.redfrog-presentation-line {
  background-color: #fafffb;
  border: 1px solid #99d2a2;
  padding: 29px 20px;
  
  margin: 26px 0;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  .downloaded{
    margin-top: 18px;
    font-size: 16px;
  }
  .inner{
    display: flex;
    align-items: center;
  }
  .input-error{
    text-align: center;
  }
  .ppt {
    width: 50px;
    margin-left: auto;
    @media (max-width: 800px) {
      margin: 0;
      margin-bottom: 14px;
      order: 1;
    }
  }
  .email {
    width: 39%;
    border-radius: 0;
    padding: 15px 18px 15px 10px;
    height: 54px;
    font-family: "Montserrat", sans-serif;
    border: 1px solid #dbe0ea;
    outline: none;
    font-size: 18px;
    color: #6f6f6f;
    text-align: center;
    @media (max-width: 800px) {
      margin: 0;
      margin-bottom: 14px;
      order: 2;
      width: 100%;
    }
  }
  .enter {
    background-color: #f64a00;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 14px 5px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-out;
    width: 53%;
    text-align: center;
    @media (max-width: 1000px) {
      padding: 8px 20px;
    }
    @media (max-width: 800px) {
      order: 3;
      width: 100%;
    }
    &:hover {
      background-color: #d63300;
      transition: all 0.2s ease-out;
    }
  }
}
