.stepFiveWrapper,.switchsBlock{
    display: flex;
    flex-direction: column;
    width: 100%;
    .left{
        display: flex;
        align-items: center;
        .text{
            margin-left: 32px;
            font-size: 14px;
        }
    }
    @media (max-width:500px){
        .left{
            flex-direction: column;
            align-items: flex-start;
            .text{
                margin-left: 0px;
                font-size: 14px;
            }
        }
        .at-step-one__container__column__title__info {
            margin-left: 5px;
        }
        .switchElem{
            align-items: flex-start;
        }
    }
    .MuiFormControl-root {
        width: 50%;
        @media (max-width:600px) {
            width: 100%;
        }
    }
    .switchBlock{
        .at-step-one__container__column__title__info {
            top: 0px;
            min-width: 14px;
        }
        .switchElemBlock{
            padding-bottom: 10px;
            &:nth-child(2n+1){
                background: #F9F9F9;
            }
            .right{
                position: relative;
                display: inline-block;
                &::after{
                    content: '';
                    top: 0;
                    left: -40%;
                    position: absolute;
                    width: 180%;
                    height: 220%;
                }
            }
            .switchElem{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 18px;
                padding-bottom: 8px;
                @media (max-width:500px){
                    align-items: flex-start;
                }
                .left{
                    .text{
                        font-size: 14px;
                        font-weight: 600;
                        @media (max-width:500px){
                            margin-top: 24px;
                        }
                        span{
                            font-weight: 400;
                        }
                    }
                }
            }
            .at-step-one__container__column__text__tooltipMoile{
                margin-left: 18px;
                margin-bottom: 0;
            }
        }
        .MuiSwitch-root{
            width: 52px;
            height: 26px;
            padding: 5px;
            & .MuiSwitch-switchBase{
                padding: 3px;
                color: #B9C0C4;
                &.Mui-checked {
                    color: #0EC22B;
                    -webkit-transform: translateX(26px);
                    -moz-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                    & .MuiSwitch-thumb{
                        color: #0EC22B;
                    }
                    & + .MuiSwitch-track {
                        background-color: rgba(153, 210, 162, 0.31);
                    }
                }
            }
            & .MuiSwitch-thumb{
                color: #B9C0C4;
            }
            & .MuiSwitch-track {
                background-color: #E5E5E5;
                opacity: 1 !important;
            }
        }
    }
    .uploader{
        display: flex;
        justify-content: space-between;
        .upload{
            padding: 32px 0;
            width: 47%;
            background: #FBF6FF;
            outline: 2px dashed #8A00F6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: relative;
            transition: all .4s linear;
            .text{
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
            }
            .subText{
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                margin-top: 12px;
                margin-bottom: 12px;
            }
            @media (max-width:500px) {
                .text{
                    display: none;
                }
                .subText{
                    display: none;
                }
                .browse{
                    margin-top: 34px;
                }
            }
            .browse{
                background: #8A00F6;
                border-radius: 15px;
                border: none;
                color: white;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                padding: 10px;
                &:hover{
                    cursor: pointer;
                    background: #7d00dd;
                }
            }
            input{
                display: block;
                width: 100%;
                border: none;
                text-transform: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: all .3s linear;
                &:hover{
                    cursor: pointer;
                }
                &:focus {
                    outline: none;
                }
            }
            input:hover~img{
                animation: startUpload 1.5s alternate ease-in-out infinite;
            }
            @keyframes startUpload {
                0% {
                    transform: translateY(0);
                }
                100% { 
                    transform: translateY(-15px);
                }
            }
        }
        .inside-drag-area{
            background: #f7efff;
            outline-offset: -12px;
            img{
                position: relative;
                animation: drag 1s alternate ease-in-out infinite;
            }
            @keyframes drag {
                0% {
                    top: 0;
                    transform: scale(1);
                }
                100% { 
                    top: -10px;
                    transform: scale(1.1);
                }
              }
        }
        .listFiles{
            display: flex;
            flex-direction: column;
            width: 47%;
            .item{
                margin: 5px 0;
            }
            .title{
                font-weight: bold;
                font-size: 14px;
                line-height: 24px;
                color: #8A00F7;
                @media (max-width: 785px){
                    margin-top: 24px;
                }
            }
            .file{
                display: flex;
                margin-top: 6px;
                margin-bottom: 6px;
                position: relative;
                &::after{
                    content: '';
                    top: 0;
                    left: -3%;
                    position: absolute;
                    width: 103%;
                    height: 103%;
                    background: none;
                    z-index: 5;
                    border-radius: 5px;
                    transition: background-color .5s linear;
                }
                &:hover{
                    &::after{
                        background: #f9f1ffde;
                    }
                }
                img{
                    margin-right: 11px;
                    z-index: 6;
                    transition: all .4s linear;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
                .details{
                    z-index: 6;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .name{
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 22px;
                        overflow-wrap: break-word;
                    }
                    .progress{
                        display: flex;
                        justify-content: space-between;
                        span{
                            font-size: 10px;
                            line-height: 18px;
                        }
                        .MuiLinearProgress-root{
                            height: 5px;
                            border-radius: 8px;
                            background: #EAD0FE;
                            .MuiLinearProgress-bar{
                                background: #8A00F6;
                            }
                        }
                    }
                }
            }
        }
    }
    .listReqiredFiles{
        .item{
            margin: 8px 0;
        }
    }
}
.stepFive{
    .at-step-one__confirmation__button{
        width: auto;
        @media (max-width: 550px){
            width: 100%;
        }
    }
}
.at-step-one__container__column__title{
    .right{
        position: relative;
        display: inline-block;
        &::after{
            content: '';
            top: -10%;
            left: -40%;
            position: absolute;
            width: 180%;
            height: 130%;
        }
    }
}