.accreditation-header{
    width: 420px;
    min-height: 100vh;
    height: 100%;
    padding: 40px 20px 50px 100px;
    float: left;
    position: absolute;

    @media(max-width: 1100px){
        width: 340px;
        padding: 40px 20px 50px 66px;
    }

    @media(max-width: 900px){
        width: 255px;
        padding: 40px 20px 50px 66px;
    }

    @media(max-width: 768px){
        display: none;
    }

    .accreditation-logo{
        max-width: 150px;
    }

    .description{
        margin-top: 105px;
        color: #fff;
        max-width: 280px;

        .title{
            font-size: 25px;
            font-weight: 700;
            line-height: 36px;
            margin-bottom: 30px;
        }

        .subtitle{
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
        }
    }
}