.signup-info-page-container {
  display: flex;
  .terms-link {
    text-decoration: underline;
    font-size: 14px;
    line-height: 20px;
  }
  .news-tooltip{
    margin-bottom: -15px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    width: 424px;
    max-width: 100%;
    margin-top: 15px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .hint {
    font-size: 12px;
    color: #929292;
    word-break: keep-all;
    @media(max-width: 500px){
      left: 50%;
      top: 110%;
      transform: translate(-50%, 0);
      width: 100%;
    }
  }

  .phone-controle {
    position: relative;
    @media(max-width: 500px){
        margin-bottom: 30px;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
  }

  .signup-info-page {
    position: relative;
    width: calc(100% - 419px - 117px);

    @media (max-width: 950px) {
      width: 100%;
    }

    .auth-close-icon {
      position: absolute;
      top: 60px;
      right: 132px;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 1100px) {
        right: 70px;
      }

      @media (max-width: 950px) {
        right: 40px;
        top: 20px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
    .signup-info-page-content {
      margin-top: 45px;

      @media (max-width: 950px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 45px;
      }

      @media (max-width: 800px) {
        margin-top: 35px;
      }

      @media (max-width: 375px) {
        margin-top: 0px;
        padding: 16px 20px 20px 20px;
      }

      .signup-info-page-title {
        width: 271px;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #000000;

        @media (max-width: 950px) {
          width: 335px;
        }

        @media (max-width: 375px) {
          font-weight: bold;
          font-size: 28px;
          letter-spacing: 0.02em;
          color: #031106;
        }
      }

      .signup-info-page-subtitle {
        width: 271px;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        opacity: 0.8;
        margin-top: 7px;

        @media (max-width: 950px) {
          width: 335px;
        }

        @media (max-width: 375px) {
          font-size: 16px;
          margin-top: 16px;
        }

        .subtitle-white {
          font-weight: 600;
          color: white;
          margin-left: 5px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          @media (max-width: 375px) {
            margin-left: 8px;
          }
        }
        .subtitle-GREEN {
          font-weight: 600;
          margin-left: 5px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          @media (max-width: 375px) {
            margin-left: 8px;
          }
        }
      }
      .auth-next-button {
        &.MuiButtonBase-root {
          display: block;
          width: 142px;
          height: 39px;
          margin-left: 0px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          border-radius: 4px;
          text-transform: none;

          &.desktop {
            @media (max-width: 950px) {
              display: none;
            }
          }

          &.mobile {
            display: none;
            width: 335px;
            max-width: 90%;
            height: 39px;
            margin-top: 45px;

            @media (max-width: 950px) {
              display: block;
            }

            @media (max-width: 800px) {
              margin-top: 63px;
            }

            @media (max-width: 375px) {
            }
          }
        }
      }
    }
  }
}

.email-confirm {
  display: flex;
  flex-direction: column;

  .confirm-button {
    font-weight: 500;
    width: 142px;
    text-transform: none;
    color: #fff;
    font-family: Poppins;
  }

  .code-confirm {
    width: 270px;
    margin-bottom: 24px;
  }

  .phone-confirm-text {
    margin: 32px 0 24px;
  }
}
