.coming-soon {
    position: relative;

    @media(max-width: 580px) {
        width: 90%;
    }

    .close-desktop {
        display: block;
        position: absolute;
        z-index: 1000;
        top: 0px;
        right: -30px;

        .close-icon {
            fill: #fff;
        }

        @media (max-width: 600px) {
            position: absolute;
            right: 10px;
            top: 10px;

            .close-icon {
                fill: #000;
            }
        }

        .mobile-icon {
            display: none;

            @media (max-width: 600px) {
                display: block;
            }
        }

        .desktop-icon {
            display: block;

            @media (max-width: 600px) {
                display: none;
            }
        }
    }
}