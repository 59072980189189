.nav-collapse-container {
    position: relative;

    .nav-collapse {
        z-index: 2;
        width: max-content;
        position: absolute;
        margin-top: 19px;
        box-shadow: 0px 9px 14px rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        right: -15px;
        padding: 10px;
        &:hover{
            cursor: default;
        }

        @media (max-width: 1300px) {
            right: -15px;
        }

        .menuBanner{
            background: #F64A00;
            color: white;
            padding: 5px 15px;
            margin-left: 20px;
            margin-right: 10px;
            font-size: 9px;
            font-weight: 500;
            text-transform: uppercase;
            border-radius: 4px;
            white-space: nowrap;
            text-decoration: none;
            &:hover{
                cursor: pointer;
            }
            &:visited{
                color: white;
            }
        }

        .MuiCollapse-wrapper {
            .MuiCollapse-wrapperInner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .collapse-header-item {
                    font-family: "Montserrat", sans-serif;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid #e5e5e5;
                    padding: 10px 10px 10px 20px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #333333;
                    .collapse-header-text{
                        display: flex;
                        align-items: center;
                        &:hover{
                            cursor: pointer;
                        }
                    }

                    &:last-child {
                        border: none;
                    }

                    .collapse-person,
                    .collapse-logout {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }
            }

        }
    }
}