.checkout-confirmation {
  padding-top: 70px;
  padding-bottom: 89px;

  @media (max-width: 768px) {
    padding-top: 0;
  }
  .container {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;

    padding-top: 34px;
    @media (max-width: 1144px) {
      padding: 70px 20px 89px;
    }
    @media (max-width: 930px) {
      flex-direction: column;
      padding: 20px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .content {
    display: flex;
    justify-content: space-between;
    @media (max-width: 930px) {
      flex-direction: column-reverse;
    }
    .left {
      width: 26%;
      @media (max-width: 930px) {
        width: 100%;
      }
    }
    .right {
      width: 71%;
      @media (max-width: 930px) {
        width: 100%;
        margin-bottom: 30px;
      }
      .inner {
        max-width: 540px;
        @media (max-width: 930px) {
          max-width: 100%;
        }
      }
      .success {
        font-size: 18px;
        font-weight: 700;
        color: #0ec22b;
        margin-bottom: 6px;
      }
      .deposit {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 16px;
      }
      .account {
        font-size: 14px;
        color: #0ec22b;
        text-decoration: underline;
        margin-bottom: 16px;
      }
      .notify {
        font-size: 14px;
        font-weight: 700;
      }
      .email {
        font-size: 14px;
        margin-bottom: 16px;
      }
      .meanwhile {
        font-size: 14px;
        a {
          color: #0ec22b;
          text-decoration: underline;
        }
      }
    }
  }

  .card {
    background-color: #fcfcfc;
    border: 1px solid #b9c0c4;
    padding: 20px;
    margin-bottom: 27px;
    .image {
      width: 100%;
      margin-bottom: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .asset {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
  }

  .chat {
    cursor: pointer;
    width: 100%;
    background-color: #fcfcfc;
    border: 1px solid #b9c0c4;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    img {
      margin-right: 14px;
    }
  }
}
