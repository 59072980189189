.trade-item {
    max-width: 220px;
    box-shadow: 0 4px 20px 0 #ECE8F1;
    padding: 12px;
    margin-right: 20px;

    .header {
        display: flex;
    }

    .logo {
        position: relative;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        margin-right: 15px;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .title-block {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 700;
            font-size: 20px;
            text-transform: uppercase;
        }

        .count {
            color: red;
        }
    }
}