.checkout-wrapper{
    padding-top: 80px;
}

.left-container {
    width: 20%;
    .check {
        font-weight: bold;
        font-size: 14px;
        line-height: 35px;
    }
    .uncheck {
        font-weight: bold;
        font-size: 14px;
        line-height: 35px;
        opacity: 0.5;
    }
    .checkbox {
        display: flex;
        p {
            margin: 5px 0;
        }
    }
    @media (max-width: 1200px) {
        display: none;
    }
}

.checkout-title {
    width: 38%;
    margin: auto;
    @media (max-width: 800px) {
        width: 688px;
    }
    @media (max-width: 600px) {
        width: 90%;
    }
    .inner-title {
        width: 370px;
        @media (max-width: 800px) {
            width: calc(50% + 142px);
            h1 {
                font-size: 24px;
                line-height: 36px;
            }
        }
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}

.checkout-container {
    .credit-container-2 {
        display: none;
        @media (max-width: 600px) {
            display: block;
            h2 {
                font-weight: bold;
                font-size: 24px;
                line-height: 100%;
            }
            p {
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
            }
            background: #f9f9f9;
            padding: 10px 20px;
            margin-top: 20px;
        }
    }
    width: 1150px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 1200px) {
        width: 824px;
        flex-direction: row;
        padding: 0 20px;
    }
    @media (max-width: 1000px) {
        width: 824px;
        flex-direction: row;
        padding: 0 30px;
    }
    @media (max-width: 800px) {
        flex-direction: column-reverse;
        width: 100%;
        justify-content: center;
        padding: 0 20px;
    }
    @media (max-width: 600px) {
        width: 100%;
        padding: 0 20px;
    }
    .custom-root {
        color: white;
        width: 244px;
        height: 54px;
        margin-top: 30px;
        text-transform: none;
        margin-bottom: 30px;
        @media (max-width: 800px) {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .right-container {
        .inner-right {
            display: flex;
            flex-direction: column;
            @media (max-width: 800px) {
                display: flex;
                flex-direction: row;
            }
            @media (max-width: 600px) {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
        .inner-image {
            padding: 10px;
            width: 271px;
            display: flex;
            flex-direction: column;
            @media (max-width: 800px) {
                flex-direction: row;
                width: 67%;
            }
            @media (max-width: 600px) {
                width: 100%;
                height: auto;
            }
        }
        .info-container {
            img {
                width: 12px;
                height: 14px;
            }
            .info1 {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 25px;
                @media (max-width: 800px) {
                    margin-bottom: 5px;
                }
                p {
                    margin: 0;
                    margin-left: 15px;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    color: #494949;
                }
            }
            .info2 {
                display: flex;
                flex-direction: row;
                h4 {
                    margin: 0;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 24px;
                    color: #333333;

                    opacity: 0.8;
                }
                div {
                    margin-left: 15px;
                }
                img {
                    margin-top: 5px;
                }
                p {
                    margin: 0;
                    margin-bottom: 8px;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;

                    color: #494949;
                }
            }
        }
        img {
            width: 271px;
            height: 178px;
            @media (max-width: 800px) {
                width: 241px;
                height: 158px;
            }
            @media (max-width: 600px) {
                width: 100%;
            }
        }
        .inner-image {
            padding: 10px;
            .inner-1 {
                @media (max-width: 800px) {
                    width: 250px;
                    height: 158px;
                    h2 {
                        margin-top: 2px;
                    }
                }
            }
        }
        h2 {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
        }
        .credit-container {
            background: #f9f9f9;
            padding: 10px 20px;
            margin-top: 20px;
            @media (max-width: 800px) {
                margin-top: 0;
                width: 189px;
            }
            h2 {
                font-weight: bold;
                font-size: 24px;
                font-size: 24px;
                line-height: 100%;
            }
            p {
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
            }
            @media (max-width: 600px) {
                display: none;
            }
        }

        width: 30%;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 800px) {
            width: 100%;
            height: 158px;
            justify-content: center;
        }
        @media (max-width: 600px) {
            height: auto;
        }
    }
    .right-container-payment {
        display: flex;
        @media (max-width: 600px) {
            display: none;
        }
    }
    .central-container {
        width: 40%;
        @media (max-width: 800px) {
            width: 70%;
            margin: auto;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
        .MuiButton-label {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
        }
        .price-borders {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            font-size: 12px;
            line-height: 18px;
            @media (max-width: 600px) {
                width: 283px;
                margin: 10px auto 20px auto;
            }

            color: #929292;
            margin-bottom: 30px;
        }
        .price {
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            margin-top: 15px !important;
            /* identical to box height, or 24px */

            color: #333333;
            @media (max-width: 600px) {
                font-size: 16px;
            }
            .MuiInputBase-input {
                font-weight: bold;
                font-size: 24px;
                line-height: 100%;
            }
        }
        .price-token {
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            width: 60%;
            margin-top: 15px !important;
            color: #333333;
            @media (max-width: 1200px) {
                width: 100%;
            }
            @media (max-width: 1000px) {
                width: 110%;
            }
            @media (max-width: 600px) {
                width: 90%;
            }

            .MuiInputBase-input {
                font-weight: bold;
                font-size: 24px;
                line-height: 100%;
                @media (max-width: 600px) {
                    font-size: 16px;
                }
            }
            .MuiInputAdornment {
                p {
                    font-size: 16px;
                }
            }
        }
        .price-div {
            text-align: right;

            .bold-p {
                font-weight: 500;
            }
            p {
                margin: 0;

                white-space: nowrap;
            }
        }
        .MuiSlider-root {
            height: 7;
            padding: 15px 0;
            width: 100%;
            margin-top: 50px;
            @media (max-width: 600px) {
                width: 283px;
                display: block;
                margin: 60px auto 0 auto;
            }
        }
        .MuiSlider-thumb {
            width: 20px;
            height: 20px;
            display: flex;
            outline: 0;
            box-sizing: border-box;
            margin-top: -7px;
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            align-items: center;
            margin-left: -10px;
            border-radius: 50%;
            justify-content: center;
            // background: rgb(88, 11, 228);
            // background: #0ec22b;
            border: #0ec22b;
            // border: 2px solid rgb(88, 11, 228);
            box-shadow: "0px 4px 20px #ece8f1, 0px 4px 12px rgb(14 194 43 / 10%)";
            &:after {
                top: 50%;
                left: 50%;
                content: "";
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background: #ffffff;
                transform: translate(-50%, -50%);
            }
        }
        .MuiSlider-valueLabel {
            top: -22;
            background: transparent;
            color: #000;
            left: auto;
            white-space: nowrap;
            @media (max-width: 850px) {
                white-space: normal;
            }

            & * {
                color: black;
                font-weight: 500;
                width: 100px;
                font-size: 13px;
                line-height: 19px;
                background-color: transparent;
                @media (max-width: 850px) {
                    width: auto;
                    text-align: center;
                }
            }
        }
        .MuiSlider-rail {
            height: 7px;
            background: #c4c4c4;
            box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.1);
        }
        .MuiSlider-track {
            height: 7px;
        }
    }
    .bid-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .MuiInputLabel-root {
            font-family: "Poppins";
            font-weight: 500;
        }
        .MuiFormLabel-root {
            color: black;
        }
        @media (max-width: 800px) {
            margin-top: 20px;
        }
        .adornment {
            @media (max-width: 600px) {
                font-size: 16px;
            }
        }
        @media (max-width: 600px) {
            border-bottom: 1px solid #c4c4c4;
            width: 283px;
            margin: auto;
            .MuiInput-underline:before {
                border-bottom: none;
            }
        }
    }
    .left-container {
        width: 20%;
        .check {
            font-weight: bold;
            font-size: 14px;
            line-height: 35px;
        }
        .uncheck {
            font-weight: bold;
            font-size: 14px;
            line-height: 35px;
            opacity: 0.5;
        }
        .checkbox {
            display: flex;
        }
        @media (max-width: 1200px) {
            display: none;
        }
    }
}
