.tokenization-dashboard-container{
    *{
        font-family: "Montserrat", sans-serif;
    }
    background: #FBF7FF;
    .wrapper{
        margin: 0 auto;
        max-width: 1144px;
        padding-top: 110px;
        padding-bottom: 30px;
        min-height: 85vh;
        @media (max-width:1165px) {
            padding-left: 20px;
            padding-right: 20px;
        }
        .title{
            font-weight: bold;
            color: #8A00F6;
            font-size: 24px;
            line-height: 36px;
            span{
                font-weight: 400;
            }
        }
        .dashboardHead{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 8px;
            margin-right: 16px;
            @media (max-width:1025px) {
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }
            .title{
                font-weight: bold;
                color: #8A00F6;
                font-size: 24px;
                line-height: 36px;
            }
            .pricing{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                .purple{
                    color: #8A00F6;
                    text-decoration: underline;
                    font-weight: 700;
                }
            }
        }
        .profiles{
            display: flex;
            flex-wrap: wrap;
            @media (max-width:1025px) {
                justify-content: center;
            }
            .profile{
                width: 48%;
                background: #ffffff;
                border: 1px solid #EAD0FE;
                margin: 15px 8px;
                padding: 35px 45px;
                transition: box-shadow,border-color .3s linear;
                @media (max-width:1025px) {
                    width: 80%;
                }
                @media (max-width:650px) {
                    width: 100%;
                }
                &:hover{
                    border: 1px solid #8A00F6;
                    box-shadow: 0px 3px 10px #a25bd865;
                }
                .profileData{
                    display: flex;
                    margin-bottom: 85px;
                    @media (max-width:530px) {
                        margin-bottom: 20px;
                    }
                    @media (max-width:460px) {
                        flex-direction: column;
                        align-items: center;
                    }
                    .data{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        margin-left: 12px;
                        width: 100%;
                        @media (max-width:530px) {
                            flex-direction: column;
                            justify-content: flex-start;
                        }
                        @media (max-width:460px) {
                            align-items: center;
                            text-align: center;
                        }
                        .data-name{
                            margin-right: 5px;
                            .tokenName{
                                color: #8A00F6;
                                font-weight: bold;
                                font-size: 28px;
                                line-height: 25px;
                                word-break: break-word;
                            }
                            .tokenSymbol{
                                font-weight: bold;
                                font-size: 16px;
                                line-height: 25px;
                            }
                        }
                        .data-status{
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 25px;
                            color: #0066FF;
                        }
                    }
                    .image{
                        min-width: 122px;
                        width: 122px;
                        height: 122px;
                        transition: .1s;
                        border: 1px solid transparent;
                        position: relative;
                        border: 1px solid #EAD0FE;
                        cursor: pointer;
                        .ava-img{
                            min-width: 120px;
                            width: 120px;
                            height: 120px;
                            object-fit: cover;
                        }
                        .input{
                            display: none;
                        }
                        .plus{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            transition: .1s;
                            opacity: 0;
                            &.blacked{
                                background-color: rgba($color: #000000, $alpha: 0.3);
                            }
                            img{
                                position: absolute;
                                top: 56.5px;
                                left: 55px;
                                transition: .1s;
                                pointer-events: none;
                            }
                        }
                        &:hover{
                            border: 1px solid #8A00F7;
                            .plus{
                                opacity: 1;
                            } 
                        }
                    }   
                }
                .menu{
                    display: flex;
                    justify-content: space-between;
                    @media (max-width:530px) {
                        flex-direction: column;
                        align-items: center;
                    }
                    .menu-item{
                        display: flex;
                        align-items: center;
                        position: relative;
                        z-index: 5;
                        &::after{
                            content: '';
                            position: absolute;
                            left: -23%;
                            top: -55%;
                            width: 100%;
                            height: 100%;
                            z-index: 4;
                            padding: 15px 23px;
                            border-radius: 25px;
                            background-color: #C4C4C433;
                            opacity: 0;
                            transition: opacity .3s linear;
                        }
                        &:hover{
                            cursor: pointer;
                            &::after{
                                opacity: 1;
                            }
                            .item-text{
                                color:#6600b4
                            }
                        }
                        @media (max-width:530px) {
                            margin: 10px 0px;
                        }
                        .item-text{
                            color: #8A00F6;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 25px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .newToken{
            width: 48%;
            background: #ffffff;
            margin: 15px 8px;
            padding: 25px 45px;
            min-height: 285px;
            border: 2px dashed #8A00F6;
            position: relative;
            overflow: hidden;
            @media (max-width:1025px) {
                width: 80%;
            }
            .spinner-wrapper{
                padding: 0;
            }
            .first{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:hover{
                    cursor: pointer;
                }
                .newText{
                    color: #8A00F6;
                    margin-top: 12px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 25px;
                }
            }
            .first.hide{
                display: none;
            }
            .second{
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 24px 35px 32px;
            }
            .second.show{
                display: inline-block;
            }
        }
    }
}