.accreditation-tax-return-page {
    display: flex;
    margin-left: 420px;
    min-height: 100vh;

    @media(max-width: 1100px) {
        margin-left: 340px;
    }

    @media(max-width: 900px) {
        margin-left: 255px;
    }

    @media(max-width: 768px) {
        margin-left: 0;
    }
}

.accreditation-tax-return {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 125px 0 0 120px;
    position: relative;
    width: 100%;

    .accreditation-tax-return-form{
        display: flex;
        flex-direction: column;
    }

    @media(max-width: 900px) {
        padding: 110px 0 0 76px;
    }

    @media(max-width: 768px) {
        padding: 25px 20px 20px 20px;
    }

    .form-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 12px;
    }

    .subtitle {
        display: inline-block;
        max-width: 560px;
        font-weight: 500;
        line-height: 24px;
        color: #8F948F;
        margin-bottom: 50px;

        @media(max-width: 768px) {
            margin-bottom: 40px;
        }
    }

    .drop-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 24px;
        max-width: 270px;
        border: 2px dashed #ddd;
        border-radius: 4px;
        outline: none;
        text-align: center;
        cursor: pointer;
        @media(max-width: 768px) {
            width: 100%;
            max-width: initial;
        }

        span {
            font-size: 14px;
            color: #909090;
            line-height: 20px;
            margin-bottom: 10px;
        }

        .drop-button {
            background-color: transparent;
            outline: none;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            padding: 4px 10px;
            cursor: pointer;
        }
    }

    .accreditation-next-button {
        font-family: Poppins !important;
        font-weight: 600 !important;
        max-width: 116px;
        width: 100%;
        color: #fff;
        margin-top: 24px;
        text-transform: none;
        @media(max-width: 768px) {
            max-width: initial;
        }
    }
    .input-error {
        font-size: 12px;
        color: #ea2d2d;
        margin-top: 10px;
    }
    .get-help-container{
        position: static !important;
        margin-top: auto;
        text-align: left ;
        padding-top: 20px;
        margin-bottom: 20px;
        @media(max-width: 768px){
            text-align: center;
            margin: auto auto 0;
            width: 100%;
        }
    }
}