.investment-info-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .my-asset-info {
        width: fit-content;
        color: #494949;

        .my-asset-name {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: #333333;
            margin-bottom: 3px;
            width: fit-content;
        }

        .location-icon {
            margin-bottom: -2px;
        }

        .my-asset-location,
        .my-asset-type {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #494949;
            margin-left: 7px;
        }

        .my-asset-location {
            margin-right: 7px;
            margin-left: 12px;
        }
    }

    .my-asset-pricing {
        text-align: right;

        .trading-percent{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            svg{
                width: 15px;
                margin-right: 4px;
            }
        }

        .trading-percent {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #580be4;
            position: relative;
        }

        .trading-price {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            text-align: right;
            color: #333333;
            margin-left: 10px;
        }

        .trading-price-text {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            text-align: right;
            color: #494949;
            margin-top: 5px;
        }
    }
}
