.look-for-opportunity-container {
    padding: 35px;
    border: 2px solid #ece8f1;
    margin-bottom: 32px;
    @media (max-width: 800px) {
        width: 94.8%;
        margin: 20px auto;
    }
    @media (max-width: 600px) {
        width: 89.33%;
        margin: 20px auto;
    }

    .look-for-opportunity-title {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        margin-bottom: 23px;
    }

    .look-for-opportunity-text {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        color: #494949;
        max-width: 318px;
        margin: 0 auto;
    }
}
