.paper-container {
    .MuiPaper-root {
        padding: 32px;
        background: white;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
    }

    h3 {
        margin: 0;
    }

    .inner-modal {
        display: flex;
        flex-direction: column;

        .divider {
            width: 100%;
            background-color: #d2d1d9;
            height: 0.5px;
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }

    .clear{
        font-size: 14px;
        font-weight: 600;
        margin: -10px 0 20px 0;
        cursor: pointer;
    }

    .amount {
        margin-bottom: 20px;
    }

    .amount-content {
        span {
            font-size: 14px;
            margin: 0 18px;
        }

        .amount-input {
            max-width: 110px;
        }
    }

    .trading-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .price-select {
            width: 70px;
        }
    }

    .trading-price-content {
        span {
            font-size: 14px;
            margin: 0 18px;
        }

        .amount-input {
            max-width: 110px;
        }
    }

    .buttons{
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;

        .apply{
            margin-left: 10px;
            color: #fff;
        }

        .apply, .cancel{
            text-transform: none;
            font-family: "Montserrat", sans-serif;
            padding: 7px 25px;
        }
    }
}