.trade-modal-container {
    margin: auto;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 50px;

    @media (max-height:900px) {
        padding: 20px;
        width: auto;
        overflow-x: hidden;
        overflow-y: auto;

        .close-mobile-btn-icon {
            background-color: transparent;
            top: 0;
            width: 100%;
            height: 23px;
            display: flex !important;
            align-items: center;
            justify-content: flex-end;
            border: none;
            cursor: pointer;
            outline: none;
            z-index: 2;
        }
    }

    @media (max-width: 600px) {
        padding: 20px;
        width: 88.33%;
        overflow-y: auto;
    }
    .close-mobile-btn-icon {

        background-color: transparent;
        top: 0;
        width: 100%;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: none;
        cursor: pointer;
        outline: none;
        z-index: 2;

    }

    .trade-modal-content-container {
        max-width: 500px;

        .trade-modal-title {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.02em;
            color: #000000;
            margin-top: 0;
        }

        .trade-modal-text {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 35px;
        }

        .trade-modal-nav {
            display: flex;
            align-items: center;
            justify-content: space-around;

            @media (max-width: 600px) {
                justify-content: space-between;
            }

            .trade-modal-link {
                font-family: "Montserrat", sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: #929292;
                background-color: transparent;
                padding: 0;
                border: none;
                outline: none;
                cursor: pointer;

                @media (max-width: 600px) {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                }

                &_active {
                    font-family: "Montserrat", sans-serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #333333;
                    position: relative;
                    background-color: transparent;
                    padding: 0;
                    border: none;
                    outline: none;
                    cursor: pointer;

                    &::after {
                        position: absolute;
                        bottom: -6px;
                        left: 50%;
                        transform: translateX(-50%);
                        content: "";
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .trade-modal-subtitle {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
            margin-top: 0;
            margin-bottom: 18px;
        }
    }

    .disable {
        background-color: gray !important;
    }

    .close-btn {
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding: 10px 34px;
        background-color: #580be4;
        border: none;
        border-radius: 3px;
        outline: none;
        margin-top: 25px;
    }
}