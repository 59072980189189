.accreditation-page {
    display: flex;
    margin-left: 420px;
    height: 100%;
    min-height: 100vh;

    @media(max-width: 1100px) {
        margin-left: 340px;
    }

    @media(max-width: 900px) {
        margin-left: 255px;
    }

    @media(max-width: 768px) {
        margin-left: 0;
    }

    .accreditation {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 125px 0 0 120px;
        position: relative;
        width: 100%;
        min-height: 100vh;

        @media(max-width: 900px) {
            padding: 110px 0 0 76px;
        }

        @media(max-width: 768px) {
            padding: 25px 20px 20px 20px;
        }
    }

    .accreditation-form {
        display: flex;
        flex-direction: column;
        width: 271px;

        @media(max-width: 768px) {
            width: 100%;
        }

        .form-title {
            font-size: 24px;
            margin-bottom: 4px;
            font-weight: 700;
        }

        .subtitle {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 25px;
            color: #333333;
        }

        label {
            color: #9F9CAA;
        }

        

        .form-field {
            display: inline-block;
            margin-bottom: 24px;
            width: 100%;
            font-weight: 500 !important;
            font-family: Poppins !important;

            .MuiInputBase-root {
                width: 100%
            }

            .MuiSelect-select.MuiSelect-select {
                font-weight: 500 !important;
                font-family: Poppins !important;
            }
        }
    }

    .accreditation-next-button {
        font-family: Poppins !important;
        font-weight: 600 !important;
        max-width: 116px;
        width: 100%;
        color: #fff;
        margin-top: 24px;
        text-transform: none;

        @media(max-width: 768px) {
            max-width: initial;
        }
    }

    .input-error {
        font-size: 12px;
        color: #ea2d2d;
    }

    .get-help-container {
        position: static !important;
        margin-top: auto;
        text-align: left;
        padding-top: 20px;
        margin-bottom: 20px;

        @media(max-width: 768px) {
            text-align: center;
            margin: auto auto 0;
            width: 100%;
        }
    }
}