.marketplace-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 20px 0 #ece8f1;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
        box-shadow: 0 4px 20px 5px #ece8f1;
        transition: 0.2s all;
    }

    .image-container {
        position: relative;
        min-height: 215px;
        max-height: 215px;
        overflow: hidden;
        object-fit: cover;

        .not-display {
            display: none;
        }

        .dash-container {
            position: absolute;
            z-index: 0;
            max-width: 137px;
            max-height: 25px;
            top: 20px;
            left: 30px;
            img {
                width: 100%;
                height: 100%;
                min-height: initial;
            }
        }

        .asset-status {
            position: absolute;
            left: 0px;
            top: 0px;
            overflow: hidden;
            width: 75px;
            height: 75px;
            text-align: right;

            span {
                font-size: 12px;
                color: #fff;
                text-transform: uppercase;
                text-align: center;
                line-height: 20px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                width: 100px;
                display: block;
                box-shadow: 0 3px 10px -5px #000;
                position: absolute;
                top: 15px;
                left: -25px;
            }
        }

        .tags-container {
            position: absolute;
            bottom: 24px;
            left: 25px;
        }

        .equity {
            // position: absolute;
            padding: 3px 10px;
            margin-right: 20px;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
            // left: 24px;
            // bottom: 22px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        min-height: 215px;
        object-fit: cover;

        @media (max-width: 768px) {
            height: 100%;
        }
    }

    @media (max-width: 768px) {
        flex-direction: row;

        .image-container {
            max-width: 45%;
            min-width: 45%;
            max-height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;

        .image-container {
            min-width: initial;
            max-width: 100%;
        }

        img {
            width: 100%;
        }
    }

    .card-content {
        padding: 30px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @media (max-width: 600px) {
            padding: 25px 12px;
            height: initial;
        }

        .title {
            display: inline-block;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        .location {
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #494949;
            font-weight: 500;

            svg {
                margin-right: 14px;
                width: 20px;
            }
        }

        .description {
            font-size: 13px;
            color: #494949;
            margin-bottom: 30px;
        }
    }



    .details {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: auto;

        .details-item {
            display: flex;
            flex-direction: column;

            &:first-child {
                align-items: flex-start;
            }

            &:last-child {
                align-items: flex-end;
            }

            &:nth-child(2) {
                border-left: 1px solid #e5e5e5;
                border-right: 1px solid #e5e5e5;
                // padding: 0 15px;
                align-items: center;
                text-align: center;

                @media (max-width: 600px) {
                    padding: 0 6px;
                }
            }

            span:first-child {
                display: inline-block;
                margin-bottom: 3px;
                font-weight: 500;
                font-size: 14px;
            }

            span:last-child {
                font-size: 12px;
                color: #929292;

                @media (max-width: 600px) {
                    font-size: 10px;
                }
            }

            @media (max-width: 600px) {
                &:first-child {
                    align-items: flex-start;
                    text-align: left;
                }

                &:last-child {
                    align-items: flex-end;
                    text-align: right;
                }
            }
        }
        .item-two{
            &:first-child {
                align-items: center;
            }
            &:nth-child(2) {
                border-left: 1px solid #e5e5e5;
                border-right: none;
            }
        }
    }
    .details-two{
        grid-template-columns: 1fr 1fr;
    }
}
