.payment-modal {
    .MuiDialog-paper {
        padding: 40px;
        max-width: 1000px;
        position: relative;
        @media (max-width: 768px) {
            padding: 40px 20px;
        }
    }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 22px;
    }

    .descr {
        font-size: 14px;
        line-height: 24px;
        color: #5C5C5C;
        margin-bottom: 27px;
    }

    .subtitle {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 33px;
    }

    .instruction {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 80px;

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }

        .item {
            display: flex;
            align-items: flex-start;
            position: relative;

            img {
                margin-right: 26px;
                width: 40px;
            }

            .content {
                display: flex;
                flex-direction: column;

                .subtitle {
                    margin-bottom: 5px;
                }

                .descr {
                    max-width: 190px;

                    @media (max-width: 768px) {
                        max-width: 100%;
                    }
                }
            }
        }

        .arrow {
            position: absolute;
        }


        .right .arrow {
            top: 40%;
            transform: rotate(-90deg);
            right: -100px;
        }

        .left .arrow {
            top: 40%;
            transform: rotate(90deg);
            left: -50px;
        }

        .bottom .arrow {
            left: 50%;
            bottom: -30px;
        }

        .top .arrow {
            left: 50%;
            top: -90px;
            transform: rotate(180deg);
        }

        .right .arrow,
        .bottom .arrow,
        .top .arrow,
        .left .arrow {
            @media (max-width: 768px) {
                transform: none;
                top: initial;
                right: initial;
                left: 50%;
                transform: translateX(-50%);
                bottom: -40px;
            }
        }
        .customer-mobile{
            display: none;
            @media (max-width: 768px) {
                display: flex;
            }
        }
    }
}