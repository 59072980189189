.auth-header {
    position: relative;
    width: 419px;
    min-height: 100vh;
    margin-right: 117px;

    @media (max-width: 950px) {
        height: 250px;
        min-height: initial;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    @media (max-width: 768px) {
        display: none;

    }


    .auth-header-txt-container {
        padding: 38px 0px 0px 100px;
        color: #ffffff;

        @media (max-width: 950px) {
            padding: 0px;

        }

        .auth-header-logo{
            cursor: pointer;
            max-width: 150px;
            img{
                width: 100%;
            }
            .logo-solid{
                margin-right: 3px;
            }

            @media (max-width: 950px) {
                margin-top: 15px;

            }
        }
        .auth-header-main-heading {
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            margin-top: 121px;
            width: 280px;

            @media (max-width: 950px) {
                margin-top: 10px;
            }
        }

        .auth-header-sub-heading {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            margin-top: 29px;
            @media (max-width: 950px) {
                margin-top: 10px;
            }
        }
    }

    .auth-header-img {
        display: flex;
        justify-content: center;

        img {
            position: absolute;
            bottom: 48px;

            @media (max-width: 950px) {
                position: relative;
                bottom: 0px;
    
            }
        }
    }
}
