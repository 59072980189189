.asset-full-access {
  background-color: #fafffb;
  border: 1px solid #0ec22b;
  padding: 44px 50px 58px;
  margin-bottom: 25px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
  .title {
    font-size: 28px;
    color: #0ec22b;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .descr {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 25px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .form {
    display: flex;
    align-items: center;
    flex-direction: column;

    .email {
      border-radius: 0;
      padding: 15px 18px 15px 10px;
      height: 54px;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #dbe0ea;
      outline: none;
      font-size: 18px;
      color: #6f6f6f;
      width: 100%;
      text-align: center;
      margin-bottom: 17px;
    }

    .enter {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      min-width: 236px;
      padding: 0 16px;
      height: 54px;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      background-color: #f64a00;
      transition: all 0.3s ease-in-out;
      width: 100%;

      &:hover {
        background-color: #d63300;
        transition: all 0.2s ease-in-out;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;

      .email {
        margin-bottom: 12px;
        max-width: 335px;
        width: 100%;
        min-width: initial;
      }

      .enter {
        max-width: 335px;
        width: 100%;
      }
    }
  }
}
