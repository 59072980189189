.at-step-edit {
  width: 100%;
  max-width: 990px;
  height: auto;
  overflow: hidden;
  padding: 15px 56px;
  border: 1px solid #ead0fe;
  background: white;
  position: relative;
  animation: closeStep .75s normal ease-in;
  @keyframes closeStep {
    0% {
      height: 600px;
    }
    100% { 
      height: 60px;
    }
  }
  @media (max-width:850px) {
    padding: 15px 20px;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }
  &__title {
    font-weight: bold;
    font-size: 20px;
    .purple {
      font-weight: normal;
      color: #8a00f7;
    }
  }
  &__button {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 55px;
    height: 31px;
    font-weight: bold;
    font-size: 14px;
    color: #8a00f7;
    border: none;
    background: #ead0fe;
    transition: all .3s linear;
    &:hover{
      background: #d1b4e7;
    }
  }
}