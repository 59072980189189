.test-landing,
.main-tokenization,
.partner-contacts,
.onboarding,
.contacts {
    overflow-x: hidden;

    .header:hover .login-button .MuiButton-label {
        color: #00060b !important;
    }

    .landing-header-desktop-buttons {
        .MuiButtonBase-root {
            font-family: inherit;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #ffffff;
            text-transform: none;


            &.login-button {
                background: inherit;
                border: none;
                margin-right: 20px;
                text-transform: none;

                .MuiButton-label {
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: none;
                }

                span {
                    font-weight: 500;
                }
            }

            &.login-button-accreditaton {
                background: inherit;
                border: none;
                margin-right: 20px;

                span {
                    font-weight: 500;
                    color: black;
                    margin-bottom: 0;
                }
            }

            &.get-started-button {
                width: 129px;
                height: 39px;
                border-radius: 0px;
            }
        }
    }

    .marketplace-cards {
        margin-top: 50px;
        padding: 0 20px;
    }

    @-webkit-keyframes bounce {

        0%,
        20%,
        53%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            -webkit-transform: translateZ(0);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            transform: translateZ(0);
        }

        40%,
        43% {
            -webkit-animation-timing-function: cubic-bezier(0.755,
                    0.05,
                    0.855,
                    0.06);
            -webkit-transform: translate3d(0, -30px, 0);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            transform: translate3d(0, -30px, 0);
        }

        70% {
            -webkit-animation-timing-function: cubic-bezier(0.755,
                    0.05,
                    0.855,
                    0.06);
            -webkit-transform: translate3d(0, -15px, 0);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            transform: translate3d(0, -15px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
        }
    }

    @keyframes bounce {

        0%,
        20%,
        53%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            -webkit-transform: translateZ(0);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            transform: translateZ(0);
        }

        40%,
        43% {
            -webkit-animation-timing-function: cubic-bezier(0.755,
                    0.05,
                    0.855,
                    0.06);
            -webkit-transform: translate3d(0, -30px, 0);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            transform: translate3d(0, -30px, 0);
        }

        70% {
            -webkit-animation-timing-function: cubic-bezier(0.755,
                    0.05,
                    0.855,
                    0.06);
            -webkit-transform: translate3d(0, -15px, 0);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            transform: translate3d(0, -15px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
        }
    }

    .bounce {
        -webkit-animation-name: bounce;
        -webkit-transform-origin: center bottom;
        animation-name: bounce;
        transform-origin: center bottom;
    }

    @-webkit-keyframes flash {

        0%,
        50%,
        to {
            opacity: 1;
        }

        25%,
        75% {
            opacity: 0;
        }
    }

    @keyframes flash {

        0%,
        50%,
        to {
            opacity: 1;
        }

        25%,
        75% {
            opacity: 0;
        }
    }

    .flash {
        -webkit-animation-name: flash;
        animation-name: flash;
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        50% {
            -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
        }

        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    @keyframes pulse {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        50% {
            -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
        }

        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    .pulse {
        -webkit-animation-name: pulse;
        animation-name: pulse;
    }

    @-webkit-keyframes rubberBand {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }

        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }

        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }

        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }

        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }

        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    @keyframes rubberBand {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }

        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }

        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }

        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }

        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }

        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    .rubberBand {
        -webkit-animation-name: rubberBand;
        animation-name: rubberBand;
    }

    @-webkit-keyframes shake {

        0%,
        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }

    @keyframes shake {

        0%,
        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }

    .shake {
        -webkit-animation-name: shake;
        animation-name: shake;
    }

    @-webkit-keyframes headShake {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        6.5% {
            -webkit-transform: translateX(-6px) rotateY(-9deg);
            transform: translateX(-6px) rotateY(-9deg);
        }

        18.5% {
            -webkit-transform: translateX(5px) rotateY(7deg);
            transform: translateX(5px) rotateY(7deg);
        }

        31.5% {
            -webkit-transform: translateX(-3px) rotateY(-5deg);
            transform: translateX(-3px) rotateY(-5deg);
        }

        43.5% {
            -webkit-transform: translateX(2px) rotateY(3deg);
            transform: translateX(2px) rotateY(3deg);
        }

        50% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    @keyframes headShake {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        6.5% {
            -webkit-transform: translateX(-6px) rotateY(-9deg);
            transform: translateX(-6px) rotateY(-9deg);
        }

        18.5% {
            -webkit-transform: translateX(5px) rotateY(7deg);
            transform: translateX(5px) rotateY(7deg);
        }

        31.5% {
            -webkit-transform: translateX(-3px) rotateY(-5deg);
            transform: translateX(-3px) rotateY(-5deg);
        }

        43.5% {
            -webkit-transform: translateX(2px) rotateY(3deg);
            transform: translateX(2px) rotateY(3deg);
        }

        50% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    .headShake {
        -webkit-animation-name: headShake;
        -webkit-animation-timing-function: ease-in-out;
        animation-name: headShake;
        animation-timing-function: ease-in-out;
    }

    @-webkit-keyframes swing {
        20% {
            -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
        }

        40% {
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }

        60% {
            -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
        }

        80% {
            -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
        }

        to {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    @keyframes swing {
        20% {
            -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
        }

        40% {
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }

        60% {
            -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
        }

        80% {
            -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
        }

        to {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .swing {
        -webkit-animation-name: swing;
        -webkit-transform-origin: top center;
        animation-name: swing;
        transform-origin: top center;
    }

    @-webkit-keyframes tada {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        10%,
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        }

        30%,
        50%,
        70%,
        90% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }

        40%,
        60%,
        80% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }

        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    @keyframes tada {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        10%,
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
        }

        30%,
        50%,
        70%,
        90% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
        }

        40%,
        60%,
        80% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
        }

        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    .tada {
        -webkit-animation-name: tada;
        animation-name: tada;
    }

    @-webkit-keyframes wobble {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        15% {
            -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
            transform: translate3d(-25%, 0, 0) rotate(-5deg);
        }

        30% {
            -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
            transform: translate3d(20%, 0, 0) rotate(3deg);
        }

        45% {
            -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
            transform: translate3d(-15%, 0, 0) rotate(-3deg);
        }

        60% {
            -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
            transform: translate3d(10%, 0, 0) rotate(2deg);
        }

        75% {
            -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
            transform: translate3d(-5%, 0, 0) rotate(-1deg);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes wobble {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        15% {
            -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
            transform: translate3d(-25%, 0, 0) rotate(-5deg);
        }

        30% {
            -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
            transform: translate3d(20%, 0, 0) rotate(3deg);
        }

        45% {
            -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
            transform: translate3d(-15%, 0, 0) rotate(-3deg);
        }

        60% {
            -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
            transform: translate3d(10%, 0, 0) rotate(2deg);
        }

        75% {
            -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
            transform: translate3d(-5%, 0, 0) rotate(-1deg);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .wobble {
        -webkit-animation-name: wobble;
        animation-name: wobble;
    }

    @-webkit-keyframes jello {

        0%,
        11.1%,
        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        22.2% {
            -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
            transform: skewX(-12.5deg) skewY(-12.5deg);
        }

        33.3% {
            -webkit-transform: skewX(6.25deg) skewY(6.25deg);
            transform: skewX(6.25deg) skewY(6.25deg);
        }

        44.4% {
            -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
            transform: skewX(-3.125deg) skewY(-3.125deg);
        }

        55.5% {
            -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
            transform: skewX(1.5625deg) skewY(1.5625deg);
        }

        66.6% {
            -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
            transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }

        77.7% {
            -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
            transform: skewX(0.390625deg) skewY(0.390625deg);
        }

        88.8% {
            -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
            transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
    }

    @keyframes jello {

        0%,
        11.1%,
        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        22.2% {
            -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
            transform: skewX(-12.5deg) skewY(-12.5deg);
        }

        33.3% {
            -webkit-transform: skewX(6.25deg) skewY(6.25deg);
            transform: skewX(6.25deg) skewY(6.25deg);
        }

        44.4% {
            -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
            transform: skewX(-3.125deg) skewY(-3.125deg);
        }

        55.5% {
            -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
            transform: skewX(1.5625deg) skewY(1.5625deg);
        }

        66.6% {
            -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
            transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }

        77.7% {
            -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
            transform: skewX(0.390625deg) skewY(0.390625deg);
        }

        88.8% {
            -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
            transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
    }

    .jello {
        -webkit-animation-name: jello;
        -webkit-transform-origin: center;
        animation-name: jello;
        transform-origin: center;
    }

    @-webkit-keyframes heartBeat {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        14% {
            -webkit-transform: scale(1.3);
            transform: scale(1.3);
        }

        28% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        42% {
            -webkit-transform: scale(1.3);
            transform: scale(1.3);
        }

        70% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes heartBeat {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        14% {
            -webkit-transform: scale(1.3);
            transform: scale(1.3);
        }

        28% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        42% {
            -webkit-transform: scale(1.3);
            transform: scale(1.3);
        }

        70% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    .heartBeat {
        -webkit-animation-duration: 1.3s;
        -webkit-animation-name: heartBeat;
        -webkit-animation-timing-function: ease-in-out;
        animation-duration: 1.3s;
        animation-name: heartBeat;
        animation-timing-function: ease-in-out;
    }

    @-webkit-keyframes bounceIn {

        0%,
        20%,
        40%,
        60%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        20% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
        }

        40% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        60% {
            -webkit-transform: scale3d(1.03, 1.03, 1.03);
            opacity: 1;
            transform: scale3d(1.03, 1.03, 1.03);
        }

        80% {
            -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
        }

        to {
            -webkit-transform: scaleX(1);
            opacity: 1;
            transform: scaleX(1);
        }
    }

    @keyframes bounceIn {

        0%,
        20%,
        40%,
        60%,
        80%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        20% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
        }

        40% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        60% {
            -webkit-transform: scale3d(1.03, 1.03, 1.03);
            opacity: 1;
            transform: scale3d(1.03, 1.03, 1.03);
        }

        80% {
            -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
        }

        to {
            -webkit-transform: scaleX(1);
            opacity: 1;
            transform: scaleX(1);
        }
    }

    .bounceIn {
        -webkit-animation-duration: 0.75s;
        -webkit-animation-name: bounceIn;
        animation-duration: 0.75s;
        animation-name: bounceIn;
    }

    @-webkit-keyframes bounceInDown {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(0, -3000px, 0);
            opacity: 0;
            transform: translate3d(0, -3000px, 0);
        }

        60% {
            -webkit-transform: translate3d(0, 25px, 0);
            opacity: 1;
            transform: translate3d(0, 25px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes bounceInDown {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(0, -3000px, 0);
            opacity: 0;
            transform: translate3d(0, -3000px, 0);
        }

        60% {
            -webkit-transform: translate3d(0, 25px, 0);
            opacity: 1;
            transform: translate3d(0, 25px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .bounceInDown {
        -webkit-animation-name: bounceInDown;
        animation-name: bounceInDown;
    }

    @-webkit-keyframes bounceInLeft {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(-3000px, 0, 0);
            opacity: 0;
            transform: translate3d(-3000px, 0, 0);
        }

        60% {
            -webkit-transform: translate3d(25px, 0, 0);
            opacity: 1;
            transform: translate3d(25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes bounceInLeft {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(-3000px, 0, 0);
            opacity: 0;
            transform: translate3d(-3000px, 0, 0);
        }

        60% {
            -webkit-transform: translate3d(25px, 0, 0);
            opacity: 1;
            transform: translate3d(25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .bounceInLeft {
        -webkit-animation-name: bounceInLeft;
        animation-name: bounceInLeft;
    }

    @-webkit-keyframes bounceInRight {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(3000px, 0, 0);
            opacity: 0;
            transform: translate3d(3000px, 0, 0);
        }

        60% {
            -webkit-transform: translate3d(-25px, 0, 0);
            opacity: 1;
            transform: translate3d(-25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes bounceInRight {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(3000px, 0, 0);
            opacity: 0;
            transform: translate3d(3000px, 0, 0);
        }

        60% {
            -webkit-transform: translate3d(-25px, 0, 0);
            opacity: 1;
            transform: translate3d(-25px, 0, 0);
        }

        75% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }

        90% {
            -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .bounceInRight {
        -webkit-animation-name: bounceInRight;
        animation-name: bounceInRight;
    }

    @-webkit-keyframes bounceInUp {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(0, 3000px, 0);
            opacity: 0;
            transform: translate3d(0, 3000px, 0);
        }

        60% {
            -webkit-transform: translate3d(0, -20px, 0);
            opacity: 1;
            transform: translate3d(0, -20px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes bounceInUp {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215,
                    0.61,
                    0.355,
                    1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            -webkit-transform: translate3d(0, 3000px, 0);
            opacity: 0;
            transform: translate3d(0, 3000px, 0);
        }

        60% {
            -webkit-transform: translate3d(0, -20px, 0);
            opacity: 1;
            transform: translate3d(0, -20px, 0);
        }

        75% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        90% {
            -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .bounceInUp {
        -webkit-animation-name: bounceInUp;
        animation-name: bounceInUp;
    }

    @-webkit-keyframes bounceOut {
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        50%,
        55% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            opacity: 1;
            transform: scale3d(1.1, 1.1, 1.1);
        }

        to {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }
    }

    @keyframes bounceOut {
        20% {
            -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
        }

        50%,
        55% {
            -webkit-transform: scale3d(1.1, 1.1, 1.1);
            opacity: 1;
            transform: scale3d(1.1, 1.1, 1.1);
        }

        to {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }
    }

    .bounceOut {
        -webkit-animation-duration: 0.75s;
        -webkit-animation-name: bounceOut;
        animation-duration: 0.75s;
        animation-name: bounceOut;
    }

    @-webkit-keyframes bounceOutDown {
        20% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        40%,
        45% {
            -webkit-transform: translate3d(0, -20px, 0);
            opacity: 1;
            transform: translate3d(0, -20px, 0);
        }

        to {
            -webkit-transform: translate3d(0, 2000px, 0);
            opacity: 0;
            transform: translate3d(0, 2000px, 0);
        }
    }

    @keyframes bounceOutDown {
        20% {
            -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
        }

        40%,
        45% {
            -webkit-transform: translate3d(0, -20px, 0);
            opacity: 1;
            transform: translate3d(0, -20px, 0);
        }

        to {
            -webkit-transform: translate3d(0, 2000px, 0);
            opacity: 0;
            transform: translate3d(0, 2000px, 0);
        }
    }

    .bounceOutDown {
        -webkit-animation-name: bounceOutDown;
        animation-name: bounceOutDown;
    }

    @-webkit-keyframes bounceOutLeft {
        20% {
            -webkit-transform: translate3d(20px, 0, 0);
            opacity: 1;
            transform: translate3d(20px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(-2000px, 0, 0);
            opacity: 0;
            transform: translate3d(-2000px, 0, 0);
        }
    }

    @keyframes bounceOutLeft {
        20% {
            -webkit-transform: translate3d(20px, 0, 0);
            opacity: 1;
            transform: translate3d(20px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(-2000px, 0, 0);
            opacity: 0;
            transform: translate3d(-2000px, 0, 0);
        }
    }

    .bounceOutLeft {
        -webkit-animation-name: bounceOutLeft;
        animation-name: bounceOutLeft;
    }

    @-webkit-keyframes bounceOutRight {
        20% {
            -webkit-transform: translate3d(-20px, 0, 0);
            opacity: 1;
            transform: translate3d(-20px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(2000px, 0, 0);
            opacity: 0;
            transform: translate3d(2000px, 0, 0);
        }
    }

    @keyframes bounceOutRight {
        20% {
            -webkit-transform: translate3d(-20px, 0, 0);
            opacity: 1;
            transform: translate3d(-20px, 0, 0);
        }

        to {
            -webkit-transform: translate3d(2000px, 0, 0);
            opacity: 0;
            transform: translate3d(2000px, 0, 0);
        }
    }

    .bounceOutRight {
        -webkit-animation-name: bounceOutRight;
        animation-name: bounceOutRight;
    }

    @-webkit-keyframes bounceOutUp {
        20% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        40%,
        45% {
            -webkit-transform: translate3d(0, 20px, 0);
            opacity: 1;
            transform: translate3d(0, 20px, 0);
        }

        to {
            -webkit-transform: translate3d(0, -2000px, 0);
            opacity: 0;
            transform: translate3d(0, -2000px, 0);
        }
    }

    @keyframes bounceOutUp {
        20% {
            -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
        }

        40%,
        45% {
            -webkit-transform: translate3d(0, 20px, 0);
            opacity: 1;
            transform: translate3d(0, 20px, 0);
        }

        to {
            -webkit-transform: translate3d(0, -2000px, 0);
            opacity: 0;
            transform: translate3d(0, -2000px, 0);
        }
    }

    .bounceOutUp {
        -webkit-animation-name: bounceOutUp;
        animation-name: bounceOutUp;
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }

    @-webkit-keyframes fadeInDown {
        0% {
            -webkit-transform: translate3d(0, -100%, 0);
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInDown {
        0% {
            -webkit-transform: translate3d(0, -100%, 0);
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
    }

    @-webkit-keyframes fadeInDownBig {
        0% {
            -webkit-transform: translate3d(0, -2000px, 0);
            opacity: 0;
            transform: translate3d(0, -2000px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInDownBig {
        0% {
            -webkit-transform: translate3d(0, -2000px, 0);
            opacity: 0;
            transform: translate3d(0, -2000px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInDownBig {
        -webkit-animation-name: fadeInDownBig;
        animation-name: fadeInDownBig;
    }

    @-webkit-keyframes fadeInLeft {
        0% {
            -webkit-transform: translate3d(-100%, 0, 0);
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInLeft {
        0% {
            -webkit-transform: translate3d(-100%, 0, 0);
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
    }

    @-webkit-keyframes fadeInLeftBig {
        0% {
            -webkit-transform: translate3d(-2000px, 0, 0);
            opacity: 0;
            transform: translate3d(-2000px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInLeftBig {
        0% {
            -webkit-transform: translate3d(-2000px, 0, 0);
            opacity: 0;
            transform: translate3d(-2000px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInLeftBig {
        -webkit-animation-name: fadeInLeftBig;
        animation-name: fadeInLeftBig;
    }

    @-webkit-keyframes fadeInRight {
        0% {
            -webkit-transform: translate3d(100%, 0, 0);
            opacity: 0;
            transform: translate3d(100%, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInRight {
        0% {
            -webkit-transform: translate3d(100%, 0, 0);
            opacity: 0;
            transform: translate3d(100%, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInRight {
        -webkit-animation-name: fadeInRight;
        animation-name: fadeInRight;
    }

    @-webkit-keyframes fadeInRightBig {
        0% {
            -webkit-transform: translate3d(2000px, 0, 0);
            opacity: 0;
            transform: translate3d(2000px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInRightBig {
        0% {
            -webkit-transform: translate3d(2000px, 0, 0);
            opacity: 0;
            transform: translate3d(2000px, 0, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInRightBig {
        -webkit-animation-name: fadeInRightBig;
        animation-name: fadeInRightBig;
    }

    @-webkit-keyframes fadeInUp {
        0% {
            -webkit-transform: translate3d(0, 100%, 0);
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInUp {
        0% {
            -webkit-transform: translate3d(0, 100%, 0);
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInUp {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }

    @-webkit-keyframes fadeInUpBig {
        0% {
            -webkit-transform: translate3d(0, 2000px, 0);
            opacity: 0;
            transform: translate3d(0, 2000px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes fadeInUpBig {
        0% {
            -webkit-transform: translate3d(0, 2000px, 0);
            opacity: 0;
            transform: translate3d(0, 2000px, 0);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .fadeInUpBig {
        -webkit-animation-name: fadeInUpBig;
        animation-name: fadeInUpBig;
    }

    @-webkit-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    .fadeOut {
        -webkit-animation-name: fadeOut;
        animation-name: fadeOut;
    }

    @-webkit-keyframes fadeOutDown {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 100%, 0);
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }
    }

    @keyframes fadeOutDown {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 100%, 0);
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }
    }

    .fadeOutDown {
        -webkit-animation-name: fadeOutDown;
        animation-name: fadeOutDown;
    }

    @-webkit-keyframes fadeOutDownBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 2000px, 0);
            opacity: 0;
            transform: translate3d(0, 2000px, 0);
        }
    }

    @keyframes fadeOutDownBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, 2000px, 0);
            opacity: 0;
            transform: translate3d(0, 2000px, 0);
        }
    }

    .fadeOutDownBig {
        -webkit-animation-name: fadeOutDownBig;
        animation-name: fadeOutDownBig;
    }

    @-webkit-keyframes fadeOutLeft {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(-100%, 0, 0);
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }
    }

    @keyframes fadeOutLeft {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(-100%, 0, 0);
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
        }
    }

    .fadeOutLeft {
        -webkit-animation-name: fadeOutLeft;
        animation-name: fadeOutLeft;
    }

    @-webkit-keyframes fadeOutLeftBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(-2000px, 0, 0);
            opacity: 0;
            transform: translate3d(-2000px, 0, 0);
        }
    }

    @keyframes fadeOutLeftBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(-2000px, 0, 0);
            opacity: 0;
            transform: translate3d(-2000px, 0, 0);
        }
    }

    .fadeOutLeftBig {
        -webkit-animation-name: fadeOutLeftBig;
        animation-name: fadeOutLeftBig;
    }

    @-webkit-keyframes fadeOutRight {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0);
            opacity: 0;
            transform: translate3d(100%, 0, 0);
        }
    }

    @keyframes fadeOutRight {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0);
            opacity: 0;
            transform: translate3d(100%, 0, 0);
        }
    }

    .fadeOutRight {
        -webkit-animation-name: fadeOutRight;
        animation-name: fadeOutRight;
    }

    @-webkit-keyframes fadeOutRightBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(2000px, 0, 0);
            opacity: 0;
            transform: translate3d(2000px, 0, 0);
        }
    }

    @keyframes fadeOutRightBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(2000px, 0, 0);
            opacity: 0;
            transform: translate3d(2000px, 0, 0);
        }
    }

    .fadeOutRightBig {
        -webkit-animation-name: fadeOutRightBig;
        animation-name: fadeOutRightBig;
    }

    @-webkit-keyframes fadeOutUp {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, -100%, 0);
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }
    }

    @keyframes fadeOutUp {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, -100%, 0);
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }
    }

    .fadeOutUp {
        -webkit-animation-name: fadeOutUp;
        animation-name: fadeOutUp;
    }

    @-webkit-keyframes fadeOutUpBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, -2000px, 0);
            opacity: 0;
            transform: translate3d(0, -2000px, 0);
        }
    }

    @keyframes fadeOutUpBig {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(0, -2000px, 0);
            opacity: 0;
            transform: translate3d(0, -2000px, 0);
        }
    }

    .fadeOutUpBig {
        -webkit-animation-name: fadeOutUpBig;
        animation-name: fadeOutUpBig;
    }

    @-webkit-keyframes flip {
        0% {
            -webkit-animation-timing-function: ease-out;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
            animation-timing-function: ease-out;
            transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
        }

        40% {
            -webkit-animation-timing-function: ease-out;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
            animation-timing-function: ease-out;
            transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
        }

        50% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
        }

        80% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
        }

        to {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
        }
    }

    @keyframes flip {
        0% {
            -webkit-animation-timing-function: ease-out;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
            animation-timing-function: ease-out;
            transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
        }

        40% {
            -webkit-animation-timing-function: ease-out;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
            animation-timing-function: ease-out;
            transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
        }

        50% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
        }

        80% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0) rotateY(0deg);
        }

        to {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
        }
    }

    .animated.flip {
        -webkit-animation-name: flip;
        -webkit-backface-visibility: visible;
        animation-name: flip;
        backface-visibility: visible;
    }

    @-webkit-keyframes flipInX {
        0% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateX(90deg);
            animation-timing-function: ease-in;
            opacity: 0;
            transform: perspective(400px) rotateX(90deg);
        }

        40% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateX(-20deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) rotateX(-20deg);
        }

        60% {
            -webkit-transform: perspective(400px) rotateX(10deg);
            opacity: 1;
            transform: perspective(400px) rotateX(10deg);
        }

        80% {
            -webkit-transform: perspective(400px) rotateX(-5deg);
            transform: perspective(400px) rotateX(-5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    @keyframes flipInX {
        0% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateX(90deg);
            animation-timing-function: ease-in;
            opacity: 0;
            transform: perspective(400px) rotateX(90deg);
        }

        40% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateX(-20deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) rotateX(-20deg);
        }

        60% {
            -webkit-transform: perspective(400px) rotateX(10deg);
            opacity: 1;
            transform: perspective(400px) rotateX(10deg);
        }

        80% {
            -webkit-transform: perspective(400px) rotateX(-5deg);
            transform: perspective(400px) rotateX(-5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    .flipInX {
        -webkit-animation-name: flipInX;
        -webkit-backface-visibility: visible !important;
        animation-name: flipInX;
        backface-visibility: visible !important;
    }

    @-webkit-keyframes flipInY {
        0% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateY(90deg);
            animation-timing-function: ease-in;
            opacity: 0;
            transform: perspective(400px) rotateY(90deg);
        }

        40% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateY(-20deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) rotateY(-20deg);
        }

        60% {
            -webkit-transform: perspective(400px) rotateY(10deg);
            opacity: 1;
            transform: perspective(400px) rotateY(10deg);
        }

        80% {
            -webkit-transform: perspective(400px) rotateY(-5deg);
            transform: perspective(400px) rotateY(-5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    @keyframes flipInY {
        0% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateY(90deg);
            animation-timing-function: ease-in;
            opacity: 0;
            transform: perspective(400px) rotateY(90deg);
        }

        40% {
            -webkit-animation-timing-function: ease-in;
            -webkit-transform: perspective(400px) rotateY(-20deg);
            animation-timing-function: ease-in;
            transform: perspective(400px) rotateY(-20deg);
        }

        60% {
            -webkit-transform: perspective(400px) rotateY(10deg);
            opacity: 1;
            transform: perspective(400px) rotateY(10deg);
        }

        80% {
            -webkit-transform: perspective(400px) rotateY(-5deg);
            transform: perspective(400px) rotateY(-5deg);
        }

        to {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }
    }

    .flipInY {
        -webkit-animation-name: flipInY;
        -webkit-backface-visibility: visible !important;
        animation-name: flipInY;
        backface-visibility: visible !important;
    }

    @-webkit-keyframes flipOutX {
        0% {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotateX(-20deg);
            opacity: 1;
            transform: perspective(400px) rotateX(-20deg);
        }

        to {
            -webkit-transform: perspective(400px) rotateX(90deg);
            opacity: 0;
            transform: perspective(400px) rotateX(90deg);
        }
    }

    @keyframes flipOutX {
        0% {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotateX(-20deg);
            opacity: 1;
            transform: perspective(400px) rotateX(-20deg);
        }

        to {
            -webkit-transform: perspective(400px) rotateX(90deg);
            opacity: 0;
            transform: perspective(400px) rotateX(90deg);
        }
    }

    .flipOutX {
        -webkit-animation-duration: 0.75s;
        -webkit-animation-name: flipOutX;
        -webkit-backface-visibility: visible !important;
        animation-duration: 0.75s;
        animation-name: flipOutX;
        backface-visibility: visible !important;
    }

    @-webkit-keyframes flipOutY {
        0% {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotateY(-15deg);
            opacity: 1;
            transform: perspective(400px) rotateY(-15deg);
        }

        to {
            -webkit-transform: perspective(400px) rotateY(90deg);
            opacity: 0;
            transform: perspective(400px) rotateY(90deg);
        }
    }

    @keyframes flipOutY {
        0% {
            -webkit-transform: perspective(400px);
            transform: perspective(400px);
        }

        30% {
            -webkit-transform: perspective(400px) rotateY(-15deg);
            opacity: 1;
            transform: perspective(400px) rotateY(-15deg);
        }

        to {
            -webkit-transform: perspective(400px) rotateY(90deg);
            opacity: 0;
            transform: perspective(400px) rotateY(90deg);
        }
    }

    .flipOutY {
        -webkit-animation-duration: 0.75s;
        -webkit-animation-name: flipOutY;
        -webkit-backface-visibility: visible !important;
        animation-duration: 0.75s;
        animation-name: flipOutY;
        backface-visibility: visible !important;
    }

    @-webkit-keyframes lightSpeedIn {
        0% {
            -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
            opacity: 0;
            transform: translate3d(100%, 0, 0) skewX(-30deg);
        }

        60% {
            -webkit-transform: skewX(20deg);
            opacity: 1;
            transform: skewX(20deg);
        }

        80% {
            -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes lightSpeedIn {
        0% {
            -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
            opacity: 0;
            transform: translate3d(100%, 0, 0) skewX(-30deg);
        }

        60% {
            -webkit-transform: skewX(20deg);
            opacity: 1;
            transform: skewX(20deg);
        }

        80% {
            -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .lightSpeedIn {
        -webkit-animation-name: lightSpeedIn;
        -webkit-animation-timing-function: ease-out;
        animation-name: lightSpeedIn;
        animation-timing-function: ease-out;
    }

    @-webkit-keyframes lightSpeedOut {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
            opacity: 0;
            transform: translate3d(100%, 0, 0) skewX(30deg);
        }
    }

    @keyframes lightSpeedOut {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
            opacity: 0;
            transform: translate3d(100%, 0, 0) skewX(30deg);
        }
    }

    .lightSpeedOut {
        -webkit-animation-name: lightSpeedOut;
        -webkit-animation-timing-function: ease-in;
        animation-name: lightSpeedOut;
        animation-timing-function: ease-in;
    }

    @-webkit-keyframes rotateIn {
        0% {
            -webkit-transform: rotate(-200deg);
            -webkit-transform-origin: center;
            opacity: 0;
            transform: rotate(-200deg);
            transform-origin: center;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: center;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: center;
        }
    }

    @keyframes rotateIn {
        0% {
            -webkit-transform: rotate(-200deg);
            -webkit-transform-origin: center;
            opacity: 0;
            transform: rotate(-200deg);
            transform-origin: center;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: center;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: center;
        }
    }

    .rotateIn {
        -webkit-animation-name: rotateIn;
        animation-name: rotateIn;
    }

    @-webkit-keyframes rotateInDownLeft {
        0% {
            -webkit-transform: rotate(-45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(-45deg);
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: left bottom;
        }
    }

    @keyframes rotateInDownLeft {
        0% {
            -webkit-transform: rotate(-45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(-45deg);
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: left bottom;
        }
    }

    .rotateInDownLeft {
        -webkit-animation-name: rotateInDownLeft;
        animation-name: rotateInDownLeft;
    }

    @-webkit-keyframes rotateInDownRight {
        0% {
            -webkit-transform: rotate(45deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(45deg);
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: right bottom;
        }
    }

    @keyframes rotateInDownRight {
        0% {
            -webkit-transform: rotate(45deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(45deg);
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: right bottom;
        }
    }

    .rotateInDownRight {
        -webkit-animation-name: rotateInDownRight;
        animation-name: rotateInDownRight;
    }

    @-webkit-keyframes rotateInUpLeft {
        0% {
            -webkit-transform: rotate(45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(45deg);
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: left bottom;
        }
    }

    @keyframes rotateInUpLeft {
        0% {
            -webkit-transform: rotate(45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(45deg);
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: left bottom;
        }
    }

    .rotateInUpLeft {
        -webkit-animation-name: rotateInUpLeft;
        animation-name: rotateInUpLeft;
    }

    @-webkit-keyframes rotateInUpRight {
        0% {
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(-90deg);
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: right bottom;
        }
    }

    @keyframes rotateInUpRight {
        0% {
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(-90deg);
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: translateZ(0);
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform: translateZ(0);
            transform-origin: right bottom;
        }
    }

    .rotateInUpRight {
        -webkit-animation-name: rotateInUpRight;
        animation-name: rotateInUpRight;
    }

    @-webkit-keyframes rotateOut {
        0% {
            -webkit-transform-origin: center;
            opacity: 1;
            transform-origin: center;
        }

        to {
            -webkit-transform: rotate(200deg);
            -webkit-transform-origin: center;
            opacity: 0;
            transform: rotate(200deg);
            transform-origin: center;
        }
    }

    @keyframes rotateOut {
        0% {
            -webkit-transform-origin: center;
            opacity: 1;
            transform-origin: center;
        }

        to {
            -webkit-transform: rotate(200deg);
            -webkit-transform-origin: center;
            opacity: 0;
            transform: rotate(200deg);
            transform-origin: center;
        }
    }

    .rotateOut {
        -webkit-animation-name: rotateOut;
        animation-name: rotateOut;
    }

    @-webkit-keyframes rotateOutDownLeft {
        0% {
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: rotate(45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(45deg);
            transform-origin: left bottom;
        }
    }

    @keyframes rotateOutDownLeft {
        0% {
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: rotate(45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(45deg);
            transform-origin: left bottom;
        }
    }

    .rotateOutDownLeft {
        -webkit-animation-name: rotateOutDownLeft;
        animation-name: rotateOutDownLeft;
    }

    @-webkit-keyframes rotateOutDownRight {
        0% {
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: rotate(-45deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(-45deg);
            transform-origin: right bottom;
        }
    }

    @keyframes rotateOutDownRight {
        0% {
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: rotate(-45deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(-45deg);
            transform-origin: right bottom;
        }
    }

    .rotateOutDownRight {
        -webkit-animation-name: rotateOutDownRight;
        animation-name: rotateOutDownRight;
    }

    @-webkit-keyframes rotateOutUpLeft {
        0% {
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: rotate(-45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(-45deg);
            transform-origin: left bottom;
        }
    }

    @keyframes rotateOutUpLeft {
        0% {
            -webkit-transform-origin: left bottom;
            opacity: 1;
            transform-origin: left bottom;
        }

        to {
            -webkit-transform: rotate(-45deg);
            -webkit-transform-origin: left bottom;
            opacity: 0;
            transform: rotate(-45deg);
            transform-origin: left bottom;
        }
    }

    .rotateOutUpLeft {
        -webkit-animation-name: rotateOutUpLeft;
        animation-name: rotateOutUpLeft;
    }

    @-webkit-keyframes rotateOutUpRight {
        0% {
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: rotate(90deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(90deg);
            transform-origin: right bottom;
        }
    }

    @keyframes rotateOutUpRight {
        0% {
            -webkit-transform-origin: right bottom;
            opacity: 1;
            transform-origin: right bottom;
        }

        to {
            -webkit-transform: rotate(90deg);
            -webkit-transform-origin: right bottom;
            opacity: 0;
            transform: rotate(90deg);
            transform-origin: right bottom;
        }
    }

    .rotateOutUpRight {
        -webkit-animation-name: rotateOutUpRight;
        animation-name: rotateOutUpRight;
    }

    @-webkit-keyframes hinge {
        0% {
            -webkit-animation-timing-function: ease-in-out;
            -webkit-transform-origin: top left;
            animation-timing-function: ease-in-out;
            transform-origin: top left;
        }

        20%,
        60% {
            -webkit-animation-timing-function: ease-in-out;
            -webkit-transform: rotate(80deg);
            -webkit-transform-origin: top left;
            animation-timing-function: ease-in-out;
            transform: rotate(80deg);
            transform-origin: top left;
        }

        40%,
        80% {
            -webkit-animation-timing-function: ease-in-out;
            -webkit-transform: rotate(60deg);
            -webkit-transform-origin: top left;
            animation-timing-function: ease-in-out;
            opacity: 1;
            transform: rotate(60deg);
            transform-origin: top left;
        }

        to {
            -webkit-transform: translate3d(0, 700px, 0);
            opacity: 0;
            transform: translate3d(0, 700px, 0);
        }
    }

    @keyframes hinge {
        0% {
            -webkit-animation-timing-function: ease-in-out;
            -webkit-transform-origin: top left;
            animation-timing-function: ease-in-out;
            transform-origin: top left;
        }

        20%,
        60% {
            -webkit-animation-timing-function: ease-in-out;
            -webkit-transform: rotate(80deg);
            -webkit-transform-origin: top left;
            animation-timing-function: ease-in-out;
            transform: rotate(80deg);
            transform-origin: top left;
        }

        40%,
        80% {
            -webkit-animation-timing-function: ease-in-out;
            -webkit-transform: rotate(60deg);
            -webkit-transform-origin: top left;
            animation-timing-function: ease-in-out;
            opacity: 1;
            transform: rotate(60deg);
            transform-origin: top left;
        }

        to {
            -webkit-transform: translate3d(0, 700px, 0);
            opacity: 0;
            transform: translate3d(0, 700px, 0);
        }
    }

    .hinge {
        -webkit-animation-duration: 2s;
        -webkit-animation-name: hinge;
        animation-duration: 2s;
        animation-name: hinge;
    }

    @-webkit-keyframes jackInTheBox {
        0% {
            -webkit-transform: scale(0.1) rotate(30deg);
            -webkit-transform-origin: center bottom;
            opacity: 0;
            transform: scale(0.1) rotate(30deg);
            transform-origin: center bottom;
        }

        50% {
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }

        70% {
            -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
        }

        to {
            -webkit-transform: scale(1);
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes jackInTheBox {
        0% {
            -webkit-transform: scale(0.1) rotate(30deg);
            -webkit-transform-origin: center bottom;
            opacity: 0;
            transform: scale(0.1) rotate(30deg);
            transform-origin: center bottom;
        }

        50% {
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }

        70% {
            -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
        }

        to {
            -webkit-transform: scale(1);
            opacity: 1;
            transform: scale(1);
        }
    }

    .jackInTheBox {
        -webkit-animation-name: jackInTheBox;
        animation-name: jackInTheBox;
    }

    @-webkit-keyframes rollIn {
        0% {
            -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
            opacity: 0;
            transform: translate3d(-100%, 0, 0) rotate(-120deg);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    @keyframes rollIn {
        0% {
            -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
            opacity: 0;
            transform: translate3d(-100%, 0, 0) rotate(-120deg);
        }

        to {
            -webkit-transform: translateZ(0);
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .rollIn {
        -webkit-animation-name: rollIn;
        animation-name: rollIn;
    }

    @-webkit-keyframes rollOut {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
            opacity: 0;
            transform: translate3d(100%, 0, 0) rotate(120deg);
        }
    }

    @keyframes rollOut {
        0% {
            opacity: 1;
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
            opacity: 0;
            transform: translate3d(100%, 0, 0) rotate(120deg);
        }
    }

    .rollOut {
        -webkit-animation-name: rollOut;
        animation-name: rollOut;
    }

    @-webkit-keyframes zoomIn {
        0% {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    @keyframes zoomIn {
        0% {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        50% {
            opacity: 1;
        }
    }

    .zoomIn {
        -webkit-animation-name: zoomIn;
        animation-name: zoomIn;
    }

    @-webkit-keyframes zoomInDown {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        }
    }

    @keyframes zoomInDown {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        }
    }

    .zoomInDown {
        -webkit-animation-name: zoomInDown;
        animation-name: zoomInDown;
    }

    @-webkit-keyframes zoomInLeft {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        }
    }

    @keyframes zoomInLeft {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
        }
    }

    .zoomInLeft {
        -webkit-animation-name: zoomInLeft;
        animation-name: zoomInLeft;
    }

    @-webkit-keyframes zoomInRight {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        }
    }

    @keyframes zoomInRight {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
        }
    }

    .zoomInRight {
        -webkit-animation-name: zoomInRight;
        animation-name: zoomInRight;
    }

    @-webkit-keyframes zoomInUp {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        }
    }

    @keyframes zoomInUp {
        0% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
        }

        60% {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        }
    }

    .zoomInUp {
        -webkit-animation-name: zoomInUp;
        animation-name: zoomInUp;
    }

    @-webkit-keyframes zoomOut {
        0% {
            opacity: 1;
        }

        50% {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        to {
            opacity: 0;
        }
    }

    @keyframes zoomOut {
        0% {
            opacity: 1;
        }

        50% {
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        to {
            opacity: 0;
        }
    }

    .zoomOut {
        -webkit-animation-name: zoomOut;
        animation-name: zoomOut;
    }

    @-webkit-keyframes zoomOutDown {
        40% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        }

        to {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            -webkit-transform-origin: center bottom;
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform-origin: center bottom;
        }
    }

    @keyframes zoomOutDown {
        40% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
        }

        to {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            -webkit-transform-origin: center bottom;
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform-origin: center bottom;
        }
    }

    .zoomOutDown {
        -webkit-animation-name: zoomOutDown;
        animation-name: zoomOutDown;
    }

    @-webkit-keyframes zoomOutLeft {
        40% {
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        }

        to {
            -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
            -webkit-transform-origin: left center;
            opacity: 0;
            transform: scale(0.1) translate3d(-2000px, 0, 0);
            transform-origin: left center;
        }
    }

    @keyframes zoomOutLeft {
        40% {
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
        }

        to {
            -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
            -webkit-transform-origin: left center;
            opacity: 0;
            transform: scale(0.1) translate3d(-2000px, 0, 0);
            transform-origin: left center;
        }
    }

    .zoomOutLeft {
        -webkit-animation-name: zoomOutLeft;
        animation-name: zoomOutLeft;
    }

    @-webkit-keyframes zoomOutRight {
        40% {
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        }

        to {
            -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
            -webkit-transform-origin: right center;
            opacity: 0;
            transform: scale(0.1) translate3d(2000px, 0, 0);
            transform-origin: right center;
        }
    }

    @keyframes zoomOutRight {
        40% {
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
        }

        to {
            -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
            -webkit-transform-origin: right center;
            opacity: 0;
            transform: scale(0.1) translate3d(2000px, 0, 0);
            transform-origin: right center;
        }
    }

    .zoomOutRight {
        -webkit-animation-name: zoomOutRight;
        animation-name: zoomOutRight;
    }

    @-webkit-keyframes zoomOutUp {
        40% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        }

        to {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            -webkit-transform-origin: center bottom;
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform-origin: center bottom;
        }
    }

    @keyframes zoomOutUp {
        40% {
            -webkit-animation-timing-function: cubic-bezier(0.55,
                    0.055,
                    0.675,
                    0.19);
            -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            opacity: 1;
            transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        }

        to {
            -webkit-animation-timing-function: cubic-bezier(0.175,
                    0.885,
                    0.32,
                    1);
            -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            -webkit-transform-origin: center bottom;
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            opacity: 0;
            transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform-origin: center bottom;
        }
    }

    .zoomOutUp {
        -webkit-animation-name: zoomOutUp;
        animation-name: zoomOutUp;
    }

    @-webkit-keyframes slideInDown {
        0% {
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes slideInDown {
        0% {
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .slideInDown {
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
    }

    @-webkit-keyframes slideInLeft {
        0% {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes slideInLeft {
        0% {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .slideInLeft {
        -webkit-animation-name: slideInLeft;
        animation-name: slideInLeft;
    }

    @-webkit-keyframes slideInRight {
        0% {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes slideInRight {
        0% {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .slideInRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
    }

    @-webkit-keyframes slideInUp {
        0% {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    @keyframes slideInUp {
        0% {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .slideInUp {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
    }

    @-webkit-keyframes slideOutDown {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: hidden;
        }
    }

    @keyframes slideOutDown {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: hidden;
        }
    }

    .slideOutDown {
        -webkit-animation-name: slideOutDown;
        animation-name: slideOutDown;
    }

    @-webkit-keyframes slideOutLeft {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
            visibility: hidden;
        }
    }

    @keyframes slideOutLeft {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
            visibility: hidden;
        }
    }

    .slideOutLeft {
        -webkit-animation-name: slideOutLeft;
        animation-name: slideOutLeft;
    }

    @-webkit-keyframes slideOutRight {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: hidden;
        }
    }

    @keyframes slideOutRight {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: hidden;
        }
    }

    .slideOutRight {
        -webkit-animation-name: slideOutRight;
        animation-name: slideOutRight;
    }

    @-webkit-keyframes slideOutUp {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
            visibility: hidden;
        }
    }

    @keyframes slideOutUp {
        0% {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }

        to {
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
            visibility: hidden;
        }
    }

    .slideOutUp {
        -webkit-animation-name: slideOutUp;
        animation-name: slideOutUp;
    }

    .animated {
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-duration: 1s;
        animation-fill-mode: both;
    }

    .animated.infinite {
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .animated.delay-1s {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }

    .animated.delay-2s {
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
    }

    .animated.delay-3s {
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
    }

    .animated.delay-4s {
        -webkit-animation-delay: 4s;
        animation-delay: 4s;
    }

    .animated.delay-5s {
        -webkit-animation-delay: 5s;
        animation-delay: 5s;
    }

    .animated.fast {
        -webkit-animation-duration: 0.8s;
        animation-duration: 0.8s;
    }

    .animated.faster {
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
    }

    .animated.slow {
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
    }

    .animated.slower {
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
    }

    @media (prefers-reduced-motion) {
        .animated {
            -webkit-animation: unset !important;
            -webkit-transition: none !important;
            animation: unset !important;
            transition: none !important;
        }
    }

    /* Colors */
    @font-face {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 400;
        src: local("Montserrat Regular"), local("Montserrat-Regular"),
            url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format("truetype");
    }

    @font-face {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        src: local("Montserrat Medium"), local("Montserrat-Medium"),
            url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gnD-w.ttf) format("truetype");
    }

    @font-face {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 600;
        src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
            url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gnD-w.ttf) format("truetype");
    }

    @font-face {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 700;
        src: local("Montserrat Bold"), local("Montserrat-Bold"),
            url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf) format("truetype");
    }

    @font-face {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 800;
        src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
            url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_c5H3gnD-w.ttf) format("truetype");
    }

    html,
    body {
        margin: 0;
        padding: 0;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 16px;
        overflow-x: hidden;
    }

    .buttons-container {
        width: 100%;
    }

    .inner-btns-container {
        display: flex;
        width: 40%;
        flex-direction: row;
        justify-content: space-between;
    }

    @media (max-width: 770px) {
        .inner-btns-container {
            flex-direction: column;
            width: 100% !important;
            align-items: center;
        }
    }

    .inner-btns-container {
        width: 40%;
        margin: auto;
    }

    .another_page_main {
        width: 100%;
        position: relative;
        height: 100%;
    }

    .custombtn {
        background-color: white;
        color: black;
        padding: 10px;
    }

    .custombtn2 {
        background-color: #0ec22b;
        color: white !important;
        padding: 10px;
    }

    .banner {
        background-color: #0ec22b;
        width: 50%;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    @media (max-width: 600px) {
        .banner {
            position: relative;
            top: 70px;
            width: 70%;
        }

        .custombtn {
            padding: 7px;
        }

        .custombtn2 {
            background-color: #0ec22b;
            color: white !important;
            padding: 10px;
        }
    }

    .innner_main_div {
        width: 50%;

        height: 100%;
        margin-top: 10%;
        margin-right: auto;
        margin-left: auto;
    }

    .another_page_title {
        border-bottom: 1px solid #366091;
        color: #366091;
    }

    .another-p {
        margin: 0;
        padding: 22px 10px;
        color: #366091;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 43px;
        text-transform: uppercase;
    }

    .sec-title {
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
        padding: 22px 10px;
        color: #366091;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 43px;
        text-transform: uppercase;
    }

    .umbrella {
        width: 200px !important;
        height: 200px;
    }

    /*.header__link {*/
    /*  margin-right: 2%; }*/

    ul,
    li,
    a {
        text-decoration: none;
        outline: none;
        list-style: none;
        margin: 0;
        padding: 0;
        color: inherit;
    }

    ol {
        list-style: circle;
    }

    .list {
        list-style: disc;
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
    }

    .container {
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;
    }

    .section {
        padding-top: 149px;
        padding-bottom: 93px;
    }

    .text-accent {
        color: #0db428;
    }

    .result-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form-wrapper .result-container {
        display: none;
    }

    .form-wrapper.show-result .form-container {
        display: none;
    }

    .form-wrapper.show-result .result-container {
        display: flex;
    }

    @keyframes showHeader {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes hideHeader {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(0);
        }
    }

    .button {
        cursor: pointer;
        max-width: 296px;
        width: 100%;
        height: 56px;
        font-size: 16px;
        background-color: #0ec22b;
        border: none;
        color: #ffffff;
        letter-spacing: 0.13px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        text-align: center;

        &:disabled {
            background-color: #1fa236;
            cursor: default;
        }
    }

    .button:hover {
        background-color: #1fa236;
    }

    .button:hover .button__icon {
        animation: arrow 1s ease infinite;
        margin-left: 10px;
    }

    .button--default {
        border: 1px solid #d4d2d2;
        background-color: #ffffff;
        box-sizing: border-box;
        color: #1c1b1b;
    }

    .button--default:hover {
        background-color: #fafafa;
    }

    .button__text-block {
        width: 100%;
        position: relative;
        font-weight: 600;
    }

    .button__icon-block {
        max-width: 56px;
        width: 100%;
        height: 56px;
        background-color: rgba(10, 55, 16, 0.1);
        position: relative;
    }

    .button__icon {
        background: url("./../../../Assets/landing/icon__arrow.svg") no-repeat;
        width: 17px;
        height: 2px;
        z-index: 1;
        position: absolute;
        padding: 7px 1px;
        top: 40%;
        right: 17px;
    }

    .button--medium {
        max-width: 240px;
        width: 100%;
        height: 56px;
    }

    .button--small {
        max-width: 218px;
        width: 100%;
        height: 56px;
    }

    .button--xxs {
        height: 56px;
        padding: 18px 63px;
        max-width: 189px;
        width: 100%;
        margin-left: 25%;
    }

    .button__scroll {
        max-width: 56px;
        width: 100%;
        height: 56px;
        z-index: 9;
        bottom: 82px;
        right: 12px;
        justify-content: center;
        position: fixed;
        display: none;
        transform: rotate(-90deg);
        background: #32b527 url("./../../../Assets/landing/icon__arrow.svg") no-repeat 18px;
    }

    @media only screen and (max-width: 600px) {
        .button {
            max-width: 274px;
            width: 100%;
            white-space: nowrap;
            font-size: 15px;
        }

        .button__scroll {
            max-width: 60px;
            width: 100%;
        }

        .button__icon {
            top: 39%;
        }
    }

    @keyframes arrow {

        0%,
        100% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(10px, 0);
        }
    }

    .text__12 {
        font-size: 12px;
    }

    .text__13 {
        font-size: 13px;
    }

    .text__14 {
        font-size: 14px;
    }

    .text__16 {
        font-size: 16px;
    }

    .text__18 {
        font-size: 18px;
    }

    .text__22 {
        font-size: 22px;
    }

    .text__40 {
        font-size: 16px;
    }

    .text__50 {
        font-size: 50px;
    }

    .text__medium {
        font-weight: 500;
    }

    .text__bold,
    .text--bold {
        font-weight: 700;
    }

    .text__uppercase {
        text-transform: uppercase;
    }

    .text--green {
        color: #0ec22b;
    }

    .text--grey {
        color: #ffffff;
        margin-top: 0;
    }

    .text--underlined {
        text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
        .text--newlined-mobile {
            display: block;
        }
    }

    .icon {
        display: inline-block;
    }

    .icon--fb {
        width: 20px;
        height: 20px;
        background-image: url("./../../../Assets/landing/fb-icon.svg");
    }

    .icon--ln {
        width: 20px;
        height: 20px;
        background-image: url("./../../../Assets/landing/ln-icon.svg");
    }

    .icon--arrow-back {
        width: 20px;
        height: 12px;
        background-image: url("./../../../Assets/landing/arrow-back.svg");
    }

    .icon--arrow {
        width: 20px;
        height: 12px;
        background-image: url("./../../../Assets/landing/arrow.svg");
    }

    .icon--arrow-back-accent {
        width: 20px;
        height: 12px;
        background-image: url("./../../../Assets/landing/arrow-back-accent.svg");
    }

    .icon--edit {
        width: 14px;
        height: 15px;
        background-image: url("./../../../Assets/landing/icons/edit-icon.svg");
    }

    .icon--successful {
        width: 233px;
        height: 233px;
        background-image: url("./../../../Assets/landing/icons/successful-icon.svg");
    }

    .form-control.uploader {
        display: block;
        margin: 0 auto;
        width: 100%;
        box-sizing: border-box;
        border: 1px dashed #a7a7a7;
        transition: all 0.2s ease-in-out;
    }

    .form-control.uploader--hover {
        border: 1px solid #0db428;
    }

    .form-control.uploader__label {
        width: 100%;
        display: block;
        height: 100%;
        width: 100%;
        padding: 2rem 1.5rem;
        text-align: center;
        background: #fff;
        border-radius: 7px;
        border: 3px solid #eee;
        transition: all 0.2s ease;
        user-select: none;
    }

    .form-control.uploader__label:hover {
        border-color: #0db428;
    }

    .form-control.uploader__label.hover {
        border: 3px solid #0db428;
        box-shadow: inset 0 0 0 6px #eee;
    }

    .form-control.uploader__label.hover #start i.fa {
        transform: scale(0.8);
        opacity: 0.3;
    }

    .form-control.uploader__start,
    .form-control.uploader #start {
        box-sizing: border-box;
        cursor: pointer;
        padding: 25px;
        float: left;
        clear: both;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .form-control.uploader__start.hidden,
    .form-control.uploader #start.hidden {
        display: none;
    }

    .form-control.uploader__start i.fa,
    .form-control.uploader #start i.fa {
        font-size: 50px;
        margin-bottom: 1rem;
        transition: all 0.2s ease-in-out;
    }

    .form-control.uploader__start .add-file__icon,
    .form-control.uploader #start .add-file__icon {
        margin-right: 20px;
    }

    .form-control.uploader__response,
    .form-control.uploader #response {
        float: left;
        clear: both;
        width: 100%;
    }

    .form-control.uploader__response.hidden,
    .form-control.uploader #response.hidden {
        display: none;
    }

    .form-control.uploader__response__messages,
    .form-control.uploader__response #messages,
    .form-control.uploader #response__messages,
    .form-control.uploader #response #messages {
        margin-bottom: 0.5rem;
    }

    .form-control.uploader__file-image,
    .form-control.uploader #file-image {
        display: inline;
        margin: 0 auto 0.5rem auto;
        width: auto;
        height: auto;
        max-width: 180px;
    }

    .form-control.uploader__file-image.hidden,
    .form-control.uploader #file-image.hidden {
        display: none;
    }

    .form-control.uploader__notimage,
    .form-control.uploader #notimage {
        display: block;
        float: left;
        clear: both;
        width: 100%;
    }

    .form-control.uploader__notimage.hidden,
    .form-control.uploader #notimage.hidden {
        display: none;
    }

    .form-control.uploader progress,
    .form-control.uploader .progress {
        display: inline;
        clear: both;
        margin: 0 auto;
        width: 100%;
        max-width: 180px;
        height: 8px;
        border: 0;
        border-radius: 4px;
        background-color: #eee;
        overflow: hidden;
    }

    .form-control.uploader .progress[value]::-webkit-progress-bar {
        border-radius: 4px;
        background-color: #eee;
    }

    .form-control.uploader .progress[value]::-webkit-progress-value {
        background: linear-gradient(to right, #0a8e20 0%, #0db428 50%);
        border-radius: 4px;
    }

    .form-control.uploader .progress[value]::-moz-progress-bar {
        background: linear-gradient(to right, #0a8e20 0%, #0db428 50%);
        border-radius: 4px;
    }

    .form-control.uploader input[type="file"] {
        display: none;
    }

    .form-control.uploader .btn {
        display: inline-block;
        margin: 0.5rem 0.5rem 1rem 0.5rem;
        clear: both;
        font-family: inherit;
        font-weight: 700;
        font-size: 14px;
        text-decoration: none;
        text-transform: initial;
        border: none;
        border-radius: 0.2rem;
        outline: none;
        padding: 0 1rem;
        height: 36px;
        line-height: 36px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        box-sizing: border-box;
        background: #0db428;
        border-color: #0db428;
        cursor: pointer;
    }

    .form-control.uploader .hidden {
        display: none;
    }

    /*======== SECTION 1 ==========*/
    .section1 {
        padding-top: 150px;
        position: relative;
        height: 700px;
        // background: url("https://solidblock-dev-asset-image.s3.eu-central-1.amazonaws.com/landing-bg.jpg");
        background-position: center !important;
        background-repeat: no-repeat !important;
        width: 100%;
        background-size: cover !important;

        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
        }
    }

    .nav-burger {
        display: none;
    }

    .navigation {
        color: #ffffff;
    }

    .navigation__container {
        display: flex;
        align-items: center;
    }

    .navigation__item {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        cursor: pointer;
    }

    .navigation__item:not(:last-child) {
        margin-right: 22px;
    }

    .navigation__item a {
        color: #ffffff;
    }

    .main-text {
        color: #ffffff;
        display: flex;
        position: relative;

        flex-direction: column;
        align-items: center;
    }

    .main-text__title {
        font-weight: 600;
        font-size: 50px;
        line-height: 64px;
        max-width: 940px;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
    }

    .main-text__subtitle {
        max-width: 584px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.13px;
    }

    .main-text__subtitle:not(:last-child) {
        margin-bottom: 45px;
    }

    .main-text__text {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.15px;
        margin-bottom: 24px;
    }

    .main-text__button {
        margin-top: 15px;
        margin: 0 10px;
    }

    .mob-navigation {
        display: none;
        z-index: 11;
        overflow-y: scroll;
    }

    .mob-navigation-shown {
        display: block;
    }

    .mob-navigation .navigation__item {
        font-size: 16px;
    }

    .mob-navigation a {
        color: #00060b;
    }

    .logo {
        width: 200px;
        height: 21px;
    }

    .logo__mobile {
        display: none;
    }

    .logo img {
        width: 100%;
    }

    .section8 {
        overflow-x: hidden;
    }

    /*==========SECTION 2===========*/
    .section2 {
        background-color: #f4f6f6;
        padding-bottom: 190px;
    }

    .section2__container {
        margin: 0 6%;
    }

    .section2 .section-title,
    .section2 .section-title__title {
        align-items: center;
        text-align: center;
    }

    .section-title {
        display: flex;
        flex-direction: column;
    }

    .section-title__subtitle-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .section-title__subtitle {
        color: #0ec22b;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1.51px;
        display: flex;
    }

    .section-title__subtitle--green-line {
        width: 43px;
        height: 2px;
        background-color: #0ec22b;
        margin-right: 16px;
        margin-top: 10px;
    }

    .section-title__title {
        font-size: 40px;
        font-weight: 600;
        line-height: 56px;
        margin-top: 23px;
        margin-bottom: 0;
    }

    .about {
        margin-top: 124px;
        display: flex;
        flex-direction: row;
    }

    .about__info {
        max-width: 373px;
        width: 100%;
        box-sizing: border-box;
        padding-right: 25px;
    }

    .about__title {
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 22px;
    }

    .about__list_item {
        margin-bottom: 24px;
        color: #212a32;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
    }

    .about__list .about__list_item {
        position: relative;
        margin-left: 19px;
    }

    .about__list_item:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background-color: #0ec22b;
        position: absolute;
        top: 8px;
        left: -20px;
    }

    .about__img {
        max-width: 848px;
        width: 100%;
        max-height: 466px;
        height: 100%;
        transition: all 0.2s linear;
    }

    /*=========== SECTION 3 ========*/
    .section3 {
        display: flex;
        flex-direction: row;
    }

    .section3__text-container {
        margin-top: 38px;
    }

    .section3__img {
        max-width: 457px;
        width: 100%;
        max-height: 618px;
        height: 100%;
        margin-right: 98px;
        transition: all 0.2s linear;
    }

    .section3__image-mobile {
        display: none;
    }

    .section3__intro,
    .section3__text {
        max-width: 490px;
        width: 100%;
        color: #212a32;
        font-weight: 400;
        line-height: 28px;
    }

    .section3__intro+.section3__text,
    .section3__text+.section3__text {
        max-width: 510px;
    }

    .section3__intro {
        margin-top: 30px;
        margin-bottom: 40px;
        font-size: 18px;
    }

    .section3__text {
        font-size: 14px;
        margin-bottom: 40px;
    }

    .section3 .section-title__title {
        max-width: 649px;
        width: 100%;
        font-size: 32px;
        font-weight: 600;
        line-height: 43px;
    }

    /*=========== SECTION 4 ========*/
    .section4 {
        height: 100%;
        background: url("./../../../Assets/landing/section4__bg.svg");
        width: 100%;
        background-size: cover;
    }

    .section4 .about__list {
        max-width: 313px;
        margin: 0 auto;
    }

    .section4 .about__list_item {
        margin-bottom: 20px;
        color: #00060b;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
    }

    .section4 .about__list_item {
        margin-left: 20px;
    }

    .section4 .top__text {
        max-width: 430px;
    }

    .section4 .blocks .button {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -45px;
    }

    .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 64px;
        padding-top: 93px;
    }

    .top__subtitle {
        color: #0ec22b;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1.51px;
    }

    .top__title {
        color: #ffffff;
        font-size: 40px;
        font-weight: 600;
        line-height: 56px;
        margin-top: 14px;
        margin-bottom: 21px;
        max-width: 892px;
        width: 100%;
        text-align: center;
    }

    .top__text {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        max-width: 511px;
        width: 100%;
        text-align: center;
    }

    .blocks {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1233px;
        margin: 0 auto;
    }

    .blocks__list-container {
        height: 228px;
    }

    .blocks__content-decor {
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: #0ec22b;
        top: 0;
        left: 0;
        transition: all 0.2s ease-in-out;
        z-index: 5;
    }

    .blocks__content-fake-top,
    .blocks__content-fake-bottom {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #ffffff;
        transition: all 0.2s ease-in-out;
    }

    .blocks__content {
        position: relative;
        max-height: 387px;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        padding-top: 5px;
    }

    .blocks__icon {
        margin: 0 auto;
        width: 96px;
        height: 96px;
        box-shadow: 0 2px 11px rgba(0, 0, 0, 0.17);
        border-radius: 2px;
        background-color: #0ec22b;
        border-top: 5px solid #0ec22b;
        position: relative;
        top: 48px;
        z-index: 6;
        transition: all 0.2s ease-in-out;
    }

    .blocks__icon--equity {
        background: url("./../../../Assets/landing/icon__equity.svg") no-repeat;
        width: 48px;
        height: 48px;
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 24px;
    }

    .blocks__icon--group {
        background: url("./../../../Assets/landing/icon__group.svg") no-repeat;
        width: 50px;
        height: 42px;
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 24px;
    }

    .blocks__icon--estate {
        background: url("./../../../Assets/landing/icon__estate.svg") no-repeat;
        width: 41px;
        height: 45px;
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 30px;
    }

    .blocks__item {
        margin-bottom: 131px;
        width: 380px;
        transition: all 0.2s ease-in-out;
    }

    .blocks__item:hover .blocks__icon {
        transform: translateY(-40px);
    }

    .blocks__item:hover .blocks__content {
        transform: translateY(-40px);
    }

    .blocks__item:hover .blocks__content-decor {
        transform: scaleX(1.06);
    }

    .blocks__item:hover .blocks__content-fake-top {
        transform: translateY(0) scaleX(1.06);
    }

    .blocks__item:hover .blocks__content-fake-bottom {
        transform: translateY(95px) scaleX(1.06);
    }

    .blocks__item:hover .blocks__button {
        opacity: 1;
    }

    .blocks__title {
        position: relative;
        margin-top: 52px;
        color: #00060b;
        font-size: 26px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: 0.22px;
    }

    .blocks__subtitle {
        position: relative;
        color: #0ec22b;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 21px;
    }

    .blocks__button {
        position: relative;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        margin: 43px auto 0 auto;
        justify-content: center;
    }

    /*================SECTION 5 ===========*/
    .section5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 6%;
        background-color: #f4f6f6;
    }

    .section5 .top__subtitle {
        color: #212a32;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0;
    }

    .section5 .top__subtitle p {
        margin: 5px;
    }

    .section5 .top__title {
        color: #000000;
        width: 630px;
    }

    .section5 .blocks {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .section5 .blocks__container {
        background: #ffffff;
        padding: 20px 52px 51px 20px;
        box-shadow: 0 2px 11px rgba(0, 0, 0, 0.2);
    }

    .section5 .blocks__item {
        max-width: 279px;
        width: 100%;
        height: 272px;
        background: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section5 .blocks__line {
        width: 252px;
        background-color: #0ec22b;
        height: 5px;
    }

    .section5 .blocks__span-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 7px;
    }

    .section5 .blocks__span-container_number {
        color: #0ec22b;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1.51px;
    }

    .section5__blocks .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .section5__blocks .content__title {
        color: #0c0f09;
        font-size: 21px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px;
    }

    .section5__blocks .content__title--height {
        width: 134px;
        height: 61px;
    }

    .section5__blocks .content__title--pre {
        white-space: pre;
    }

    .section5__blocks .content__text {
        color: #6c6c6c;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.11px;
        margin-top: 25px;
    }

    .section5__blocks .blocks__content {
        border-top: none;
    }

    .section5#section5 .section5__blocks {
        margin-bottom: 122px;
        justify-content: center;
    }

    .section5#section5 .blocks__item {
        margin: 20px 10px;
    }

    .section5#section5 .blocks__item:hover .blocks__content {
        transform: none;
    }

    /* ==============SECTION 6==============*/
    .section6 {
        background-color: #0c0f09;
        padding-bottom: 121px;
    }

    .section6 .top__text {
        max-width: 920px;
        width: 100%;
    }

    .section6 .button {
        margin: 0 auto;
    }

    .partners__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 78px;
        margin-right: 6%;
        margin-left: 6%;
    }

    .partners__img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 125px;
        background: #fff;
        margin: 0 auto 15px !important;
        padding: 15px;
        border: 2px solid #0ec22b;
        order: unset !important;
        width: 234px;
    }

    .partners__img a {
        display: block;
        width: 175px;
        height: auto;
        margin: 0 auto;
    }

    .partners__img img {
        display: block;
        width: 80%;
        height: auto;
        margin: 0 auto;
        max-height: 100px;
    }

    .partners__img:hover {
        opacity: 1;
        transition: all 0.2s linear;
    }

    /* ==============SECTION 7==============*/
    .section7 {
        padding-bottom: 134px;
        background-color: #f4f6f6;
    }

    .section7 .top__title {
        color: #000000;
    }

    .team {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .team:not(:last-child) {
        margin-bottom: 79px;
    }

    .team__text-content {
        font-size: 28px;
        padding: 44px 32px;
        background: #ffffff;
        box-sizing: border-box;
        height: 100%;
        font-weight: 600;
    }

    .team__text-content .icon--arrow {
        display: block;
        margin-top: 60px;
    }

    .team__container_text {
        position: absolute;
        top: 0;
        bottom: 0;
        display: none;
    }

    .team__wrapper {
        padding: 0 60px;
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-direction: row;
    }



    .team__container {
        display: flex;
        width: 230px;

        flex-direction: column;
        align-items: center;
    }

    .team__container:not(:last-child) {
        margin-right: 48px;
        margin-bottom: 48px;
    }

    /*.team__line {*/
    /*  max-width: 252px;*/
    /*  width: 100%;*/
    /*  height: 5px;*/
    /*  background-color: #0ec22b; }*/
    .team__img {
        width: 140px;
        border-radius: 50%;
        height: 140px;
    }

    // const AdinaJP = require("./../../../Assets/images/team/teamJpg/adina.jp2");
    // const AlonJP = require("./../../../Assets/images/team/teamJpg/Alon.jp2");
    // const IdoJP = require("./../../../Assets/images/team/teamJpg/ido.jp2");
    // const IsraelJP = require("./../../../Assets/images/team/teamJpg/israel.jp2");
    // const LeoJP = require("./../../../Assets/images/team/teamJpg/leo.jp2");
    // const MoranJP = require("./../../../Assets/images/team/teamJpg/moran.jp2");
    // const NatalieJP = require("./../../../Assets/images/team/teamJpg/natalie.jp2");
    // const RonitJP = require("./../../../Assets/images/team/teamJpg/ronit.jp2");
    // const SamJP = require("./../../../Assets/images/team/teamJpg/sam.jp2");
    // const ShueyJP = require("./../../../Assets/images/team/teamJpg/shuey.jp2");
    // const TeresaJP = require("./../../../Assets/images/team/teamJpg/teresa.jp2");
    // const VanessaJP = require("./../../../Assets/images/team/teamJpg/vanessa.jp2");
    // const YaelJP = require("./../../../Assets/images/team/teamJpg/yael.jp2");
    // const YuvalJP = require("./../../../Assets/images/team/teamJpg/yuval.jp2");
    // const ZimbaJP = require("./../../../Assets/images/team/teamJpg/zimba.jp2");
    .team__img--pic1 {
        background: url("./../../../Assets/images/team/Team/Yuval-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic2 {
        background: url("./../../../Assets/images/team/Team/Yael-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic10 {
        background: url("./../../../Assets/images/team/daniel.png") no-repeat;
        background-size: cover;
    }

    /*.team__img--pic3 {*/
    /*  background: url("/images/DanielW.png") no-repeat;*/
    /*  background-size: cover; }*/
    .team__img--pic3 {
        background: url("./../../../Assets/images/team/Team/NAtalie.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic4 {
        background: url("./../../../Assets/images/team/Team/Samuel.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic5 {
        background: url("./../../../Assets/images/team/Team/Ido.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic6 {
        background: url("./../../../Assets/images/team/Team/Israel\ Frenkel.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic7 {
        background: url("./../../../Assets/landing/Simcha.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic8 {
        background: url("./../../../Assets/images/team/Team/Shuey\ Foge.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic9 {
        background: url("./../../../Assets/landing/Yishai.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic11 {
        background: url("./../../../Assets/images/team/shahar.png") no-repeat;
        background-size: cover;
    }

    .team__img--pic12 {
        background: url("./../../../Assets/landing/sheeba.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic13 {
        background: url("./../../../Assets/landing/Jeni.png") no-repeat;
        background-size: cover;
    }

    .team__img--pic14 {
        background: url("./../../../Assets/landing/betty.png") no-repeat;
        background-size: cover;
    }

    .team__img--pic15 {
        background: url("./../../../Assets/images/team/Team/Moran-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic16 {
        background: url("./../../../Assets/images/team/Team/Zimra-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic17 {
        background: url("./../../../Assets/images/team/Team/Teresa-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic18 {
        background: url("./../../../Assets/images/team/Team/Vanessa-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic19 {
        background: url("./../../../Assets/landing/Mark.png") no-repeat;
        background-size: cover;
    }

    .team__img--pic20 {
        background: url("./../../../Assets/landing/Bren.png") no-repeat;
        background-size: cover;
    }

    .team__img--pic21 {
        background: url("./../../../Assets/landing/AlonCohen.png") no-repeat;
        background-size: cover;
    }

    .team__img--pic22 {
        background: url("./../../../Assets/landing/DavidOlodovsky.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic23 {
        background: url("./../../../Assets/images/team/Team/Leo-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic24 {
        background: url("./../../../Assets/images/team/Team/Adina-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__img--pic25 {
        background: url("./../../../Assets/images/team/Team/Ronit-min.jpg") no-repeat;
        background-size: cover;
    }

    .team__photo {
        max-width: 273px;
        width: 100%;
        max-height: 273px;
        height: 100%;
        position: relative;
    }

    .team__photo:hover {
        width: 120%;
        height: 120%;
    }

    .team__photo.show .team__container_text {
        display: none;
    }

    /*.team__photo_text {*/
    /*  width: 273px;*/
    /*  height: 273px; }*/
    .team__container_text {
        box-shadow: 0 2px 11px rgba(0, 0, 0, 0.04);
        background-color: rgba(12, 15, 9, 0.9);
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        width: 250px;
        height: 280px !important;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        /*line-height: 22px;*/
        padding: 5px 5px;
        pointer-events: none;
    }

    @media (max-width: 1650px) {
        .team__container_text {
            width: 220px;
            height: 370px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @media (max-width: 1200px) {
        .team__container_text {
            width: 250px;
            height: 180px;
            position: absolute;
            right: -20%;
        }
    }

    .team__photo:hover .team__container_text {
        display: block;
    }

    .team__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        justify-content: center;
        max-width: 252px;
        margin: 0 auto;
    }

    .team__info_job {
        color: #333333;
        opacity: 0.8;
        font-size: 12px;
        /*font-weight: 500;*/
        line-height: 24px;
    }

    .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 24px;
    }

    .info__name {
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        /*margin-right: 12px; }*/
    }

    .info__link {
        background: url("./../../../Assets/landing/icon__linkdn.svg");
        width: 17px;
        height: 17px;
        display: none;
        /*display: inline-block;*/
        cursor: pointer;
        margin-top: 5px;
        margin-left: 2px;
    }

    /* ==============SECTION 8==============*/
    .section8 {
        background: #ffffff;
        padding-bottom: 120px;
    }

    .section8__container {
        display: flex;
        flex-direction: column;
        background-size: cover;
        max-width: 1233px;
        width: 100%;
        margin: 0 auto;
    }

    .section8__top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .section8__top__subtitle {
        color: #f4f6f6;
        font-size: 32px;
        font-weight: 600;
        line-height: 56px;
    }

    .section8__top__title {
        color: #f4f6f6;
        font-size: 40px;
        font-weight: 600;
        line-height: 56px;
        text-transform: uppercase;
        max-width: 497px;
        width: 100%;
    }

    .section8 .top {
        margin-bottom: 28px;
        padding-top: 90px;
        margin-top: -90px;
    }

    .section8 .top__title {
        color: #0c0f09;
    }

    .section8__body {
        background: url("./../../../Assets/landing/Aspen_bg.jpg") no-repeat;
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 78px 80px 66px;
    }

    .section8 .section-title__title {
        width: 511px;
    }

    .section8__info {
        display: flex;
        flex-direction: column;
        margin-right: 88px;
    }

    .section8__text {
        display: flex;
        flex-direction: column;
    }

    .section8__text--big,
    .section8__text--small,
    .section8__text--medium {
        color: #f4f6f6;
        font-weight: 400;
        line-height: 28px;
    }

    .section8__text--big {
        margin-top: 40px;
        max-width: 452px;
        width: 100%;
        margin-bottom: 35px;
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.09px;
    }

    .section8__text--small {
        max-width: 531px;
        width: 100%;
        margin-bottom: 50px;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
    }

    .section8__label {
        visibility: visible;
        animation-name: fadeInUp;
        align-self: flex-start;
        justify-self: flex-end;
        width: 150px;
        height: 32px;
        border: 1px solid #0ec22b;
        background-color: rgba(14, 194, 43, 0.1);
        color: white;
        justify-content: center;
        align-items: center;
        display: flex;
        position: absolute;
        right: 126px;
    }

    .group-img {
        position: relative;
        margin-left: 88px;
    }

    .group-img__first {
        width: 490px;
        height: 441px;
        background-color: #05881a;
        position: absolute;
        bottom: -32px;
        z-index: 5;
        transition-duration: 0.3s;
    }

    .group-img__second {
        max-width: 421px;
        width: 100%;
        max-height: 565px;
        height: 100%;
        position: absolute;
        top: 83px;
        right: 47px;
        z-index: 6;
        transition-duration: 0.3s;
        transition-delay: 0.35s;
    }

    .group-img__third {
        max-width: 442px;
        width: 100%;
        max-height: 340px;
        height: 100%;
        position: absolute;
        top: 271px;
        left: -110px;
        z-index: 7;
        transition-duration: 0.3s;
        transition-delay: 0.65s;
    }

    /* ==============SECTION 9==============*/
    .section9 {
        background: url("./../../../Assets/landing/stats_bg.jpg") no-repeat;
        background-size: cover;
    }

    .section9 .top__title {
        color: #ffffff;
        max-width: 490px;
        width: 100%;
    }

    .section9 .top__text {
        color: #ffffff;
        width: 100%;
        max-width: 600px;
    }

    .section9 .blocks__container {
        box-shadow: 0 2px 11px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background-color: rgba(13, 180, 40, 0.9);
    }

    .section9 .section5 {
        background-color: inherit;
    }

    .section9 .section5 .blocks__item {
        background-color: inherit;
        margin: 0 15px 30px 15px;
    }

    .section9 .section5 .blocks__container {
        max-width: 292px;
        width: 100%;
        max-height: 272px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .section9 .section5 .section5__blocks {
        padding-bottom: 125px;
        justify-content: center;
        max-width: 1250px;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .box__name {
        margin-top: 29px;
        margin-bottom: 16px;
        color: #ffffff;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.18px;
    }

    .box__price {
        color: #ffffff;
        font-size: 40px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 0.33px;
    }

    .box__icon--global {
        background: url("./../../../Assets/landing/section9__icon-1.svg");
        background-size: cover;
        width: 60px;
        height: 60px;
    }

    .box__icon--rocket {
        background: url("./../../../Assets/landing/section9__icon-2.svg");
        background-size: cover;
        width: 60px;
        height: 60px;
    }

    .box__icon--eco {
        background: url("./../../../Assets/landing/section9__icon-3.svg");
        background-size: cover;
        width: 66px;
        height: 62px;
    }

    .box__icon--investors {
        background: url("./../../../Assets/landing/section9__icon-4.svg");
        background-size: cover;
        width: 64px;
        height: 58px;
    }

    /* ==============SECTION 10==============*/
    .info-box__inner.bggray {
        background-color: #d7d7d7;
    }

    .text--center {
        text-align: center;
    }

    .title_blue_center {
        text-align: center;
        color: #366091;
        font-size: 22px;
        font-weight: 900;
        line-height: 78px;
        font-family: "Montserrat", sans-serif;
        text-decoration: underline;
        text-decoration-color: currentcolor;
        text-decoration-color: #4fac3a;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 28px;
    }

    .row.nobr {
        margin-bottom: 0;
    }

    .row .col-3 {
        width: 33.333%;
        display: flex;
        align-items: stretch;
    }

    .row .col-2 {
        width: 50%;
        display: flex;
        align-items: stretch;
    }

    .row .topp {
        margin-bottom: 15px;
    }

    .row .imgg img {
        display: block;
        width: auto;
        height: 68px;
        margin: 20px auto;
    }

    .row .imgg.line img {
        display: inline-block;
        height: 50px;
    }

    .row .cxitem {
        box-sizing: border-box;
        border: 1px solid #0ec22b;
        margin: 5px;
        padding: 25px;
        border-radius: 3px;
        width: 100%;
    }

    .row .cxitem2 {
        box-sizing: border-box;
        margin: 5px;
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .row .cxitem2 .topp {
        margin-bottom: 0;
        width: 100%;
    }

    .row .cxitem2 .topp strong {
        padding: 0 15px;
        font-size: 22px;
        color: #4fac3a;
    }

    .row .cxitem2 .topp:after,
    .row .cxitem2 .topp:before {
        content: "→";
        font-size: 40px;
        line-height: 44px;
        top: 5px;
        position: relative;
    }

    @media screen and (max-width: 800px) {
        .title_blue_center {
            line-height: 1.5;
            margin-bottom: 25px;
        }

        .row .col-3,
        .row .col-2 {
            width: 100%;
        }

        .row.nobr.fix {
            margin-bottom: 25px;
        }

        .row .cxitem2 .topp:after,
        .row .cxitem2 .topp:before {
            display: block;
            content: "↓";
            line-height: 1;
            top: 0;
        }
    }

    /* ==============FOOTER==============*/
    .footer {
        background: url("./../../../Assets/landing/footer__bg.png");
        width: 100%;
        background-size: cover;
        position: relative;
    }

    .footer__container {
        padding-top: 136px;
        padding-bottom: 101px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer__title {
        color: #ffffff;
        font-size: 40px;
        font-weight: 600;
        line-height: 56px;
        max-width: 760px;
        width: 100%;
        text-align: center;
    }

    .footer__subtitle {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 21px;
        max-width: 584px;
        width: 100%;
        text-align: center;
        margin-bottom: 57px;
    }

    .footer__image {
        background: url("./../../../Assets/landing/footer.png") top center;
        height: 100px;
    }

    .footer__copyright {
        background: #1c1c1c;
        border: none;
        margin: 0;
        padding: 20px 0 40px;
        display: flex;
        justify-content: center;
    }

    .logo__foot {
        width: 200px;
        height: 21px;
        margin-bottom: 30px;
    }

    .foot__1 {
        display: flex;

        width: 40%;
    }

    .foot__11 {
        display: flex;
        flex-direction: column;
        color: white;
    }

    .foot__2 {
        display: flex;
        flex-direction: row;
        width: 40%;
        justify-content: space-between;
        color: white;
    }

    .foot__h4 {
        margin-top: 0;
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
    }

    .foot__21 {
        display: flex;
        flex-direction: column;
    }

    .foot__21 a {
        text-decoration-line: underline;
        margin-top: 10px;
    }

    .footer__copyright-link {
        margin-left: 5px;
    }

    .wow-pre-animated {
        transform: translateY(100px);
        opacity: 0;
        transition: all 1s ease-in-out;
    }

    .wow-pre-animated--200ms {
        transition-delay: 0.2s;
    }

    .wow-pre-animated--400ms {
        transition-delay: 0.4s;
    }

    .wow-pre-animated--600ms {
        transition-delay: 0.6s;
    }

    .wow-pre-animated--800ms {
        transition-delay: 0.8s;
    }

    .animated {
        transform: translateY(0);
        opacity: 1;
    }

    /*========MEDIA SCREENS==========*/
    @media only screen and (max-width: 1224px) and (min-width: 967px) {

        /*.header__link {*/
        /*  margin-right: 2%; }*/
        .blocks {
            margin: 0 6%;
            justify-content: space-evenly;
        }

        .section5 .blocks {
            justify-content: center;
        }

        .team__wrapper {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    @media only screen and (max-width: 1024px) {
        .section8__body {
            padding: 26px;
        }

        .section8__label {
            display: none;
        }

        .group-img {
            margin-left: 0;
        }

        .group-img__second,
        .group-img__third {
            display: none;
        }
    }

    @media only screen and (max-width: 1024px) and (min-width: 601px) {
        .main-text__title {
            font-size: 49px;
        }

        .navigation {
            display: none;
        }

        .header__link {
            display: none;
        }

        .nav-burger {
            display: flex;
            position: relative;
            transition: all 0.5s ease-in-out;
        }

        .block {
            display: flex !important;
        }

        .mob-navigation {
            position: fixed;
            top: 64px;
            left: 0;
            bottom: 0;
            background: #fff;
            width: 100%;
            height: 100%;
            align-items: flex-start;
            justify-content: center;
            transition: all 0.3s linear;
        }

        .mob-navigation__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 35px;
        }

        .navigation__item {
            font-size: 15px;
        }

        .navigation__item:not(:last-child) {
            margin-right: 0;
            margin-bottom: 53px;
        }

        .section-title__title {
            font-size: 34px;
        }

        .blocks {
            flex-direction: column;
            align-items: center;
        }

        .blocks__item {
            margin-bottom: 96px;
        }

        .about__img {
            height: 73%;
        }

        .section3 {
            margin-left: 6% !important;
        }

        .section3__img {
            height: 65%;
            width: 80%;
        }

        .section3__text-container {
            margin-top: 0;
            margin-right: 13%;
        }

        .section5 .blocks {
            justify-content: center;
        }

        .team__wrapper {
            justify-content: center;
            flex-wrap: wrap;
        }

        .section8__container {
            flex-direction: column;
            margin-right: 6%;
            margin-left: 6%;
        }

        .section8__body {
            position: relative;
        }

        .section8 .section-title__title {
            width: 372px;
        }

        .section8 .button {
            height: 52px;
        }

        .group-img__first {
            bottom: -632px;
            right: 161px;
        }

        .group-img__second {
            top: 87px;
            right: 189px;
        }

        .group-img__third {
            top: 289px;
            left: -68px;
        }

        .footer__title {
            font-size: 35px;
        }
    }

    @media only screen and (max-width: 600px) and (min-width: 300px) {
        .main-text__title {
            font-size: 36px;
        }

        .navigation {
            display: none;
        }

        .header {
            background-color: #ffffff;
        }

        .header__link {
            display: none;
        }

        .nav-burger {
            display: flex;
            position: relative;
            transition: all 0.5s ease-in-out;
        }

        .block {
            display: flex !important;
        }

        .mob-navigation {
            position: fixed;
            top: 64px;
            left: 0;
            bottom: 0;
            background: #fff;
            width: 100%;
            height: 91%;
            align-items: flex-start;
            justify-content: center;
            transition: all 0.3s linear;
        }

        .mob-navigation__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 35px;
        }

        .navigation__item:not(:last-child) {
            margin-right: 0;
            margin-bottom: 53px;
        }

        .main-text {
            margin: 1px 4% 0 4%;
            padding-bottom: 110px;
        }

        .main-text__title {
            font-size: 34px;
            line-height: 49px;
        }

        .main-text__subtitle {
            font-size: 16px;
            line-height: 25px;
        }

        .main-text__button {
            margin-bottom: 50px;
        }

        .main-text__button .button__icon-block {
            height: 41px;
        }

        /*===========  SECTION 2 ==========*/
        .section {
            padding-right: 6%;
            padding-left: 6%;
            padding-top: 117px;
            padding-bottom: 47px;
        }

        .section-title__title {
            font-size: 23px;
            margin: 0;
            line-height: 38px;
        }

        .section-title__subtitle {
            font-size: 14px;
        }

        .about {
            margin-top: 42px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .about__title {
            font-size: 18px;
        }

        .about__info {
            margin-bottom: 50px;
        }

        .about .about__list_item {
            margin-bottom: 13px;
        }

        .about .button {
            height: 56px;
            font-size: 14px;
            border: none;
        }

        .about .button__icon-block {
            height: 56px;
        }

        /* ==============SECTION 3==============*/
        .section3 {
            display: flex;
            flex-direction: column;
            margin-right: 12%;
        }

        .section3__image {
            margin-right: 12%;
            order: 1;
        }

        .section3__text-container {
            margin-right: 12%;
            margin-bottom: 60px;
        }

        .section3 .button,
        .section3 .section3 .button__icon-block {
            height: 50px;
        }

        .section3 .section4 {
            padding-bottom: 88px;
        }

        .section4__blocks {
            flex-direction: column;
            margin-left: 5%;
            margin-right: 5%;
            align-items: center;
        }

        .top {
            margin-left: 6%;
            margin-right: 6%;
            margin-bottom: 20px;
            padding-top: 38px;
        }

        .top__subtitle {
            font-size: 14px;
        }

        .top__title {
            font-size: 24px;
            line-height: 44px;
        }

        .blocks__item {
            margin: 0;
        }

        .blocks__item:not(:last-child) {
            margin: 0;
        }

        .blocks__icon {
            top: 55px;
            left: 35%;
        }

        .blocks__content {
            max-height: 100%;
            height: 100%;
            border-radius: 4px;
        }

        .blocks__list-container {
            height: 100%;
        }

        /* ==============SECTION 5==============*/
        .section5 .top__title {
            max-width: 325px;
            width: 100%;
        }

        .section5 .top__subtitle {
            font-size: 12px;
            line-height: 23px;
        }

        .section5 .blocks {
            flex-direction: column;
        }

        .section5 .blocks__item {
            margin-bottom: 24px;
        }

        /* ==============SECTION 6==============*/
        .section6 .button {
            height: 55px;
        }

        .partners__container {
            flex-direction: column;
            margin-bottom: 54px;
        }

        .partners__img {
            margin-bottom: 15px;
        }

        /* ==============SECTION 7==============*/
        .section7 {
            padding-bottom: 38px;
        }

        .team {
            flex-direction: column;
        }

        .team:not(:last-child) {
            margin-bottom: 0;
        }

        .team__container:not(:last-child) {
            margin-right: 0;
            margin-bottom: 0;
        }

        .team__info {
            margin-bottom: 50px;
        }

        .team .team__wrapper {
            align-items: center;
            flex-direction: column;
        }

        /* ==============SECTION 8==============*/
        .section8__container {
            flex-direction: column;
            margin-right: 6%;
            margin-left: 6%;
        }

        .section8 .section-title__title {
            width: 372px;
        }

        .section8 .button {
            height: 52px;
        }

        .section8 .button__icon-block {
            height: 50px;
        }

        .group-img__first {
            bottom: -632px;
            right: -27px;
        }

        .group-img__second {
            top: 87px;
            right: 59px;
        }

        .group-img__third {
            top: 289px;
            left: -110px;
        }

        /* ==============SECTION 9==============*/
        /* ============== FOOTER ==============*/
        .footer__container {
            margin: 0 6%;
            padding-top: 48px;
        }

        .footer .button__text-block {
            height: 22px;
        }

        .footer .button__icon {
            top: 37%;
        }

        .footer__title {
            font-size: 35px;
        }
    }

    .section-onboarding .header {
        display: none;
    }

    .nav-back {
        display: none;
    }

    .error {
        position: absolute;
        top: -30px;
        left: 0;
        font-size: 12px;
        text-transform: uppercase;
        color: red;
    }

    .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .buttons-wrapper .button {
        margin-right: 24px;
        flex-shrink: 0;
        margin-top: 15px;
    }

    .contacts-page .title {
        color: #151515;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        font-size: 40px;
    }

    .contacts-page .text {
        margin-top: 40px;
        font-size: 16px;
        text-align: center;
        color: #6d7171;
    }

    .contacts-page--partner .form__submit {
        margin-top: 50px;
    }

    .contacts-page .form-group--custom-radio {
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .contacts-page .form-group--custom-checkbox {
        margin-bottom: 60px;
    }

    .section-contact {
        display: flex;
        padding-top: 84px;
        min-height: 100vh;
        width: 100%;
        background: rgb(247, 247, 247);;

        body {
            background: red;
        }
        @media(max-width: 750px) {
            flex-direction: column-reverse;
        }
    }
    .form-box{
        padding: 60px 10px 0px;
    }
    .contact-box {
        width: 1020px;
        display: flex;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);

        .radio-buttons {
            .MuiFormControlLabel-root {
                display: inline-flex !important;
                width: 45%;
            }
        }

        .MuiRadio-colorSecondary.Mui-checked {
            color: green;
        }

        .checkboxes {
            margin-bottom: 35px;

            .MuiFormControlLabel-root {
                display: inline-flex !important;
                width: 30%;
            }
        }

        .MuiFormGroup-root {
            flex-direction: row;
        }
    }

    .contact-box h2 {
        font-size: 32px;
        font-weight: 700;
        color: #151515;
        margin: 0;
        margin-bottom: 31px;
    }

    .form-wrapper {
        box-sizing: border-box;
        width: 640px;
        padding: 57px;
        background: #ffffff;

        .MuiFormControl-root {
            display: block;
        }
    }

    .contacts {
        padding: 57px 53px 61px 57px;
        color: #ffffff;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        background-color: #0ec22b;
        background-size: cover;
    }

    .contacts__info {
        margin-bottom: 30px;
    }

    .contacts__item {
        font-style: normal;
        color: #f4f6f6;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 28px;
        display: block;
    }

    .contacts__item a {
        display: block;
    }

    .contacts__socials {
        display: flex;
    }

    .contacts__socials-item {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-sizing: border-box;
    }

    @media(max-width: 750px) {
        .main-text__title {
            font-size: 36px;
        }
    }

    .contacts h2 {
        color: #ffffff;
    }

    .partner-contacts {
        background-image: url("./../../../Assets/landing/partner-contacts-bg.jpg");
        background-size: cover;
        display: flex;
        flex-grow: 1;
    }

    .form {
        width: 525px;
        margin-top: 63px;
    }

    .form .hide {
        display: none;
    }

    .form__field {
        font-size: 14px;
        box-sizing: border-box;
        width: 100%;
        padding: 14px 20px;
        height: 48px;
        background-color: #f4f6f6;
        color: #555556;
        border: none;
        font-weight: 500;
    }

    .form__field::placeholder {
        display: none;
        color: transparent;
    }

    .form__field:focus,
    .form__field:hover {
        outline: none;
        background-color: #eeefef;
    }

    .form__field:focus~.form__field-placeholder,
    .form__field--active~.form__field-placeholder {
        top: -24px;
        left: 0;
        font-size: 13px;
        color: #767575;
    }

    .form__field--hidden {
        display: none;
    }

    .form__radio {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #979797;
        position: absolute;
        left: 2px;
        top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .form__radio:after {
        content: none;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #15bd0d;
    }

    .form__checkbox {
        position: relative;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        border: 1px solid #979797;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .form__checkbox:after {
        content: none;
        width: 16px;
        height: 16px;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 1;
        background-image: url("./../../../Assets/landing/icons/check-sign.svg");
        background-color: #0ec22b;
        background-repeat: no-repeat;
        background-position: center;
    }

    .form__checkbox--md {
        width: 20px;
        height: 20px;
    }

    .form__checkbox--md:after {
        width: 20px;
        height: 20px;
    }

    .form__label-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .form__label-group:last-child {
        margin-bottom: 0;
    }

    .form__label-group .form__label {
        flex-basis: 50%;
    }

    .form__label-group .form__label:last-child {
        margin-top: 0;
    }

    .form__label-group--third .form__label {
        flex-basis: 30%;
    }

    .form__label-group .form__label-info {
        border: none;
        padding: 8px 0;
    }

    .form__label-group .form__radio {
        top: 50%;
        transform: translateY(-50%);
    }

    .form__label {
        position: relative;
        display: flex;
        align-items: center;
    }

    .form__label:last-child {
        margin-top: 9px;
    }

    .form__label-info {
        margin-left: 30px;
        padding: 18px 0 18px 0;
        border-bottom: 1px solid #eaeaea;
        width: 100%;
        font-size: 14px;
    }

    .form__label input[type="radio"],
    .form__label input[type="checkbox"] {
        display: none;
    }

    .form__label input:checked~.form__radio:after,
    .form__label input:checked~.form__checkbox:after {
        content: "";
    }

    .form__field-other {
        height: 40px;
        width: 100%;
        background-color: #f4f6f6;
        padding: 0 14px;
        border: none;
        font-size: 14px;
    }

    .form__field-placeholder {
        color: #555556;
        transition: all 0.2s ease-in-out;
        position: absolute;
        font-size: 13px;
        font-weight: 300;
        font-family: "Montserrat", sans-serif;
        top: -24px;
        left: 0px;
        pointer-events: none;
    }

    .form-control {
        width: 250px;
        position: relative;
    }

    .form-control .error {
        width: 100%;
        z-index: 2;
        background: white;
        margin: 0;
        padding: 12px 0 0 0;
    }

    .form-control .error:empty {
        display: none;
    }

    .form-control__field-name {
        color: #000000;
        font-size: 13px;
        font-weight: 300;
    }

    .form-control__field-value {
        color: #151515;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .form-control--radio-list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .form-control--radio-list .error {
        top: -20px;
        background-color: transparent;
    }

    .form-step {
        position: relative;
        margin-bottom: 46px;
    }

    .onboard-1 {
        .MuiFormGroup-root {
            flex-direction: row;
        }

        .MuiFormControlLabel-root {
            width: 45%;
        }

        .onboard-input {
            width: 100%;
        }

        .form__field-other {
            width: 100%;
        }

        .MuiTypography-body1 {
            width: 100%;
        }

        .MuiRadio-colorSecondary.Mui-checked,
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: green !important;
        }
    }

    .form-step__title {
        position: relative;
        color: #151515;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        display: flex;
    }

    .form-step__title--break {
        max-width: 450px;
    }

    .form-step__title .text-accent {
        margin-right: 5px;
        display: block;
    }

    .form-step__content {
        margin-top: 47px;
    }

    .form-step__content.hide {
        display: none;
    }

    .form-step__content--short {
        margin-top: -13px;
    }

    .form-step__result {
        margin-top: -10px;
    }

    .form-step__result.hide {
        display: none;
    }

    .form-step__result .form-group {
        margin-bottom: 0;
    }

    .form-step__result .form-control__field-name {
        margin: 12px 0 0 0;
    }

    .form-step__result .form-control__field-value {
        margin: 10px 0;
    }

    .form-step__controls {
        margin-top: 48px;
        display: flex;
    }

    .form-step__controls.hide {
        display: none;
    }

    .form-step__controls .button {
        width: 185px;
    }

    .form-step__controls .button+.button {
        margin-left: 24px;
    }

    .form-step .icon--edit {
        position: absolute;
        right: -5px;
        top: 10px;
        cursor: pointer;
        z-index: 1;
    }

    .form-step .icon--edit.hide {
        display: none;
    }

    .form-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 37px;
    }

    .form-group--full-width {
        width: 100%;
        margin-bottom: 34px;
    }

    .form-group--full-width .form-control {
        width: 100%;
    }

    .form-group--textarea .form__field {
        resize: none;
        height: 72px;
    }

    .label-group {
        display: flex;
        justify-content: space-between;
    }

    .label-group .form__label-info {
        margin-left: 30px;
        border: none;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: flex-start;
        padding: 18px 0 21px 0;
        line-height: 20px;
    }

    .label-group .form__label {
        margin-top: 0;
        position: relative;
        width: 30%;
    }

    .label-group .form__radio {
        position: absolute;
        left: -2px;
        top: 19px;
    }

    .label-group .text-thin {
        margin-top: 10px;
    }

    @media screen and (max-width: 1100px) {
        .section-contact {
            padding-bottom: 0;
        }

        .section-contact .text {
            padding: 0 20px;
        }

        .section-contact .contact-box {
            flex-direction: column;
            width: auto;
        }

        .section-contact .contact-box h2 {
            text-align: center;
            margin-bottom: 40px;
        }

        .section-contact .form-wrapper {
            width: 100%;
        }

        .section-contact .form-wrapper .form {
            margin: 0 auto;
        }

        .section-contact .form-wrapper .form .form__submit {
            margin: 0 auto;
        }

        .section-contact .contacts {
            padding: 60px;
            width: auto;
            background-size: cover;
        }

        .section-contact .contacts__info {
            text-align: center;
        }

        .section-contact .contacts__socials {
            justify-content: center;
        }
    }

    @media screen and (max-width: 600px) {
        .form {
            width: 100%;
        }

        .form-wrapper {
            padding: 40px;
        }

        .form-group {
            flex-wrap: wrap;
        }

        .form-group .form-control {
            width: 100%;
        }

        .form-group .form-control:not(:last-child) .form__field {
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: 480px) {
        .button__icon-block {
            display: none;
        }

        .contacts-page .title {
            color: #151515;
            text-transform: uppercase;
            text-align: center;
            font-weight: 600;
            line-height: 32px;
            font-size: 20px;
            margin-top: 52px;
            margin-bottom: 17px;
        }

        .contacts-page .text {
            font-weight: normal;
            line-height: 28px;
            font-size: 14px;
            text-align: center;
            color: #6d7171;
            padding: 0 23px;
            margin: 0 auto 27px;
        }

        .contacts-page .contact-box {
            margin: 0 auto;
        }

        .contacts-page .contact-box h2 {
            font-weight: bold;
            line-height: 44px;
            font-size: 26px;
            letter-spacing: 0.216667px;
            text-align: left;
        }

        .contacts-page .form-wrapper {
            padding: 42px 23px 48px;
        }

        .contacts-page .form-wrapper .form__field-placeholder {
            top: -24px;
        }

        .contacts-page .form-wrapper .form__field:focus~.form__field-placeholder,
        .contacts-page .form-wrapper .form__field--active~.form__field-placeholder {
            top: -24px;
        }

        .contacts-page .form-wrapper .form__field {
            background: #eeefef;
            min-height: 56px;
        }

        .contacts-page .form-wrapper .form__field.valid~.form__field-placeholder {
            color: #0db428;
        }

        .contacts-page .form-wrapper .form__submit {
            max-width: 100%;
        }

        .contacts-page .form-wrapper .form .form-group:not(:last-of-type) {
            margin-bottom: 48px;
        }

        .contacts-page .form-wrapper .form .form-group--textarea {
            margin-bottom: 32px;
        }

        .contacts-page .form-wrapper .form .form-group--textarea .form__field {
            min-height: 88px;
        }

        .contacts-page .form-wrapper .form .form-group .form-control {
            display: flex;
        }

        .contacts-page .form-wrapper .form .form-group .form-control:not(:last-child) .form__field {
            margin-bottom: 48px;
        }

        .contacts-page .section-contact {
            padding-top: 64px;
        }

        .contacts-page .contacts h2 {
            text-align: center;
            margin-bottom: 25px;
        }

        .contacts-page .contacts__info {
            margin-bottom: 6px;
        }
    }

    .section-onboarding {
        min-height: 100vh;
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .section-onboarding .header {
        display: none;
    }

    .section-onboarding .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 30px;
    }

    .section-onboarding .form {
        width: 100%;
        min-width: 525px;
        max-width: 525px;
        margin: 0;
    }

    .section-onboarding .form-wrapper {
        margin-left: 44%;
        width: 740px;
        padding: 17px 71px 100px 137px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .section-onboarding .form-wrapper .title {
        color: #151515;
        margin-top: 50px;
        margin-bottom: 27px;
    }

    .section-onboarding .form-wrapper .form__submit {
        margin-top: 50px;
    }

    .section-onboarding .form-wrapper.show-result {
        display: flex;
        justify-content: center;
    }

    .section-onboarding .control {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .section-onboarding .control .icon {
        margin-right: 10px;
    }

    .section-onboarding .form__label-group {
        margin-top: 10px;
    }

    .section-onboarding .result-wrapper {
        margin-left: 0;
        width: auto;
    }

    .section-onboarding--investor .form-wrapper {
        padding-top: 30px;
        padding-left: 140px;
        padding-bottom: 50px;
    }

    .section-onboarding--investor .form-wrapper.show-result {
        justify-content: center;
    }

    .section-onboarding--investor .title {
        max-width: 420px;
        margin-top: 80px;
        margin-bottom: 40px;
    }

    .section-onboarding--investor .form-step__content {
        padding-left: 0;
        margin-top: 35px;
    }

    .section-onboarding--investor .form {
        max-width: 525px;
        width: 100%;
    }

    .section-onboarding--investor .form-control--radio-list {
        margin-top: -5px;
    }

    .section-onboarding--investor .form-control--radio-list .sub-title {
        margin: 0 0 3px 0;
    }

    .section-onboarding--investor .form-control--radio-list .error {
        top: -10px;
        text-align: right;
        right: 0;
    }

    .section-onboarding--investor .steps-container .title {
        margin-bottom: 30px;
        margin-top: 90px;
    }

    .section-onboarding--investor .steps-list__item {
        margin-bottom: 38px;
        max-width: 290px;
    }

    .section-onboarding--investor .steps-list__item:nth-child(1) {
        margin-bottom: 28px;
    }

    .section-onboarding--investor .steps-list__item:nth-child(1) .steps-list__item-text {
        max-width: 240px;
    }

    .section-onboarding--investor .steps-list__item:nth-child(2) {
        margin-bottom: 49px;
    }

    .section-onboarding--investor .steps-list__item:nth-child(2) .steps-list__item-text {
        max-width: 260px;
    }

    .section-onboarding--investor .steps-list__item-title {
        padding-top: 3px;
        padding-bottom: 9px;
    }

    .section-onboarding--investor .steps-list__item-text {
        max-width: 250px;
    }

    .section-onboarding--investor .result-container {
        text-align: center;
    }

    .section-onboarding .form-step__title {
        padding: 0 20px 0 0;
    }

    .steps {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        color: #ffffff;
        width: 44%;
        background-color: #115c1c;
        display: flex;
        justify-content: flex-end;
    }

    .steps__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("./../../../Assets/landing/bg-onboarding.png");
        background-size: cover;
        opacity: 0.4;
        pointer-events: none;
    }

    .steps-container {
        padding-left: 101px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 640px;
        justify-content: center;
        align-items: flex-start;
        position: relative;
    }

    .steps-container .logo {
        top: 30px;
        left: 100px;
        position: absolute;
    }

    .steps-container .title {
        line-height: 48px;
        margin-bottom: 100px;
        margin-top: 37px;
    }

    .steps-list {
        position: relative;
    }

    .steps-list:before {
        content: "";
        top: 20px;
        bottom: 80px;
        left: 24px;
        width: 1px;
        background-color: #ffffff;
        position: absolute;
        display: block;
    }

    .steps-list__item {
        position: relative;
        color: #ffffff;
        max-width: 270px;
        padding-left: 75px;
        counter-increment: listcounter;
        margin-bottom: 64px;
    }

    .steps-list__item:before {
        content: counter(listcounter);
        position: absolute;
        left: 0;
        top: 4px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #115c1c;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
    }

    .steps-list__item--active:before {
        background-color: #ffffff;
        color: #0db428;
    }

    .steps-list__item-title {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding-top: 19px;
    }

    .steps-list__item-text {
        font-size: 13px;
        font-weight: 300;
        line-height: 24px;
        margin: 0;
    }

    .result-wrapper {
        margin-left: 44%;
        width: 740px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .result-wrapper__title {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        margin-top: 47px;
        margin-bottom: 0;
    }

    .result-wrapper__description {
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        margin: 15px 0;
    }

    .result-wrapper .button {
        margin-top: 40px;
    }

    // .text-page {
    //     margin-top: 73px;
    // }

    .text-page__content {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        padding: 46px 20px 200px 20px;
        box-sizing: border-box;
    }

    .text-page__content--top {
        padding-bottom: 45px;
    }

    .text-page__content--bottom {
        padding-top: 45px;
        padding-bottom: 77px;
    }

    .text-page__list-wrapper {
        margin-top: 52px;
    }

    .text-page__list-title {
        text-transform: uppercase;
        line-height: 43px;
        font-size: 24px;
        color: #151515;
        text-align: center;
        font-weight: 600;
        margin-bottom: 28px;
    }

    .text-page__list-item {
        line-height: 28px;
        font-size: 18px;
    }

    .text-page__text-wrapper {
        width: 100%;
        max-width: 1237px;
        margin: -90px auto 0 auto;
        background-color: #ffffff;
    }

    .text-page__image img {
        max-width: 100%;
        height: auto;
    }

    .text-page .title {
        color: #ffffff;
        font-size: 40px;
        font-weight: 600;
        line-height: 54px;
        text-transform: uppercase;
    }

    .text-page .sub-title {
        margin: 0;
        padding: 22px 10px;
        color: #000000;
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 43px;
        text-transform: uppercase;
    }

    .text-page .sub-title:nth-of-type(2) {
        margin-top: 26px;
    }

    .text-page .details {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.13px;
    }

    .text-page p {
        color: #212a32;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin: 10px 0;
    }

    .text-page p+p {
        margin-top: 22px;
    }

    .text-page p.section-404__text {
        margin-top: 25px;
    }

    .text-page .text--bold {
        font-weight: 700;
    }

    .section-privacy-policy .hero-block,
    .section-tokenize-real-estate .hero-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 100px;
        box-sizing: border-box;
        min-height: 450px;
        background-image: url("./../../../Assets/landing/bg/privacy-policy.png");
        background-color: #0b3913;
        background-size: cover;
        background-position: center;
        text-align: center;
    }

    .section-jobs__top {
        box-sizing: border-box;
        background: url("./../../../Assets/landing/jobs-bg.png");
        padding: 220px 20px 212px 20px;
        position: relative;
        height: 100%;
        width: 100%;
        background-size: cover;
    }

    .jobs-top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .jobs-top__title {
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-size: 40px;
        font-weight: 600;
        line-height: 54px;
        text-transform: uppercase;
        margin-bottom: 14px;
    }

    .jobs-top__subtitle {
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.13px;
        text-align: center;
    }

    .jobs-describe__bg {
        background: #ffffff;
    }

    .jobs-describe__wrapper {
        max-width: 1237px;
        width: 100%;
        margin: 0 auto;
        padding: 36px 60px 80px 60px;
        position: relative;
        top: -94px;
        background: #ffffff;
        box-sizing: border-box;
    }

    .jobs-describe__main-title {
        color: #000000;
        font-size: 32px;
        font-weight: 600;
        line-height: 56px;
        text-transform: uppercase;
        margin-bottom: 32px;
    }

    .jobs-describe__info {
        max-width: 1112px;
        width: 100%;
        border: 1px solid #d7d7d7;
        background-color: #ffffff;
        padding: 48px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .jobs-info__top {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .jobs-info__title {
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
    }

    .jobs-info__location {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .jobs-info__line {
        width: 43px;
        height: 2px;
        background-color: #0ec22b;
    }

    .jobs-info__city {
        color: #0ec22b;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 1.51px;
        margin-left: 18px;
    }

    .jobs-info__text {
        color: #212a32;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        margin-bottom: 32px;
        margin-top: 32px;
        max-width: 937px;
        width: 100%;
    }

    .jobs-cv {
        background-color: #f3f6f6;
        padding: 64px 166px 57px;
        z-index: 5;
        position: relative;
    }

    .jobs-cv__wrapper {
        max-width: 1237px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }

    .jobs-cv__text {
        display: flex;
        flex-direction: column;
        margin-right: 162px;
    }

    .jobs-cv__title {
        color: #000000;
        font-family: "Montserrat", sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 43px;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    .jobs-cv__subtitle {
        color: #212a32;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
    }

    .jobs-cv__button {
        display: flex;
        align-items: center;
    }

    .jobs-cv__btn {
        border: 1px solid #cacaca;
        padding: 18px 76px;
        height: 56px;
        color: #303940;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.13px;
        background: inherit;
        white-space: nowrap;
        cursor: pointer;
    }

    @media only screen and (max-width: 1000px) and (min-width: 320px) {
        .jobs-top__title {
            text-align: center;
        }

        .jobs-describe__wrapper {
            padding: 36px 44px 80px 44px;
        }

        .jobs-info__top {
            flex-direction: column;
            align-items: flex-start;
        }

        .jobs-cv {
            padding: 64px 44px 57px;
        }

        .jobs-cv__wrapper {
            flex-direction: column;
            align-items: center;
        }

        .jobs-cv__text {
            margin-right: 0;
            margin-bottom: 41px;
        }

        .jobs-cv__title {
            text-align: center;
            white-space: nowrap;
            font-size: 28px;
        }
    }

    @media only screen and (min-width: 2500px) {
        .footer-jobs {
            height: 108px;
            position: fixed;
            left: 0px;
            bottom: 0px;
            z-index: 6;
            margin-top: -2px;
            /* prevents infinite scroll if #footer have a border of 2px*/
            //top: expression(documentElement.scrollTop+(documentElement.clientHeight-this.clientHeight));
        }
    }

    @media screen and (max-width: 400px) {
        .jobs-describe__main-title {
            text-align: center;
        }

        .jobs-describe__wrapper {
            padding: 18px;
        }

        .jobs-info {
            padding: 28px;
        }

        .jobs-cv {
            padding: 18px;
        }
    }

    .section-404 {
        min-height: calc(100vh - 181px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #f4f6f6;
    }

    .section-404__title {
        font-weight: bold;
        font-size: 80px;
        line-height: 80px;
        text-align: center;
        text-transform: uppercase;
        color: #0ec22b;
        margin: 0;
    }

    .section-404 .sub-title {
        margin: 0;
        padding: 0;
    }

    .section-404__text {
        max-width: 415px;
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }

    .section-404__button {
        margin-top: 29px;
    }

    .section-404 .text-page__text-wrapper {
        background: transparent;
    }

    .section-404 .text-page__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 73px 0 0;
    }

    /* The Modal (background) */
    .modal {
        display: none;
        position: fixed;
        z-index: 7;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        /* Enable scroll if needed */
        background-color: black;
        background-color: rgba(0, 0, 0, 0.4);
        overflow-y: hidden;
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        border: 1px solid #888;
        max-width: 641px;
        width: 100%;
        padding: 20px;
    }

    .close {
        color: #b5b5b5;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    .form-control--lg {
        width: 524px;
    }

    .cv-modal__add-file {
        max-width: 471px;
        width: 100%;
        max-height: 76px;
        height: 100%;
        border: 1px dashed #a7a7a7;
        margin-bottom: 40px;
        padding: 25px;
        cursor: pointer;
    }

    .add-file {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .add-file__icon {
        background: url("./../../../Assets/landing/icon__add.svg") no-repeat;
        width: 28px;
        height: 29px;
        margin-right: 19px;
    }

    .add-file__text {
        display: flex;
        flex-direction: column;
    }

    .add-file__title {
        color: #555556;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .add-file__title--green {
        color: #0db428;
    }

    .add-file__format {
        color: #919191;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    @media screen and (max-width: 768px) {
        .modal {
            overflow: auto;
        }

        .modal .cv-modal {
            box-sizing: border-box;
        }

        .modal .cv-modal .form-wrapper {
            box-sizing: border-box;
            width: 100%;
        }
    }

    @media screen and (max-width: 480px) {
        .modal .cv-modal {
            padding: 20px 23px 48px;
            margin: 64px auto 40px;
        }

        .modal .cv-modal .form-wrapper {
            padding: 20px 0;
        }

        .modal .cv-modal .form-wrapper .jobs-info__button {
            max-width: 100%;
        }
    }

    .info-box {
        margin: 36px 0;
    }

    .info-box__inner {
        max-width: 1112px;
        width: 100%;
        border: 1px solid #d7d7d7;
        background-color: #ffffff;
        padding: 48px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .info-box__top {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .info-box__title {
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
    }

    .info-box__text {
        color: #212a32;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        margin-top: 32px;
        max-width: 937px;
        width: 100%;
    }

    .info-box__text p:last-child {
        margin-bottom: 0;
    }

    .text-article p>a {
        color: #0ec22b;
        text-decoration: underline;
    }

    .text-article__subtitle {
        text-transform: uppercase;
        line-height: 43px;
        font-size: 24px;
        color: #151515;
        text-align: center;
        font-weight: 600;
        margin-bottom: 28px;
    }

    .text-article__button {
        margin: 40px auto;
    }

    .text-article_img {
        padding-top: 220px;
        position: relative;
        height: 100%;
        background: url("./../../../Assets/landing/bg/real-estate.jpg");
        background-position: center;
        width: 100%;
        background-size: cover;
    }

    .text-article_img:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 1;
    }

    .text-article_img .text-page__text-wrapper {
        background-color: transparent;
    }

    .text-article_img .text-article__title,
    .text-article_img .text-article__text {
        text-align: center;
    }

    .text-article_img .text-article__title,
    .text-article_img .text-article__text,
    .text-article_img .text-article__link {
        z-index: 2;
    }

    .header {
        box-sizing: border-box;
        padding: 0 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 73px;
        z-index: 10;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out 0s;
        position: fixed;
    }

    .header--fixed,
    .header--inverse,
    .header:hover {
        background: #ffffff;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
    }

    .header--fixed .nav-burger.active span,
    .header--inverse .nav-burger.active span,
    .header:hover .nav-burger.active span {
        background: transparent;
    }

    .header--fixed .nav-burger span,
    .header--fixed .nav-burger span:after,
    .header--fixed .nav-burger span:before,
    .header--inverse .nav-burger span,
    .header--inverse .nav-burger span:after,
    .header--inverse .nav-burger span:before,
    .header:hover .nav-burger span,
    .header:hover .nav-burger span:after,
    .header:hover .nav-burger span:before {
        background: #0ec22b;
    }

    .header--fixed .header__btn,
    .header--inverse .header__btn,
    .header:hover .header__btn {
        background: #0ec22b;
        color: #ffffff;
        font-weight: 600;
    }

    .header--fixed .navigation__item a,
    .header--inverse .navigation__item a,
    .header:hover .navigation__item a {
        color: #00060b;
        text-decoration: none;
        position: relative;
        margin: 0;
        padding: 0;
        display: block;
    }

    .header--fixed .navigation__item a:after,
    .header--inverse .navigation__item a:after,
    .header:hover .navigation__item a:after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        border-top: 2px solid #0ec22b;
        content: "";
        padding-bottom: inherit;
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
        transition: -webkit-transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
        transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
        transition: transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1),
            -webkit-transform 0.35s cubic-bezier(0.52, 0.01, 0.16, 1);
        bottom: -4px;
    }

    .header--fixed .navigation__item:hover a:after,
    .header--inverse .navigation__item:hover a:after,
    .header:hover .navigation__item:hover a:after {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: scaleX(1);
    }

    .header--fixed .logo__mobile,
    .header--inverse .logo__mobile,
    .header:hover .logo__mobile {
        display: block;
    }

    .header--fixed .logo__desktop,
    .header--inverse .logo__desktop,
    .header:hover .logo__desktop {
        display: none;
    }

    .header--show {
        animation: showHeader 0.3s ease-in-out 0s forwards;
    }

    .header--hide {
        animation: hideHeader 0.3s ease-in-out 0s forwards;
    }

    .header__btn {
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 13px 22px;
        background: #0ec22b;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
    }

    .header .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav-burger {
        cursor: pointer;
        padding: 12px 0;
        background: transparent;
        border: none;
    }

    .nav-burger:focus {
        border: none;
        outline: none;
    }

    .nav-burger span {
        position: relative;
        display: block;
        width: 25px;
        height: 2px;
        background: #0ec22b;
        transition: all 0.2s ease-in-out;
    }

    .nav-burger span:before,
    .nav-burger span:after {
        position: absolute;
        background: #0ec22b;
        content: "";
        width: 25px;
        height: 2px;
        transition: all 0.2s ease-in-out;
        left: 0;
        top: 7px;
    }

    .nav-burger span:before {
        top: -7px;
    }

    button.active span {
        background: transparent;
    }

    button.active span:before {
        transform: rotate(45deg) translate(5px, 5px);
    }

    button.active span:after {
        transform: rotate(-45deg) translate(5px, -5px);
    }

    @media screen and (max-width: 600px) {
        .header {
            height: 64px;
        }

        .button__scroll {
            height: 60px;
        }

        .logo__mobile {
            display: block;
        }

        .logo__desktop {
            display: none;
        }

        .section-title__subtitle--green-line {
            margin-right: 0;
        }

        .top__title {
            margin-top: 20px;
        }

        .top__text {
            margin-top: 14px;
        }

        .blocks__item {
            position: relative;
            margin-top: 72px;
        }

        .blocks__item:not(:last-child) {
            margin-top: 72px;
        }

        .blocks__icon {
            position: absolute;
            width: 72px;
            height: 72px;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: -35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }

        .blocks__icon--estate,
        .blocks__icon--group,
        .blocks__icon--equity {
            width: 36px;
            height: 36px;
            background-size: 36px;
            position: relative;
            top: auto;
            left: auto;
        }

        .blocks__title {
            margin-top: 40px;
        }

        .blocks__content {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .blocks__list-container {
            padding-bottom: 15px;
        }

        .blocks .about__list {
            margin-left: 20px;
            margin-right: 10px;
        }

        .blocks .about__list_item {
            list-style: none;
        }

        .blocks .about__list_item:before {
            width: 7px;
            height: 7px;
            top: 8px;
            left: -18px;
        }

        .main-text__title {
            margin-top: 90px;
            margin-bottom: 35px;
            font-size: 24px;
            line-height: 36px;
            max-width: 300px;
        }

        .main-text__text {
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            text-align: center;
            margin-bottom: 30px;
        }

        .main-text__button {
            height: 48px;
        }

        .main-text__button .button__icon-block {
            height: 48px;
            max-width: 50px;
            width: 100%;
        }

        .main-text__button .button__icon-block .button__icon {
            right: 15px;
        }

        .main-text__subtitle:not(:last-child) {
            margin-bottom: 35px;
        }

        .section-title__title {
            font-size: 20px;
            line-height: 32px;
        }

        .top__title {
            font-size: 20px;
            line-height: 32px;
        }

        .section .section-title__subtitle-container {
            margin-bottom: 20px;
        }

        .section1 {
            padding-top: 90px;
        }

        .section2 {
            padding-top: 57px;
            padding-bottom: 70px;
        }

        .section2 .section-title__title {
            max-width: 350px;
            width: 100%;
        }

        .section2__container {
            margin: 0 5%;
        }

        .section2 .about__info {
            order: 1;
            padding: 0;
            margin-bottom: 0;
        }

        .section2 .about__title {
            margin-top: 17px;
            text-align: center;
            font-size: 20px;
        }

        .section2 .about__image {
            order: 0;
        }

        .section2 .about__image img {
            margin-right: -64px;
        }

        .section2 .about__list_item {
            margin-left: 27px;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 20px;
            max-width: 263px;
            width: 100%;
        }

        .section2 .about__button {
            margin: 28px auto 0 auto;
            height: 56px;
        }

        .section3 {
            margin: 14px 0 0 0;
            padding: 0 0 60px 0;
            box-sizing: border-box;
        }

        .section3__text-container {
            margin-right: 0;
            margin-left: 0;
        }

        .section3__image {
            display: none;
        }

        .section3__image-mobile {
            height: 66vh;
            overflow: hidden;
            margin-top: 20px;
            display: block;
        }

        .section3__image-mobile img {
            width: 100%;
        }

        .section3__title {
            font-size: 20px;
            line-height: 32px;
        }

        .section3__intro {
            box-sizing: border-box;
            font-size: 16px;
            padding: 10px 25px 10px 18px;
            margin-bottom: 14px;
        }

        .section3__text {
            box-sizing: border-box;
            padding: 0 18px;
        }

        .section3 .button {
            margin: 0 auto;
            height: 56px;
            box-sizing: border-box;
        }

        .section3 .button__icon-block {
            height: 56px;
        }

        .section3 .section-title__subtitle--green-line {
            display: none;
        }

        .section3 .section-title__subtitle {
            margin: 0 auto;
        }

        .section3 .section-title__title {
            text-align: center;
            font-size: 20px;
            line-height: 32px;
            margin: 0 auto;
            max-width: 300px;
        }

        .section4 {
            padding-bottom: 88px;
            background-position-x: 600px;
            background-position-y: 1px;
            background-size: inherit;
        }

        .section4__top {
            margin-bottom: 43px;
        }

        .section5#section5 {
            padding: 0;
        }

        .section5#section5 .section-title__subtitle--green-line {
            margin: 0;
        }

        .section5#section5 .top {
            margin: 0;
            padding-top: 52px;
        }

        .section5#section5 .top__title {
            margin-top: 22px;
            margin-bottom: 40px;
        }

        .section5#section5 .top__subtitle {
            font-size: 14px;
            line-height: 27px;
            max-width: 95%;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            max-width: 330px;
            width: 100%;
        }

        .section5#section5 .blocks {
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: stretch;
        }

        .section5#section5 .blocks__container {
            box-shadow: none;
            padding: 22px 3px 31px 20px;
        }

        .section5#section5 .blocks__container--padding {
            padding: 22px 3px 51px 20px;
        }

        .section5#section5 .blocks__item {
            box-sizing: border-box;
            height: auto;
            width: 50%;
            margin: 0;
            display: block;
            box-shadow: 0 2px 11px rgba(0, 0, 0, 0.04);
        }

        .section5#section5 .blocks__item:nth-child(even) {
            border-left: 1px solid #eceeee;
        }

        .section5#section5 .blocks__item:nth-last-child(-n + 2) {
            border-top: 1px solid #eceeee;
        }

        .section5#section5 .blocks__line {
            display: none;
        }

        .section5#section5 .blocks .content__title {
            font-size: 14px;
            line-height: 25px;
            height: 52px;
        }

        .section5#section5 .blocks .content__title--height {
            height: auto;
        }

        .section5#section5 .blocks .content__text {
            font-size: 11px;
            margin-top: 5px;
            line-height: 20px;
        }

        .section5#section5 .blocks .section-title__subtitle--green-line {
            width: 43px;
            margin-right: 16px;
        }

        .section6 .top {
            padding-top: 64px;
        }

        .section6 .top__text {
            margin-top: 8px;
        }

        .section6 .partners__container {
            margin-top: 80px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-self: center;
            margin-left: 0;
            margin-right: 0;
        }

        .section6 .partners__img {
            width: 38%;
            margin-bottom: 35px;
        }

        .section6 .partners__img img {
            width: 100%;
        }

        .section6 .partners__img:nth-child(3) {
            order: 4;
        }

        .section6 .partners__img:nth-child(4) {
            order: 5;
            margin-top: -30px;
        }

        .section7__top {
            padding-top: 71px;
            margin-bottom: 29px;
        }

        .section9 .section5 {
            padding-bottom: 72px;
        }

        .section8 {
            padding-bottom: 0;
        }

        .section8__body {
            padding-bottom: 50px;
        }

        .section8__info {
            margin-right: 0;
        }

        .section8 .top {
            margin-bottom: -5px;
        }

        .section8__top__subtitle {
            width: 100%;
            font-size: 21px;
        }

        .section8__top__title {
            font-size: 16px;
            line-height: 29px;
        }

        .section8__text--big {
            line-height: 28px;
            font-size: 16px;
            max-width: 95%;
            font-weight: 400;
        }

        .section8__container {
            margin: 0;
            padding: 9px 13px;
            box-sizing: border-box;
            margin-bottom: 30px;
        }

        .section8 .button {
            margin: 0 auto;
        }

        .section9 .blocks {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
        }

        .section9 .blocks__item {
            flex-basis: 45%;
            margin: 0;
            height: 234px;
        }

        .section9 .blocks .box {
            max-height: 202px;
            padding: 9px;
        }

        .section9 .blocks .box__name {
            font-size: 15px;
        }

        .section9 .blocks .box__price {
            font-size: 28px;
        }

        .footer {
            background-position-x: 600px;
            background-position-y: 0;
            background-size: inherit;
        }

        .footer__title {
            font-size: 20px;
            line-height: 32px;
        }
    }

    @media screen and (max-width: 1200px) {
        .section-onboarding .result-wrapper {
            margin: 0 auto;
        }

        .section-onboarding .result-wrapper .result-wrapper__title {
            text-align: center;
        }
    }

    @media screen and (max-width: 450px) {

        .section-onboarding .form__label-group,
        .form__label-group {
            margin-top: 0;
            margin-bottom: 0;
            flex-wrap: wrap;
        }

        .section-onboarding .form__label-group .form__label,
        .form__label-group .form__label {
            flex-basis: 100%;
        }
    }

    @media screen and (max-width: 1200px) {
        .section-onboarding .header {
            display: flex;
        }

        .section-onboarding .header .nav-burger {
            display: block;
        }

        .section-onboarding .steps {
            display: none;
        }

        .section-onboarding .form-wrapper {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            padding: 60px 20px;
            justify-content: center;
            align-items: center;
        }

        .section-onboarding .form-wrapper .title {
            margin-top: 20px;
        }

        .section-onboarding .form-wrapper .control {
            align-self: flex-start;
        }

        .section-onboarding .form {
            min-width: auto;
        }

        .section-onboarding .form__submit {
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 450px) {
        .section4 .blocks__item {
            width: initial;
        }

        .section4 .blocks__item:hover .blocks__content-fake-bottom {
            transform: translateY(35px) scaleX(1.06);
        }

        .section9 .blocks__item {
            height: 226px;
        }

        .section9 .blocks .box {
            padding: 4px 9px;
        }

        .section9 .blocks .box__icon {
            width: 50px;
            height: 50px;
            margin-top: 6px;
        }

        .section9 .blocks .box__name {
            margin-top: 13px;
            margin-bottom: 13px;
        }
    }

    /*@media screen and (min-width: 450px) {*/
    /*  .team__photo:hover .team__container_text {*/
    /*    display: block; } }*/

    @media screen and (max-width: 600px) {
        .section5#section5 .blocks {
            margin-bottom: 0;
        }

        .section5#section5 .blocks__item {
            max-width: none;
        }

        .section5#section5 .blocks__item .blocks__container {
            padding: 22px 3px 51px 20px;
        }

        .section5#section5 .blocks__item .content__text {
            min-height: 60px;
        }

        .section5#section5 .blocks__item:hover .blocks__content {
            transform: none;
        }
    }

    @media screen and (max-width: 700px) {
        .section8 {
            margin-top: 50px;
        }

        .section8 .top {
            padding-top: 40px;
        }
    }

    @media screen and (max-width: 700px) {
        .section9 .section5 {
            padding: 0 10px;
        }

        .section9 .section5 .section5__blocks {
            display: flex;
            flex-direction: row;
        }

        .section9 .section5 .blocks__container {
            max-height: 100%;
        }

        .section9 .section5 .blocks__item {
            flex-basis: 40%;
            height: 200px;
            flex-shrink: 0;
            flex-grow: 0;
            width: 40%;
            margin: 0 17px 24px 17px;
        }

        .section9 .section5 .blocks__container {
            flex-shrink: 0;
            flex-grow: 0;
        }
    }

    @media screen and (max-width: 480px) {
        .footer--contacts-page {
            display: none;
        }
    }

    @media screen and (max-width: 400px) {
        .section-onboarding--investor .label-group {
            flex-direction: column;
        }

        .section-onboarding--investor .form__label {
            width: 100%;
        }
    }

    @media screen and (max-width: 370px) {
        .section9 .section5 .blocks__item {
            flex-basis: 38%;
            width: 30%;
        }
    }
}