.security-form {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top: 31px;

    @media(max-width: 900px) {
        max-width: 270px;
        margin: 0 auto;
    }

    @media(max-width: 500px) {
        max-width: 100%;
        width: 100%;
    }

    &-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 8px;

        @media (max-width: 500px) {
            display: none;
        }
    }

    .MuiInputBase-input {
        font-weight: 500 !important;
        font-family: Poppins, sans-serif !important;
        color: #1B1A21;
        line-height: 24px;
        font-size: 14px;
    }

    .form-field{
        margin-bottom: 20px;
    }

    .security-label {
        display: inline-block;
        padding-top: 22px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8f948f;
    }

    .password-eye-label{
        font-family: Poppins;
        font-size: 14px;
    }

    .security-input-container {
        display: flex;
        flex-direction: column;
        position: relative;

        .security-info {
            border: 1px solid #8f948f;
            border-radius: 50px;
            color: #8f948f;
            padding: 10px;
            position: absolute;
            right: -35px;
            transition: .2s all;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                transition: .2s all;
            }

            &:hover+.security-info-modal {
                display: block;
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            @media(max-width: 500px) {
                padding: 8px;
                font-size: 12px;
                top: 12%;
                left: initial;
                right: 10px;
            }

        }

        .security-info-modal {
            display: none;
            position: absolute;
            right: -295px;
            top: 38%;
            font-size: 11px;
            line-height: 18px;
            color: #fff;
            background-color: #514E5B;
            max-width: 200px;
            padding: 20px 25px 20px 20px;

            @media (max-width: 500px) {
                top: 10px;
                right: initial;
                left: 30%;
            }

            &::before {
                content: '';
                position: absolute;
                width: 15px;
                height: 15px;
                background-color: #514E5B;
                left: -5px;
                transform: rotate(-45deg);


            }

        }
    }

    .security-button {
        cursor: pointer;
        font-family: inherit;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        padding: 15px 112px;
        border: none;
        border-radius: 3px;
        outline: none;
        margin-top: 50px;
    }

    .input-error {
        font-size: 12px;
        color: #EA2D2D;
    }


}