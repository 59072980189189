.at-step-two {
  * {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }

  &__confirmation {
    display: flex;
    justify-content: flex-end;
    @media (max-width:550px) {
      flex-direction: column;
      justify-content: center;
    }
    .right{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width:550px) {
        align-items: center;
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    border: 1px solid #dbe0ea;
    padding: 10px 20px;
    .MuiTextField-root:last-child{
      margin-bottom: 10px;
    }
  }
}

.MuiFormGroup-row{
  .MuiFormControl-root {
    width: auto;
  } 
}
