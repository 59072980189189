.kyc-page {
    display: flex;
    margin-left: 420px;

    @media(max-width: 1100px) {
        margin-left: 340px;
    }

    @media(max-width: 900px) {
        margin-left: 255px;
    }

    @media(max-width: 768px) {
        margin-left: 0;
    }

    .kyc {
        padding: 125px 0 0 120px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        @media(max-width: 900px) {
            padding: 110px 0 0 76px;
        }

        @media(max-width: 768px) {
            padding: 25px 20px 20px 20px;
            align-items: center;
        }
    }

    .kyc-form {
        display: flex;
        flex-direction: column;
        width: 271px;

        @media(max-width: 768px) {
            width: 100%;
        }

        .react-date-picker__wrapper{
            border: none;
            border-bottom: 1px solid #E1DFE6;
            input{
                font-family: Poppins;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .MuiAutocomplete-root {
            margin-top: 16px;
        }

        .countries-input {
            .MuiInputLabel-root {
                font-size: 14px;
                font-family: 'Roboto';
            }

            .MuiSelect-select {
                font-weight: 500 !important;
            }
        }

        .MuiInputLabel-root {
            font-size: 14px;
            font-family: 'Roboto';
        }

        .form-title {
            font-size: 24px;
            margin-bottom: 4px;
            margin-top: 0;
        }

        .subtitle {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 25px;
            color: #333333;
        }

        .form-field {
            margin-top: 20px !important;
            font-family: Poppins;
            font-weight: 500;
        }
        .birth-label{
            font-size: 10px;
            font-family: 'Roboto';
            color: rgba(0, 0, 0, 0.54);
            margin-bottom: 4px;
            margin-left: 2px;
        }

        .kyc-date {
            .MuiInputBase-root {
                width: 100%;
                font-family: Poppins !important;
                font-weight: 500;
            }
        }

        .kyc-input {
            input {
                margin-top: 35px;
                width: 100%;
                font-family: Poppins;
                font-weight: 500;

                &::placeholder {
                    font-weight: 400;
                }
            }
        }

        .lastname-input {
            margin-bottom: 14px;
        }

        .kyc-button {
            color: #fff;
            max-width: 116px;
            font-family: inherit;
            text-transform: none;
            margin-top: 30px;
            font-family: Poppins !important;
            font-weight: 600 !important;

            @media(max-width: 768px) {
                max-width: initial;
            }
        }
    }

    .input-error {
        font-size: 12px;
        color: #ea2d2d;
    }

    .get-help-container {
        position: static !important;
        margin-top: auto;
        text-align: left;
        padding-top: 20px;
        margin-bottom: 20px;


        @media(max-width: 768px) {
            text-align: center;
        }
    }
}