.confirmation-wrapper {
    padding-top: 70px;
}

.confirmation-title {
    display: flex;
    align-items: center;
    width: 50%;
    margin: auto;
    justify-content: space-between;

    .inner-title {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    p {
        display: none;
    }

    @media (max-width: 800px) {
        display: flex;
        align-items: center;
        width: 90%;
        margin: auto;
        flex-direction: row;
        justify-content: space-between;

        h1 {
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
        }

        p {
            display: block;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #8f948f;

            opacity: 0.8;
        }
    }
}

.confirmation-container {
    position: relative;
    width: 1150px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1200px) {
        width: 824px;
        flex-direction: row;
    }

    @media (max-width: 800px) {
        flex-direction: column-reverse;
        width: 688px;
        justify-content: center;
        padding: 0 20px;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    .MuiButton-root {
        background-color: rgb(88, 11, 228);
        color: white;
        width: 244px;
        height: 54px;
        text-transform: none;
        margin-top: 30px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;

        @media (max-width: 800px) {
            display: block;
            margin: auto;
            margin-top: 25px;
            margin-bottom: 30px;
        }

        @media (max-width: 600px) {
            width: 95%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &:hover {
            background-color: rgb(88, 11, 228);
        }
    }

    .confirmation-component {
        width: 80%;

        @media (max-width: 800px) {
            width: 100%;
        }

        .inner-confirmation {
            display: flex;
            flex-direction: column;

            @media (max-width: 1200px) {
                flex-direction: row-reverse;
            }

            @media (max-width: 800px) {
                flex-direction: column;
                margin: auto;
                width: 100%;
            }
        }

        .confirmation-overview {
            @media (max-width: 800px) {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            @media (max-width: 600px) {
                align-items: flex-start;
            }
        }

        .geo {
            display: flex;
            flex-direction: row;

            img {
                margin-right: 10px;
            }
        }

        .confirmation-1 {
            h2 {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
            }

            p {
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                margin: 0;
            }

            margin-bottom: 50px;
        }

        .confirmation-2 {
            width: 465px;

            h2 {
                font-weight: bold;
                font-size: 13px;
                line-height: 24px;
                margin-bottom: 30px;

                @media (max-width: 600px) {
                    width: 90%;
                }
            }

            p {
                font-size: 13px;
                line-height: 24px;
                opacity: 0.8;

                @media (max-width: 600px) {
                    width: 90%;
                }
            }

            margin-bottom: 50px;

            @media (max-width: 1200px) {}

            @media (max-width: 800px) {
                width: 465px;
                margin: auto;
                text-align: center;
            }

            @media (max-width: 600px) {
                width: 100%;
                text-align: left;
                height: 400px;
            }
        }

        .crown-container {
            position: relative;
            width: 271px;
            height: 158px;

            @media (max-width: 800px) {
                width: 100%;
            }

            .crown-asset {
                width: 100%;
                object-fit: cover;
                height: 158px;
            }

            .crown-img {
                position: absolute;
                height: 29px;
                width: 34px;
                right: -15px;
                top: -20px;

                @media (max-width: 800px) {
                    left: 18px;
                }
            }
        }
    }
}