.my-asset-card-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px 4px 20px #ece8f1;
    margin-bottom: 32px;
    @media (max-width: 800px) {
        flex-direction: column;
    }
    @media (max-width: 600px) {
        display: none;
    }
    .my-asset-img {
        width: 341px;
        height: 336px;
        object-fit: cover;
        @media (max-width: 800px) {
            width: 100%;
            height: 190px;
        }
    }

    .my-asset-type-label {
        position: absolute;
        bottom: 15px;
        left: 25px;
        z-index: 10;
        padding: 3px 12px;
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        background-color: #580be4;
        border-radius: 3px;
        @media (max-width: 800px) {
            display: none;
        }
    }

    .my-asset-info-container {
        width: 100%;
        padding: 25px 40px 20px 40px;

        .investment-value {
            width: 100%;
            margin-bottom: 16px;

            .investment-value-tokens {
                font-family: Poppins, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 27px;
                color: #580be4;
            }

            .investment-value-currency {
                font-family: Poppins, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #929292;
            }
        }

        .buttons-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 25px;

            .history-btn-container {
                display: flex;
                align-items: center;
            }

            .see-full-history-btn {
                font-family: Poppins, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #580be4;
                cursor: pointer;
                outline: none;
                position: relative;
                border: none;
                background-color: #ffffff;
                margin-left: 5px;
            }

            .buy-more-btn {
                cursor: pointer;
                font-family: Poppins, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #ffffff;
                padding: 10px 34px;
                background-color: #580be4;
                border: none;
                border-radius: 3px;
                outline: none;
            }
        }
    }
}
