.landing-subscribe {
  width: 100%;
  background-color: #f6f5f8;

  .subscribe-inner {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 110px 0;

    @media (max-width: 1144px) {
      padding: 110px 20px;
    }

    @media (max-width: 950px) {
      flex-direction: column;
      padding: 80px 20px;
      text-align: center;
    }

    .title {
      font-size: 32px;
      line-height: 40px;
      max-width: 410px;
      margin: 0;
      color: #333;

      @media (max-width: 950px) {
        margin-bottom: 15px;
      }
    }

    .subscribe-form {
      display: flex;
      color: #5b5b5b;

      @media (max-width: 550px) {
        flex-direction: column;
      }

      input {
        width: 380px;
        color: #5b5b5b;

        @media (max-width: 550px) {
          width: 100%;
        }
      }

      .subscribe-button {
        margin-left: 15px;
        span {
          text-transform: none;
          font-family: "Poppins" !important;
          font-weight: 500;
        }

        @media (max-width: 550px) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }
}
