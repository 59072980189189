.trade-companies-container {
    margin-top: 40px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 17px 17px;
    @media (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }

    .trade-company-btn {
        width: 100px;
        height: 100px;
        background: #ffffff;
        box-shadow: 0px 4px 20px #ece8f1;
        cursor: pointer;
        outline: none;
        border: none;
        @media (max-width: 600px) {
            width: 100%;
        }

        &:focus {
            border: 3px solid #580be4;
        }

        .trade-company-img {
            width: 63px;
            height: 63px;
        }
    }
}
