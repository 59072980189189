.signup-auth-page-container {
  display: flex;

  .subtitle-white {
    font-weight: 600;
    color: white;
    margin-left: 5px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 375px) {
      margin-left: 8px;
    }
  }

  .form-container {
    display: flex;

    @media(max-width: 1100px) {
      flex-direction: column;

    }

    @media(max-width: 950px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .password-steps {
    padding-top: 60px;
    margin-left: 55px;

    @media(max-width: 1100px) {
      padding-top: 20px;
      margin-left: 0;
    }

    @media(max-width: 950px) {
      padding-top: 15px;
      width: 90%;
      margin: 0 auto;

      .step {
        font-size: 11px;
      }
    }

    .step {
      position: relative;
      display: block;
      font-size: 12px;
      color: #929292;
      padding-left: 8px;
      max-width: 375px;
      line-height: 24px;
      margin-bottom: 4px;

      .checked {
        display: none;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.done {
        color: #088d1e;

        .checked {
          display: inline;
        }
      }
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
  }

  .signup-auth-page {
    position: relative;
    width: calc(100% - 419px - 117px);

    @media (max-width: 950px) {
      width: 100%;
    }

    .auth-close-icon {
      position: absolute;
      top: 60px;
      right: 132px;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 1100px) {
        right: 70px;
      }

      @media (max-width: 950px) {
        right: 40px;
        top: 20px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .signup-auth-page-content {
      margin-top: 127px;

      .error-message {
        color: red;
      }

      @media (max-width: 950px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-top: 45px;
      }

      @media (max-width: 800px) {
        // margin-top: 35px;
      }

      @media (max-width: 375px) {
        // margin-top: 27px;
        padding: 20px;
      }

      .signup-auth-page-title {
        width: 271px;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #000000;

        @media (max-width: 950px) {
          width: 335px;
        }

        @media (max-width: 375px) {
          font-weight: bold;
          font-size: 28px;
          letter-spacing: 0.02em;
          color: #031106;
        }
      }

      .signup-auth-page-subtitle {
        width: 271px;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        opacity: 0.8;
        margin-top: 7px;

        @media (max-width: 950px) {
          width: 335px;
        }

        @media (max-width: 375px) {
          font-size: 16px;
          margin-top: 16px;
        }

        .subtitle-GREEN {
          font-weight: 600;
          margin-left: 5px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          @media (max-width: 375px) {
            margin-left: 8px;
          }
        }
      }

      .auth-next-button {
        &.MuiButtonBase-root {
          display: block;
          width: 116px;
          height: 39px;
          margin-left: 0px;
          margin-top: 24px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          border-radius: 4px;
          text-transform: none;

          &.desktop {
            @media (max-width: 950px) {
              display: none;
            }
          }

          &.mobile {
            display: none;
            width: 335px;
            height: 39px;
            margin-top: 45px;

            @media (max-width: 950px) {
              display: block;
            }

            @media (max-width: 800px) {
              margin-top: 63px;
            }
          }
        }
      }
    }
  }
}