.profile-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 271px;

    @media (max-width: 500px) {
        width: 100%;
    }

    .phone-inputs {
        display: flex;

        .phone-country {
            width: 50px;
        }

        .phone-input {
            width: 100%;
        }
    }

    .form-blocks-description {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-top: 36px;
        color: #000000;
        margin-bottom: 10px;

        &:first-child {
            margin-top: 0px;
            margin-bottom: 0;
        }
    }

    .inputlabel-label{
        ///padding-top: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2%;
        color: #8f948f;
        font-family: "Montserrat", sans-serif;
        padding-top: 20px;
    }

    .country-label{
        margin-bottom: 8px;
    }

    .form-field {
        width: 100%;
        border-bottom: 1px solid #dddddd;
        font-size: 14px !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #1b1a21;
        font-weight: 500;
        margin-top: 20px;
        .MuiInputBase-input{
            font-weight: 500 !important;
            font-family: Poppins, sans-serif !important;
            color: #1B1A21;
            line-height: 24px;
        }
        .Mui-disabled{
            color: rgba(0, 0, 0, 0.38) !important;
        }

        &:before,
        .MuiInput-underline:before {
            border-bottom: none !important;
        }

        .MuiInputBase-root {
            height: 24px !important;

            .MuiSelect-root {
                font-size: 14px !important;
            }
        }
    }

    .country-phone {
        width: 50px;
    }

    .security-button {
        cursor: pointer;
        font-family: inherit;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        padding: 15px 112px;
        border: none;
        border-radius: 3px;
        outline: none;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .input-error {
        font-size: 12px;
        color: #ea2d2d;
    }
}
