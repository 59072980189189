.forgot-password-new-container {
    display: flex;

    @media (max-width: 950px) {
        flex-direction: column;
    }

    .forgot-password-new {
        position: relative;
        width: calc(100% - 419px - 117px);

        @media (max-width: 950px) {
            width: 100%;
        }

        .auth-close-icon {
            position: absolute;
            top: 60px;
            right: 132px;

            &:hover {
                cursor: pointer;
            }

            @media (max-width: 1100px) {
                right: 70px;
            }

            @media (max-width: 950px) {
                right: 40px;
                top: 20px;
            }

            @media (max-width: 768px) {
                display: none;
            }
        }
        .forgot-password-new-content {
            margin-top: 45px;

            @media (max-width: 950px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 45px;
            }

            @media (max-width: 800px) {
                margin-top: 35px;
            }

            @media (max-width: 375px) {
                margin-top: 0px;
                padding: 16px 20px 20px 20px;
            }


            .forgot-password-new-title {
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                color: #000000;
                margin-bottom: 15px;

                @media (max-width: 950px) {
                    width: 335px;
                }

                @media (max-width: 375px) {
                    font-weight: bold;
                    font-size: 28px;
                    letter-spacing: 0.02em;
                    color: #031106;
                }
            }

            .forgot-password-new-subtitle {
                max-width: 435px;
                font-size: 14px;
                line-height: 24px;
                color: #333333;
                opacity: 0.8;
                margin-top: 16px;

                @media (max-width: 950px) {
                    width: 335px;
                }

                @media (max-width: 375px) {
                    font-size: 16px;
                    margin-top: 16px;
                }
            }

            .auth-next-button {
                &.MuiButtonBase-root {
                    display: block;
                    width: 142px;
                    height: 39px;
                    margin-left: 0px;
                    margin-top: 26px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #ffffff;
                    border-radius: 4px;
                    text-transform: none;

                    &.desktop {
                        @media (max-width: 950px) {
                            display: none;
                        }
                    }

                    &.mobile {
                        display: none;
                        width: 335px;
                        height: 39px;
                        margin-top: 45px;

                        @media (max-width: 950px) {
                            display: block;
                        }

                        @media (max-width: 800px) {
                            margin-top: 63px;
                        }

                        @media (max-width: 375px) {
                            margin-top: 368px;
                        }
                    }
                }
            }
        }
    }
}
