.cards-header {
    display: flex;
    justify-content: space-between;

    .filter-title {
        margin: 10px 0;
        @media(max-width: 800px) {
            font-size: 22px;
        }
    }
}

.cards-filter {
    display: flex;
    align-items: center;

    @media(max-width: 600px) {
        display: none;
    }

    .filter-assets {
        display: flex;
        align-items: center;

        @media(max-width: 800px) {
            font-size: 14px;
        }
    }

    .view {
        display: flex;
        align-items: center;
        margin-left: 25px;

        @media(max-width: 800px) {
            margin-left: 5px;
        }

        svg {
            margin-left: 24px;
            cursor: pointer;
            fill: #C2BFCD;

            @media(max-width: 800px) {
                margin-left: 10px;
            }

            &:hover {
                fill: #333;
            }
        }
    }

    .download {
        background-color: #fff;
        border: 2px solid #DDD;
        border-radius: 3px;
        color: #8F948F;
        font-weight: 600;
        font-size: 16px;
        padding: 5px 15px;
        cursor: pointer;
        margin-left: 24px;
        transition: .2s all;

        @media(max-width: 800px) {
            padding: 4px 4px;
            font-size: 14px;
            margin-left: 15px;
        }

        &:hover {
            background-color: #DDD;
            transition: .2s all;
        }
    }
}