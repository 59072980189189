.file-item-container {
  display: flex;
  background: #f2f2f2;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
  padding: 15px;
  cursor: pointer;
  justify-content: space-between;
  svg {
    margin-right: 12px;
    min-width: 20px;
  }

  .left {
    display: flex;
    background: #f2f2f2;
    flex-direction: row;
    height: 12%;
    align-items: center;
    max-width: 85%;
  }
  a {
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
  }

  .doc-name {
    color: #0ec22b;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
    word-break: break-all;
  }

  &.disabled {
    .doc-name {
      color: #929292;
    }
    svg #Base {
        fill: #929292;
      }
  }
}

.accreditation-modal {
  width: 35%;

  height: 25%;
  background-color: #ffffff !important;
  display: flex;
  flex-direction: row;
}
.modal-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}
.file-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  width: 90%;
  @media (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 150%;
  }
}
.file-main-container {
  height: 50%;
  width: 60%;
  outline: none;
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    position: relative;

    display: block;
  }

  @media(max-height: 600px){
    height: 80%;
    width: 80%;
  }
  .paper {
    width: 95%;
    height: 100%;
    padding: 16px 40px 24px;
    background-color: #fff;
    overflow-y: auto;
    @media (max-width: 600px) {
      padding: 25px 12px 14px;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    width: 90%;
  }
  .close-desktop {
    display: block;
    position: relative;
    @media (max-width: 600px) {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .mobile-icon {
      display: none;
      @media (max-width: 600px) {
        display: block;
      }
    }
    .desktop-icon {
      display: block;
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}

@media screen and (min-height: 1000px) {
  .close-accreditation {
    height: 22%;
  }
}
@media screen and (max-height: 1000px) {
  .close-accreditation {
    height: 26%;
  }
}
