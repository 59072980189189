
    .referred-users-item {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;

        .referred-users-info {
            display: flex;
            align-items: center;
        }

        .referred-users-photo {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 15px;

            img {
                width: 100%;
            }
        }
    }