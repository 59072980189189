.last-transactions-text {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #494949;
    margin-top: 21px;
    margin-bottom: 10px;
}

.last-transactions-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    .last-transactions-head {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: #929292;
        border-bottom: 1px solid #cbcad5;
        text-align: right;
    }

    .last-transactions-info {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        margin-top: 8px;
        text-align: right;
    }

    .first-column {
        text-align: left;
    }

    .status-info {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #929292;
    }
}
