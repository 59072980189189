.landing-teams {
    text-align: center;

    .title {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 55px;
        color: #333;
    }

    .team-list {
        max-width: 1144px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        overflow-x: scroll;

        @media(max-width: 768px){
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        &::-webkit-scrollbar {
            height: 0px;
        }

        &::-webkit-scrollbar-track {
            height: 0px;
        }

        .team-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 10px 15px;
            width: 160px;

            

            .item-image {
                border-radius: 50%;
                overflow: hidden;
                width: 110px;
                height: 110px;

                img {
                    width: 100%;
                }
            }

            .name {
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                margin: 30px 0 7px;
                color: #333;
            }

            .job{
                color: #5b5b5b;
            }
        }
    }

    .executive {
        background-color: #F5F5F5;
        padding: 95px 0;

        @media(max-width: 700px) {
            padding: 40px 0;
        }
    }

    .advisory {
        background-color: #EBEBEC;
        padding: 95px 0;

        @media(max-width: 700px) {
            padding: 40px 0;
        }
    }
}