.email-confirm {
  margin: 0 auto;
  max-width: 1144px;
  padding-top: 70px;
  @media (max-width: 1200px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  button {
    cursor: pointer;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }
  label.Mui-focused{
    color: #8a00f7;
  }
  .MuiInput-underline:after{
    border-bottom-color: #8a00f7
  }
  .MuiOutlinedInput-root{
    &.Mui-focused fieldset{
      border-color: #8a00f7
    }
  }
  .hint{
    color: #8A00F7;
    font-size: 14px;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    margin-bottom: 36px;
    @media (max-width:1100px) {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__btn-back {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      font-size: 16px;
      color: #A6AAA6;
      border: none;
      background-color: transparent;
    }

    &__right {
      margin-top: 48px;
      max-width: 504px;
    }

    &__form {
      width: 504px;
      margin-top: 8.5px;
      background: #fbf7ff;
      border: 1px solid #ead0fe;
      padding: 33px 85px 50px 85px;
      &__headline-top {
        text-align: center;
        font-weight: bold;
        font-size: 30px;
        color: #8a00f7;
        margin-bottom: 25px;
      }

      &__text {
        margin: 7px -55px 0 -55px;
        font-size: 14px;
        text-align: center;
      }

      &__button {
        margin-top: 28px;
        margin-bottom: 21px;
        width: 100%;
        height: 54px;
        font-size: 18px;
        color: white;
        border: none;
        font-weight: bold;
        background: #8A00F7;
      }
      &__btn-container {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        &__item {
          color: #8A00F7;
          text-decoration-line: underline;
          font-size: 14px;
          border: none;
          background-color: transparent;
        }
      }
    }

    &__text {
      margin-top: 11px;
      font-size: 14px;
      color: #8A00F7;
      text-align: center;
    }
  }
}
