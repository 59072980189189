p{
  padding: 0;
  margin: 0;
}
body{
  overflow-y:scroll;
}
.at-step-one {
  position: relative;
  width: 100%;
  max-width: 990px;
  overflow: hidden;
  height: auto;
  padding: 37px 56px 23px;
  border: 1px solid #ead0fe;
  background: white;
  transition: all .75s ease-in;
  animation: openStep .75s normal ease-in;
  @keyframes openStep {
    0% {
      // height: 60px;
      max-height: 60px;
    }
    100% { 
      max-height: 1000px;
    }
  }
  @media (max-width:850px) {
    padding: 37px 20px 23px;
  }
  .MuiFormControl-root{
    .MuiOutlinedInput-root{
      .MuiOutlinedInput-input{
        padding: 10.5px 14px;
      }
      .MuiInputBase-inputAdornedStart {
        padding-left: 0px;
      }
      .MuiOutlinedInput-inputAdornedStart {
        padding-left: 0;
      }
      &.Mui-focused fieldset{
        border-color: #DBE0EA;
      }
    }
  }
  #here{
    position: relative;
    top: -130px;
  }
  .errorText{
    font-size: 11px;
    line-height: 16px;
    color: #FF1818;
    text-align: right;
  }
  * {
    font-family: "Montserrat", sans-serif;
  }
  &__headlineTop {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 4px;
  }
  .hint{
    color: #a6aaa6;
    font-size: 14px;
  }
  &__headlineBottom {
    @extend .at-step-one__headlineTop;
    color: #8a00f7;
    margin-top: 0;
    font-weight: 400;
  }

  &__text {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    &__column {
      display: flex;
      flex-direction: column;
      width: 47%;
      .MuiAutocomplete-root{
        width: 100%;
      }
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0px;
      }
      .MuiInputAdornment-positionStart {
        margin-right: 5px;
      }
      .react-date-picker__inputGroup__input{
        outline: none;
      }
      .react-date-picker__inputGroup__input:invalid {
        background: none;
      }
      .react-date-picker__wrapper{
        border: 1px solid #DBE0EA;
      }
      .react-date-picker__inputGroup{
        padding-left: 10px;
      }
      .react-date-picker__calendar{
        width: 100%;
        .react-calendar {
          width: 100%;
          box-shadow: 0px 0px 20px #1b1a217c;
          border: none;
          border-radius: 5px;
        }
        .react-calendar__tile--now {
          background: #ddd0e7;
          &:hover{
            background: #cabcd4;
          }
        }
        .react-calendar__month-view__days__day--weekend {
          color: #8A00F6;
        }
        .react-calendar__tile--active {
          color: white;
          background: #8A00F6;
          &:hover{
            background: #7b00da;
          }
        }
      }
      &__title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
        margin-top: 10px;
        &__info {
          position: relative;
          top: 3px;
          transition: all .2s linear;
          &:hover{
            background: #a7a7a770;
          }
        }
        &__helper {
          font-weight: normal;
          font-size: 12px;
        }
      }
      &__text.purpleText{
        color: #8A00F6;
      }
      &__text.greenText{
        color: #0EC22B;
      }
      &__text {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 5px;
        transition: all .45s ease-in-out;
        &__tooltipMoile{
          display: block;
          box-sizing: border-box;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.7);
          overflow: hidden;
          max-height: 0px;
          opacity: 0;
          transition: max-height 1s linear, opacity .5s linear;
          margin-bottom: 8px;
        }
        &__tooltipMoile.active{
          max-height: 200px;
          opacity: 1;
        }
      }
      &__input {
        .MuiOutlinedInput-root {
          border-radius: 0;
        }
      }

      &__date {
        height: 40px;
        &:focus {
          padding: 9px;
          border: 2px solid #0ec22b;
        }
      }

      &__textarea {
        padding: 10px;
        outline: none;
        -moz-appearance: none;
        height: 126px;
        resize: none;
        border: 1px solid #DBE0EA;
        font-size: 14px;

        &:focus {
          padding: 9px;
          border: 2px solid #DBE0EA;
        }
      }
    }
    @media (max-width:785px) {
      flex-direction: column;
      align-items: center;
      &__column{
        width: 100%;
      }
      .stepFiveWrapper{
        .uploader{
          flex-direction: column;
          .upload{
            width: 100%;
          }
          .listFiles{
            width: 100%;
          }
        }
      }
    }
  }
  &__confirmation {
    display: flex;
    justify-content: space-between;
    @media (max-width:550px) {
      flex-direction: column;
      justify-content: center;
    }
    &__button {
      margin-bottom: 5px;
      width: 100%;
      padding: 14px 34px;
      // width: 231px;
      // height: 54px;
      background: #8a00f7;
      font-weight: bold;
      font-size: 18px;
      color: white;
      border: none;
      cursor: pointer;
      margin-top: 16px;
      transition: all .3s linear;
      &:hover{
        background: #6e00c2;
      }
      &:disabled{
        cursor: not-allowed;
        background: #5f07a3;
      }
    }
    &__text {
      font-weight: 500;
      font-size: 16px;
      color: #a6aaa6;
    }
  }
  .loader{
    // position: absolute;
    // top: 160px;
    // left: 50%;
    display: flex;
    margin: 50px auto;
    .arrow-down {
      margin: 30px;
    }
    .arrow-down span {
        display: block;
        width: 30px;
        height: 30px;
        border-bottom: 5px solid #d1b4e7;
        border-right: 5px solid #d1b4e7;
        transform: rotate(45deg);
        margin: -16px;
        animation: arrow-down 2s infinite;
    }
    .arrow-down span:nth-child(2){
        animation-delay: -0.2s;
    }
    .arrow-down span:nth-child(3){
        animation-delay: -0.4s;
    }
    @keyframes arrow-down {
        0%{
            opacity: 0;
            transform: rotate(45deg) translate(-20px,-20px);
        }
        50%{
            opacity: 1;
        }
        100%{
            opacity: 0;
            transform: rotate(45deg) translate(20px,20px);
        }
    }
  }
  // .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  //   color: #0ec22b;
  // }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .MuiButtonBase-root.MuiCheckbox-root.Mui-checked{
    color: #0ec22b;
  }
  .checkboxs{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .checkboxColumn{
      width: 50%;
      display: flex;
      flex-direction: column;
      @media (max-width:440px){
        width:90%;
      }
    }
  }
}
.at-textfield {
  * {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }

  .MuiInputBase-input {
    font-weight: 400 !important;
    font-family: "Montserrat", sans-serif !important;
    color: #1b1a21;
    font-size: 14px;
    line-height: 24px;
  }

  .MuiInputBase-root {
    height: 40px !important;
    border-radius: 0 !important;
  }
}
.at-textfield.tokenSymbol {
  .MuiInputBase-input {
    text-transform: uppercase;
  }
  .MuiInputAdornment-positionStart{
    margin-right: 0px;
  }
}

.at-textfield.country {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
    right: 9px;
    top: 10px;
  }
  @extend .at-textfield;
  .MuiInputBase-input {
    font-weight: 400 !important;
    font-family: "Montserrat", sans-serif !important;
    color: #1b1a21;
    font-size: 14px;
    padding-top: 0;
  }
}

.at-select {
  border-radius: 0 !important;
  font-family: "Montserrat" !important;
  width: 100%;
  .MuiSelect-root {
    font-family: "Montserrat" !important;
    height: 20px !important;
  }
  .MuiSelect-select {
    font-size: 14px;
    font-family: "Montserrat" !important;
  }

  .MuiOutlinedInput-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.at-step-radio-label {
  font-family: "Montserrat" !important;
}
.MuiAutocomplete-root{
  width: 100%;
}
.MuiFormControl-root{
  width: 100%;
  .MuiOutlinedInput-root{
    width: 100%;
    .MuiInputBase-inputAdornedStart {
      padding-left: 0px;
    }
    .MuiOutlinedInput-inputAdornedStart {
      padding-left: 0;
    }
    &.Mui-focused fieldset{
      border-color: #DBE0EA;
    }
  }
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:  #DBE0EA !important;
}
.css-viou3o-MuiAutocomplete-root {
  width: 100% !important;
  .MuiOutlinedInput-root {
    padding: 0 39px 0 9px!important;
  }
}
.MuiOutlinedInput-root.Mui-disabled {
  background: #f9f9f9;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #DBE0EA !important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #DBE0EA !important;
}
.date{
  .MuiFormControl-root .MuiOutlinedInput-root {
    border-radius: 0;
    font-size: 14px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    .MuiOutlinedInput-input{
      padding: 9px 14px;
    }
  }
}
.MuiAutocomplete-noOptions{
  display: none;
}
.separator{
  display: block;
  margin-top: 26px;
  padding-bottom: 6px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #8A00F6;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}
.separator:after {
  content: "..........................................................................................................";
  letter-spacing: 6px;
  font-size: 11px;
  color: #8A00F6;
  display: block;
}
