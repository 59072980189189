.not-display {
  display: none;
}
.desktop-nav-container {
    width: 100vw;
    background-color: #fff;

  @media (max-width: 768px) {
    display: none;
  }

  .logo-cube {
    width: 40px;
  }

  .logo-main {
    width: 180px;
  }

  .desktop-nav {
    width: 100%;
    max-width: 1144px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 1200px) {
      padding: 0 20px;
    }
    .dekstop-nav-logo {
      margin-top: 10px;
      max-width: 120px;

      // @media (max-width: 1144px) {
      //     margin-left: 20px;
      // }

      img {
        width: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .desktop-nav-ava {
      min-width: 320px;

      .desktop-nav-link {
        margin-right: 25px;
        font-weight: 500;
        color: #5c5c5c;
        font-family: "Montserrat", sans-serif;
      }

      .desktop-nav-secure {
        display: flex;
        gap: 14px;

        font-family: "Poppins";
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;

        color: #0ec22b;

        opacity: 0.8;
      }

      .logout {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .no-photo {
      color: #fff;
      font-weight: 700;
      font-size: 36px;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .logout.reminder{
      position: relative;
      &::after{
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #F64A00;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .logout {
      display: flex;
      flex-direction: column;

      .logout-icon-container {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .active {
      color: #0ec22b;
    }

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: 70px;

    .desktop-nav-ava {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .desktop-nav-link {
      text-decoration: none;
    }

    @media (max-width: 675px) {
      display: none;
    }
  }
}
