.accreditation-close-icon {
    position: absolute;
    top: 55px;
    right: 10%;
    cursor: pointer;
    z-index: 100;

    @media(max-width: 768px) {
        display: none;
    }
}