
.assetTokenize {
  margin-top: 75px;
  @media (max-width:1010px) {
    margin-top: 24px;
  }
  * {
    font-family: "Montserrat", sans-serif;
  }

  &__headline {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    margin: 0;
    margin-bottom: 12px;
  }

  &__text {
    max-width: 570px;
    font-size: 24px;
    line-height: 170%;
    @media (max-width:1200px) {
      font-size: 20px;
      line-height: 34px;
    }
  }

  &__img {
    max-width: 91%;
  }
}
