.button-container {
    height: 30px;
    padding: 0 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 15px;
    border-width: 0;
    border-radius: 3px;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__img {
        margin-left: 8px;
    }

    &.button-default {
        background-color: #580be4;
        color: #ffffff;

        &:hover {
            background-color: #580be4;
            box-shadow: 0 4px 4px rgba(14, 194, 43, 0.24);
            border-radius: 3px;
        }

        &:active {
            background: #580be4;
            box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.08);
        }
    }

    &.funded{
        background-color: gray !important;
        cursor: default;
        &:hover {
            box-shadow: none
        }
    }

    &.button-link {
        color: GREEN;
    }

    &.doc-button {
        font-weight: 600;
        padding: 0;
        justify-content: left;
        font-size: 13px;
        letter-spacing: 0.18px;
        width: fit-content !important;
    }

    .button-icon {
        display: flex;
        margin-right: 8px;
        justify-content: center;
    }

    &.button-border-link {
        color: GREEN;
        border: 1px solid GREEN;
    }

    &.check-assets {
        font-family: mainFont;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        width: fit-content;
        margin-top: 10px;
    }
}
