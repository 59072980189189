.kyc-modal-container {

    .MuiPaper-root {
        align-items: flex-start;
        padding: 40px !important;
        position: relative;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 1000;
        cursor: pointer;
    }

    .MuiDialogTitle-root,
    .MuiDialogContent-root {
        padding: 0;
    }

    .MuiDialogTitle-root .MuiTypography-h6 {
        font-size: 24px;
        font-weight: 700;
        font-family: inherit;
    }

    .MuiDialogContent-root {
        font-size: 16px;
        margin: 16px 0 32px;
    }

    .button {
        text-transform: none;
        color: #fff;
        font-family: inherit;
        font-weight: 700;
        font-size: 16px;
        padding: 15px 70px;
    }
}