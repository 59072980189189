.error-page{
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: contain;
    overflow: hidden;
    .bg{
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        filter: blur(5px)
    }
    .error-content{
        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        color: #fff;
        h1{
            font-size: 80px;
            font-weight: 700;
            line-height: 26px;
        }
        span{
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 60px;
        }

        a{
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            text-decoration: none;
            padding: 9px 50px;
        }
    }
}