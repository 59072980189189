.accreditation-way-page {
    display: flex;
    margin-left: 420px;
    min-height: 100vh;

    @media(max-width: 1100px) {
        margin-left: 340px;
    }

    @media(max-width: 900px) {
        margin-left: 255px;
    }

    @media(max-width: 768px) {
        margin-left: 0;
    }
}

.accreditation-way {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 125px 40px 0 120px;
    position: relative;
    width: 100%;

    .accreditation-way-form {
        display: flex;
        flex-direction: column;
    }

    @media(max-width: 900px) {
        padding: 110px 20px 0 76px;
    }

    @media(max-width: 768px) {
        padding: 25px 20px 20px 20px;
    }

    .form-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 32px;
    }

    .ways {
        display: flex;

        @media(max-width: 1250px) {
            flex-direction: column;
        }

        .way-item {
            display: flex;
            flex-direction: column;
            max-width: 250px;
            align-items: flex-start;
            padding: 16px 24px 34px 16px;
            border-radius: 4px;
            margin-right: 20px;
            cursor: pointer;

            .way-container {
                display: flex;
                flex-direction: column;
            }

            @media(max-width: 1250px) {
                width: 100%;
                margin-bottom: 15px;
                max-width: initial;
                flex-direction: row;

                .way-container {
                    padding-top: 10px;
                }
            }

            .way-checkbox {
                margin-bottom: 20px;
                margin-left: -9px;

            }

            .way-title {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 16px;
            }

            .way-description {
                font-size: 16px;
                font-weight: 600;
                color: #8F948F;
            }
        }
    }

    .accreditation-next-button {
        font-family: Poppins !important;
        font-weight: 600 !important;
        max-width: 116px;
        width: 100%;
        color: #fff;
        margin-top: 24px;
        text-transform: none;

        @media(max-width: 768px) {
            max-width: initial;
        }
    }

    .input-error {
        font-size: 12px;
        color: #ea2d2d;
        margin-top: 10px;
    }

    .get-help-container {
        position: static !important;
        margin-top: auto;
        text-align: left;
        padding-top: 20px;
        margin-bottom: 20px;

        @media(max-width: 768px) {
            text-align: center;
            margin: auto auto 0;
            width: 100%;
        }
    }
}