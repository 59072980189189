.landing-header {
    width: 100%;
    height: 100vh;
    position: relative;
    object-fit: cover;
    .bg {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
    }

    .header-inner {
        position: relative;
        z-index: 10;
        max-width: 1144px;
        width: 100%;
        height: 80%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 1144px) {
            padding: 0 20px;
        }
    }

    .title {
        margin: 0;
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 1.5;
        color: #fff;
        max-width: 500px;
        margin-top: 20%;
        text-transform: uppercase;

        @media (max-width: 850px) {
            font-size: 24px;
            max-width: 280px;
            line-height: 30px;
            padding-top: 15px;
        }
    }

    .description {
        display: inline-block;
        max-width: 470px;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin: 32px 0 60px;

        @media (max-width: 850px) {
            font-size: 14px;
            max-width: 280px;
            margin: 20px 0 40px;
        }
    }

    .marketplace {
        cursor: pointer;
        padding: 9px 20px;
        color: #fff;
        margin-right: 18px;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;

        @media (max-width: 520px) {
            margin-right: 10px;
        }
    }

    .submit {
        cursor: pointer;
        background-color: #fff;
        font-size: 14px;
        padding: 9px 20px;
        font-weight: 500;
        text-decoration: none;
        color: #333333;
    }
}
