@import "./pages/";
@import "./components";
@import "./reusable-components";

// @font-face {
//     font-family: "Poppins";
//     src: local("Poppins"),
//         url(../Assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
// }
* {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #fdfdfd;
}
.visabilityHidden{
    height: 0;
    overflow: hidden;
    position: absolute;
}
h2{
    margin: 0;
}
.pipedriveWebForms{
    display: flex;
    justify-content: center;
}