.financial-projection {
  background-color: #fafffb;
  border: 1px solid #99d2a2;
  padding: 25px 32px;
  margin-bottom: 30px;

  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #0ec22b;
  }

  .field-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #99d2a2;

    &:last-of-type {
      border-bottom: none;
    }

    .field {
      font-size: 18px;
    }

    .value {
      font-size: 18px;
      font-weight: 700;
      text-align: right;
    }
  }

  @media(max-width: 768px) {
    padding: 20px 10px;

    .title {
      font-size: 24px;
    }

    .field-item {

      .field,
      .value {
        font-size: 16px;
      }
    }
  }
}