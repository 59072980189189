.subscription-page {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-top: 75px;
  
    @media (max-width: 1144px) {
      padding: 0 30px;
      padding-top: 75px;
    }
  
    @media (max-width: 1000px) {
      flex-direction: column;
      max-width: 500px;
    }
  
    @media (max-width: 500px) {
      padding: 0 20px;
      padding-top: 75px;
    }
  
    &-content {
      margin-top: 22px;
  
      @media (max-width: 1000px) {
        margin-top: 50px;
      }
  
      @media (max-width: 500px) {
        margin-top: 15px;
      }
      .main{
        display: flex;
        flex-direction: column;
        margin-top: 31px;
      }
    }
  
    .form-title {
      display: inline-block;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
  
      @media (max-width: 1000px) {
        display: none;
      }
    }
    .subscriptionData{
        display: flex;
        .text{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-right: 10px;
        }
    }
    .cancel-link{
        font-family: inherit;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        padding: 15px 50px;
        border: none;
        border-radius: 3px;
        outline: none;
        background-color: rgb(14, 194, 43);
        margin-top: 15px;
        transition: .3s background-color linear;
        &:hover{
            cursor: pointer;
            background-color: rgb(15, 149, 37);
        }
    }
    .form-title-tablet {
      font-size: 28px;
      font-weight: 700;
      line-height: 40px;
      display: none;
      margin: 0 auto;
      margin-bottom: 10px;
      margin-top: 20px;
  
      @media (max-width: 1000px) {
        display: inline-block;
      }
  
      @media (max-width: 500px) {
        margin: 20px 0 6px 0;
      }
    }
  
    .security-form-title-mobile {
      display: none;
  
      @media (max-width: 500px) {
        display: inline-block;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }