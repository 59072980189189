.about-page {
  font-family: 'Montserrat' !important;
  .container {
    max-width: 1255px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1255px) {
      padding: 0 20px;
    }
  }

  .header {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    transition: all 0.2s ease-in-out;

    .mobile-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      z-index: 1001;
      background: linear-gradient(to bottom, #bfee92 0%, #ffffff 70%);
      overflow: hidden;
      padding: 40px 20px 50px;
      transition: 0.3s all ease-in-out;

      &.hidden {
        left: -150%;
        transition: 0.3s all ease-in-out;
      }

      .dropdown-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        &.active {
          height: 100%;
          transition: all 0.3s ease-in-out;
        }

        .sub-link {
          font-size: 15px;
          margin-bottom: 20px;
          font-weight: 400;
          font-family: "Montserrat", sans-serif;
          color: #000;
          text-decoration: none;
        }
      }

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20%;

        .logo {
          display: flex;
          padding-left: 0;
        }

        .logo-cube-mobile {
          width: 48px;
          margin-right: 17px;
        }

        .logo-main-mobile {
          width: 180px;
        }
      }

      .nav-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nav-link {
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 24px;
          color: #000;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;

        .login {
          width: 100%;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 24px;
          color: #000;
          text-decoration: none;
          text-align: center;
        }

        .sign-up {
          width: 100%;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          color: #fff;
          text-decoration: none;
          text-align: center;
          font-weight: 500;
          background-color: #8A00F6;
          padding: 9px 24px;
        }
      }
    }
  }

  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 20px;

    @media (max-width: 1255px) {
      padding: 25px 20px 20px;
    }

    .logo {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 110px;
      transition: all 0.4s ease-in-out;

      .logo-cube {
        position: absolute;
        width: 86px;
        transform: translateY(15px);
        left: 0;
        transition: all 0.4s ease-in-out;
      }
    }

    .nav {
      .menu-item {
        margin-left: 35px;
        font-size: 15px;
        opacity: 0.8;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        transition: all 0.2s linear;

        .drop-controller {
          display: inline-flex;
          align-items: center;

          img {
            margin-left: 5px;
          }
        }
      }

      .dropdown {
        position: relative;
      }

      .signup {
        font-size: 14px;
        color: #fff;
        padding: 10px 24px;
      }
    }

    .burger-nav {
      display: none;
      cursor: pointer;
    }

    .logo-main{
      max-width: 255px;
    }

    @media (max-width: 965px) {
      .logo {
        padding-left: 60px;

        .logo-cube {
          bottom: initial;
          width: 44px;
          margin-right: 17px;
          transform: translateY(0);
        }
      }
    }

    @media (max-width: 1010px) {
      .logo-main {
        max-width: 180px;
      }
    }

    @media (max-width: 960px) {
      .logo-main {
        max-width: 180px;
      }

      .nav {
        display: none;
      }

      .burger-nav {
        display: block;
      }
    }
  }

  .header.scrolled {
    box-shadow: 0 2px 12px rgba(00, 00, 00, 0.12);
    transition: all 0.2s ease-in-out;

    .logo {
      padding-left: 60px;
      transition: all 0.4s ease-in-out;

      .logo-cube {
        bottom: initial;
        width: 44px;
        margin-right: 17px;
        transform: translateY(0);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  .companies {
    background-color: #f6f5f8;
    padding: 45px 0;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1144px) {
        padding: 0 20px;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: space-around;

        .img-container {
          margin: 0 20px 15px 20px;
        }
      }

      @media (max-width: 500px) {
        .img-container {
          margin: 0 20px 25px 20px;
        }
      }
    }

    .img-container {
      max-width: 130px;
      max-height: 65px;

      img {
        max-width: 130px;
        max-height: 65px;
        filter: grayscale(100%);
        transition: 0.3s all ease-in-out;

        &:hover {
          filter: grayscale(20%);
          transform: scale(1.05);
          transition: 0.2s all ease-in-out;
        }
      }
    }
  }
}
