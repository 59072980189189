

.asset-info-photo {
    display: flex;
    flex-direction: row;
    max-width: 1150px;
    margin: auto;
    justify-content: space-between;
    margin-top: 44px;
    max-width: 100%;
    width: 100%;

    .main-images-container {
        width: 100%;
    }

    .main-asset-image {
        width: 755px;
        height: 672px;

        @media (max-width: 1140px) {
            width: 100%;
            max-width: 690px;
        }

        @media (max-width: 1100px) {
            width: 100%;
            max-width: 620px;
        }

        @media (max-width: 1025px) {
            max-width: initial;
            width: initial;
        }

        .swiper-pagination-bullet {
            border-radius: 0%;
        }

        .swiper-pagination-bullet-active {
            transform: scale(1.3);
            background: white;
            border-radius: 15%;
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
            font-size: 16px;
            font-weight: 500;
        }

        .swiper-button-next,
        .swiper-button-prev {
            color: #ffffff;
            border: 1px solid white;
            width: 30px;
            height: 30px;
        }

        img {
            width: 755px;
            height: 672px;
            object-fit: cover;

            @media (max-width: 600px) {
                height: 230px;
            }
        }
    }

    .main-asset-image-2 {
        display: none;
    }

    .main-images-container {
        @media (max-width: 1025px) {
            width: 65%;

            img {
                width: 65%;
            }
        }

        @media (max-width: 800px) {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .main-asset-image {
        @media (max-width: 1025px) {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 269px;

                @media (max-width: 600px) {
                    height: 330px;
                }
            }
        }

        @media (max-width: 780px) {
            width: 100%;
            height: 280px;
        }
    }

    @media (max-width: 1025px) {
        width: 980px;
    }

    @media (max-width: 778px) {
        width: 728px;
        flex-direction: column-reverse;

        .main-asset-image {
            height: 280px;

            @media (max-width: 600px) {
                height: 330px;
            }
        }
    }

    @media (max-width: 600px) {
        flex-direction: column-reverse;
        width: 92%;
    }
}

.asset-wrapper {
    padding-top: 70px;
    @media (max-width: 768px) {
        padding-top: 96px;
    }
}

.asset-container {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1200px) {
        padding: 0 20px;
    }

    @media (max-width: 600px) {
        padding: 0 0;
    }
}
