.marketplace-modal {
    .mobile-icon {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        background-color: white;
        @media (max-width: 600px) {
            display: flex;
            width: 54px;
            height: 54px;
            justify-content: center;
            align-items: center;
            img {
                width: 16px !important;
                height: 16px;
            }
        }
    }
    .MuiDialog-paperWidthSm {
        max-width: 850px;
    }
    .MuiDialog-paper {
        @media (max-width: 600px) {
            margin: 0;
            width: 89.33%;
        }
    }
    .MuiDialog-container {
        @media (max-width: 600px) {
            margin-top: 25px;
        }
    }
    .display {
        display: none;
    }
    .border {
        border: 1px solid #e5e5e5;
        // transform: rotate(90deg);
        @media (max-width: 600px) {
            display: none;
        }
    }
    .marketplace-slider {
        width: 850px;
        img {
            width: 100%;
            object-fit: cover;
            max-height: 332px;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    .modal-fields {
        display: flex;
        flex-direction: row;
        padding: 25px;
        justify-content: space-between;
        @media (max-width: 600px) {
            flex-wrap: wrap;
        }

        .details-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            @media (max-width: 600px) {
                display: inline-flex;
                width: 50%;
                margin-bottom: 15px;
            }
            .item-1 {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;

                @media (max-width: 600px) {
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 5px;
                }
            }
            .item-2 {
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #929292;
                @media (max-width: 600px) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .left {
            @media (max-width: 600px) {
                align-items: flex-end;
            }
        }
    }

    .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        .location-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .divider {
            border: 1px solid #f0f0f1;
        }
        .MuiButton-root {
            width: 20%;
            margin: auto;
            margin-bottom: 25px;
            @media (max-width: 600px) {
                width: auto;
            }
        }
        .btn-container {
            width: 100%;
            justify-content: center;
        }
    }
    .content {
        padding: 25px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 600px) {
            flex-direction: column;
            padding-top: 15px;
        }
        .content-2 {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media (max-width: 600px) {
                width: 100%;
            }
            .details-item {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                @media (max-width: 600px) {
                    align-items: center;
                }
                .item-1 {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    @media (max-width: 600px) {
                        font-size: 14px;
                        line-height: 21px;
                        margin-bottom: 5px;
                    }
                }
                .item-2 {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    @media (max-width: 600px) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                    /* identical to box height */

                    color: #929292;
                }
            }
        }
        .content-1 {
            width: 50%;
            @media (max-width: 600px) {
                width: 100%;
                margin-bottom: 15px;
            }
            h2 {
                padding: 0;
                margin: 0;
                font-weight: bold;
                font-size: 20px;
                line-height: 30px;
            }
            p {
                padding: 0;
                margin: 0;
                font-weight: 500;
                font-size: 13px;
                color: #494949;
                line-height: 19px;
                margin-left: 10px;
            }
        }
    }
}
