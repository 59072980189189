.tokenization-dashboard-container{
    .wrapper{
        .switchsBlock{
            margin-top: 18px;
            margin-bottom: 18px;
            padding: 48px 61px;
            border: 1px solid #EAD0FE;
            background: white;
            @media (max-width:954px) {
                padding: 48px 20px;
            }
        }
        .helpBlock{
            margin-top: 18px;
            margin-bottom: 20px;
            padding: 23px 20px;
            border: 1px solid #EAD0FE;
            background: white;
            text-align: center;
            .bold{
                font-weight: bold;
            }
            p{
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                .link{
                    color: #8A00F6;
                    font-weight: 600;
                }
            }
        }
        .listReqiredFiles{
            .item{
                margin: 6px 0;
            }
        }
        .buttons{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 16px;
            .top{
                display: flex;
                justify-content: flex-end;
                .outlineBtn{
                    border: 1px solid #8A00F6;
                    color: #8A00F6;
                    background: transparent;
                    padding: 14px 34px;
                    margin-right: 24px;
                    font-weight: bold;
                    font-size: 18px;
                    cursor: pointer;
                    margin-top: 16px;
                    transition: all .3s linear;
                    &:hover{
                        background: #e7c5f09b;
                    }
                    &:disabled{
                        cursor: not-allowed;
                        background: #d9b9e29b;
                    }
                }
                .filledBtn{
                    color: white;
                    background: #8A00F6;
                    padding: 14px 34px;
                    font-weight: bold;
                    font-size: 18px;
                    border: none;
                    cursor: pointer;
                    margin-top: 16px;
                    transition: all .3s linear;
                    &:hover{
                        background: #6e00c2;
                    }
                    &:disabled{
                        cursor: not-allowed;
                        background: #5f07a3;
                    }
                }
            }
            .greenText{
                font-weight: bold;
                font-size: 16px;
                line-height: 40px;
                color: #0EC22B;
                opacity: 0;
                transition: opacity .3s linear;
            }
            .greenText.show{
                opacity: 1;
            }
        }
        .uploader{
            display: flex;
            justify-content: space-between;
            @media (max-width:760px) {
                flex-direction: column;
            }
            .upload{
                padding: 32px 0;
                background: #FBF6FF;
                outline: 2px dashed #8A00F6;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: relative;
                transition: all .4s linear;
                .text{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                }
                .subText{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
                @media (max-width:500px) {
                    .text{
                        display: none;
                    }
                    .subText{
                        display: none;
                    }
                    .browse{
                        margin-top: 34px;
                    }
                }
                .browse{
                    background: #8A00F6;
                    border-radius: 15px;
                    border: none;
                    color: white;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    padding: 10px;
                    &:hover{
                        cursor: pointer;
                        background: #7d00dd;
                    }
                }
                input{
                    display: block;
                    width: 100%;
                    border: none;
                    text-transform: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all .3s linear;
                    &:hover{
                        cursor: pointer;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                input:hover~img{
                    animation: startUpload 1.5s alternate ease-in-out infinite;
                }
                @keyframes startUpload {
                    0% {
                        transform: translateY(0);
                    }
                    100% { 
                        transform: translateY(-15px);
                    }
                }
            }
            .inside-drag-area{
                background: #f7efff;
                outline-offset: -12px;
                img{
                    position: relative;
                    animation: drag 1s alternate ease-in-out infinite;
                }
                @keyframes drag {
                    0% {
                        top: 0;
                        transform: scale(1);
                    }
                    100% { 
                        top: -10px;
                        transform: scale(1.1);
                    }
                  }
            }
            .listFiles{
                display: flex;
                flex-direction: column;
                width: 47%;
                @media (max-width:760px) {
                    width: 100%;
                }
                .item{
                    margin: 5px 0;
                }
                .file{
                    display: flex;
                    margin-top: 6px;
                    margin-bottom: 6px;
                    position: relative;
                    &::after{
                        content: '';
                        top: 0;
                        left: -3%;
                        position: absolute;
                        width: 103%;
                        height: 103%;
                        background: none;
                        z-index: 5;
                        border-radius: 5px;
                        transition: background-color .5s linear;
                    }
                    &:hover{
                        &::after{
                            background: #f9f1ffde;
                        }
                    }
                    img{
                        margin-right: 11px;
                        z-index: 6;
                        transition: all .4s linear;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                    .details{
                        z-index: 6;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .name{
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 22px;
                            overflow-wrap: break-word;
                        }
                        .progress{
                            display: flex;
                            justify-content: space-between;
                            span{
                                font-size: 10px;
                                line-height: 18px;
                            }
                            .MuiLinearProgress-root{
                                height: 5px;
                                border-radius: 8px;
                                background: #EAD0FE;
                                .MuiLinearProgress-bar{
                                    background: #8A00F6;
                                }
                            }
                        }
                    }
                }
            }
        }
        .uploader{
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            .left{
                width: 50%;
                margin-right: 32px;
                @media (max-width:760px) {
                    width: 100%;
                }
                .wrapperBlock{
                    margin-top: 10px;
                    margin-bottom: 10px;
                    padding: 22px 29px 34px;
                    border: 1px solid #EAD0FE;
                    background: white;
                    .at-step-one__container__column__title{
                        text-align: center;
                    }
                }
            }
            .listFiles{
                width: 50%;
                @media (max-width:760px) {
                    width: 100%;
                }
                .wrapperBlock{
                    height: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    padding: 22px 29px 34px;
                    border: 1px solid #EAD0FE;
                    background: white;
                    .at-step-one__container__column__title{
                        text-align: center;
                    }
                }
            }
        }
    }
}