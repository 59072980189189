.main-exchange {
    max-width: 1144px;
    margin: auto;
    min-height: 80vh;
    padding-top: 110px;

    @media(max-width: 1144px) {
        padding: 0 20px;
    }

    .exchange-options {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        @media (max-width:768px) {
            display: block;
        }

        .exchange-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media (max-width:768px) {
                justify-content: flex-start;
            }

            .MuiButton-text {
                padding: 6px 16px;
            }
        }

        .exchange-btn {
            color: white;
            text-transform: none;
        }
    }

    .exchange-params {
        width: 100%;
        background: #f9f9f9;
        padding: 13px 24px;

        @media (max-width: 768px) {
            display: none;
        }

        .sell-container {
            width: 32%;
            font-size: 16px;
            font-weight: normal;
        }

        .other-fields {
            width: 17%;
            display: flex;
            justify-content: flex-end;
            font-size: 16px;
            font-weight: normal;
        }

        .inner-params {
            p {
                margin: 0;
            }

            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .svg-block {
            display: flex;
            align-items: center;
            width: 17%;
            justify-content: flex-end;

            svg {
                cursor: pointer;
            }
        }

    }

    .svg-block-mobile {
        display: none;

        @media (max-width: 768px) {
            display: block;
            margin-left: auto;
        }
    }

    .exchange-lastchild:last-child {
        margin-bottom: 26px;
    }

    .empty-exchange {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 150px;

        .title {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 5px;
        }

        .descr {
            font-size: 11px;
            color: #494949;
        }

        .start {
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            padding: 15px 88px;
            margin-top: 30px;
            cursor: pointer;
        }
    }
}