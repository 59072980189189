.error-message {
    color: red;
    margin: 0;
}
.login-page-content{
    .signin-page-form {     
        width: 100%;     
        @media (max-width: 950px) {
            max-width: 100%;
        }
        .MuiFormControl-root {
            .MuiFormLabel-root.MuiInputLabel-formControl{
                top: 0;
                left: 0;
                position: absolute;
                font-family: "Montserrat", sans-serif;
                border-radius: 0px;
                transform: translate(15px, 19px) scale(1);
            }
            .MuiFormLabel-root.MuiInputLabel-shrink {
                transform: translate(15px, -4.5px) scale(0.75);
                transform-origin: top left;
                z-index: 100;
            }
        }
    }
}
.signin-page-form {
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    .tool-1 {
        @media (max-width: 800px) {
            display: none;
        }
    }
    .tool-2 {
        display: none;
        @media (max-width: 800px) {
            display: block;
        }
    }
    .MuiFormControl-root {
        margin-top: 20px;
    }
    @media (max-width: 950px) {
        max-width: 335px;
    }
    @media (max-width: 375px){
        width: 100%;
        max-width: 100%;
    }
    
    @media (max-width: 375px) {
        margin-top: 24px;
    }

    .MuiFormLabel-root {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.3px;
        color: #9f9caa;
        font-family: Roboto;
        margin-top: 26px;

        &:first-child {
            margin-top: 0px;
        }
    }

    .password-input-container {
        position: relative;
        width: 271px;
        @media (max-width: 950px) {
            width: 100%;
        }
    }

    .security-info {
        border: 1px solid #8f948f;
        border-radius: 50px;
        color: #8f948f;
        padding: 10px;
        position: absolute;
        right: -35px;
        transition: 0.2s all;
        top: 50%;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            transition: 0.2s all;
        }
    }

    .MuiInputBase-root {
        width: 271px;

        @media (max-width: 950px) {
            width: 100%;
        }

        .MuiInputBase-input {
            height: 15px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #1b1a21;
            font-family: Roboto;
            letter-spacing: 0.02em;
        }

        .MuiInputAdornment-root {
            .MuiButtonBase-root {
                margin-right: -12px;

                .MuiIconButton-label {
                    .MuiSvgIcon-root {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .form-buttons {
        width: 271px;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        @media (max-width: 950px) {
            width: 335px;
            justify-content: flex-end;
        }
        @media (max-width: 375px){
            width: 100%;
            max-width: 100%;
        }

        .forgot-password {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            opacity: 0.8;
            margin-top: 30px;
            text-decoration: none;
            cursor: pointer;

            @media (max-width: 375px) {
                margin-top: 15px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
