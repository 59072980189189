.input-container {

    position: relative;
}
    .view-password{
        position: absolute;
        right: 10px;
        cursor: pointer;

        .not-shown{
            position: absolute;
            width: 15px;
            height: 1px;
            background: #000;
            top: 35%;
            left: 5%;
            transform: translate(-50%, -50%);
            transform: rotate(45deg);
            
        }
    }

    .security-input {
        height: 24px !important;
        width: 100%;
        border-bottom: 1px solid #dddddd;
        font-size: 14px !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #1b1a21;
        padding-right: 32px;

        &:before,
        .MuiInput-underline:before {
            border-bottom: none !important;
        }

        .MuiInputBase-root {
            height: 24px !important;

            .MuiSelect-root {
                font-size: 14px !important;
            }
        }
    }
