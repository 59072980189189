.history-modal-wrapper {
    position: fixed;
    font-family: "Poppins";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;

    .history-modal-container {
        margin: auto;
        background: #ffffff;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 50px;
        max-height: 78%;
        @media (max-width: 600px) {
            width: 89.33%;
            padding: 20px;
        }

        .close-btn-icon {
            position: absolute;
            background-color: transparent;
            position: absolute;
            right: -40px;
            top: 0;
            width: 23px;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            outline: none;
            z-index: 2;
            @media (max-width: 600px) {
                display: none;
            }
        }
        .close-icon-container {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
        .mobile-close-icon {
            display: none;

            @media (max-width: 600px) {
                display: block;
                height: 16px;
                width: 16px;
            }
        }

        .history-modal-content-container {
            max-width: 585px;
            overflow-y: scroll;

            .history-modal-title {
                font-family: Poppins, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                line-height: 36px;
                letter-spacing: 0.02em;
                color: #000000;
                margin-top: 0;
                @media (max-width: 600px) {
                    font-size: 20px;
                    line-height: 36px;
                }
            }

            .history-modal-text {
                font-family: Poppins, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 45px;
                @media (max-width: 600px) {
                    font-size: 13px;
                }
            }

            .history-modal-subtitle {
                font-family: Poppins, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 22px;
                color: #000000;
                margin-top: 0;
                margin-bottom: 18px;
            }
        }

        .close-btn {
            cursor: pointer;
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #ffffff;
            padding: 10px 34px;
            background-color: #580be4;
            border: none;
            border-radius: 3px;
            outline: none;
            margin-top: 25px;
            @media (max-width: 600px) {
                display: none;
            }
        }
    }
}
