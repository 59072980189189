.back-button-accreditation {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 55px;

    color: #a6aaa6;
    svg {
        fill: #a6aaa6;
    }
    @media (max-width: 900px) {
        left: 75px;
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.back-button-checkout {
    display: none;

    color: #a6aaa6;
    svg {
        fill: #a6aaa6;
    }
    @media (max-width: 900px) {
        left: 75px;
    }
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    @media (max-width: 600px) {
        display: none;
    }
}
