.stories-container {
    max-width: 1150px;
    margin-top: 32px;
    overflow-x: scroll;
    margin: 30px auto 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        height: 0px;
    }

    &::-webkit-scrollbar-track {
        height: 0px;
    }

    .stories-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .stories-title {
            font-family: Poppins, sans-serif;
            font-weight: bold;
            font-size: 28px;
            line-height: 42px;
            color: #000000;

            @media(max-width: 600px) {
                font-size: 24px;
            }
        }

        .stories-see-all {
            font-family: Poppins, sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #494949;
            margin-right: 15px;

            &:hover {
                cursor: pointer;
                color: #242424;
            }

            @media (max-width: 500px) {
                display: none;
            }
        }
    }

    .stories {
        display: flex;
        margin-top: 15px;
        overflow-x: scroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            height: 0px;
            width: 0;
        }

        &::-webkit-scrollbar-track {
            height: 0px;
            width: 0;
        }

        .stories-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            min-width: 175px;
            min-height: 228px;
            width: 175px;
            height: 228px;
            border-radius: 10px;
            background-image: url("https://sb-aws-assets-images-test.s3.us-east-2.amazonaws.com/LDB11.webp");
            background-position: center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            margin-right: 20px;
            cursor: pointer;

            &-shadow {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 10px;
                z-index: 100;
                background: linear-gradient(to top, rgba(00,00,00,0.65) 0%, rgba(00,00,00,0) 68%)
            }

            &.tag {
                justify-content: flex-end;
            }

            @media (max-width: 420px) {
                min-width: 155px;
                width: 155px;
            }

            &:last-child {
                margin-right: 0px;
            }

            .stories-checked {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;
                margin-top: 10px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-image: linear-gradient(180deg,
                        #0ec22b 0%,
                        #a2f2af 100%);

                .stories-checked-img {
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    width: 46px;
                    height: 46px;
                    // background-image: url("https://sb-aws-assets-images-test.s3.us-east-2.amazonaws.com/LDB11.webp");
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Poppins, sans-serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height */

                    color: #ffffff;
                    background-position: center center;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                }
            }

            .stories-body {
                margin-bottom: 12px;
                margin-left: 8px;

                .stories-main-txt {
                    font-family: Poppins, sans-serif;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                    position: relative;
                    z-index: 200;
                }

                .stories-sub-txt {
                    margin-top: 3px;
                    font-family: Poppins, sans-serif;
                    font-weight: 500;
                    width: 162px;
                    font-size: 13px;
                    line-height: 19px;
                    color: #ffffff;
                    position: relative;
                    z-index: 200;
                }
            }

            .stories-body-tag {
                margin-bottom: 12px;
                margin-left: 8px;

                .stories-main-txt {
                    font-family: Poppins, sans-serif;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                }

                .stories-sub-txt {
                    margin-top: 3px;
                    font-family: Poppins, sans-serif;
                    font-weight: 500;
                    width: 162px;
                    font-size: 13px;
                    line-height: 19px;
                    color: #ffffff;
                }
            }
        }
    }
}

.stories-modal-container {
    height: 568px;
    margin-top: 120px;

    &>div {
        &:first-child {
            &>div {
                &:first-child {
                    padding-top: 20px !important;
                    padding-right: 12px !important;
                    padding-left: 12px !important;

                    @media (max-width: 768px) {
                        padding-top: 48px !important;
                        padding-right: 15px !important;
                        padding-left: 15px !important;
                    }

                    &>div {
                        opacity: 1 !important;
                    }
                }
            }
        }
    }

    @media (max-width: 990px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
    }

    @media (max-width: 768px) {
        display: block;
        margin-top: 0px;
        height: 100vh;
        position: relative;
    }

    .open-full-article {
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 64px;
        text-decoration: none;

        @media (max-width: 990px) {
            margin-top: 25px;
        }

        @media (max-width: 768px) {
            position: absolute;
            bottom: 40px;
            margin-left: calc(50% - 72.5px);
        }

        .top-arrow-container {
            width: 31px;
            height: 31px;
            background-color: #ffffff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }

        .open-full-article-txt {
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #ffffff;
            margin-top: 8px;
        }
    }

    .close-stories-modal-icon {
        display: block;
        position: absolute;
        top: 73px;
        right: 20px;
        z-index: 1000;

        &:hover {
            cursor: pointer;
        }
    }
}

.close-stories-modal-icon {
    position: absolute;
    top: 60px;
    right: 65px;
    z-index: 1;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        display: none;
    }
}

.slick-track {
    display: flex;
}

.stories-card-modal-active {
    .stories-checked-active {
        display: flex;
        align-items: center;
        margin-top: 39px;
        margin-left: 20px;

        @media (max-width: 768px) {
            margin-top: 66px;
            margin-left: 20px;
        }

        .stories-checked-img-active {
            border-radius: 50%;
            width: 46px;
            height: 46px;
            // background-image: url("https://sb-aws-assets-images-test.s3.us-east-2.amazonaws.com/LDB11.webp");
            background-position: center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }

        .stories-checked-title-active {
            font-family: Poppins, sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            margin-left: 8px;
        }
    }

    .stories-body-active {
        margin-left: 20px;
        margin-bottom: 15px;

        @media (max-width: 768px) {
            margin-bottom: 110px;
            margin-left: 20px;
        }

        .stories-main-txt-active {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
        }

        .stories-sub-txt-active {
            margin-top: 5px;
            width: 513px;
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            color: #ffffff;

            @media (max-width: 768px) {
                width: 324px;
            }
        }
    }
}

.stories-card-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 598px;
    height: 344px;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @media (max-width: 990px) {
        width: 500px;
        height: 280px;
    }

    @media (max-width: 375px) {
        width: 100%;
        height: 100vh;
    }

    .stories-checked {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 20px;

        .stories-checked-img {
            border-radius: 50%;
            width: 46px;
            height: 46px;
            background-image: url("https://sb-aws-assets-images-test.s3.us-east-2.amazonaws.com/LDB11.webp");
            background-position: center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }

        .stories-checked-title {
            font-family: Poppins, sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #ffffff;
            margin-left: 8px;
        }
    }

    .stories-body {
        margin-left: 20px;
        margin-bottom: 10px;

        .stories-main-txt {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
        }

        .stories-sub-txt {
            margin-top: 5px;
            width: 513px;
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            color: #ffffff;
        }
    }
}

.stories-loading {
    text-align: center;
    padding: 100px;
}

.stories-navigation {
    margin: 30px auto 20px;
    display: flex;
    justify-content: center;

    @media(max-width: 800px) {
        display: none;
    }

    span {
        padding: 0 8px 3px;
        border: 1px solid #e5e5e5;
        cursor: pointer;

        &:first-child {
            margin-right: 10px;
        }
    }

    .arrow-left img {
        transform: rotate(-90deg);
    }

    .arrow-right img {
        transform: rotate(90deg);
    }
}