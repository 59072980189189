.signin-page-form .notifications-checkbox:last-of-type{
    @media (max-width: 950px){
        text-align: right;
    }
}
.notifications-checkbox {
    display: block !important;
    margin-top: 15px;
    @media (max-width: 375px) {
        margin-top: 15px;
        min-width: 72px;
    }

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        font-family: Poppins, sans-serif;
    }

    svg {
        width: 20px;
    }
    .custom-icon {
        padding: 7px;
        position: relative;
        border-radius: 1px;
    }
    .custom-checked {
        box-sizing: border-box;
        width: 9px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #fff !important;
    }
}
