.strategic-partners{
    padding: 40px 0 92px;
    background: #FAFFFB;
    border-top: 1px solid #99D2A2;
    border-bottom: 1px solid #99D2A2;
    .title{
        font-size: 30px;
        color: #0EC22B;
        text-align: center;
        margin-bottom: 42px;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
    }
    .archax{
        padding-bottom: 45px;
    }
    .container {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
    
        @media (max-width: 1400px) {
          padding: 0 20px;
        }
      }

    .partners-list{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        @media(max-width: 500px){
            flex-direction: column;
        }
        .partner{
            margin: 3px 25px;
            img{
                max-width: 100%;
            }
        @media(max-width: 500px){
            margin: 30px 0;
        }
        }
    }
}