.card-item-main {
    display: none;
    @media (max-width: 600px) {
        .divider-img {
            width: 100%;
        }
        .divider-not-display {
            display: none;
        }
        .my-asset-img-mobile {
            height: 190px;
        }
        display: flex;
        background: #ffffff;
        box-shadow: 0px 4px 20px #ece8f1;
        flex-direction: column;
        margin-bottom: 20px;

        .item-inner {
            padding: 20px;
        }
        .info-1 {
            display: flex;
            flex-direction: column;
            .geo-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
            }
            img {
                margin-right: 10px;
            }
            h3 {
                font-weight: bold;
                font-size: 15px;
                line-height: 22px;
                margin: 0;
            }
            p {
                font-weight: 500;
                margin: 0;
                font-size: 14px;
                line-height: 21px;
            }
        }
        .info-2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 13px;
            margin-bottom: 10px;
            height: 50px;
            .traiding-p {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;

                color: #494949;
            }
            .info-2-inner {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
                .currency {
                    font-weight: bold;
                    font-size: 15px;
                    margin-left: 5px;
                }
                p,
                h2 {
                    margin: 0;
                }
                .total-amount {
                    font-size: 12px;
                    line-height: 18px;
                    margin: 0;
                    color: #929292;
                }
                .stocks-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    img {
                        margin-right: 5px;
                    }
                }
                h2 {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 27px;
                }
            }
        }
        .transactions {
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;

            color: #494949;
        }
        .transactions-info-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .transactions-info-1 {
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                color: #929292;
            }
            .transactions-info-2 {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                /* identical to box height */

                color: #333333;
            }
            .info-field {
                display: flex;
                flex-direction: row;
                p {
                    margin: 7px 0;
                }
                justify-content: space-between;
                .key {
                    font-size: 16px;
                    line-height: 24px;

                    color: #929292;
                }
                .value {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;

                    color: #333333;
                }
            }
        }
        .see-history {
            display: flex;
            flex-direction: row;
            width: 50%;
            margin: auto;
            margin-top: 15px;
            align-items: center;

            p {
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                margin: 0;
                margin-left: 10px;
            }
        }
        .custom-root {
            @media (max-width: 800px) {
                display: block;
                margin-left: auto;
                margin-right: auto;
                color: white;
                width: 100%;
                height: 54px;
                margin-top: 20px;
                text-transform: none;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
