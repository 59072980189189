.wrapper{
    padding-top: 84px;
    background-color: #0EC22B;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    max-width: 100%;
    .form{
        min-height: unset;
        max-width: calc(100% - 32px);
        .page_header{
            margin-top: 25px;
            margin-bottom: 18px;
            font-family: Montserrat;
            font-weight: bold;
            font-size: 30px;
            color: #FFFFFF;
            @media(max-width: 768px) {
                font-size: 24px;
            }
        }
        .fields{
            padding: 26px 30px;
            margin-bottom: 10px;
            background-color: white;
            width: 592px;
            max-width: 100%;
            font-size: 14px;
            .subhead{
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 700;
                text-align: center;
            }
            .subscription{
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
            }
            .input_wrapper{
                display: flex;
                align-items: center;
                &>img{
                    margin-right: 30px;
                    @media(max-width: 768px) {
                        margin: 0;
                        margin-top: 30px;
                        width: 64px !important;
                    }
                }
                @media(max-width: 768px) {
                    flex-direction: column;
                }
            }
            label :global(.MuiInputLabel-asterisk){
                display: none;
            }
            label:global(.Mui-focused){
                color: #0EC22B;
                width: fit-content;
            }
            & :global(.MuiFormHelperText-root){
                font-family: 'Montserrat';
                font-size: 11px;
                line-height: 14px;
                margin-bottom: 3px;
                text-align: right;
                color: #FF1818;
            }
            .input{
                min-height: 54px;
                border-radius: 0px;
                &>div{
                    border-radius: 0px;
                }
            }
            .checkbox{
                margin-top: 20px;
            }
            .checkbox :global(.MuiFormControlLabel-label){
                font-family: Montserrat;
                font-size: 14px;
                letter-spacing: 0em;
            }
            .submit{
                text-transform: none;
                background-color: #F64A00;
                
                color: #ffffff;
                font-family: Montserrat;
                font-weight: bold;
                font-size: 18px;

                border: none;
                border-radius: 0;
                height: 56px;
                &:global(.Mui-disabled){
                    opacity: .3;
                }
            }
            .resend{
                .resend_button{
                    color: #0EC22B;
                    text-transform: none;
                    font-family: Montserrat;
                    font-weight: 700;
                    &:hover{
                        background-color: transparentize(#0EC22B , 0.9); 
                    }
                }
            }
        }
        .help_button{
            display: block;
            margin: 0 auto;

            text-transform: none;
            text-decoration: underline;
            color: #ffffff;
        }
        .sent_indicator{
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 14px;
            color: #BFEE92;
            opacity: 1;
            transition: ease-in .2s;
            img{
                margin-left: 10px;
            }
            &.hidden{
                opacity: 0;
                transition: ease-out .2s;
            }
        }
        .help_form_container{
            transition:ease-in-out 1s;
            max-height: 700px;
            overflow: hidden;
            .help_form{
                margin: 20px 0;
                position: relative;
                box-sizing: border-box;
                padding: 26px 30px;
                background-color: white;
                h3{
                    font-family: 'Montserrat';
                    margin: 0;
                    font-size: 14px;
                    margin-bottom: 10px;
                }
                .input{
                    border-radius: 0px;
                    &>div{
                        border-radius: 0px;
                    }
                }
                .submit{
                    margin-top: 20px;
                    text-transform: none;
                    color: #F64A00;
                    border-color:#F64A00 ;
                    background-color: #ffffff;
                    font-family: Montserrat;
                    font-weight: bold;
                    font-size: 18px;
                    
                    border-radius: 0;
                    height: 56px;
                    &:global(.Mui-disabled){
                        opacity: .3;
                    }
                }
                .send{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-height: 500px;
                    overflow: hidden;
                    transition:ease-in-out 1s;
                    p{
                        text-align: center;
                        font-weight: 500;
                        &>span{
                            font-weight: 700;
                            color: #0EC22B;
                        }
                    }
                    img{
                        margin-top: 16px;
                    }
                    &.hidden{
                        max-height: 0px;
                    }
                }
            }
            &.hidden{
                max-height: 0px;
            }
        }
    }
    
}