.back-button-container {
    cursor: pointer;
    z-index: 10;
    margin-top: 60px;
    @media (max-width: 950px) {
        display: flex;
        justify-content: center;
    }

    @media (max-width: 375px) {
        // margin-top: 6px;
    }
    .back-button {
        @media (max-width: 950px) {
            width: 335px;
        }
        .back-button-txt {
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #a6aaa6;
            margin-left: 10px;
        }
    }
}
