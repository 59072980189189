.landing-companies {
  background-color: #F6F5F8;
}

.companies-inner {
  box-sizing: border-box;
  max-width: 1144px;
  width: 100%;
  padding: 50px 100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  justify-items: center;

  @media(max-width: 800px) {
    padding: 40px 20px;
  }

  @media(max-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-row-gap: 20px;
  }

  @media(max-width: 500px) {
    grid-template-columns: 1fr 1fr;

  }
}

.landing-trade {
  padding: 45px 0 10px;
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    height: 0px;
  }
}