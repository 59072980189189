.asset-stage-container {
    width: 1150px;
    margin: 45px auto 45px auto;
    @media (max-width: 1025px) {
        width: 980px;
    }
    .title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
            margin-right: 10px;

            @media (max-width: 758px) {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    @media (max-width: 991px) {
        .title-container {
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
    .asset-stage-control-container {
        display: flex;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #333333;
        opacity: 0.8;
        margin-left: 17px;
        margin-top: 5px;
        svg {
            font-weight: 600;
            font-size: 20px;
            width: 1.2em;
            height: 1.2em;
        }
        span {
            margin-left: 10px;
            line-height: 25px;
            letter-spacing: 0.25px;

            @media (max-width: 758px) {
                font-size: 13px;
                line-height: 24px;
                font-weight: 600;

                &:last-child {
                    font-weight: 400;
                    margin-left: 130px;
                }
            }
        }
    }
    @media (max-width: 991px) {
        .asset-stage-control-container {
            margin-left: 0;
        }
    }
    @media (max-width: 758px) {
        .asset-stage-control-container {
            margin-left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }
    }
    .asset-stage-container-list {
        display: flex;
        padding-top: 27px;
        margin-left: -10px;
        margin-right: -10px;
        @media (max-width: 800px) {
            justify-content: space-between;
        }
    }
    @media (max-width: 1199px) {
        .asset-stage-container-list {
            flex-flow: row wrap;
        }
    }
    @media (max-width: 420px) {
        .asset-stage-container-list {
            margin-left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }
    }

    .asset-stage-container-list .asset-stage-card-container {
        min-width: 271px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 4px 0 6px 0;
        @media (max-width: 800px) {
            min-width: 311px;
        }
        @media (max-width: 420px) {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .asset-stage-container-list .asset-stage-card-container:last-child {
        margin-left: 12px !important;
        @media (max-width: 420px) {
            margin-left: 0px !important;
            margin-right: 0px;
        }
    }
    .asset-stage-card-container {
        .progressBar-wrap {
            position: relative;
            margin: 25px 0px;
            height: 7px;

            width: 100%;
            .progressBar {
                position: relative;
                background: #5a5a5a;
                height: 7px;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 7px;
                min-width: 271px;
                width: 100%;
                opacity: 0.5;
                // z-index: 1;
            }
        }
        .asset-stage-card-date {
            font-family: "Montserrat", sans-serif;
            font-weight: bold;
            margin-left: 15px;
        }
        .asset-stage-card-taxt {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: #929292;
        }
        .asset-stage-card-taxt.GREEN-card {
            color: #1f6a0f;
            font-weight: 600;
            position: relative;
            display: inline-block;
            // min-height: 20px;
            padding: 2px 10px;
            letter-spacing: 0.1px;
            transform: translateY(-4px);
        }
        .asset-stage-card-taxt.GREEN-card:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            border-width: 4px;
            border-style: solid;
            border-top-color: #cdf1d3;
            border-left-color: #cdf1d3;
            border-bottom-color: transparent;
            border-right-color: transparent;
            width: 0;
            height: 0;
        }
        .asset-stage-card-taxt.GREEN-card + .progressBar-wrap {
            margin: 21px 0 !important;
        }
        .asset-stage-card-taxt.GREEN-card:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(14, 194, 43, 0.2);
        }
        .asset-stage-card-taxt:first-child + .asset-stage-card-taxt {
            margin-top: 8px;
        }
        .asset-stage-card-taxt:last-child {
            margin: 29px 0 0 2px;
        }
    }
}

@media (max-width: 991px) {
    .asset-stage-container {
        width: 92%;
        margin-top: 36px;
    }
}
