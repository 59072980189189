.wrapper{
    font-family: "Montserrat", sans-serif;
    overflow: hidden;
    padding: 0 30px;
    @media(max-width: 768px){
        padding: 0;
    }
    .shown{
        position: relative;
        min-height: 74px;
        display: flex;
        align-items: center;
        .checkbox{
            height: 48px;
            width: 48px;
            display: flex;
            align-items: center;
            border-right: 1px solid #DBE0EA;
            margin-left: 50px;
            padding-right: 78px;
            @media(max-width: 768px){
                margin-left: 6px;
                padding-right: 16px;
            }
        }
        .info{
            margin-left: 3px;
            min-width: 14px;
        }
        .text{
            font-family: "Montserrat", sans-serif;
            padding-left: 54px;
            flex-grow: 1;
            
            font-weight: bold;
            font-size: 18px;
            color: #8A00F7;
            @media(max-width: 768px){
                padding-left: 20px;
                font-size: 14px;
            }
            span{
                color:rgba(138, 0, 247, 0.5);
                font-size: 14px;
                font-weight: 400;
            }
        }
        .price{
            font-family: "Montserrat", sans-serif;
            width:  123px;
            text-align: center;
            @media(max-width: 768px){
                width: unset;
                margin-right: 15px;
                margin-left: 5px;
            }
        }
    }
    .tooltip_text{
        background-color: inherit;
        width: 100%; 
        z-index: 10;
        max-height: 200px;
        transition: margin .2s ease-in, max-height .5s ease-in;
        padding: 0 20px;
        padding-left: 185px;      
        @media(max-width: 768px){
            padding: 0 20px;
        }  
        &.hidden{
            max-height: 0px;
            transition: margin .1s ease-out, max-height .7s ease-out;
        }
    }
}
    
