.profile-delete-container {
    grid-area: delete;
    display: inline;
    margin-top: 60px;
    height: 110px;

    @media (max-width: 1200px) {
        grid-row-start: 3;
        grid-row-end: 3;
    }

    @media(max-width: 1000px) {
        text-align: center;
    }

    @media (max-width: 500px) {
        grid-row-start: 6;
        height: 130px;
        grid-row-end: 6;
        text-align: left;
        margin-top: 0;
    }

    .profile-delete-title {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .profile-delete-description {
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        margin-top: 5px;

        @media(max-width: 500px){
            max-width: 300px;
        }

        a {
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            margin-top: 5px;
            color: black;
        }
    }

    .button-delete-profile {
        color: #8f948f;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        border: 2px solid rgba(143, 148, 143, 0.3);
        border-radius: 5px;
        background-color: inherit;
        padding: 3px 12px;
        white-space: nowrap;
        font-family: Poppins, sans-serif;
        margin: 0px;
        transition: .3s all;
        cursor: pointer;
        margin-top: 20px;
        text-transform: none;

        &:hover {
            transition: .3s all;
        }
    }
}