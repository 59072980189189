.signup-form-header-title {
    width: 271px;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #000000;

    @media (max-width: 950px) {
        width: 335px;
    }

    @media (max-width: 375px) {
        font-weight: bold;
        font-size: 28px;
        letter-spacing: 0.02em;
        color: #031106;
        width: 100%;
        max-width: 100%;
    }
}

.signup-form-header-subtitle {
    width: 271px;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    opacity: 0.8;
    margin-top: 7px;

    @media (max-width: 950px) {
        width: 335px;
    }

    @media (max-width: 375px) {
        font-size: 16px;
        margin-top: 16px;
        width: 100%;
        max-width: 100%;
    }

    .subtitle-white {
        font-weight: 600;
        color: white;
        margin-left: 5px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        @media (max-width: 375px) {
            margin-left: 8px;
        }
    }
    .subtitle-GREEN {
        font-weight: 600;
        margin-left: 5px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        @media (max-width: 375px) {
            margin-left: 8px;
        }
    }
}

.mountx-logo{
    margin-top: -60px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;

    @media(max-width: 768px){
        margin-top: 0;
    }
    .sb-img{
        max-width: 195px;
        @media (max-width: 400px){
            width: 120px;
        }
    }
    .mountx-img{
        max-width: 110px;
    }
    span{
        color: #525252;
        font-weight: 500;
        font-size: 16px;
        margin: 0 15px;
    }
}
