.email-confirm-container {
    .resend {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
    }

    .confirm-button {
        .MuiButton-label {
            font-family: Poppins !important;
            font-weight: 600 !important;

            .arrow {
                font-weight: 20px;
            }
        }
    }

    .resend-time {
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        opacity: 0.8;
    }

    .signup-form-header-subtitle {
        max-width: 410px;
        width: 100%;
        margin-bottom: 8px;

        span {
            font-weight: 500;
        }
    }

    @media (max-width: 950px) {
        flex-direction: row;

        .auth-header-txt-container {
            padding: 38px 20px 0px 50px !important;
        }

        .auth-header-logo {
            margin-bottom: 190px;
        }

        .signup-auth-page-content {
            align-items: flex-start !important;
        }
    }

    @media(max-width: 800px) {
        .email-confirm {
            align-items: flex-start;
        }
    }

    @media (max-width: 768px) {
        .email-confirm {
            padding: 20px;

            .signup-form-header-title {
                width: 100%;
            }

            .signup-form-header-subtitle {
                width: 100%;
            }

            .code-confirm {
                width: 100%;
            }

            .confirm-button {
                width: 100%;
            }
        }
    }

    @media (max-width: 800px) {
        .auth-header {
            display: initial;
        }
    }

    @media (max-width: 768px) {
        .auth-header {
            display: none;
        }
    }
}