.asset-documents {
  background-color: #fcfcfc;
  border: 1px solid #b9c0c4;
  padding: 25px 32px;
  margin-bottom: 30px;

  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #000;

    @media(max-width: 768px) {
      font-size: 24px;
    }
  }

  .document {
    display: flex;
    padding: 20px 0;
    border-top: 1px solid #B9C0C4;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    @media(max-width: 768px) {
      font-size: 16px;
    }

    img {
      width: 18px;
      margin-right: 21px;
    }

  }
  .disabled{
    // background: #f2f2f294;
    cursor: text;
    color: #929292;
  }
}