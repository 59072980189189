.auth-page-social{
    margin-top: 31px;


    @media (max-width: 375px){
        width: 100%;
        max-width: 100%;
    }
  .social-link{
      text-decoration: none;

    &:link,
    &:visited {
        color: white;
    }
  }
  .social-link-google{
    text-decoration: none;

  &:link,
  &:visited {
    color: rgba(0, 0, 0, 0.54);
  }
}
    .social-continue{
        display: flex;
        align-items: center;

        @media (max-width: 950px) {
            display: flex;
            justify-content: center;
        }

        .dash{
            width: 63px;
            border-top: 1px solid #E1DFE6
        }

        .social-continue-txt{
            margin-left: 16px;
            margin-right: 16px;
            font-size: 14px;
            line-height: 24px;
            color: #333333;
            opacity: 0.8;
        }
    }
    
    .social-buttons{
        margin-top: 25px;
        @media (max-width: 375px){
            width: 100%;
            max-width: 100%;
        }

        .social-button{
           
            &.MuiButtonBase-root{
                display: flex;
                flex-direction: column;
                width: 271px;
                height: 54px;
                border-radius: 5px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-transform: none;
                word-spacing: -2px;

                @media (max-width: 950px) {
                    width: 335px;
                }

                @media (max-width: 460px) {
                    width: 100%;
                    max-width: 100%;
                }

                &.facebook{
                    background-color: #1877F2;
                    color: #ffffff;

                    img{
                        margin-right: 9px;
                    }
                }

                &.google{
                    color: rgba(0, 0, 0, 0.54);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
                    margin-top: 16px;
                    background: white;

                    img{
                        margin-right: 12px;
                    }
                }

                &.linkedIn{
                    background-color: #007AB9;
                    color: #ffffff;
                    margin-top: 16px;

                    img{
                        margin-right: 9px;
                    }
                }
            }
        }
    }
}