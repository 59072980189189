.history-divider {
    display: none;
    @media (max-width: 600px) {
        display: block;
    }
}
.last-divider {
    display: none;
}

.payments-grid-mobile {
    display: none;
    @media (max-width: 600px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .fields-info {
            text-align: right;
            font-weight: 500;
            font-size: 16px;
        }
        .fields-names {
            font-weight: normal;
            font-size: 16px;

            color: #929292;
        }
    }
}

.payments-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 20px;
    @media (max-width: 600px) {
        display: none;
    }

    .payments-head {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: #929292;
        border-bottom: 1px solid #cbcad5;
        text-align: right;
    }

    .payments-info {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        margin-top: 8px;
        text-align: right;

        .upload-history-doc-btn {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 8px;
            text-align: right;
            color: #580be4;
            cursor: pointer;
            outline: none;
            position: relative;
            background-color: transparent;
            border: none;
            padding: 0;
            display: flex;
            align-items: center;
            svg {
                width: 17px;
            }
        }
    }

    .first-column {
        text-align: left;
        p {
            margin: 0;
        }
    }

    .status-info {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #929292;
    }
}
