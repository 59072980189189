.countries-form {
    .MuiInputLabel-formControl {
        position: relative;
    }
}

.dropdown-img {
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    margin-right: 15px;
}

.MuiPopover-paper {
    &::-webkit-scrollbar {
        width: 9px;
        border-radius: 5px;
        height: 100px;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 3px 3px 10px rgba(121, 119, 119, 0.3);
        width: 7px;
        border-radius: 5px;
        height: 170px;
    }
}
// .MuiPopover-root {
//     .MuiPaper-root {
//         .MuiList-root {
//         }
//     }
// }
