.tokenization-steps {
  padding: 126px 20px 85px 20px;
  background: #FBF7FF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .at-step-one:first-child{
    overflow: visible;
  }
  * {
    // margin: 0;
    // padding: 0;
    font-family: "Montserrat", sans-serif;
  }

  // &__field {
  //   border: 1px solid #EAD0FE;
  // }
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0EC22B !important;
}
