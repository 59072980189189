
.main-information {
    display: flex;
    padding-top: 75px;
    font-family: "Montserrat", sans-serif;
    max-width: 1144px;
    margin: 0 auto;
    background-color: #fdfdfd;

    .profile-nav {
        @media(max-width: 500px) {
            padding: 0 20px;
            margin: 0 !important;
        }
    }

    @media (max-width: 1200px) {
        max-width: 940px;
    }

    @media (max-width: 991px) {
        max-width: 777px;
    }

    @media (max-width: 1000px) {
        max-width: 500px;
        flex-direction: column;
    }

    .profile-nav {
        @media(max-width: 500px) {
            margin-left: 20px;
        }
    }

    .form-title {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: #000000;

        @media(max-width: 1000px) {
            display: none;
        }
    }

    .form-subtitle {
        display: none;
    }

    .form-title-tablet {
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        margin: 0 auto;
        margin-bottom: 15px;
        display: none;

        @media (max-width: 1000px) {
            display: block;
        }

        @media(max-width: 500px) {
            margin: 20px 0 0 20px;
        }
    }

    .form-subtitle-mobile {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: none;
        margin: 6px 0px 10px 20px;

        @media(max-width: 500px) {
            display: inline-block;
        }
    }

    .form-blocks-description {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-top: 5px;

        &-first {
            @media (max-width: 1000px) {
                display: none;
            }
        }

    }

    .main-information-content {
        width: 80%;
        margin-top: 22px;

        @media (max-width: 1000px) {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (max-width: 500px) {
            width: 100%;
            margin-top: 0;
        }

        .content {
            width: 100%;
            display: grid;
            grid-template-columns: 0.7fr 1.3fr;
            grid-template-rows: repeat(5, 138px);
            grid-column-gap: 15%;
            grid-template-areas:
                "form photo"
                "form delete"
                "save delete";
            margin-top: 31px;

            @media (max-width: 1000px) {
                justify-content: space-around;
                grid-template-areas:
                    "photo form"
                    "delete form"
                    "delete save";
            }

            @media (max-width: 500px) {
                grid-template-areas:
                    "photo"
                    "form"
                    "delete"
                    "save";
                margin-top: 0;
                grid-template-rows: repeat(7, 200px);
                justify-content: normal;
                grid-template-columns: 1fr;
            }
        }

        .form-container {
            margin-right: 10%;
            grid-area: form;
            grid-row-start: 1;
            grid-row-end: 6;

            @media (max-width: 1000px) {
                margin-right: 0;
            }

            @media (max-width: 500px) {
                grid-row-start: 2;
                grid-row-end: 6;
            }

            @media (max-width: 375px) {
                margin-top: 0px;
                grid-row-start: 2;
                grid-row-end: 7;
            }
        }

        .form-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #000000;

            @media (max-width: 375px) {
                font-size: 24px;
                color: #000000;
            }
        }

        .button-change-photo {
            width: 100%;
        }

        .button-change-photo,
        .button-delete-photo {
            color: #8f948f;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: center;
            border: 2px solid rgba(143, 148, 143, 0.3);
            border-radius: 5px;
            background-color: inherit;
            padding: 3px 12px !important;
            white-space: nowrap;
            font-family: "Montserrat", sans-serif;
            margin: 0px;
            transition: .3s all;
            cursor: pointer;
            outline: none;
            text-transform: none;
            min-width: 120px;

            &:hover {
                transition: .3s all;
            }
        }

    }

    .input-error {
        font-size: 12px;
        color: #EA2D2D;
    }
}

#country-select-demo{
    width: 100%;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
}
