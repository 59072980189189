.photo-container {
  display: flex;
  grid-area: photo;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 500px) {
    width: 100%;
    order: -1;
    align-items: center;
    flex-direction: row;
    justify-content: left;
    text-align: left;
  }

  .profile-photo-container {
    width: 120px;
    min-width: 120px;
    height: 120px;
    min-height: 120px;
    border-radius: 50%;
    margin-right: 35px;
    margin-bottom: 35px;
    overflow: hidden;
    position: relative;

    .no-photo {
      width: 100%;
      height: 100%;
      text-align: center;
      color: #fff;
      font-weight: 700;
      font-size: 32px;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      word-break: keep-all;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media (max-width: 1000px) {
      margin: 0 0 15px 0;
    }

    @media (max-width: 500px) {
      margin: 0 25px 0 0;
    }

    .profile-photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .file-input {
    display: none;
  }

  .edit-profile-photo-container {
    display: inline-block;
    box-sizing: content-box;

    @media (max-width: 500px) {
      display: inline-block;
      width: 150px;
      margin-top: 10px;
    }

    .profile-photo-user-name {
      display: inline-block;
      font-family: Poppins, sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 10px;
      //max-width: 200px;
      word-break:break-word;
      max-height: 100px;
      overflow: hidden;
    }

    .profile-edit-photo-buttons {
      display: flex;

      @media (max-width: 1235px) {
        flex-wrap: wrap;
        width: 150px;
        margin-bottom: 25px;
      }

      .button-change-photo,
      .button-delete-photo {
        margin-top: 10px;
      }

      .button-change-photo {
        margin-right: 5%;
      }
    }
  }
}
