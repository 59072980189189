.security-page {
  max-width: 1144px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding-top: 75px;

  @media (max-width: 1144px) {
    padding: 0 30px;
    padding-top: 75px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    max-width: 500px;
  }

  @media (max-width: 500px) {
    padding: 0 20px;
    padding-top: 75px;
  }

  &-content {
    margin-top: 22px;

    @media (max-width: 1000px) {
      margin-top: 50px;
    }

    @media (max-width: 500px) {
      margin-top: 15px;
    }
  }

  .form-title {
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .form-title-tablet {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    display: none;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 20px;

    @media (max-width: 1000px) {
      display: inline-block;
    }

    @media (max-width: 500px) {
      margin: 20px 0 6px 0;
    }
  }

  .security-form-title-mobile {
    display: none;

    @media (max-width: 500px) {
      display: inline-block;
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .input-error {
    font-size: 12px;
    color: #ea2d2d;
  }

  .input-success {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #0ec22b;
  }

  .verification {
    display: flex;
    flex-direction: column;
    margin-top: 44px;
    @media(max-width: 900px){
      max-width: 270px;
      margin: 0 auto;
      margin-top: 44px;
    }
    @media(max-width: 500px){
      max-width: 100%;
      width: 100%;
    }
    
    .security-form-title {
      margin-bottom: 22px;
      @media(max-width: 500px){
        display: initial;
      }
    }
    .form-field {
      margin-bottom: 25px;
    }
    .security-input-container {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .security-info {
      border: 1px solid #D80027;
      border-radius: 50px;
      color: #8f948f;
      padding: 10px;
      position: absolute;
      right: -35px;
      transition: 0.2s all;
      top: 50%;
      transform: translateY(-50%);
      @media(max-width: 500px){
        padding: 8px;
        font-size: 12px;
        top: 12%;
        left: 30%;
        right: initial;
      }
      span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #D80027;
      }
    }
  }
}

.MuiTooltip-popper{
    pointer-events: all !important;
    .MuiTooltip-tooltip{
        padding: 15px;
    }
}
.tooltip-confirm-link{
    color: #fff;
    border: 2px solid #dddddd;
    border-radius: 4px;
    padding: 6px 17px;
    font-size: 14px;
    font-family: Poppins;
    text-decoration: none;
}
.tooltip-confirm-title{
    font-family: Poppins;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 15px;
}
