.mountx-header{
    position: relative;
    width: 419px;
    height: 100vh;
    max-height: 100vh;
    overflow-y: hidden;
    margin-right: 117px;

    @media (max-width: 950px) {
        height: 250px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        overflow-x: hidden;
    }

    @media (max-width: 800px) {
        display: none;

    }
    img{
        width: 417px;
        max-width: 417px;
    }
}