.snackbar {
    p {
        padding: 0;
        margin: 0;
    }
    .MuiAlert-filledSuccess {
        background-color: #43a047;
    }
    .snack-inner {
        display: flex;
        flex-direction: row;
        .snack-text {
            margin-right: 30px;
            .p1 {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
            .p2 {
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }

    .MuiButtonBase-root {
        width: 10px;
        height: 10px;
        align-items: flex-start;
        padding: 0;
    }
}
