.about-cards {
  padding-top: 250px;
  margin-bottom: 90px;
  @media(max-width: 768px){
    padding-top: 150px;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 68px;
    @media (max-width: 1050px) {
      gap: 30px;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 60px;
    }
  }
  .cards-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 160px 60px 47px 60px;
    @media (max-width: 1050px) {
      padding: 160px 30px 30px;
    }
    @media (max-width: 768px) {
      padding: 58px 20px 20px;
    }

    &:first-child {
      border: 1px solid #99d2a2;
      background-color: #fafffb;
      .title {
        color: #0ec22b;
      }
    }
    &:last-child {
      border: 1px solid #ead0fe;
      background-color: #fbf6ff;
      .title {
        color: #8a00f6;
      }
    }

    img {
      position: absolute;
      top: -140px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 220px;
      @media (max-width: 768px) {
        max-width: 90px;
        top: -55px;
        &.house{
          max-width: 70px;
        }
      }
    }
    .title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
      font-family: "Montserrat", sans-serif;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .descr {
      font-size: 20px;
      line-height: 34px;
      max-width: 490px;
      margin-bottom: 30px;
      font-family: "Montserrat", sans-serif;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    .link {
      margin-top: auto;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
      color: #000;
      font-family: "Montserrat", sans-serif;
    }
  }
}
