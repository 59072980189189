.add-offering-step1-page {
    display: flex;
    margin-left: 420px;
    min-height: 100vh;

    @media(max-width: 1100px) {
        margin-left: 340px;
    }

    @media(max-width: 900px) {
        margin-left: 255px;
    }

    @media(max-width: 768px) {
        margin-left: 0;
    }

    .add-offering-step1 {
        padding: 125px 0 0 120px;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        min-height: 100vh;

        @media(max-width: 900px) {
            padding: 110px 0 0 76px;
        }

        @media(max-width: 768px) {
            padding: 25px 20px 20px 20px;
            align-items: center;
        }
    }

    .form-title-container {
        margin-bottom: 28px;
        display: flex;
        align-items: center;

        .form-title {
            font-size: 28px;
            margin: 0 32px 0 0;
        }

        .step {
            font-size: 16px;
            color: #8f948f;
            text-transform: uppercase;
            font-weight: 500;
            opacity: 0.8;
        }
    }

    .add-offering-step1-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 280px;

        @media(max-width: 768px) {
            width: 100%;
        }

        .inputlabel-label {
            font-size: 12px;
            line-height: 16px;
            color: #9F9CAA;
        }

        .form-field {
            margin-bottom: 28px;
        }

        .checkbox-label {
            font-size: 14px;
            font-weight: 600;
            color: #333;
        }

        .checkbox-group {
            flex-direction: row;
            margin-bottom: 24px;

            .MuiTypography-root {
                font-size: 14px;
                font-weight: 500;
                font-family: inherit;
            }
        }

        .day{
            margin-bottom: 26px;
        }
    }

    .accreditation-next-button {
        max-width: 116px;
        width: 100%;
        color: #fff;
        margin-top: 24px;
        margin-bottom: 2px;
        text-transform: none;

        @media(max-width: 768px) {
            max-width: initial;
        }
    }

    .get-help-container {
        position: static !important;
        margin-top: auto;
        text-align: left;
        padding-top: 20px;
        margin-bottom: 20px;

        @media(max-width: 768px) {
            text-align: center;
            margin: auto auto 0;
            width: 100%;
        }
    }
}