.asset-info {
  .asset-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 768px){
      flex-direction: column-reverse;
    }
    .promo{
      width: 300px;
      @media (max-width: 768px){
        margin-bottom: 15px;
      }
    }
    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      margin-bottom: 5px;

      @media (max-width: 768px) {
        font-size: 24px;
        width: 85%;
        margin-bottom: 2px;
        line-height: 32px;
      }
    }

    .location {
      font-size: 16px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 16px;
      }
    }

    .descr {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 15px;
      max-width: 610px;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .asset-info-main {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .left {
      width: 60%;

      @media (max-width: 768px) {
        order: 2;
        width: 100%;
      }
    }

    .right {
      width: 40%;

      @media (max-width: 768px) {
        order: 1;
        width: 100%;
      }
    }

    .asset-image {
      margin-bottom: 20px;
      height: 400px;
      width: 100%;
      position: relative;
      .loading{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 400px;
        animation-duration: 1.7s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: placeholderAnimate;
        background: #f6f7f8;
        background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
        background-size: 1300px;
      }
      @keyframes placeholderAnimate {
          0%{ background-position: -650px 0; }
          100%{ background-position: 650px 0; }
      }
      iframe {
        width: 100%;
        height: 400px;
      }
      img{
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .tags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;

      .tag-item {
        background-color: #e8eaed;
        color: #6d7584;
        border-radius: 6px;
        margin-right: 10px;
        font-size: 14px;
        padding: 4px 10px;
      }
    }

    .asset-top-right-wrapper {
      overflow: hidden;
      padding: 0 30px;
      @media (max-width: 768px) {
        padding: 0;
      }
    }

    .asset-top-right {
      position: relative;

      .to-checkout {
        animation: SlideOut 0.3s ease-in-out;
      }
    }

    .raised {
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      color: #0ec22b;
      margin-bottom: 4px;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
      }
    }

    .line {
      background-color: #e3f6e6;
      border: 1px solid #0ec22b;
      border-radius: 8px;
      width: 100%;
      height: 10px;
      margin-bottom: 8px;
      overflow: hidden;

      .filled {
        background-color: #0ec22b;
        border-radius: 8px;
        width: 0%;
        height: 100%;
        transition: width 1s ease-in-out;
      }
    }

    .percentage {
      color: #6d7584;
      font-size: 18px;
      margin-bottom: 8px;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      .green {
        font-weight: 700;
        color: #0ec22b;
      }
    }

    .info-field {
      padding: 9px 0 20px;
      margin-bottom: 0;
      transition: margin-bottom 0.3s ease-in-out;
      border-top: 1px solid #e5e5e5;

      // &.last {
      //   border-bottom: none;
      // }

      .tooltip-wrapper {
        &::after {
          display: none;
          position: absolute;
          content: " ";
          background-color: #fff;
          border-top: 1px solid #0ec22b;
          border-left: 1px solid #0ec22b;
          transform: rotate(45deg);
          width: 18px;
          height: 18px;
          z-index: 0;
          top: 21px;
          left: 167px;
        }
      }

      .days-left-tooltip {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background-color: #fff;
        color: #6d7584;
        font-size: 13px;
        width: 98%;
        height: 0;
        padding-left: 5px;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        // z-index: 100;
      }

      &.tooltiped {
        margin-bottom: 50px;
        transition: margin-bottom 0.3s ease-in-out;

        .days-left-tooltip {
          height: 50px;
          border: 1px solid #0ec22b;
          transition: height 0.3s ease-in-out;
        }

        .tooltip-wrapper {
          &::after {
            display: block;
          }
        }
      }

      .value {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 4px;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }

      .field {
        font-size: 18px;
        color: #6d7584;
        position: relative;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .question {
        cursor: pointer;
        position: relative;
        top: -10px;
        right: -5px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .buy {
      // margin-top: 72px;
      background-color: #f64a00;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      padding: 14px 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease-out;

      @media (max-width: 1227px) {
        padding: 10px 30px;
        margin-bottom: 10px;
      }

      @media (max-width: 768px) {
        margin-bottom: 20px;
        padding: 13px;
      }

      &:hover {
        background-color: #d63300;
        transition: all 0.2s ease-out;
      }

      img {
        margin-left: 8px;
      }
    }
  }

  @keyframes SlideOut {
    from {
      left: 0;
    }

    to {
      left: -500px;
    }
  }
}

.days-to-invest-tooltip {
  color: #6d7584;
  font-size: 13px;
}

.asset-image {
  width: 100%;
  margin-bottom: 20px;

  iframe {
    width: 100%;
    height: 400px;
  }
}