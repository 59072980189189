.wrapper{
    .content{
        margin: 0 auto;
        max-width: 1400px;
        width: 100%;
        padding: 71px 160px 74px ;
        @media (max-width: 1400px) {
            padding: 71px 30px 74px ;
          }
        .header{
            font-family: "Montserrat", sans-serif;
            font-weight: bold;
            font-size: 30px;
            text-align: center;
            color: #0EC22B;
            margin-bottom: 20px;
        }
        .text{
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 35px;
            color: #000000;
            margin-bottom: 28px;
        }
    }
}