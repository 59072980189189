.direct-access {
    width: 100%;
    max-width: 1144px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;


    @media(max-width: 1144px) {
        padding: 0 20px;
    }

    .title {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        color: #333;

        @media(max-width: 500px) {
            font-size: 24px;
        }
    }

    .subtitle {
        margin: 22px 0 44px;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        text-align: center;
        color: #5b5b5b;

        @media(max-width: 500px) {
            font-size: 14px;
        }
    }

    .assets {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
        justify-content: center;

        @media(max-width: 950px) {
            grid-template-columns: 1fr;
        }
    }

    .values {
        width: 100%;
        position: relative;
    }

    .rect-bg {
        position: absolute;
        top: 50px;

        img {
            width: 100%;
        }

        @media(max-width: 820px) {
            display: none;
        }
    }

    .rect-bg2 {
        position: absolute;
        bottom: -40px;

        img {
            width: 100%;
        }

        @media(max-width: 820px) {
            display: none;
        }
    }

    .values-list {
        display: flex;
        justify-content: space-between;
        padding: 160px 120px 100px;

        @media(max-width: 800px) {
            padding: 50px 0;
        }

        @media(max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }

        .values-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 170px;
            text-align: center;
            color: #5b5b5b;

            @media(max-width: 600px) {
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .title {
                margin-bottom: 15px;
            }
        }
    }


}