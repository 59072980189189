.profile-nav {
    display: flex;
    flex-direction: column;
    margin-right: 130px;
    margin-top: 90px;
    min-width: 150px;
    align-items: flex-start;


    @media (max-width: 1000px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 0;
    }

    &__link {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 35px;
        color: #333333;
        opacity: 0.5;
        white-space: nowrap;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 600;
        padding: 0 10px;
        position: relative;

        .dot {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            display: none;
        }

        &_active {
            line-height: 35px;
            opacity: 1;

            @media (max-width: 1000px) {
                .dot {
                    display: block;
                }
            }
        }
    }

}
