.documents-table {
    margin-top: 31px;

    @media(max-width: 768px) {
        margin-top: 0;
    }

    .documents-clear {
        color: #929292;
        display: block;
        margin-top: 50px;
        @media(max-width: 1000px){
            text-align: center;
        }
    }

    .documents-list {
        padding-top: 10px;
    }

    .documents-header {
        color: #929292;
        font-size: 13px;
        border-bottom: 2px solid #929292;

        th{
            color: #929292;
            font-family: 'Poppins';
            font-weight: 400;
        }


        @media(max-width: 768px) {
            &-desktop {
                display: none;
            }
        }

    }

}