.get-started {
  margin: 0 auto;
  max-width: 1144px;
  padding-top: 116px;
  @media (max-width: 1200px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }
  button {
    cursor: pointer;
  }
  label.Mui-focused{
    color: #8a00f7;
  }
  .MuiInput-underline:after{
    border-bottom-color: #8a00f7
  }
  .MuiOutlinedInput-root{
    &.Mui-focused fieldset{
      border-color: #8a00f7
    }
  }
  .hint{
    color: #8a00f7;
    font-size: 14px;
    margin-top: -7px;
    width: 100%;

    &.hidden{
      opacity: 0;
      pointer-events: none;
    }
    @media (max-width:1010px) {
      width: 50%;
    }
    @media (max-width:590px) {
      width: 100%;
    }
  }
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 74px;
    &__form {
      margin-top: 35px;
      background: #fbf7ff;
      border: 1px solid #ead0fe;
      max-width: 504px;
      padding: 33px 85px 50px 85px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width:1010px) {
        max-width: 100%;
        width: 100%;
        padding: 33px 20px 50px;
      } 
      .signup-form-header-subtitle{
        width: auto;
        display: flex;
        justify-content: center;
        @media (max-width:440px) {
          flex-direction: column;
          align-items: center;
        }
      }
      &__input {
        width: 100%;
        background: white;
        .MuiOutlinedInput-root {
          border-radius: 0;
          font-family: "Montserrat", sans-serif;
        }
      }

      &__headline-top {
        text-align: center;
        font-weight: bold;
        font-size: 30px;
        color: #8a00f7;
        margin-bottom: 25px;
      }

      &__headline-bottom {
        font-weight: normal;
        text-align: center;
        margin: 0 -10px 10px -10px;
        font-size: 30px;
        font-size: 24px;
      }

      &__button {
        margin-top: 28px;
        width: 100%;
        height: 54px;
        font-size: 18px;
        color: white;
        border: none;
        font-weight: bold;
        background-color: #8A00F7;
        transition: background-color .3s linear;
        @media (max-width:1010px) {
          width: 50%;
        }
        @media (max-width:590px) {
          width: 100%;
        }
        &:hover{
          cursor: pointer;
          background-color: #6a0db1;
        }
        &:disabled{
          cursor: not-allowed;
          background-color: #66279b;
        }
      }
      .makeStyles-icon-11 {
        border: 2px solid #8A00F7;
      }
      .makeStyles-checkedIcon-12 {
        border: 2px solid #8A00F7;
        background-color: #8A00F7;
      }
      .MuiFormControl-root {
        background: white;
        margin-top: 16px;
        margin-bottom: 8px;
        @media (max-width:1010px) {
          width: 50%;
        }
        @media (max-width:590px) {
          width: 100%;
        }
      }
      .MuiAutocomplete-root {
        @media (max-width:1010px) {
          width: 50%;
        }
        @media (max-width:590px) {
          width: 100%;
        }
        .MuiFormControl-root {
          background: white;
          margin-top: 16px;
          margin-bottom: 8px;
          @media (max-width:1010px) {
            width: 100%;
          }
        }
      }
      .MuiOutlinedInput-root {
        border-radius: 0px;
      }
      .MuiOutlinedInput-input {
        font-family: "Montserrat", sans-serif;
      }
      .MuiInputLabel-formControl {
        top: 0;
        left: 0;
        position: absolute;
        font-family: "Montserrat", sans-serif;
        border-radius: 0px;
        transform: translate(15px, 19px) scale(1);
      }
      .MuiInputLabel-shrink {
        transform: translate(15px, -4.5px) scale(0.75);
        transform-origin: top left;
        z-index: 100;
      }
      &__text {
        margin: 7px 5px 0;
        font-size: 14px;
        color: #8A00F7;
        text-align: center;
      }
    }
  }
}
