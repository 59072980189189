.MuiDrawer-root {
    .mobile-nav-drawer-header {
        width: 100%;
        padding: 25px 30px;

        @media (max-width: 768px) {
            // width: calc(100% - 60px);
            display: flex;
            justify-content: space-between;
        }

        @media (max-width: 375px) {
            // width: calc(100% - 40px);
            padding: 10px 20px;
        }

        .mobile-nav-drawer-logo {
            max-width: 100px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .mobile-nav-drawer-title {
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
        flex-grow: 1;
        align-items: center;
        display: flex;
        justify-content: space-around;
    }

    .mobile-nav-drawer-list {
        display: flex;
        flex-direction: column;
        align-items: center;

        .mobile-nav-drawer-link {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #ffffff;
            letter-spacing: 0.00938em;
            text-decoration: none;
            margin-top: 22px;

            &-active {
                // color: #0EC22B;
                text-decoration: none;
            }
        }
    }

    .mobile-nav-drawer-txt {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        opacity: 0.6;
        padding: 0 46px;
        margin-top: 65px;
        margin-bottom: 20px;
    }

    .mobile-nav-drawer-logout-item {
        margin: 30px auto;
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        .mobile-nav-drawer-logout {
            width: 36px;
            height: 36px;
            margin-right: 8px;
        }
    }

    .MuiPaper-root {
        background-color: #353341;
        width: 100%;

        .MuiButtonBase-root {
            padding: 0px;
            @media (max-width: 600px) {
                margin-bottom: 20px;
            }

            .MuiIconButton-label {
                color: white;
            }
        }

        .solidblock-logo {
            width: 120px;
            height: 14px;
        }
    }
}
