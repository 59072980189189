.gate-container {
  background: #FBF7FF;
  min-height: 100vh;
  li{
    margin-left: 15px;
  }
  .icon {
    background: #8a00f7;
    width: 21px;
    height: 21px;
    transition: all 0.5s linear;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  
    &:hover::after {
      transform: rotate(-135deg);
      transition: all 0.5s linear;
      margin: 8px 0 0 0;
    }
    &:after {
      content: " ";
      margin: 4px 4px 3px 4px;
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border: solid #fff;
      border-width: 0 0.15em 0.15em 0;
      transition: all 0.5s linear;
  
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &__gate {
    margin: 0 auto;
    max-width: 1144px;
    padding-top: 100px;
    padding-bottom: 30px;
    @media (max-width: 1200px) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @media (max-width: 768px){
      padding-top: 125px;
    }
    button {
      cursor: pointer;
    }
    * {
      margin: 0;
      padding: 0;
      font-family: "Montserrat", sans-serif;
    }
  
    &__field {
      width: 100%;
      background: #ffffff;
      border: 1px solid #ead0fe;
      padding: 35px 6% 50px 6%;
      display: flex;
      flex-direction: column;
  
      &__toggle {
        display: flex;
        align-items: center;
        gap: 14px;
        border: none;
        background-color: transparent;
        font-weight: bold;
        font-size: 16px;
      }
  
      &__headlineTop {
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
        @media (max-width: 1024px){
          font-size: 26px;
          line-height: 33px;
          text-align: center;
        }
        @media (max-width: 580px){
          font-size: 20px;
          line-height: 30px;
        }
      }
  
      &__headlineBottom {
        @extend .gate-container__gate__field__headlineTop;
        color: #8a00f7;
        margin-bottom: 31px;
        @media (max-width: 1024px){
          font-size: 26px;
          line-height: 33px;
          text-align: center;
        }
        @media (max-width: 580px){
          font-size: 20px;
          line-height: 30px;
        }
      }
  
      &__text {
        max-width: 685px;
        font-size: 14px;
        line-height: 24px;
        margin: 0 0 24px 35px;
        transition: max-height .8s linear, opacity 1s linear;
        overflow: hidden;
        // max-height: 0px;
        // opacity: 0;
        a{
          color: #8a00f6;
        }
      }
      &__text.active{
        max-height: 500px;
        opacity: 1;
      }
      .close{
        // animation: close 0.45s normal ease-in-out both 1;
        max-height: 0px;
        opacity: 0;
      }
      @keyframes open {
        0% {
        }
        100% { 
          max-height: 1000px;
          opacity: 1;
          transform: scale(1);
        }
      }
      @keyframes close {
        0% {
          max-height: 1000px;
          opacity:1;
          transform:scale(1);
        }
        100% { 
          max-height: 0px;
          opacity: 0;
          transform:scale(0.9) rotateX(-60deg);
          transform-origin: 50% 0;
        }
      }
  
      &__container {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 580px){
          justify-content: center;
        }
        &__ready {
          width: 100%;
          padding: 13px 34px;
          background: #8a00f7;
          font-weight: bold;
          font-size: 18px;
          color: white;
          border: none;
          transition: all .3s linear;
          &:hover{
            background: #6e00c2;
          }
          @media (max-width: 580px){
            font-size: 16px;
          }
        }
      }
    }
  }
}

/* Customize the label (the container) */
.labelContainer {
  // display: inline-block;
  display:flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 14px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.labelContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkGate {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  background: #8a00f7;
  padding-bottom: 3px;
  &:after {
    content: " ";
    display: inline-block;
    width: 8px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/* When the checkbox is checked, add a blue background */
.labelContainer input:checked ~ .checkmarkGate::after {
  display: inline-block;
  transform: rotate(-135deg);
  transition: 0.5s;
}

.labelContainer input:checked ~ .checkmarkGate {
  padding-top: 9px;
}

// /* Show the checkmark when checked */
// .container input:checked ~ .checkmark:after {
//   display: block;
// }

// /* Style the checkmark/indicator */
// .container .checkmark:after {
//   left: 9px;
//   top: 5px;
//   width: 5px;
//   height: 10px;
//   border: solid white;
//   border-width: 0 3px 3px 0;
//   -webkit-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   transform: rotate(45deg);
// }

// .da {
//   position: relative;
//   left: -70px;
//   width: 100px;
//   height: 50px;
//   background: #000;
//   margin-top: 5px;
//   transition: left .2s linear;
//   &:hover {
//     left: 0;
//   }
// }
