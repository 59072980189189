.checkout-overview {
  padding-top: 70px;
  padding-bottom: 89px;
  @media (max-width: 768px) {
    padding-top: 97px;
  }
  .container {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 34px;
    @media (max-width: 1144px) {
      padding: 70px 20px 89px;
    }
    @media (max-width: 930px) {
      flex-direction: column;
      padding: 20px 0;
    }
  }
  .cart {
    width: 71%;
    @media (max-width: 930px) {
      width: 100%;
    }
    .head {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      height: 28px;
      @media (max-width: 930px) {
        display: none;
      }
      .asset {
        width: 55%;
      }
      .quantity {
        width: 15%;
      }
      .price {
        width: 15%;
      }
      .subtotal {
        width: 15%;
      }
    }
    .card {
      display: flex;
      padding: 15px;
      background-color: #fafffb;
      border: 1px solid #99d2a2;

      .img {
        width: 77px;
        height: 77px;
        overflow: hidden;
        margin-right: 16px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .label{
        display: none;
        @media (max-width: 930px) {
          display: block;
        }
      }
      .asset {
        display: flex;
        width: 55%;
        @media (max-width: 930px) {
          margin-bottom: 4px;
          width: 100%;
        }
        .title {
          font-size: 18px;
          font-weight: 700;
          @media (max-width: 930px) {
            font-size: 16px;
          }
        }
        .tokens {
          font-size: 14px;
        }
      }
      .quantity {
        width: 15%;
      }
      .price {
        width: 15%;
      }
      .subtotal {
        width: 15%;
      }
      @media (max-width: 930px) {
        flex-direction: column;
        margin: 0 20px;
        .quantity,
        .price,
        .subtotal {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          .label {
            font-weight: 700;
          }
        }
      }
    }
  }
  .right {
    margin-top: 33px;
    width: 26%;
    @media (max-width: 930px) {
      width: 100%;
      margin-top: 24px;
      padding: 0 20px;
    }
    .wrapper {
      background-color: #fcfcfc;
      border: 1px solid #b9c0c4;
      margin-bottom: 15px;
    }
    .discount-error{
      font-size: 10px;
      color: tomato;
      margin-top: 6px;
    }
    .discount-success{
      font-size: 10px;
      color: #0ec22b;
      margin-top: 6px;
    }
    .discount {
      padding: 14px 26px 26px;
      border-bottom: 1px solid #b9c0c4;
      @media (max-width: 768px) {
        padding: 14px 20px 26px;
      } 
      .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 7px;
        @media (max-width: 930px) {
          font-size: 16px;
        }   
      }
      .discount-input {
        width: 100%;
        .apply {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0ec22b;
          background-color: #fafffb;
          border: 1px solid #99d2a2;
          height: 100%;
          width: 72px;
          font-weight: 700;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: #c7ffd3;
            transition: all 0.2s ease-in-out;
          }
        }
        .MuiInputAdornment-positionEnd {
          max-height: 100%;
          height: 100%;
          margin-right: 0;
        }
      }
      .MuiOutlinedInput-root {
        border-radius: 0;
        height: 38px;
        outline: none;
        padding-right: 0;
        .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: #c4c4c4;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #0ec22b;
        }
      }
    }
    .credits {
      border-bottom: 1px solid #b9c0c4;
      padding: 12px 26px 31px;
      @media (max-width: 768px) {
        padding: 12px 20px 31px;
      } 
      .use {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .label {
          margin-left: 9px;
          margin-top: 8px;
          font-size: 18px;
          font-weight: 700;
          @media (max-width: 930px) {
            font-size: 16px;
            margin-left: 0;
          }
        }
        .notifications-checkbox {
          min-width: initial;
        }
      }
      .balance {
        font-size: 12px;
        .amount {
          font-weight: 700px;
        }
      }
      .earn {
        font-size: 12px;
        color: rgba($color: #000000, $alpha: 0.6);
      }
    }
    .total {
      padding: 31px 26px 22px;
      @media (max-width: 768px) {
        padding: 31px 20px 22px;
      } 
      .result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
        .title {
          font-size: 16px;
          font-weight: 700;
        }
        .amount {
          font-size: 18px;
          color: #0ec22b;
          font-weight: 700;
          .currency {
            font-weight: 400;
          }
        }
      }
      .next {
        // margin-top: 72px;
        background-color: #f64a00;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        padding: 12px 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;

        @media (max-width: 1000px) {
          padding: 10px 30px;
          margin-bottom: 10px;
        }

        @media (max-width: 768px) {
          margin-bottom: 20px;
          padding: 13px;
        }

        &:hover {
          background-color: #d63300;
          transition: all 0.2s ease-out;
        }

        img {
          margin-left: 8px;
        }
      }
    }
  }
}
