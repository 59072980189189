.about-team {
  padding-top: 180px;
  background: linear-gradient(to bottom, #bfee92 0%, #ffffff 160px);
  .container {
    text-align: center;
    position: relative;
  }
  .head{
    display: flex;
    align-items: flex-start;
    @media (max-width: 1400px) {
     flex-direction: column-reverse;
     align-items: center;
    }
  }
  .about-container{
    margin-left: 29px;
    @media (max-width: 1400px) {
      margin-left: 0;
     }
  }
  .about-image{
    margin-left: 50px;
    margin-top: -17px;
    @media (max-width: 1400px) {
      margin-left: 0;
     }
  }
  .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 12px;
    font-family: "Montserrat", sans-serif;
    color: #0EC22B;

    &.about{
      margin-top: -7px;
      text-align: start;
    }
    &.about-sub{
      margin-top: 24px;
      color: black;
      padding-right: 70px;
      @media (max-width: 1400px) {
        padding-right: 0px;
      }
    }
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .line{
    margin: 0 auto;
    margin-top: 51px;
    margin-bottom: 65px;
    width: 220px;
    height: 3px;
    background: #8A00F7;
  }

  .descr {
    font-size: 20px;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 35px;
    font-family: "Montserrat", sans-serif;
    &.about{
      width: unset;
      text-align: start;
      color: black;
      padding-right: 100px;
      font-weight: 400;
      line-height: 31px;
      margin-top: 25px;
      @media (max-width: 1400px) {
        padding-right: 0px;
      }
    }

    @media (max-width: 768px) {
      font-size: 16px;
      width: 100%;
    }
  }

  .team-cards {
    display: flex;
    justify-content: center;
    // justify-content: center;
    margin-top: 70px;
    margin-bottom: 100px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      // justify-content: center;
      align-items: center;
    }
  }

  .team-item {
    width: 33%;
    max-width: 253px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      width: 90%;
    }

    max-width: initial;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .team-img {
      max-width: 192px;
      margin-bottom: 15px;
      
      img {
        border-radius: 50%;
        width: 192px;
        height: 192px;
        object-fit: contain;
        filter: grayscale(1);
      }
    }

    .name {
      font-size: 20px;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
    }

    .job {
      font-size: 20px;
      opacity: 0.5;
      font-family: "Montserrat", sans-serif;
    }

    .more {
      display: none;
      font-family: "Montserrat", sans-serif;

      @media (max-width: 768px) {
        display: block;
        text-align: center;
        margin-top: 16px;
        color: #0ec22b;
        text-decoration: underline;
        font-weight: 500px;
        cursor: pointer;
      }
    }

    .more-block {
      display: none;
      margin-top: 24px;
      text-align: center;
      font-size: 15px;
      line-height: 22px;
      height: 0px;
      overflow-y: hidden;
      transition: height 0.2s ease-in-out;
      font-family: "Montserrat", sans-serif;

      @media (max-width: 768px) {
        display: block;
      }

      &.shown {
        height: 100%;
        transition: height 0.2s ease-in-out;
      }
    }
  }
}

// .team-tooltip{
//   @media (max-width: 768px) {
//     display: none !important;
//   }
// }

.team-descr {
  position: relative;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 768px) {
    display: none !important;
    opacity: 0;
  }

  // &:after {
  //   content: " ";
  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   background-color: #fff;
  //   border-bottom: 1px solid #0ec22b;
  //   border-left: 1px solid #0ec22b;
  //   transform: rotate(-45deg);
  //   bottom: -26px;
  //   left: 30px;

  //   @media (max-width: 768px) {
  //     display: none !important;
  //     opacity: 0;
  //   }
  // }
}