.payment-wrapper{
    padding-top: 70px;
}

.check-inputs {
    display: grid;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .check-title {
        font-size: 20px;
        font-weight: 700;
    }

    .check-content {
        font-size: 20px;
    }
}

.payment-title {
    width: 780px;
    display: flex;
    align-items: center;
    width: 38%;
    margin: auto;
    justify-content: space-between;

    .inner-title {
        display: flex;
        flex-direction: row;
        width: 65%;
        justify-content: space-between;
        align-items: center;
    }

    p {
        display: none;
    }

    @media (max-width: 800px) {
        display: flex;
        align-items: center;
        width: 90%;
        margin: auto;
        flex-direction: row;
        justify-content: space-between;

        .inner-title {
            width: 65%;
        }

        h1 {
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
        }

        p {
            display: none;
        }
    }

    @media (max-width: 600px) {
        p {
            display: block;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #8f948f;

            opacity: 0.8;
        }

        .inner-title {
            width: 100%;
        }
    }
}

.central-payment {
    .iframe{
        margin-top: 50px;
        min-width: 100%;
        max-width: 100%;
    }

  
    .options-container {

            margin-top: 10px;

       
        .MuiFormLabel-root {
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;

            color: #000000;
        }

        .MuiTypography-body1 {
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            font-family: "Poppins";
        }
    }
    width: 40%;
    .input-error {
        font-size: 18px;
    }
    .MuiRadio-root {
        color: #0ec22b;
    }
    .MuiRadio-colorSecondary.Mui-checked {
        color: #0ec22b;
    }

    @media (max-width: 800px) {
        width: 470px;
        margin: auto;
        margin-top: 30px;
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 20px;
    }

    .MuiButton-root {
        background-color: rgb(88, 11, 228);
        color: white;
        width: 244px;
        height: 54px;
        text-transform: none;
        margin-top: 30px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;

        @media (max-width: 800px) {
            display: block;
            margin: auto;
            margin-top: 25px;
            margin-bottom: 30px;
        }

        @media (max-width: 600px) {
            width: 100%;
        }

        &:hover {
            background-color: rgb(88, 11, 228);
        }
    }
    .secure-invisible{
        display: none;
    }
    .MuiCircularProgress-colorPrimary{
        color:white
    }

    .secure-container {
        margin-top: 20px;
        background: #f9f9f9;
        padding: 30px;
        width: 465px;
        height: 166px;

        @media (max-width: 600px) {
            width: 100%;
            height: 119.59px;
        }

        .secure-images {
            height: 50px;
            margin-top: 15px;
            width: 50%;
            display: flex;
            justify-content: space-between;

            img {
                width: 81.74px;
                height: 45.5px;

                @media (max-width: 600px) {
                    width: 58.89px;
                    height: 32.78px;
                }
            }
        }

        h2 {
            margin: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            @media (max-width: 600px) {
                font-size: 12px;
            }
        }
    }

    .method-containers {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;

        @media (max-width: 600px) {
            width: 100%;
        }

        .container {
            width: 105px;
            height: 92px;
            background: #ffffff;
            padding: 0 20px;
            box-shadow: 0px 4px 20px #ece8f1;
            margin-right: 20px;

            &.inactive{
                h3{
                    color: rgba($color: #8f948f, $alpha: 0.6);
                }
                .custom-icon{
                    border-color: rgba($color: #8f948f, $alpha: 0.6);
                }
            }

            @media (max-width: 600px) {
                width: 22%;
                height: 82px;
                padding: 0 10px;
            }

            h3 {
                margin: 0;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
            }

            .info {
                position: absolute;
                z-index: 100;
                width: 17px;
                height: 17px;
                top: 15px;
                right: 15px;
                cursor: pointer;
            }
        }
    }

    .ach-manual {
        display: inline-block;
        margin: 25px 0;
        font-size: 18px;
        font-weight: 700;
    }

    .ach-container {
        .radio-title {
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
        }

        .MuiTypography-body1 {
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
        }

        .radio-group {
            display: flex;
            flex-direction: row;

            @media (max-width: 600px) {
                width: 200px;
            }
        }

        .MuiButton-root {
            background-color: rgb(88, 11, 228);
        }

        .ach-inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            .ach-title {
                font-size: 20px;
                font-weight: 700;

                @media (max-width: 600px) {
                    font-size: 16px;
                }
            }

            .ach-content {
                font-size: 20px;

                @media (max-width: 600px) {
                    font-size: 16px;
                }
            }

            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }
        }

        .MuiRadio-root {
            color: #0ec22b;
        }

        .Mui-checked {
            color: #0ec22b;
        }
    }

    .main-crypto {
        h4 {
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
        }

        .qr-title {
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
        }

        .crypto-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .crypto-item {
                display: flex;

                .inner-crypto {
                    display: flex;
                    flex-direction: row;
                    width: 50%;
                    align-items: center;
                    margin-left: 15px;
                    justify-content: space-between;

                    p {
                        margin: 0;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 21px;
                        margin-left: 10px;
                    }
                }

                width: 144px;
                height: 59px;
                cursor: pointer;
                box-shadow: 0px 4px 12px rgba(14, 194, 43, 0.1),
                    0px 4px 20px #ece8f1;

                @media (max-width: 600px) {
                    width: 105px;
                    height: 59px;
                }
            }
        }
    }

    .qr-image {
        @media (max-width: 800px) {
            display: block;
            margin: auto;
        }
    }
}
