.documents-item {
  width: 100%;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  padding: 10px 0 10px;
  border-bottom: none;
  border: none !important;

  td {
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
    color: #031106;
    border: none !important;
  }
  .divider {
    width: 100%;
    height: 3px;
    background-color: #909090;
    margin-top: 15px;
  }

  .button {
    text-align: right;
  }
  
  .cloud-icon{
      cursor: pointer;
  }

  &-mobile {
    display: none;
  }

  .approved {
    color: #0ec22b;
  }

  .waiting {
    color: #968aad;
  }

  .not-loaded {
    color: #8f948f;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    font-size: 16px;
    border-bottom: 2px solid #919191;
  }

  .span-container {
    display: flex;
    margin-right: 15px;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }

    .desktop {
      @media (max-width: 768px) {
        max-width: 220px;
      }
    }

    .mobile {
      display: none;

      @media (max-width: 768px) {
        display: inline-block;
      }
    }
  }

  @media (max-width: 768px) {
    &-mobile {
      display: flex;
      border-bottom: 3px solid #909090;

      .upload-block {
        border-bottom: 3px solid #909090;
      }
    }

    &-desktop {
      display: none !important;
    }
  }

  .upload-block {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cloud-icon {
      cursor: pointer;
      position: relative;
      bottom: -3px;
      img {
        cursor: pointer;
      }
    }
  }

  .upload-button {
    margin-left: 15px;
    cursor: pointer;
    color: #8f948f;
    border: 2px solid #dddddd;
    padding: 4px 18px;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    transition: 0.3s all;

    &:hover {
      color: #fff;
      background-color: #dddddd;
      transition: 0.3s all;
    }
  }
}
