
.landing-card {
    max-width: 404px;
    width: 100%;
    border: 1px solid #e5e5e5;
    height: 650px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 0 9px 0 rgba($color: #000000, $alpha: 0.2);
        transition: all 0.3s ease-in-out;
    }
    .card-timer {
        width: 100%;

        .line {
            background-color: #e3f6e6;
            border-radius: 8px;
            border: 0.2px solid #0ec22b;
            height: 18px;
            margin-bottom: 10px;
            transition: all 0.5s ease-in-out;
            overflow: hidden;
        }

        .raised {
            background-color: #0ec22b;
            border-radius: 8px;
            width: 0%;
            height: 100%;
            transition: all 0.5s ease-in-out;
            transition-delay: 0.3s;
        }

        .amount {
            font-family: "Montserrat", sans-serif;
            font-size: 11px;
            color: #6d7584;
            margin-bottom: 8px;
        }

        .investors {
            display: flex;
            justify-content: space-between;
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
            margin-bottom: 8px;

            .green {
                display: inline-block;
                font-size: 15px;
                color: #0ec22b;
                font-weight: 700;
                margin-right: 5px;
            }

            .left,
            .right{
                font-family: "Montserrat", sans-serif;
            }
        }
    }

    .card-content {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 12px 20px 20px;
        height: 74%;
    }

    .card-img {
        position: relative;
        width: 100%;
        max-height: 26%;
        min-height: 26%;
        overflow: hidden;
        .promo{
            position: absolute;
            right: 0;
            top: 0;
            width: unset;
            height: unset;
            width: 200px;
            background-color: #fff;
            padding: 5px;
        }

        .label {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 12px;
            left: 12px;
            padding: 6px 10px;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            color: #fff;
            font-weight: 700;
            background-color: #0ec22b;
            box-shadow: 1px 1px 2px 0 rgba($color: #000000, $alpha: 0.35);
            img{
              width: 9px;
              margin-right: 6px;
            }
          }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-title {
        font-size: 18px;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 8px;
        // height: 10%;
        // min-height: 42px;
        max-height: 24px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        span{
          font-family: "Montserrat", sans-serif;
          color: #0ec22b;
        }
    }

    .card-location {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        color: #a6a6a6;
        margin-bottom: 14px;
        // height: 5%;
        text-transform: capitalize;
    }

    .card-descr {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 25px;
        min-height: 52px;
        max-height: 52px;
        // height: 14%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }

    .get-access {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        height: 54px;
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        max-width: 230px;
        background-color: #f64a00;
        margin: auto auto 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: #a13000;
            transition: all 0.2s ease-in-out;
        }
    }

    .field {
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
        padding: 13px 0 15px;
        margin-top: auto;

        .field-value {
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
            font-weight: 700;
            margin-right: 10px;
        }

        .field-title {
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
        }
    }
}