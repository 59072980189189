.documents-page {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding-top: 75px;

    @media(max-width: 1144px) {
        padding: 0 30px;
        padding-top: 75px;
    }

    @media(max-width: 500px) {
        padding: 0 20px;
        padding-top: 75px;
    }

    @media(max-width: 1000px) {
        flex-direction: column;
    }

    .documents-nav {
        .profile-nav {
            @media(max-width: 1000px) {
                max-width: 500px;
                width: 100%;
            }
        }

        @media(max-width: 1000px) {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    &-content {
        margin-top: 22px;
        width: 100%;
    }

    .page-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;

        @media (max-width: 1000px) {
            display: none;
        }
    }

    .page-title-tablet {
        display: none;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;

        @media(max-width: 1000px) {
            display: inline-block;
            margin: 20px auto 0;
            margin-bottom: 15px;
        }

        @media(max-width: 600px) {
            margin: 20px 0 0 0;
        }
    }

    .table-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        @media (max-width: 1000px) {
            display: none;
        }
    }

    .table-title-mobile {
        display: none;
        font-size: 16px;
        font-weight: 500;
        margin: 6px 0 15px;

        @media (max-width: 600px) {
            display: inline-block;
        }
    }

}