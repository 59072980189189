.marketplace-cards {

    margin-bottom: 120px;

    @media(max-width: 1144px) {
        padding: 20px;
    }

    .cards-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;

        @media(max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }

        @media(max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    .landing-card {
        @media(max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }
    }
}