.addToAssets {
    background: #fdfdfd;
    padding: 20px 0;
    padding-bottom: 20px;

    a {
        color: rgba(51, 51, 51, 0.8) !important;
    }
}

.landing-header-desktop-buttons {
    margin-left: 35px;
    display: inline-flex;
    .logout.reminder{
        position: relative;
        &::after{
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #F64A00;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .logout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 16px;

        .logout-icon-container {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .MuiButtonBase-root {
        font-family: inherit;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        text-transform: none;

        @media (max-width: 600px) {
            margin-bottom: 10px !important;
        }

        &.login-button {
            background: inherit;
            border: none;
            margin-right: 20px;


            span {
                opacity: 0.8;
                font-weight: 500;
                color: #000 !important;
            }
        }

        &.login-button-accreditaton {
            background: inherit;
            border: none;
            margin-right: 20px;

            span {
                font-weight: 500;
                color: black;
                margin-bottom: 0;
            }
        }

        &.get-started-button {
            width: 129px;
            height: 39px;
            border-radius: 0px;
        }
    }
}

.contacts {
    .landing-header-desktop-container {
        background-color: #fff;
    }

    .landing-header-desktop-links a {
        color: #333333 !important;
    }
}

.desktop-nav-container {
    position: fixed;
    z-index: 1200;

    .logo-scrollable {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 110px;
        transition: all 0.4s ease-in-out;

        .logo-cube {
            position: absolute;
            width: 86px;
            transform: translateY(15px);
            left: 0;
            transition: all 0.4s ease-in-out;
        }
    }

    @media (max-width: 965px) {
        .logo-scrollable {
            padding-left: 60px;

            .logo-cube {
                bottom: initial;
                width: 44px;
                margin-right: 17px;
                transform: translateY(0);
            }
        }
    }

    @media (max-width: 1010px) {
        .logo-main {
            max-width: 180px;
        }
    }

    @media (max-width: 960px) {
        .logo-main {
            max-width: 180px;
        }
    }

    &.scrolled {
        box-shadow: 0 2px 12px rgba(00, 00, 00, 0.12);
        transition: all 0.2s ease-in-out;

        .logo-scrollable {
            padding-left: 60px;
            transition: all 0.4s ease-in-out;

            .logo-cube {
                bottom: initial;
                width: 44px;
                margin-right: 17px;
                transform: translateY(0);
                transition: all 0.4s ease-in-out;
            }
        }
    }
}

.landing-header-desktop-container {
    position: fixed;
    z-index: 100009;
    transition: all 0.3s ease-in-out;

    .landing-header-desktop-logo-dark {
        display: none;
        transition: all 0.3s ease-in-out;
    }


    &.scrolled {
        background-color: #fff;
        box-shadow: 0 2px 12px rgba(00, 00, 00, 0.16);
        transition: all 0.3s ease-in-out;

        .landing-header-desktop-logo-dark {
            display: block;
            margin-top: 10px;
            max-width: 190px;
            margin-left: 20px;

            @media (max-width: 1255px) {
                margin-left: 20px;
            }

            @media (max-width: 1040px) {
                max-width: 120px;
                margin-right: 10px;
            }

            @media (max-width: 840px) {
                max-width: 100px;
                margin-right: 10px;
            }
        }

        .landing-header-desktop-logo {
            display: none;
            transition: all 0.3s ease-in-out;
        }

        .login-button,
        .login-button-accreditaton {
            .MuiButton-label {
                color: #333333 !important;
                font-size: 14px;
            }
        }

        .login-button {
            @media (max-width: 600px) {
                margin-bottom: 20px;
            }
        }

        .landing-header-desktop-links a {
            color: #333333 !important;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #333333 !important;
                text-decoration: underline;
            }
        }
    }
}

.landing-header-desktop-container {
    width: 100%;
    top: 0;
    z-index: 100;
    padding-bottom: 15px;

    @media (max-width: 768px) {
        display: none;
    }

    .header-assets-desktop {
        margin-top: 0px !important;
    }

    .landing-header-desktop {
        margin: 0 auto;
        margin-top: 23.5px;
        max-width: 1500px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &-logo {
            margin-top: 10px;
            margin-left: 20px;
            max-width: 190px;

            @media (max-width: 1144px) {
                margin-left: 20px;
            }

            @media (max-width: 1040px) {
                max-width: 120px;
                margin-right: 10px;
            }

            @media (max-width: 840px) {
                max-width: 100px;
                margin-right: 10px;
            }
        }

        .login-button:hover {
            text-decoration: underline;
        }

        .align {
            display: flex;
            align-items: center !important;
        }

        &-nav {
            display: flex;
            margin-right: 20px;

            .landing-header-desktop-links {
                margin-right: 80px;
                display: flex;
                align-items: center;
                height: 39px;

                @media (max-width: 1000px) {
                    margin-right: 40px;
                }

                @media (max-width: 800px) {
                    margin-right: 20px;
                }

                a {
                    margin-right: 48px;
                    font-size: 14px;
                    line-height: 21px;
                    color: #ffffff;
                    opacity: 0.8;
                    text-decoration: none;

                    @media (max-width: 1000px) {
                        margin-right: 20px;
                    }

                    &:hover {
                        color: #ffffff;
                        opacity: 1;
                        text-decoration: underline;
                    }

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }

            .landing-header-desktop-buttons {
                .MuiButtonBase-root {
                    font-family: inherit;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #ffffff;
                    text-transform: none;

                    @media (max-width: 600px) {
                        margin-bottom: 10px !important;
                    }

                    &.login-button {
                        background: inherit;
                        border: none;
                        margin-right: 20px;

                        span {
                            font-weight: 500;
                        }
                    }

                    &.login-button-accreditaton {
                        background: inherit;
                        border: none;
                        margin-right: 20px;

                        span {
                            font-weight: 500;
                            color: black;
                            margin-bottom: 0;
                        }
                    }

                    &.get-started-button {
                        width: 129px;
                        height: 39px;
                        border-radius: 0px;
                    }
                }
            }
        }
    }

    .header-hide {
        display: none;
    }
}