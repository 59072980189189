.my-investments-page-wrapper{
    background: #FAFFFB;
    padding-top: 70px;
    padding-bottom: 100px;
    min-height: 80vh;
    @media (max-width: 1144px) {
        padding: 70px 0px;
    }
}
.my-investments-page-container {
    max-width: 1140px;
    margin: 0 auto;
    padding-top: 0px;

    @media (max-width: 1144px) {
        padding: 0px 20px;
    }

    .my-investments-title-container {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;
        @media (max-width: 800px) {
            margin: auto;
            margin-top: 20px;
            display: flex;
        }
        @media (max-width: 600px) {
            margin: auto;
            margin-top: 20px;
            display: flex;
        }

        .my-investments-title {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.02em;
            color: #031106;
            margin: 16px 0;
            @media (max-width: 820px) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .link{
            color: #231F20;
            font-size: 16px;
            font-weight: 500;
            &:visited{
                color: #231F20;
            }
        }  

        .trade-button {
            color: #8f948f;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: center;
            border: 2px solid rgba(143, 148, 143, 0.3);
            border-radius: 5px;
            background-color: inherit;
            padding: 8px 22px;
            white-space: nowrap;
            font-family: "Montserrat", sans-serif;
            margin: 0px;
            transition: 0.3s all;
            cursor: pointer;
            &:hover {
                border: 2px solid #580be4;
                color: #580be4;
                transition: 0.3s all;
            }
        }
    }   

    .pagination{
        display: flex;
        justify-content: flex-end;
    }

    .investment-table{
        display: flex;
        flex-direction: column;
        border: 1px solid #99D2A2;
        background: white;
        margin-bottom: 20px;
        .table-titles{
            background: #BEBEC533;
            padding: 5px 0px;
            .rowTitle{
                padding-left: 18px;
                color: #494949;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .gridItem{
                color: #494949;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                &:nth-child(n+1){
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }  
            }
        }
        .grid-rows{
            display: grid;
            grid-template-columns: 1.5fr 1fr 3fr 2fr 1fr;
            column-gap: 10px;
        }
        .table-row{
            display: grid;
            grid-template-columns: 2fr 8fr;
            column-gap: 10px;
            &:nth-child(n+3){
                border-top: 1px solid #99D2A2;
            }
            @media (max-width: 820px) {
                display: none;
            }
            .token-rows{
                display: flex;
                flex-direction: column;
                .token-row{
                    min-height: 80px;
                    .tokenItem{
                        display: flex;
                        align-items: center;
                        padding: 13px 0px;
                        font-family: "Montserrat", sans-serif;
                        &:nth-child(n+1){
                            justify-content: center;
                            text-align: center;
                        }
                    }
                    .buttons{
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        .button{
                            padding: 0px 15px;
                        }
                        .buyLink{
                            color: #0EC22B;
                            text-decoration: none;
                            font-weight: 600;
                            font-style: 14px;
                            &:visited{
                                color: #0EC22B;
                            }
                            &:hover{
                                cursor: pointer;
                            }
                        }
                        .disabled{
                            color: #9de0a8;
                            font-weight: 600;
                            font-style: 14px;
                            cursor: default;
                        }
                    }
                }
            }
            .token-name{
                display: flex;
                flex-direction: column;
                padding: 10px;
                padding-top: 22px;
                padding-left: 18px;
                .alias{
                    color: #000000;
                    font-weight: 700;
                    font-size: 16px;
                }
                .name{
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
        .statusField{
            position: relative;
            @media (max-width: 820px) {
                display: flex;
                justify-content: flex-end;
                padding: 22px 16px;
            }
            .status{
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: #FF9900;
                padding: 0;
            }
            .status-tooltip{
                flex-direction: column;
                position: absolute;
                width: 193px;
                height: auto;
                padding: 13px;
                box-shadow: 0px 2px 10px 2px #03030328;
                background: #FF9900;
                bottom: 70%;
                right: -40%;
                text-align: left;
                display: flex;
                visibility: hidden;
                opacity: 0;
                transition: opacity .3s linear;
                @media (max-width: 1250px) {
                    right: 0%;
                }
                .tooltipTitle{
                    color: white;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18.24px;
                    font-family: "Montserrat", sans-serif;
                }
                .tooltipText{
                    color: white;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18.24px;
                    font-family: "Montserrat", sans-serif;
                }
                .line {
                    background-color: #FFFFFF66;
                    border-radius: 8px;
                    height: 10px;
                    transition: all 0.5s ease-in-out;
                    overflow: hidden;
                    margin-top: 15px;
                    margin-bottom: 4px;
                }
                .raised {
                    background-color: #BFEE91;
                    border-radius: 8px;
                    width: 0%;
                    height: 100%;
                }
                .amount{
                    color: #BFEE91;
                    font-size: 10px;
                    line-height: 12px;
                    font-weight: 700;
                }
                &::after{
                    position: absolute;
                    bottom: -6px;
                    right: 44%;
                    content: "";
                    width: 15px;
                    height: 15px;
                    transform: rotate(45deg);
                    background: inherit;
                    @media (max-width: 1250px) {
                        right: 19%;
                    }
                    @media (max-width: 950px) {
                        right: 15%;
                    }
                    @media (max-width: 820px) {
                        right: 10%;
                    }
                }
            }
            .status-tooltip.active{
                visibility: visible;
                opacity: 1;
            }
            .PENDING{
                background: #F64A00;
            }
            .SETTLED{
                background: #0066FF;
            }
            .CANCELED{
                background: #be2f5a;
            }
        }
        .token-name-mobile{
            display: flex;
            justify-content: space-between;
            padding: 24px 16px;
            background: #BEBEC533;
            border-bottom: 1px solid #99D2A2;
            @media (min-width: 820px) {
                display: none;
            }
            .title{
                font-size: 14px;
                color: #494949;
                line-height: 20px;
                font-weight: 600;
            }
            .right{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            .alias{
                color: #000000;
                font-weight: 700;
                font-size: 16px;
            }
            .name{
                font-weight: 400;
                font-size: 14px;
            }
        }
        .table-row-mobile{
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #99D2A2;
            &:last-child{
                border-bottom: none;
            }
            .mobileRow{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .tokenItem{
                color: #000000;
                font-size: 16px;
                line-height: 19.5px;
                font-weight: 600;
                font-family: "Montserrat", sans-serif;
                text-align: right;
                padding: 22px 16px;
            }
            .buttons{
                display: flex;
                justify-content: space-around;
                align-items: center;
                .button{
                    padding-left: 30px;
                }
                .buyLink{
                    color: #0EC22B;
                    text-decoration: none;
                    font-weight: 600;
                    font-style: 14px;
                    &:visited{
                        color: #0EC22B;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
                .disabled{
                    color: #9de0a8;
                    font-weight: 600;
                    font-style: 14px;
                    cursor: default;
                }
            }
            .gridItem{
                color: #494949;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                text-align: left;
                padding: 24px 16px;
            }
            @media (min-width: 820px) {
                display: none;
            }
        }
    }


    .transactions-table{
        display: flex;
        flex-direction: column;
        border: 1px solid #99D2A2;
        background: white;
        margin-bottom: 20px;
        .table-titles{
            background: #BEBEC533;
            padding: 5px 0px;
            display: grid;
            grid-template-columns: repeat(8,1fr);
            column-gap: 10px;
            .gridItem{
                color: #494949;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            @media (max-width: 820px) {
                display: none;
            }
        }
        .table-row{
            display: grid;
            grid-template-columns: repeat(8,1fr);
            column-gap: 10px;
            border-top: 1px solid #99D2A2;
            padding: 23px 0;
            .tokenItem{
                color: #000000;
                font-size: 16px;
                line-height: 19.5px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                text-align: center;
            }
            @media (max-width: 820px) {
                display: none;
            }
        }
        .table-row-mobile{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #99D2A2;
            &:last-child{
                border-bottom: none;
            }
            .table-moobile-left{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background: #BEBEC533;
            }
            .table-moobile-right{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .tokenItem{
                color: #000000;
                font-size: 16px;
                line-height: 19.5px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                text-align: right;
                padding: 24px 16px;
            }
            .gridItem{
                color: #494949;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                text-align: left;
                padding: 24px 16px;
            }
            @media (min-width: 820px) {
                display: none;
            }
        }
        .table-row.cancelled,.table-row-mobile.cancelled{
            .tokenItem{
                color: #0000004f;
            }
        }
        .table-row.pending,.table-row-mobile.pending{
            .tokenItem{
                color: rgba(255, 153, 0, 1);
            }
        }
    }

    .credits-amount-container {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 30px;
        @media (max-width: 800px) {
            width: 94.8%;
            margin: auto;
            margin-bottom: 20px;
        }
        @media (max-width: 600px) {
            width: 89.33%;
            margin: auto;
            margin-bottom: 20px;
        }

        .credits-amount {
            margin-left: 5px;
            font-weight: 600;
            color: #580be4;
        }
    }
}
