
.projection-container {
  grid-area: col4;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  position: relative;
  margin-top: 40px;

  // @media (max-width: 600px) {
  //     background-color: #F9F9F9;
  // }

  // &.blur {
  //     filter: blur(2px);
  // }

  .divider {
      height: 3px;
      width: 100%;
      background: #919191;

      @media(max-width:600px) {
          background: #F9F9F9;
          display: none;
      }
  }

  .projection-inner-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media(max-width:800px) {
          flex-wrap: wrap;
      }

      @media(max-width:600px) {
          display: block;
      }
  }

  .projection-item {
      text-align: center;

      @media(max-width:800px) {
          width: 25%;
          display: inline-block;
      }

      @media(max-width:600px) {
          text-align: left;
          width: 50%;
          display: inline-block;
      }

      p {
          font-size: 12px;
          line-height: 18px;

          color: #929292;
      }

      h2 {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;

      }
  }

}

.projection {
  position: relative;
  margin-top: 14px;

  .get-access {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background-color: rgba($color: #fff, $alpha: 1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      filter: none;
      border: 1px solid #0EC22B;

      .title {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 17px;
          text-align: center;
      }

      .descr {
          font-size: 16px;
          line-height: 19px;
          max-width: 577px;
          color: #494949;
          margin-bottom: 40px;
          text-align: center;
      }

      .login {
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          padding: 8px 24px;
          border-radius: 4px;
          text-decoration: none;
      }
  }
}