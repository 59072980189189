.trade-modal-section {
    margin-top: 40px;
    margin-bottom: 20px;
    .total {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        align-items: center;
        .total-p {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            margin-right: 7px;
        }
        .alia-p {
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            margin-right: 3px;

            color: rgba(51, 51, 51, 0.5);
        }
        .amount-p {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
        }
    }
    .inner-div {
        display: flex;
        flex-direction: row;
        width: 100%;
        .trade-input {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            width: 90%;
            line-height: 21px;
            color: #333333;
            margin-top: 29px;
        }
    }
    .alias-div {
        width: 10%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: flex-end;
        /* identical to box height */

        color: rgba(51, 51, 51, 0.5);
    }
    .double-label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            opacity: 0.6;
        }
        .MuiInputLabel-formControl {
            position: relative;
        }
    }
    .trade-input-label {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        color: #333333;
    }

    .trade-input {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        line-height: 21px;
        color: #333333;
        margin-top: 29px;
    }
}
