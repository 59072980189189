.verify-modal {
    .MuiDialog-paper {
        padding: 40px;
        @media (max-width: 500px){
            padding: 24px;
        }
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: 8px;
    }

    .descr {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 32px;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
        .white-space{
            margin: 0 10px;
        }
        @media (max-width: 600px){
            flex-direction: column;
            .downloadBtn{
                margin-bottom: 10px;
            }
        }
    }
    .input{
        display: flex;
        @media (max-width:560px) {
            flex-direction: column;
        }
    }
    @media (max-width: 768px){
        .accreditation-close-icon.download {
            display: block;
        }
    }
    .emailDownload{
        border: 1px solid #DBE0EA;
        padding: 16px;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #A6A6A6;
        outline: none;
        width: 70%;
        @media (max-width:560px) {
            width: 100%;
        }
    }
    .downloadBtn{
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: left;
        display: inline;
        color: #FFFFFF;
        padding: 16px 20px;
        border-radius: 0;
        &.subscription{
            min-width: 200px;
        }
    }
    .verify {
        width: 110px;
        margin: 0 auto;

        .MuiButton-label {
            color: #fff;
            text-transform: none;
            font-family: Poppins, sans-serif;
            font-weight: 600;
        }
    }
}
.nft-modal {
    .MuiDialog-paper {
        text-align: center;
        max-width: 1000px;
        width: 100%;
        padding: 40px;
        @media (max-width: 500px){
            padding: 24px;
        }
    }
    .title {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
    }
    .descr {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
    }
    @media (max-width: 768px){
        .accreditation-close-icon.download {
            display: block;
        }
    }
}