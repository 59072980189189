.redfrog-page {
  & * {
    font-family: "Montserrat", sans-serif;
  }

  .asset-top {
    padding-top: 160px;
    background-image: linear-gradient(to bottom, #bfee92 0%, #fff 20%);

    @media(max-width: 768px) {
      padding-top: 91px;
      background-image: linear-gradient(to bottom, #bfee92 0%, #fff 10%);
    }
  }

  .container {
    max-width: 1227px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1227px) {
      padding: 0 20px;
    }
    .asset-title {
      span{
          color: #0ec22b;
      }
    }
  }



  .content {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;

    @media(max-width: 768px) {
      flex-direction: column;
    }

    .left {
      width: 61%;

      @media(max-width: 768px) {
        order: 2;
        width: 100%;
      }
    }

    .right {
      width: 36%;

      @media(max-width: 768px) {
        order: 1;
        width: 100%;

        .investor-perks,
        .back-to-top {
          display: none;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    margin: 20px 0;
  }

  .mobile-blocks {
    display: none;

    @media(max-width: 768px) {
      display: block;
    }
  }

  .map-area {
    .title {
      display: inline-block;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .map-img {
      width: 100%;
    }
  }

  .back-to-top {
    text-align: right;
    color: #F64A00;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
  }
}