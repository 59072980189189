.referred-users-title {
    padding-bottom: 22px;
    border-bottom: 2px solid #909090;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 26px;
}

.referred-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba($color: #909090, $alpha: 0.4);
    font-size: 13px;
    font-weight: 600;
}