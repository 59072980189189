.discount-container {
    display: flex;
    flex-direction: column;
    width: 465px;
    align-items: center;
    justify-content: space-evenly;
    background: #f9f9f9;
    padding: 20px 0;
    .change-code-btn {
        display: flex;
        justify-content: center;
    }
    @media (max-width: 600px) {
        width: 335px;
        margin: 0 auto;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
    .inner-credit {
        width: 90%;
        margin: auto;
        .inner-1 {
            font-size: 12px;
            line-height: 18px;

            color: #494949;
            span {
                font-weight: bold;
            }
        }
        .inner-2 {
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #929292;
        }
    }
    .exclamation-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .notifications-checkbox span{
            font-weight: 700;
        }

    }
    .inner-card {
        margin: 0 16px;
        .checkbox-error {
            .makeStyles-checkedIcon-9 {
                background-color: red;
                border-color: red;
            }
        }
        p {
            margin: 0;
            font-size: 12px;
            line-height: 18px;
        }
    }
    .card-1 {
        .MuiButton-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
        }
        .error-discount {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            justify-content: center;
        }
        .MuiFilledInput-root {
            background-color: white;
            border: 1px solid #ececec;
            display: flex;
            width: 90%;
            margin: auto;
            height: 40px;
            @media (max-width: 800px) {
                width: 100%;
            }
        }
        .MuiFilledInput-input {
            padding: 0 0 0 15px;
            font-weight: 500;
            font-size: 14px;
            line-height: 28px;
            font-family: "Poppins";
        }
        width: 90%;
        margin-bottom: 20px;
        @media (max-width: 600px) {
            width: 315px;
        }
        .discount-input-button {
            color: #0ec22b;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.02em;
            text-transform: capitalize !important;
            .error-color {
                color: red;
            }
            .mobile-btn {
                display: none;

                @media (max-width: 380px) {
                    display: inline;
                }
            }
            .desktop-btn {
                display: inline;

                @media (max-width: 380px) {
                    display: none;
                }
            }
            &:disabled {
                color: #ececec;
            }
            &:hover {
                cursor: pointer;
            }

            &:checked {
                color: black;
            }
        }
    }
    .card-2 {
        cursor: pointer;
        height: 113px;
        width: 90%;
        @media (max-width: 600px) {
            width: 315px;
        }
    }
}
