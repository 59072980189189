.referrals-page {
    display: flex;
    width: 100%;
    max-width: 1144px;
    margin: 0 auto;
    padding-top: 75px;

    @media(max-width: 1144px) {
        padding: 0 30px;
        padding-top: 75px;
    }

    @media(max-width: 500px) {
        padding: 0 20px;
        padding-top: 75px;
    }
    
    @media(max-width: 1000px) {
        flex-direction: column;
    }

    .referrals-page-nav {
        @media(max-width: 1000px) {
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
        }
    }

    .page-subtitle-mobile {
        display: none;
        margin-bottom: 15px;

        @media(max-width: 500px) {
            display: inline-block;
            font-weight: 500;
            font-size: 16px;
            margin-top: 6px;
        }
    }

    .referrals-content {
        display: flex;
        margin-top: 22px;

        @media(max-width: 1000px) {
            margin-top: 42px;
        }

        @media(max-width: 700px) {
            flex-direction: column;
            align-items: center;
        }

        @media(max-width: 500px) {
            margin-top: 24px;
        }
    }

    .page-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;

        @media(max-width: 1000px) {
            display: none;
        }
    }

    .page-title-tablet {
        display: none;
        margin: 0 auto;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 15px;
        margin-top: 20px;
        line-height: 40px;

        @media(max-width: 1000px) {
            display: inline-block;
        }

        @media(max-width: 500px) {
            margin: 20px 0 0 0;
        }
    }

    .page-title {
        margin-bottom: 31px;
    }

    .referrals-works {
        margin-top: 71px;
        display: flex;
        flex-direction: column;
        font-size: 13px;

        @media(max-width: 700px) {
            max-width: 465px;
            text-align: center;
        }

        .referrals-works-title {
            margin-bottom: 42px;
            line-height: 24px;
            font-size: 16px;
            font-weight: 700;
        }

        .referrals-works-subtitle {
            font-weight: 600;
            margin-bottom: 4px;
        }

        .referrals-works-descr {
            margin-bottom: 20px;
        }
    }
}