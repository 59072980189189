.id-verification-page {
    display: flex;
    margin-left: 420px;
    min-height: 100vh;

    @media(max-width: 1100px) {
        margin-left: 340px;
    }

    @media(max-width: 900px) {
        margin-left: 255px;
    }

    @media(max-width: 768px) {
        margin-left: 0;
    }

    .id-verification {
        padding: 125px 0 0 120px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        min-height: 100vh;

        @media(max-width: 900px) {
            padding: 110px 0 0 76px;
        }

        @media(max-width: 768px) {
            padding: 25px 20px 20px 20px;
        }
    }

    .form-title {
        font-size: 28px;
        margin-bottom: 4px;
        margin-top: 0;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 25px;
        color: #8F948F;
        max-width: 560px;
    }

    .form-subtitle {
        font-size: 16px;
        font-weight: 600;
        color: #031106;
        margin-bottom: 12px;
    }

    .id-verification-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        @media(max-width: 768px) {
            max-width: 100%;
        }

        label {
            font-size: 12px;
            color: #8F948F;
        }

        .form-field {
            max-width: 270px;

            @media(max-width: 768px) {
                max-width: initial;
            }
        }

        .form-subtitle:last-of-type {
            margin-top: 50px;
        }
        .drop-file-container{
            @media(max-width: 768px){
                width: 100%;
            }
        }

        .drop-file {
            align-items: center;
            justify-content: center;
            padding: 20px 24px;
            border: 2px dashed #ddd;
            border-radius: 4px;
            outline: none;
            text-align: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            

            span {
                font-size: 14px;
                color: #909090;
                line-height: 20px;
                margin-bottom: 10px;
            }

            .drop-span {
                display: inline-block;
                max-width: 200px;
            }

            .drop-button {

                background-color: transparent;
                outline: none;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                padding: 4px 10px;
                cursor: pointer;

            }

            .image-container {
                width: 72px;
                margin-right: 10px;

                img {
                    width: 100%;
                }
            }

            .add-more-files {
                font-size: 14px;
                font-weight: 600;

            }

            .preview-container {

                .image-name {
                    -webkit-line-clamp: 1;
                }
            }
        }

        .files-added {
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    .accreditation-next-button {
        max-width: 116px;
        width: 100%;
        color: #fff;
        margin-top: 24px;
        margin-bottom: 2px;
        text-transform: none;
        font-family: Poppins !important;
        font-weight: 600 !important;

        @media(max-width: 768px) {
            max-width: initial;
        }
    }

    .input-error {
        font-size: 12px;
        color: #ea2d2d;
    }
    .get-help-container{
        position: static !important;
        margin-top: auto;
        text-align: left ;
        padding-top: 20px;
        margin-bottom: 20px;
        @media(max-width: 768px){
            text-align: center;
            margin: auto auto 0;
            width: 100%;
        }
    }
}