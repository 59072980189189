.referral-link-block {
    display: flex;
    flex-direction: column;
    margin-right: 120px;
    width: 100%;
    max-width: 400px;

    @media(max-width: 700px) {
        margin-right: 0;
        max-width: 465px;
    }

    .referral-link-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        @media(max-width: 500px) {
            display: none;
        }
    }

    .referral-label {
        margin-top: 20px;
        font-size: 12px;
        color: #8F948F;
        line-height: 16px;
    }

    .referral-input-container {
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;

        @media(max-width: 500px) {
            flex-direction: column;
        }
    }

    .referral-input {
        font-size: 14px;
        margin-right: 25px;
        width: 100%;
        max-width: 385px;
        border: none;
        outline: none;
        border-bottom: 1px solid #DDDDDD;

        @media(max-width: 500px) {
            margin: 0 0 15px 0;
            max-width: 100%;
        }
    }

    .send-button{
        border: 2px solid transparent;
        background-color: #F4F5F4;
        color: #A6AAA6;
        border-radius: 4px;
        padding: 9px 20px;
        cursor: pointer;
        transition: .3s all;
        font-weight: 700;
   
        &.active{
            background-color: #0EC22B;
            color: #fff;
            border-color: #0EC22B; 
        }
    }

    .referrals-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 4px;
        outline: none;



        span {
            display: none;
            margin: 3px 0;

            @media(max-width: 500px) {
                display: inline-block;
            }
        }

        img {
            @media(max-width: 500px) {
                display: none;
            }
        }
    }

    .success{
        color: rgb(14, 194, 43);
        font-size: 12px;
    }

}