.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    .modal-container {
        display: flex;
        flex-direction: row;
        .inner-container {
            display: flex;
            background: #ffffff;
        }
    }
}
