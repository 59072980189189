.grid-areas {
    width: 1150px;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;

    @media (max-width: 600px) {
        padding: 0 20px;
    }

    // grid-template-areas:
    //     "col1"
    //     "col2"
    //     "col3"
    //     "col4";

    // @media (max-width: 1025px) {
    //     width: 980px;
    // }
    // @media (max-width: 800px) {
    //     width: 748px;
    // }
    // @media (max-width: 600px) {
    //     width: 92%;
    // }
    .docs-list {
        grid-area: col2;
        display: flex;
        flex-flow: row wrap;
        margin-left: -10px;
        margin-right: -10px;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    .buyButton {
        height: 60px;
        text-align: center;
        color: #ffffff;
        max-width: 326px;
        margin: 0 auto 72px;
        width: 100%;

        .MuiButton-label {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            text-transform: capitalize;
        }
    }

    .map-area {
        grid-area: col1;
        display: flex;
        flex-direction: column;
        margin-bottom: 70px;
        margin-top: 15px;
        max-width: 100%;

        .title {
            display: inline-block;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        .map-img {
            width: 100%;
            max-height: 220px;
            object-fit: cover;

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        @media (max-width: 800px) {
            width: 100%;
            margin-top: 70px;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            width: 100%;
            margin-bottom: 50px;
            margin-top: 50px;
        }
    }

    .market-analysis {
        width: 33%;
        height: 315px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f0f0f0;

        @media (max-width: 600px) {
            width: 100%;
            height: 187px;
            margin: 20px 0 40px 0;
        }

        .inner-analysis {
            width: 85%;
            height: 85%;
            margin: auto;

            .usa-img {
                @media (max-width: 600px) {
                    display: none;
                }
            }
        }

        h2 {
            font-size: 14px;
            line-height: 18px;
        }

        h3 {
            font-weight: bold;
            font-size: 10px;
            line-height: 10px;
            color: #929292;
            opacity: 0.8;
        }

        p {
            font-size: 12px;
            line-height: 18px;
        }

        a:link,
        :visited {
            font-size: 12px;
            line-height: 18px;
            text-decoration: none;
            color: #580be4;
        }

        .analysis-label {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                margin-right: 10px;
            }

            a {
                margin: 0px 0 0px 5px;
                color: #0ec22b;
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
            }
        }
    }

    .map-box-container {
        width: 65%;
        height: 315px;

        @media (max-width: 600px) {
            width: 100%;
        }

        .map-box {
            height: 100%;
            width: 100%;
        }
    }
}