.secure-checkout {
  display: none;
  background-color: #0ec22b;
  padding: 5px 0;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  @media (max-width: 768px) {
    display: flex;
  }
  img {
    margin-right: 8px;
  }
}
