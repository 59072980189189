.stay-tuned{
    padding: 150px 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    @media(max-width: 1144px){
        padding: 150px 20px;
    }

    @media(max-width: 600px){
        padding: 50px 20px;
    }

    .title{
        font-size: 32px;
        max-width: 480px;
    }

    .description{
        display: inline-block;
        margin: 28px 0 55px;
        font-size: 18px;
        line-height: 27px;
        max-width: 550px;
    }

    .link{
        color: #000;
        padding: 15px 25px;
        background-color: #fff;
        border-radius: 4px;
        cursor: pointer;
    }
}