.info-section {
    display: flex;
    flex-direction: column;
    .info-title{
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-transform: uppercase;
        color: #000000;
    }
    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        .p1 {
            font-size: 16px;
            line-height: 24px;
            color: #989898;
            width: 50%;
        }
        .p2 {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            width: 40%;
            white-space: nowrap;
        }
    }
}
.input-section {
    display: flex;
    flex-direction: column;

    .MuiFormControl-root{
        margin-top: 20px;
    }
    .MuiInputBase-input{
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        padding:10px 0 10px
    }
    .MuiInput-underline:after{
        border: 1px solid #C4C4C4;
    }
    .MuiFormLabel-root.Mui-focused{
        font-weight: 500;
color: #000000;
    }
    .error {
        color: red;
        font-size: 18px;
    }
}
