body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-display: swap;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

*{
    font-family: "Montserrat", sans-serif;
    font-display: swap;
}

#root{
    min-height: 100vh;
}

main{
    min-height: 100vh;
    // padding-bottom: 260px;
}
