.docs-container {
    margin-bottom: 70px;

    .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 30px;
    }
}


.doc-container {
    flex: 0 0;
    flex-basis: calc(25% - 20px);
    margin: 0 10px 20px 10px;
    cursor: pointer;
    height: 174px;

    @media(max-width:1025px) {
        flex-basis: calc(33.33% - 20px);
    }

    @media(max-width:800px) {
        flex-basis: calc(50% - 20px);
    }

    background: #F2F2F2;

    &:hover {
        background: #FFFFFF;
        box-shadow: 0px 4px 20px #ECE8F1;
    }

    .inner-doc {
        margin: 20px
    }

    h2 {
        font-weight: 500;
        font-size: 16px;
    }

    p {
        margin-top: 25px;
        font-size: 12px;
        color: #929292;
    }
}