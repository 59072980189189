.new-landing {
  font-family: "Montserrat", sans-serif;

  .container {
    max-width: 1255px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1255px) {
      padding: 0 20px;
    }
  }

  .header {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    transition: all 0.2s ease-in-out;

    .mobile-menu {
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      outline: 0;
      z-index: 1200;
      position: fixed;
      overflow-y: auto;
      flex-direction: column;
      background: linear-gradient(to bottom, #bfee92 0%, #ffffff 70%);
      padding: 40px 20px 50px;
      transition: 0.3s all ease-in-out;

      &.hidden {
        left: -150%;
        transition: 0.3s all ease-in-out;
      }

      .dropdown-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        &.active {
          height: 100%;
          transition: all 0.3s ease-in-out;
        }

        .sub-link {
          font-size: 15px;
          margin-bottom: 20px;
          font-weight: 400;
          font-family: "Montserrat", sans-serif;
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20%;

        .logo {
          display: flex;
          padding-left: 0;
        }

        .logo-cube-mobile {
          width: 48px;
          margin-right: 17px;
        }

        .logo-main-mobile {
          width: 180px;
        }
      }

      .nav-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nav-link {
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 24px;
          color: #000;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
        }
      }

      .bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;

        .login {
          width: 100%;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 24px;
          color: #000;
          text-decoration: none;
          text-align: center;
        }

        .sign-up {
          width: 100%;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          line-height: 27px;
          color: #fff;
          text-decoration: none;
          text-align: center;
          font-weight: 500;
          background-color: #8A00F6;
          padding: 9px 24px;
        }
      }
    }
  }

  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 20px;

    @media (max-width: 1255px) {
      padding: 25px 20px 20px;
    }

    .logo {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 110px;
      transition: all 0.4s ease-in-out;

      .logo-cube {
        position: absolute;
        width: 86px;
        transform: translateY(15px);
        left: 0;
        transition: all 0.4s ease-in-out;
      }
    }

    .nav {
      .menu-item {
        margin-left: 35px;
        font-size: 15px;
        opacity: 0.8;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        transition: all 0.2s linear;

        .drop-controller {
          display: inline-flex;
          align-items: center;

          img {
            margin-left: 5px;
          }
        }
      }

      .dropdown {
        position: relative;
      }

      .signup {
        font-size: 14px;
        color: #fff;
        padding: 10px 24px;
      }
    }

    .burger-nav {
      display: none;
      cursor: pointer;
    }

    .logo-main{
      max-width: 255px;
    }

    @media (max-width: 965px) {
      .logo {
        padding-left: 60px;

        .logo-cube {
          bottom: initial;
          width: 44px;
          margin-right: 17px;
          transform: translateY(0);
        }
      }
    }

    @media (max-width: 1010px) {
      .logo-main {
        max-width: 180px;
      }
    }

    @media (max-width: 960px) {
      .logo-main {
        max-width: 180px;
      }

      .nav {
        display: none;
      }

      .burger-nav {
        display: block;
      }
    }
  }

  .header.scrolled {
    box-shadow: 0 2px 12px rgba(00, 00, 00, 0.12);
    transition: all 0.2s ease-in-out;

    .logo {
      padding-left: 60px;
      transition: all 0.4s ease-in-out;

      .logo-cube {
        bottom: initial;
        width: 44px;
        margin-right: 17px;
        transform: translateY(0);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  .hero {
    .hero-bg-desktop {
      position: absolute;
      max-width: 613px;
      top: 71px;
      right: 0;
      z-index: 10;
      width: 45%;
    }

    .hero-bg-mobile {
      display: none;
    }
    .main {
      min-height: 600px;
      background: linear-gradient(to bottom, #bfee92 0%, #ffffff 35%);
      padding-top: 80px;

      .container {
        position: relative;
        padding: 140px 0 0 0;
      }

      .hero-content {
        display: flex;
        flex-direction: column;
        max-width: 540px;
      }

      .hero-button-container {
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        gap: 60px;

        @media (max-width: 868px) {
          margin-bottom: 35px;
        }

        @media (max-width: 600px) {
          flex-direction: column;
          gap: 25px;
        }

        &__block {
          @media (max-width: 868px) {
            display: flex;
            flex-direction: column;
          }

          &__title {
            font-family: "Montserrat", sans-serif;
            font-weight: bold;
            font-size: 16px;
          }
          .button {
            margin-top: 10px;
            height: 54px;
            width: 236px;
            font-weight: bold;
            font-size: 18px;
            color: #ffffff;
            border: none;
            &:hover {
              cursor: pointer;
            }
          }
          &__btn-left {
            @extend .button;
            background: #0ec22b;
            transition: background-color 0.3s linear;
            &:hover {
              background-color: #0ca025;
            }
          }
          &__btn-right {
            @extend .button;
            background: #8a00f7;
            transition: background-color 0.3s linear;
            &:hover {
              background-color: #5a00a1;
            }
          }
        }
      }

      .learn-more {
        display: inline-block;
        font-family: "Montserrat", sans-serif;
        color: #a6a6a6;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        margin-top: 21px;
        margin-left: auto;
        cursor: pointer;

        &.logged {
          margin-left: initial;
        }

        &:hover {
          color: #ea2d2d;

          svg {
            fill: #ea2d2d;
          }
        }
      }

      .title {
        color: #000;
        font-family: "Montserrat", sans-serif;
        font-size: 36px;
        font-weight: 700;
        max-width: 498px;
        line-height: 43px;
        margin-bottom: 14px;
        max-width: 500px;
      }

      .subtitle {
        font-family: "Montserrat", sans-serif;
        font-size: 22px;
        line-height: 34px;
        max-width: 426px;
        margin-bottom: 10px;
      }

      .learn-more-top {
        display: block;
        margin-bottom: 40px;
        display: inline-block;
        font-family: "Montserrat", sans-serif;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        // transition: color 0.3s ease-in-out;

        &:hover {
          color: #ea2d2d;
          // transition: color 0.3s ease-in-out;

          svg {
            fill: #ea2d2d;
            // transition: color 0.3s ease-in-out;
          }
        }
      }
    }

    .accepted {
      border-top: 1px solid #99d2a2;
      border-bottom: 1px solid #99d2a2;
      background-color: #fafffb;
      display: flex;
      justify-content: center;
      padding: 19px 0 13px;

      .content {
        display: flex;
        align-items: center;

        .cryptos {
          max-width: 405px;
          margin-right: 20px;

          img {
            width: 100%;
          }
        }

        .descr {
          font-size: 30px;
          color: #0ec22b;
          font-family: "Montserrat", sans-serif;
        }
      }
    }

    @media (max-width: 1290px) {
      .main .container {
        padding: 120px 20px 0;
      }
    }

    @media (max-width: 960px) {
      .hero-content {
        max-width: 480px;
      }

      // .hero-bg-mobile {
      //   display: block;

      // }
    }

    @media (max-width: 868px) {
      .accepted .content {
        flex-direction: column;

        .accept {
          font-size: 24px;
        }
      }

      .main {
        background: linear-gradient(to bottom, #bfee92 0%, #ffffff 75%);

        .container {
          padding: 40px 20px 0;
        }

        .learn-more {
          margin: 16px auto 36px;
        }

        .hero-bg-desktop {
          display: none;
        }

        .hero-bg-mobile {
          display: block;
          position: static;
          max-width: 330px;
          width: 100%;
          margin-bottom: 34px;
        }

        .hero-content {
          align-items: center;
          max-width: 100%;
        }

        .title {
          text-align: center;
          font-size: 24px;
          line-height: 28px;
          max-width: 330px;
        }

        .subtitle {
          text-align: center;
          font-size: 16px;
          line-height: 25px;
          max-width: 280px;
        }
      }
    }
  }

  .form {
    display: flex;
    align-items: center;

    .email {
      max-width: 335px;
      border-radius: 0;
      padding: 15px 18px 15px 10px;
      height: 54px;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #dbe0ea;
      outline: none;
      font-size: 18px;
      color: #6f6f6f;
      width: 70%;
    }

    .enter {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      min-width: 236px;
      padding: 0 16px;
      height: 54px;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      background-color: #f64a00;
      transition: all 0.3s ease-in-out;
      width: 40%;

      &:hover {
        background-color: #d63300;
        transition: all 0.2s ease-in-out;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;

      .email {
        margin-bottom: 12px;
        max-width: 335px;
        width: 100%;
      }

      .enter {
        max-width: 335px;
        width: 100%;
      }
    }
  }

  .explained {
    background-color: #fafffb;
    border: 1px solid #99d2a2;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 44px 0 67px;

      @media (max-width: 1255px) {
        padding: 44px 20px 67px;
      }
    }

    .title {
      font-size: 30px;
      color: #000;
      font-weight: 700;
      line-height: 48px;
      font-family: "Montserrat", sans-serif;

      @media (max-width: 980px) {
        font-size: 24px;
      }
    }

    .descr {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      text-align: center;
      color: #0ec22b;
      font-family: "Montserrat", sans-serif;

      @media (max-width: 980px) {
        font-size: 18px;
      }
    }

    .process {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 70px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 350px;

        .title {
          color: #0ec22b;
          margin-bottom: 11px;
        }

        .descr {
          color: #000;
          font-weight: 400;
          font-size: 22px;

          @media (max-width: 980px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        @media (max-width: 768px) {
          margin-bottom: 110px;
        }

        &:last-child {
          @media (max-width: 768px) {
            margin-bottom: 0;
          }
        }
      }

      &__img {
        position: relative;
        height: 100%;
        max-width: 120px;
        min-height: 155px;
        max-height: 155px;
        margin-bottom: 19px;

        .arrows {
          position: absolute;
          width: 160px;
          top: 50%;
          transform: translateY(-50%);
          right: -220%;

          @media (max-width: 1200px) {
            right: -170%;
          }

          @media (max-width: 980px) {
            width: 100px;
            right: -110%;
          }

          @media (max-width: 768px) {
            display: none;
          }
        }

        .arrows-down1 {
          bottom: -325px;
        }

        .arrows-down2 {
          bottom: -300px;
        }

        .arrows-down {
          display: none;

          @media (max-width: 768px) {
            display: block;
            position: absolute;
            z-index: 12;
            width: 27px;
            left: 50%;
            transform: translateX(-50%);
          }

          @media (max-width: 400px) {
            &.arrows-down1 {
              bottom: -350px;
            }

            &.arrows-down2 {
              bottom: -330px;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .tokenization {
        @media (max-width: 768px) {
          max-height: 90px;
          min-height: 90px;
        }
      }
    }
  }

  .hidden-height {
    display: block;
    height: 90px;
    margin-top: -90px;
    visibility: hidden;
  }
  .podcast {
    padding: 80px 0;

    img {
      max-width: 100%;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      color: #0ec22b;
      margin-top: 15px;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }

    .descr {
      font-size: 24px;
      line-height: 40px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 70px;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 26px;
        width: 80%;
      }
    }
    .series {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .item {
        display: flex;
        align-items: center;
        width: 45%;
        margin-right: 15px;
        margin-bottom: 60px;
        text-align: left;
        @media (max-width: 768px) {
          width: 100%;
        }
        .itemTitle {
          font-family: "Montserrat", sans-serif;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
          cursor: pointer;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
        .date {
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          line-height: 40px;
          color: #000000;
        }
        .preview {
          height: 140px;
          width: 140px;
          object-fit: cover;
          object-position: right top;
          margin-right: 27px;
          cursor: pointer;
        }
        .play {
          display: flex;
          align-items: center;
          img {
            margin-right: 9px;
            &:hover {
              transform: scale(1.1);
              cursor: pointer;
            }
          }
          .time {
            font-family: "Montserrat", sans-serif;
            font-size: 10px;
            line-height: 17px;
            color: #a1a3a7;
          }
        }
      }
    }
    .enter {
      background-color: #f64a00;
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 28px;
      padding: 16px 40px;
      text-align: center;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: #d63300;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .course {
    border-top: 1px solid #ead0fe;
    border-bottom: 1px solid #ead0fe;
    background-color: #fbf6ff;
    padding: 74px 0;

    img {
      max-width: 100%;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      color: #8a00f6;
      margin-top: 20px;
      margin-bottom: 35px;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }

    .descr {
      font-size: 24px;
      line-height: 40px;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 26px;
        width: 80%;
      }
    }
    .enter {
      background-color: #8a00f7;
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 28px;
      padding: 16px 40px;
      text-align: center;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: #5a00a1;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .blog {
    padding: 80px 0;

    img {
      max-width: 100%;
      cursor: pointer;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 30px;
      line-height: 40px;
      font-weight: 700;
      color: #000000;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }

    .descr {
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      color: #0ec22b;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 26px;
        width: 80%;
      }
    }
    .series {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 60px;
      @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0;
      }
      .item {
        border: 1px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 384px;
        min-height: 463px;
        width: 100%;
        margin: 0 10px;
        text-align: left;
        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 40px;
          max-width: fit-content;
        }
        .content-date {
          padding: 21px 43px 21px 43px;
          .itemTitle {
            font-family: "Montserrat", sans-serif;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            cursor: pointer;
            @media (max-width: 768px) {
              font-size: 18px;
            }
          }
          .date {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            line-height: 25px;
            color: #000000;
          }
          .preview {
            width: 100%;
            margin-bottom: 21px;
            &:hover {
              cursor: pointer;
            }
          }
          .moreBtn {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            line-height: 25px;
            color: #0ec22b;
            &:hover {
              cursor: pointer;
            }
          }
          .play {
            display: flex;
            align-items: center;
            img {
              margin-right: 9px;
              &:hover {
                transform: scale(1.1);
                cursor: pointer;
              }
            }
            .time {
              font-family: "Montserrat", sans-serif;
              font-size: 10px;
              line-height: 17px;
              color: #a1a3a7;
            }
          }
        }
      }
    }
    .enter {
      background-color: #f64a00;
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 28px;
      padding: 16px 40px;
      text-align: center;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: #d63300;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .raise-capital {
    border-top: 1px solid #ead0fe;
    border-bottom: 1px solid #ead0fe;
    background-color: #fbf6ff;
    padding: 57px 0;

    .container {
      display: flex;
      justify-content: space-between;
    }

    .capital-bg {
      min-width: 600px;
      margin-right: 75px;

      img {
        width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .title {
        font-family: "Montserrat", sans-serif;
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        color: #8a00f6;
        margin-bottom: 19px;
      }

      .descr {
        font-size: 24px;
        line-height: 40px;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 19px;
      }

      .apply {
        background-color: #8a00f7;
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 28px;
        max-width: 267px;
        padding: 11px 5px;
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: #5a00a1;
          transition: all 0.2s ease-in-out;
        }
      }

      .learn-more {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 16px;
        text-decoration: underline;
        cursor: pointer;
        color: #000;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: #8a00f7;
          transition: all 0.3s ease-in-out;

          svg {
            fill: #8a00f7;
          }
        }
      }
    }

    @media (max-width: 1255px) {
      .container {
        padding: 0 20px;
      }

      .capital-bg {
        max-width: 50%;
        width: 100%;
        min-width: initial;
      }

      .content {
        max-width: 50%;
        width: 100%;
      }
    }

    @media (max-width: 990px) {
      .content {
        .title {
          font-size: 24px;
          line-height: 31px;
        }

        .descr {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 20px 0;

      .container {
        flex-direction: column;
        align-items: center;
      }

      .capital-bg {
        max-width: 287px;
        margin-right: 0;
      }

      .content {
        width: 100%;
        max-width: 100%;

        .apply {
          margin: 0 auto;
          width: 100%;
          margin-bottom: 16px;
        }

        .learn-more {
          margin: 0 auto;
        }
      }
    }
  }

  .why-solidblock {
    padding: 68px 0 50px;
    background-color: #fafffb;
    border-top: 1px solid #99d2a2;
    border-bottom: 1px solid #99d2a2;
    // padding: 35px 0 74px;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .subtitle {
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #0ec22b;
      margin-bottom: 60px;
    }

    .benefits {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 80px;
    }

    .benefits-item {
      max-width: 370px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        max-height: 127px;
        margin-bottom: 20px;
      }

      .title {
        color: #0ec22b;
        margin-bottom: 19px;
      }

      .descr {
        font-family: "Montserrat", sans-serif;
        font-size: 22px;
        line-height: 37px;
        text-align: center;
      }
    }

    @media (max-width: 900px) {
      padding: 32px 0;

      .title {
        font-size: 24px;
      }

      .subtitle {
        font-size: 20px;
      }

      .benefits-item {
        .title {
          font-size: 22px;
        }

        .descr {
          font-size: 16px;
          line-height: 27px;
        }
      }
    }

    @media (max-width: 768px) {
      .benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .companies {
    background-color: #f6f5f8;
    padding: 45px 0;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1144px) {
        padding: 0 20px;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: space-around;

        .img-container {
          margin: 0 20px 15px 20px;
        }
      }

      @media (max-width: 500px) {
        .img-container {
          margin: 0 20px 25px 20px;
        }
      }
    }

    .img-container {
      max-width: 130px;
      max-height: 65px;

      img {
        max-width: 130px;
        max-height: 65px;
        filter: grayscale(100%);
        transition: 0.3s all ease-in-out;

        &:hover {
          filter: grayscale(20%);
          transform: scale(1.05);
          transition: 0.2s all ease-in-out;
        }
      }
    }
  }

  .buzz {
    padding: 87px 0;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .emailDownload {
        width: 100%;
        border-radius: 0;
        padding: 15px 18px 15px 10px;
        height: 54px;
        font-family: "Montserrat", sans-serif;
        border: 1px solid #acb3c0;
        outline: none;
        font-size: 18px;
        color: #6f6f6f;
        margin-top: 14px;
        text-align: center;
      }
    }

    .buzz-img {
      width: 130px;
      margin-bottom: 5px;

      img {
        width: 100%;
      }
    }

    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 30px;
      color: #0ec22b;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .swiper-container {
      max-width: 100%;
    }

    .slide-inner {
      text-align: center;
    }

    .slide-descr {
      font-size: 32px;
      font-family: "Montserrat", sans-serif;
      line-height: 46px;
      font-weight: 700;
      max-width: 878px;
      margin: 0 auto 40px;
    }
    .slideWithImg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 22px;
      }
      @media (max-width: 550px) {
        img {
          width: 250px;
        }
      }
      .slide-descr {
        margin: 0;
        text-align: center;
        span {
          color: #0ec22b;
          font-family: "Montserrat", sans-serif;
        }
      }
      .enter {
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 15px 25px;
        margin-top: 25px;
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        background-color: #f64a00;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #d63300;
          transition: all 0.2s ease-in-out;
        }
      }
    }
    .author {
      font-family: "Montserrat", sans-serif;
      font-size: 24px;
      color: #0ec22b;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .job {
      font-family: "Montserrat", sans-serif;
      font-size: 24px;
      line-height: 29px;
      opacity: 0.7;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #6d7584;

      &:after {
        font-size: 28px;
      }
    }

    @media (max-width: 768px) {
      padding: 37px 20px;

      .title {
        font-size: 22px;
        text-align: center;
      }

      .buzz-img {
        width: 88px;
      }

      .slide-descr {
        font-size: 18px;
        line-height: 26px;
        width: 80%;
      }

      .author {
        font-size: 22px;
      }

      .job {
        font-size: 18px;
      }
    }
  }

  .landing-cards {
    padding: 45px 0;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        font-size: 24px;
        text-align: center;
      }
    }

    .subtitle {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      color: #0ec22b;
      font-size: 20px;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
      }
    }

    .slider-container {
      position: relative;
      max-width: 100%;
    }

    .swiper-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .view-all {
      font-family: "Montserrat", sans-serif;
      font-size: 24px;
      font-weight: 500;
      color: #f64a00;
      margin-top: 30px;
      text-decoration: none;

      @media (max-width: 768px) {
        font-size: 20px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .nav-left {
      left: -20px;

      @media (max-width: 768px) {
        top: initial;
        bottom: -22px;
        left: 35%;
      }
    }

    .nav-right {
      right: -20px;
      transform: rotate(180deg);

      @media (max-width: 768px) {
        top: initial;
        bottom: -10px;
        right: initial;
        left: 55%;
      }
    }

    .swiper-container {
      width: 95%;
      margin: 0 auto;

      @media (max-width: 768px) {
        padding-bottom: 30px;
      }
    }

    .card-item {
      max-width: 404px;
      width: 100%;
      border: 1px solid #e5e5e5;
      height: 690px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 0 8px 0 rgba($color: #000000, $alpha: 0.2);
        transition: all 0.3s ease-in-out;
      }
    }

    .card-timer {
      width: 100%;

      .line {
        background-color: #e3f6e6;
        border-radius: 8px;
        border: 0.2px solid #0ec22b;
        height: 18px;
        margin-bottom: 10px;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
      }

      .raised {
        background-color: #0ec22b;
        border-radius: 8px;
        width: 0%;
        height: 100%;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.3s;
      }

      .amount {
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        color: #6d7584;
        margin-bottom: 8px;
      }

      .investors {
        display: flex;
        justify-content: space-between;
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        margin-bottom: 8px;

        .green {
          display: inline-block;
          font-size: 15px;
          color: #0ec22b;
          font-weight: 700;
          margin-right: 5px;
        }
      }
    }

    .card-content {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 12px 20px 20px;
      height: 74%;
    }

    .card-img {
      position: relative;
      width: 100%;
      max-height: 26%;
      min-height: 26%;
      overflow: hidden;

      .promo{
        position: absolute;
        right: 0;
        top: 0;
        width: unset;
        height: unset;
        width: 200px;
        background-color: #fff;
        padding: 5px;
    }

      .label {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 12px;
        left: 12px;
        padding: 6px 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        color: #fff;
        font-weight: 700;
        background-color: #0ec22b;
        box-shadow: 1px 1px 2px 0 rgba($color: #000000, $alpha: 0.35);
        img {
          width: 9px;
          margin-right: 6px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .card-title {
      font-size: 18px;
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 8px;
      // height: 10%;
      // min-height: 42px;
      max-height: 24px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      span {
        font-family: "Montserrat", sans-serif;
        color: #0ec22b;
      }
    }

    .card-location {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      color: #a6a6a6;
      margin-bottom: 14px;
      // height: 5%;
      text-transform: capitalize;
    }

    .card-descr {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 25px;
      min-height: 52px;
      max-height: 52px;
      // height: 14%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .get-access {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      height: 54px;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      max-width: 230px;
      background-color: #f64a00;
      margin: auto auto 0;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #a13000;
        transition: all 0.2s ease-in-out;
      }
    }

    .field {
      display: flex;
      align-items: center;
      border-top: 1px solid #e5e5e5;
      padding: 13px 0 15px;
      margin-top: auto;

      .field-value {
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        font-weight: 700;
        margin-right: 10px;
      }

      .field-title {
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
      }
    }
  }
}
