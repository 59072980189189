.wrapper{
    background: #FAFFFB;
    border-top: 1px solid #99D2A2;
    border-bottom: 1px solid #99D2A2;
    .content{
        margin: 0 auto;
        max-width: 1400px;
        width: 100%;
        padding: 52px 160px 43px ;
        @media (max-width: 1400px) {
            padding: 52px 30px 43px ;
        }
        .header{
            font-family: "Montserrat", sans-serif;
            font-weight: bold;
            font-size: 30px;
            text-align: center;
            color: #0EC22B;
            margin-bottom: 19px;
        }
        .text{
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 35px;
            color: #000000;
            margin-bottom: 47px;
        }
    }
}