.signup-accrd-page-container {
    display: flex;

    @media (max-width: 950px) {
        flex-direction: column;
    }

    .signup-accrd-page {
        position: relative;
        width: calc(100% - 419px - 117px);

        @media (max-width: 950px) {
            width: 100%;
        }

        .auth-close-icon {
            position: absolute;
            top: 60px;
            right: 132px;

            &:hover {
                cursor: pointer;
            }

            @media (max-width: 1100px) {
                right: 70px;
            }

            @media (max-width: 950px) {
                right: 40px;
                top: 20px;
            }

            @media (max-width: 768px) {
                display: none;
            }
        }
        .signup-accrd-page-content {
            margin-top: 45px;

            @media (max-width: 950px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 45px;
            }

            @media (max-width: 800px) {
                margin-top: 35px;
            }

            @media (max-width: 375px) {
                margin-top: 0px;
                padding: 16px 20px 20px 20px;
            }

            .signin-accrd-form {
                margin-top: 32px;
                @media (max-width: 950px) {
                    width: 335px;
                }

                @media (max-width: 375px) {
                    margin-top: 25px;
                }
                .signup-accrd-page-title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    color: #031106;

                    @media (max-width: 950px) {
                        width: 335px;
                    }

                    @media (max-width: 375px) {
                        font-size: 16px;
                    }
                }

                .signup-accrd-page-subtitle {
                    max-width: 466px;
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                    opacity: 0.8;
                    margin-top: 8px;
                    margin-right: 10px;

                    @media (max-width: 950px) {
                        width: 335px;
                    }

                    @media (max-width: 375px) {
                        width: 326px;
                    }
                }
                .signup-accrd-choose-buttons-container {
                    margin-top: 25px;

                    @media (max-width: 375px) {
                        margin-top: 15px;
                    }
                    .signup-accrd-choose-button {
                        &.MuiButtonBase-root {
                            width: 71px;
                            height: 40px;
                            margin-right: 16px;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            letter-spacing: 0.02em;
                            text-transform: none;
                            color: #8f948f;
                            border: 2px solid #dddddd;

                            @media (max-width: 375px) {
                                width: 335px;
                                margin-right: 0px;
                                margin-top: 16px;

                                &:first-child {
                                    margin-top: 0px;
                                }
                            }

                            &.active {
                                width: 78px;

                                @media (max-width: 375px) {
                                    width: 335px;
                                }
                            }

                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }

            .auth-next-button {
                &.MuiButtonBase-root {
                    display: block;
                    width: 116px;
                    height: 39px;
                    margin-left: 0px;
                    margin-top: 23px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #ffffff;
                    border-radius: 4px;
                    text-transform: none;

                    &.desktop {
                        @media (max-width: 950px) {
                            display: none;
                        }
                    }

                    &.mobile {
                        display: none;
                        width: 335px;
                        height: 39px;
                        margin-top: 45px;

                        @media (max-width: 950px) {
                            display: block;
                        }

                        @media (max-width: 800px) {
                            margin-top: 63px;
                        }

                        @media (max-width: 375px) {
                            margin-top: 227px;
                        }
                    }
                }
            }
        }
    }
}
