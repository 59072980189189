.login-page-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #0EC22B;
    .login-page {
        margin-top: 127px;
        .login-page-title{
            color: white;
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;
            margin-bottom: 15px;
            @media (max-width:600px) {
                font-size: 22px;
                line-height: 26px;
                text-align: center;
            }
        }
        .forgotLink{
            margin-top: 23px;
            margin-bottom: 15px;
        }
        .login-page-content {
            width: 540px;
            @media (max-width:600px) {
                width: 100%;
            }
            background: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px 33px 33px;
            .login-page-form {
                width: 100%;
                display: flex;
                justify-content: center;
                padding-top: 10px;
            }

            .auth-next-button {
                &.MuiButtonBase-root {
                    display: block;
                    height: 54px;
                    margin-left: 0px;
                    margin-top: 24px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 21px;
                    text-align: center;
                    color: #ffffff;
                    border-radius: 4px;
                    text-transform: none;

                    &.desktop {
                        @media (max-width: 950px) {
                            display: none;
                        }
                    }

                    &.mobile {
                        display: none;
                        width: 100%;
                        height: 54px;

                        @media (max-width: 950px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
