.checkout-stages {
  width: 100%;
  background-color: #fafffb;
  padding: 56px 0 24px;
  border: 1px solid #99d2a2;
  display: flex;
  align-items: center;
  justify-content: center;
  .line {
    width: 340px;
    height: 2px;
    background-color: #0ec22b;
    position: relative;
    @media (max-width: 500px) {
      width: 240px;
    }
    @media (max-width: 350px) {
      width: 200px;
    }
  }
  .first {
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
  .third {
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
  .second {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .first,
  .second,
  .third {
    position: absolute;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    background-color: #fff;
    border: 1px solid #0ec22b;
    &.active {
      background-color: #0ec22b;
      .label {
        font-weight: 700;
      }
    }
    transition: background-color .3s linear;
    &:hover{
      cursor: pointer;
      background-color: #dbecdf;
    }
  }

  .label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;
    font-size: 14px;
    @media (max-width: 930px) {
      font-size: 12px;
    }
  }
}
