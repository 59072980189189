.exch-confirmation-modal {
    .MuiDialog-paper {
        padding: 40px;
        width: 445px;
        border-radius: 0;
    }
    .cancel-text{
        margin: 0;
        margin-bottom: 20px;
        max-width: 270px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
    }

    .close {
        position: absolute;
        top: -20px;
        right: -20px;
        z-index: 1000;
    }

    .confirmation {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 24px;
    }

    .content {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
        margin-bottom: 20px;

        .title {
            font-family: "Montserrat", sans-serif;
            font-size: 16px;
            color: #929292;
        }

        .value {
            font-family: "Montserrat", sans-serif;
            font-size: 16px;
            color: #333333;
            font-weight: 500;
            text-align: right;
        }
    }

    .buttons {
        width: 100%;
        text-align: right;

        button .MuiButton-label {
            font-family: "Montserrat", sans-serif;
            text-transform: none;
            font-weight: 500;
        }

        .buy {
            color: #fff;
            margin-left: 10px;
            padding: 7px 32px;
        }

        .cancel {
            padding: 8px 32px;
        }
    }
}