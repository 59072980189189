.finalContainer{
    width: 100%;
    background: #ffffff;
    border: 1px solid #ead0fe;
    padding: 35px 6% 50px 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title{
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 10px;
    }
    .subTitle{
        font-size: 18px;
        line-height: 28px;
        span{
            font-weight: 700;
        }
        .link{
            color: #8A00F7;
            text-decoration: none;
        }
    }
    .text{
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
        color: #8A00F7;
        margin-bottom: 20px;
    }
    .subText{
        text-align: center;
        margin-top: 36px;
        font-size: 18px;
        line-height: 28px;
        span{
            font-weight: 700;
        }
    }
    .completeBtn{
        width: 334px;
        height: 54px;
        background: #8a00f7;
        font-weight: bold;
        font-size: 18px;
        color: white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            margin-left: 10px;
        }
    }
    .roket{
        margin-top: 27px;
        animation: start 1.5s alternate ease-in-out infinite;
    }
    @keyframes start {
        0% {
            margin-top: 27px;
            margin-bottom: 0;
        }
        100% { 
            margin-top: 2px;
            margin-bottom: 25px;
        }
    }
}