.checkout-payment {
  padding-top: 70px;
  padding-bottom: 89px;
  min-height: 70vh;
  .container {
    max-width: 1144px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 34px;
    @media (max-width: 1200px) {
      padding: 20px;
    }
    @media (max-width: 1140px) {
      flex-direction: column;
    }
  }
  .copyIcon{
    transform: translateY(4px);
    padding: 4px 4px 3px;
    border: none;
    background-color: transparent;
    border-radius: 2px;
    transition: background-color .3s linear;
    &:hover{
      cursor: pointer;
      background-color: #0EC22B1A;
    }
    &:active{
      background-color: #0EC22B4D;
    }
    img{
      max-width: fit-content;
    }
  }
  .left {
    width: 71%;
    @media (max-width: 1140px) {
      width: 100%;
    }
    .head {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 6px;
      height: 28px;
      @media (max-width: 1140px) {
        text-align: center;
      }
    }
    .methods {
      display: flex;
      @media (max-width: 830px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
      @media (max-width: 435px) {
        display: flex;
        flex-direction: column;
      }
    }
    .method-item {
      display: flex;
      align-items: center;
      padding: 25px;
      margin-right: 15px;
      box-shadow: 0 4px 19px 0 rgba($color: #000000, $alpha: 0.1);
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      @media (max-width: 1050px) {
        padding: 18px 20px;
      }
      @media (max-width: 830px) {
        margin-right: 0;
        padding: 10px 20px 18px;
        width: initial;
      }
      @media (max-width: 400px) {
        margin-right: 0;
        padding: 10px 10px 18px;
      }
      .notifications-checkbox {
        min-width: initial;
      }
      .label {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 700;
        margin-left: -6px;
      }
    }
  }

  .right {
    margin-top: 33px;
    width: 26%;
    @media (max-width: 1140px) {
      width: 100%;
    }
    .wrapper {
      padding: 10px 21px 23px;
      background-color: #fcfcfc;
      border: 1px solid #b9c0c4;
      margin-bottom: 15px;
      .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .image {
        width: 100%;
        margin-bottom: 8px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .asset {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        .name {
          font-size: 14px;
          font-weight: 700;
        }
        .cost {
          font-size: 14px;
        }
      }
      .tokens {
        font-size: 14px;
        padding-bottom: 6px;
        border-bottom: 1px solid #b9c0c4;
      }
      .discount,
      .credits {
        color: #6d7584;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 14px 0 10px;
        border-bottom: 1px solid #b9c0c4;
      }
      .total {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        .title {
          font-size: 16px;
          font-weight: 700;
        }
        .amount {
          color: #0ec22b;
          font-size: 18px;
          font-weight: 700;
          .currency {
            font-weight: 400;
          }
        }
      }
      .verifyText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 24px;
        span{
          font-family: "Montserrat", sans-serif;
          font-weight: 700;
          font-size: 14px;
        }
        .link{
          font-family: "Montserrat", sans-serif;
          font-weight: 700;
          font-size: 14px;
          color: #F64A00;
          text-transform: uppercase;
          margin-top: 10px;
          &:visited{
            color: #F64A00;
          }
        }
      }
      .next {
        // margin-top: 72px;
        border: none;
        outline: none;
        background-color: #f64a00;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        padding: 12px 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;

        @media (max-width: 1000px) {
          padding: 10px 30px;
          margin-bottom: 10px;
        }

        @media (max-width: 768px) {
          margin-bottom: 20px;
          padding: 13px;
        }

        &:hover {
          background-color: #d63300;
          transition: all 0.2s ease-out;
        }

        &.disabled {
          background-color: rgba($color: #f64a00, $alpha: 0.2);
          cursor: initial;
        }

        img {
          margin-left: 8px;
        }
      }
    }
  }

  .options-container {
    width: 100%;

    .MuiRadio-colorSecondary.Mui-checked{
      color: #0ec22b;
    }
    .options {
    width: 100%;
      background-color: #fafffb;
      border: 1px solid #99d2a2;
      padding: 20px 44px;
      margin-bottom: 44px;
    }
    
    .next {
      // margin-top: 72px;
      background-color: #f64a00;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      max-width: 335px;
      padding: 12px 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease-out;
      border-radius: 0;

      @media (max-width: 1000px) {
        padding: 10px 30px;
        margin-bottom: 10px;
      }

      @media (max-width: 768px) {
        margin-bottom: 20px;
        padding: 13px;
      }

      &:hover {
        background-color: #d63300;
        transition: all 0.2s ease-out;
      }

      &.disabled {
        background-color: rgba($color: #f64a00, $alpha: 0.2);
        cursor: initial;
      }

      img {
        margin-left: 8px;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 22px;
      color: #000;
    }
  }
}
.checkout-wire-wrapper{
  padding: 70px 15px 89px;
  max-width: 1144px;
  width: 100%;
  margin: 0 auto;
  min-height: 70vh;
}
.checkout-wire {
  .divider{
    border-bottom: 3px solid #8A00F6;
    margin: 33px 0;
    max-width: 220px;
  }
  .title {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 23px;
  }
  .descr {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-left: 0;
    ul{
      padding-left: 20px;
      li{
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .link{
      color: #0ec22b;
      &:visited{
        color: #0ec22b;
      }
    }
  }
  .fields {
    background-color: #fafffb;
    border: 1px solid #99d2a2;
    padding: 52px 26px 26px;
    .downloadPdfBtn{
      background-color: inherit;
      padding: 16px 42px;
      color: #f64a00;
      font-weight: 700;
      font-size: 18px;
      outline: none;
      border: 1px solid #f64a00;
      transition: background-color .3s linear;
      &:hover{
        cursor: pointer;
        background-color: #f64a0013;
      }
    }
  }
  .field-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
    @media (max-width: 673px) {
      flex-direction: column;

    }
    .title {
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      margin: 0;
      margin-right: 18px;
      font-weight: 400;
      text-transform: uppercase;
      width: 30%;
      text-align: right;
      margin-top: 3px;
      @media (max-width: 744px) {
        font-size: 14px;
      }
      @media (max-width: 673px) {
        width: 100%;
        text-align: left;
      }
    }
    .info {
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      width: 70%;
      display: block;
      @media (max-width: 744px) {
        font-size: 14px;
      }
      @media (max-width: 673px) {
        width: 100%;
      }
    }
  }
}

.checkout-check {
  .descr {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .fields {
    background-color: #fafffb;
    border: 1px solid #99d2a2;
    padding: 20px 26px 26px;
  }
  .field-item {
    display: flex;
    margin-bottom: 15px;
    .title {
      font-size: 14px;
      margin-right: 18px;
      font-weight: 700;
      text-transform: uppercase;
      width: 30%;
      text-align: right;
    }
    .info {
      font-size: 14px;
      text-transform: uppercase;
      width: 45%;
    }
  }
}
.checkout-crypto-wrapper {
  margin-top: 24px;
  .title {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 23px;
  }
  .descr {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-left: 0;
    ul{
      padding-left: 20px;
      li{
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .link{
      color: #0ec22b;
      &:visited{
        color: #0ec22b;
      }
    }
  }
  .checkout-crypto {
    padding: 20px 25px 31px;
    border: 1px solid #99d2a2;
    background: #fafffb;
    .title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 21px;
      margin-top: 0;
      font-family: "Montserrat", sans-serif;
    }
    .methods {
      margin-bottom: 15px;
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
      }
      .method-item {
        font-family: "Montserrat", sans-serif;
        padding: 15px;
        font-size: 18px;
        font-weight: 700;
        border: 1px solid transparent;
        background: white;
        min-width: 133px;
        &.active {
          background-color: white;
          border-color: #99d2a2;
          box-shadow: none;
        }
        img {
          margin-right: 8px;
          width: 40px;
        }
      }
    }
    .qrBox{
      display: flex;
      align-items: center;
      margin-top: 10px;
      @media (max-width: 543px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .qr-right{
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        @media (max-width: 543px) {
          margin-left: 0;
        }
        .qr-title{
          font-family: "Montserrat", sans-serif;
          display: block;
          font-size: 14px;
          font-weight: 700;
          margin-top: 7px;
        }
        .qr-wallet{
          font-family: "Montserrat", sans-serif;
          background: white;
          border: 1px solid #99D2A2;
          word-break: break-all;
          padding: 13px 15px;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }    
    .qr-image {
      width: 150px;
      height: 150px;
      border: 1px solid #e5e5e5;
      background: white;
      padding: 10px;
      display: block;
      @media (max-width: 543px) {
        width: 90%;
        height: 243px;
      }
    }
  }
}
.gradient{
  background: linear-gradient(to bottom, #bfee92 0%, #ffffff 160px);
  .images{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 15px;
  }
  .checkout-payment-title{
    margin-top: 74px;
    font-weight: 700;
    color: #0ec22b;
    font-size: 30px;
    line-height: 45px;
  }
  .checkout-payment-descr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:900px) {
      flex-direction: column;
      align-items: flex-start;
    }
    p{
      font-weight: 700;
      font-size: 20px;
      line-height: 31px;
    }
    .link{
      color: #0ec22b;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      &:visited{
        color: #0ec22b;
      }
    }
  }
}
