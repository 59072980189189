.drop-file-container {
    max-width: 272px;
    @media(max-width: 768px) {
        max-width: initial;
        width: 100%;
    }
}

.drop-file {
    align-items: center;
    justify-content: center;
    padding: 20px 24px;
    border: 2px dashed #ddd;
    border-radius: 4px;
    outline: none;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    span {
        font-size: 14px;
        color: #909090;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .drop-span {
        display: inline-block;
        max-width: 200px;
    }

    .drop-button {

        background-color: transparent;
        outline: none;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        padding: 4px 10px;
        cursor: pointer;

    }

    .image-container {
        width: 85px;
        margin-right: 10px;
        position: relative;
        img {
            width: 100%;
            margin-top: 10px;
        }
        .close-icon{
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 14px;
            height: 14px;
        }
    }

    .add-more-files {
        font-size: 14px;
        font-weight: 600;

    }

    .preview-container {

        .image-name {
            -webkit-line-clamp: 1;
        }
    }
}

.files-added {
    flex-direction: row;
    justify-content: flex-start;
    @media(max-width: 768px){
        justify-content: center !important;
    }
}