.asset-highlights {
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;

  .title {
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
    margin-bottom: 4px;

    @media(max-width: 768px) {
      font-size: 24px;
    }
  }

  .highlights-list {
    padding-left: 20px;
  }

  .highlight-item {
    font-size: 18px;
    line-height: 40px;
    color: #6d7584;

    @media(max-width: 768px) {
      font-size: 16px;
    }
  }
}